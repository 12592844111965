import React, { useState, useEffect, useContext, useRef } from "react";
import { Table, Table2 } from "../teacherPages/CheckSubject";
import { useNavigate, useLocation } from "react-router-dom";
import { BackButton } from "../studentPages/PersonalData";
import Button from "../../components/Button/Button";
import { adminContext } from "../context/adminContext";
import ClipLoader from "react-spinners/ClipLoader";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { MyButton, Button2 } from "../../components/Button/Button";
import { toast } from "react-hot-toast";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import axios from "axios";
import Logo from "../../images/logo.png";
import Popup from "../../components/Popup/Popup";
import "./SettingsPreviewResult.css";

// src/images/logo.png

export default function SettingsPreviewResult() {
  const admin_id = localStorage.getItem("adminId");
  const location = useLocation();
  const result = location.state.result;
  const session = location.state.session;
  const is_validated = location.state.is_validated;
  const tempAdminToken = location.state.tempAdminToken;
  const student_img_url = location.state.student_img_url;
  const next_term_resumption_date = location.state.next_term_resumption_date;

  const navigate = useNavigate();
  const values = useContext(adminContext);
  const { teacherData, admintoken } = values;
  const adminName = localStorage.getItem("name");

  const traits_style = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 16,
  };

  const result_summary_style = {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "flex-start",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 10,
  };
  function addThousandDelimiter(amount) {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const showCustomToast = (type, message) => {
    if (type == 0) {
      toast.error(message, {
        icon: "❗️",
        position: "bottom-center",
      });
    } else {
      toast.success(message, {
        position: "bottom-center",
      });
    }
  };

  const tableValues = [
    { data: "S/N", center: false },
    { data: "SUBJECTS", center: false },
    { data: "1ST C.A.T (20)", center: true },
    { data: "2ND C.A.T (20)", center: true },
    { data: "EXAM (60)", center: true },
    { data: "TOTAL", center: true },
    { data: "GRADE", center: true },
    { data: "REMARK", center: true },
  ];

  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (document.documentElement.clientWidth <= 800) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const pdfRef = useRef();

  const downloadAndUploadPDF = () => {
    const currentDateTime = new Date();

    const input = pdfRef.current;
    html2canvas(input, {
      letterRendering: 1,
      allowTaint: false,
      useCORS: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 2;
      // const imgY = 30;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );

      const bodyFormData = new FormData();
      bodyFormData.append("token", tempAdminToken);
      const pdfBlob = pdf.output("blob");
      bodyFormData.append(
        "pdf",
        pdfBlob,
        `${result.student_name}_result_${currentDateTime.toLocaleString()}.pdf`
      );
      // for (const pair of bodyFormData.entries()) {
      //   console.log(pair[0] + ', ' + pair[1]);
      // }
      toast.error("Thanks for your patience...", {
        icon: "⏳️",
        position: "bottom-center",
      });
      toast.error("Almost ready...", {
        icon: "⏳️",
        position: "bottom-center",
      });

      axios
        .post(
          "https://server-olhms.onrender.com/admin_result/upload_validated_result",
          bodyFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )

        .then(async (response) => {
          setGenerating_result(false);

          if (response.status === 200) {
            try {
              const res = await fetch(
                "https://server-olhms.onrender.com/admin_result/update_validated_result_url",
                {
                  method: "POST",
                  body: JSON.stringify({
                    token: tempAdminToken,
                    result_id: result._id,
                    validated_result_url: response.data.pdf_url,
                    validated_result_url_id: response.data.pdf_id,
                  }),
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json, text/plain, */*",
                  },
                }
              );
              const data = await res.json();
              setGenerating_result(false);

              if (data.msg == "success") {
                showCustomToast(
                  1,
                  "Result successfully validated and delivered"
                );
                pdf.save(
                  `${
                    result.student_name
                  }_result_${currentDateTime.toLocaleString()}.pdf`
                );
              } else {
                showCustomToast(
                  0,
                  "An error occured while sending result to student"
                );
              }
            } catch (error) {
              showCustomToast(
                0,
                "It seems an error occured while sending result to student"
              );
              setGenerating_result(false);
            }
          } else {
            showCustomToast(
              0,
              "oOps! An error occured while sending result to student"
            );
          }
          console.log(response.data);
        })
        .catch((error) => {
          setGenerating_result(false);

          showCustomToast(
            0,
            "Sorry, the operation failed. Please go back and try again"
          );
        });
    });
  };

  const [generating_result, setGenerating_result] = useState(true);
  // const [generating_result, setGenerating_result] = useState(false);
  useEffect(() => {
    downloadAndUploadPDF();
  }, []);

  return (
    <div
      className={mobile ? "" : "set-questions"}
      // style={mobile ? { margin: 24 } : {}}
    >
      <div>
        <BackButton
          click={() => {
            // downloadPDF();
            navigate(-1);
            navigate(-1);
          }}
        />

        <div
          ref={pdfRef}
          style={{
            // border: "1px solid #000",
            padding: "10px",
            margin: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img
              style={{
                objectFit: "cover",
                height: "150px",
                width: "150px",
                borderStyle: "solid",
                borderColor: "#a5a2a0",
                borderWidth: "0.6px",
              }}
              src={Logo}
              alt="logo"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "space-between",
                alignItems: "center",
                margin: "0 50px",
                padding: "15px",
                // boxSizing:"border-box"
              }}
            >
              <p
                style={{
                  fontSize: "28px",
                  fontWeight: "bold",
                  color: "black",
                  marginBottom: "15px",
                }}
              >
                OUR LADY OF HOPE MONTESSORI SCHOOLS
              </p>
              <p
                style={{
                  color: "#484442",
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                }}
              >
                Emerald Centre: 131 2nd East Circular Road by Wisdom Street
                Junction, Benin City. Nigeria.
                <br />
                Topaz Centre: 41, Agbonwonba Street off MM way by Ogiso
                junction, Benin City. Nigeria.
              </p>
              <div>
                <span
                  style={{
                    color: "#484442",
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                  }}
                >
                  Email:{" "}
                </span>
                <span
                  style={{
                    color: "#e05616",
                    textDecoration: "underline",
                    fontSize: "1.2rem",
                  }}
                >
                  ourladyofhopemontessorischools@gmail.com
                </span>
                <span
                  style={{
                    marginLeft: "40px",
                    color: "#484442",
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                  }}
                >
                  Website:{" "}
                </span>
                <span
                  style={{
                    color: "#e05616",
                    textDecoration: "underline",
                    fontSize: "1.2rem",
                  }}
                >
                  ourladyofhopemontessorischools.com
                </span>
              </div>
              <p
                style={{
                  color: "#484442",
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                }}
              >
                Phone lines: +2348151707292, +2348140974206, +2348134082834
              </p>
            </div>
            <div
              style={{
                height: "60px",
                width: "60px",
                backgroundColor: "transparent",
              }}
            ></div>
          </div>

          {/* main body */}

          <div className="prev-section1-container">
            <div className="prev-section1-left">
              <img src={student_img_url} alt="passport" />
              <div>
                <p className="preview-profile-text-style">
                  Name: {result.student_name}
                </p>
                <p className="preview-profile-text-style">
                  Student ID: {result.student_id}
                </p>
                <p className="preview-profile-text-style">
                  Class: {result.class_name}
                </p>
                <p className="preview-profile-text-style">
                  Term: {result.term}
                </p>
                <p className="preview-profile-text-style">
                  Session: {result.session}
                </p>
                <p className="preview-profile-text-style">
                  Validated By: {result.is_validated_by}
                </p>
              </div>
            </div>

            <div className="prev-section1-right">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    marginTop: 16,
                    marginBottom: 5,
                    // borderBottom: "1px solid #000",
                    textDecoration: "underline",
                  }}
                >
                  RESULT SUMMARY
                </p>

                <div style={result_summary_style}>
                  <p className="preview-text-style">{result.term}'s Total:</p>
                  <p className="preview-text-style" style={{ marginRight: 60 }}>
                    {result.total_score}/{result.results.length * 100}
                  </p>
                </div>

                <div style={result_summary_style}>
                  <p className="preview-text-style">Term's Average:</p>
                  <p className="preview-text-style" style={{ marginRight: 60 }}>
                    {/* {result.average.toFixed(1)} */}
                    {result.average !== undefined
                      ? result.average.toFixed(1)
                      : "N/A"}
                  </p>
                </div>
                {/* <div style={result_summary_style}>
                  <p className="preview-text-style">No of Subjects passed:</p>
                  <p className="preview-text-style" style={{ marginRight: 60 }}>
                    {result.no_of_subjects_passed}
                  </p>
                </div>
                <div style={result_summary_style}>
                  <p className="preview-text-style">No of subjects failed:</p>
                  <p className="preview-text-style" style={{ marginRight: 60 }}>
                    {result.no_of_subjects_failed ?? 0}
                  </p>
                </div> */}
                <div style={result_summary_style}>
                  <p className="preview-text-style">Attendance score:</p>
                  <p className="preview-text-style" style={{ marginRight: 60 }}>
                    {result.attendance_score}
                  </p>
                </div>
                <div style={result_summary_style}>
                  <p className="preview-text-style">Remark:</p>
                  <p className="preview-text-style" style={{ marginRight: 60 }}>
                    {result.overall_remark}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="word-row" style={{ marginTop: "20px" }}>
            <span style={{ fontWeight: "500", fontSize: "1.2rem" }}>
              Interpretation of grades
            </span>
            <span>100(A) Distinction</span>
            <span>70-99(A) Excellent</span>
            <span>60-69(B) Very Good</span>
            <span>50-59(C) Good</span>
            <span>40-49(D) Fair</span>
            <span>≤39(F) Fail</span>
          </div>

          <div style={{ padding: "20px" }}>
            <p
              className="preview-main-title"
              style={{ paddingBottom: "15px", marginTop: "30px" }}
            >
              A. COGNITIVE REPORT
            </p>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "5px",
              }}
            >
              {/* <Table2
                    columns="0.5fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr"
                    data={tableValues}
                  /> */}
              <div className="grid-container">
                {tableValues.map((item, index) => (
                  <div
                    key={index}
                    className="grid-item"
                    style={{
                      textAlign: item.center === true ? "center" : "",
                    }}
                  >
                    {item.data}
                  </div>
                ))}
              </div>

              {result.results.map((res, index) => (
                <div key={index} className="grid-container">
                  <div className="grid-item">{index + 1}</div>
                  <div className="grid-item">{res.subject}</div>
                  <div className="grid-item centered-text">
                    {res.first_ca_obj + res.first_ca_theory}
                  </div>
                  <div className="grid-item centered-text">
                    {res.second_ca_obj + res.second_ca_theory}
                  </div>
                  <div className="grid-item centered-text">
                    {res.exam_obj + res.exam_theory}
                  </div>
                  <div className="grid-item centered-text">{res.total}</div>
                  <div className="grid-item centered-text">{res.grade}</div>
                  <div className="grid-item centered-text">{res.remark}</div>
                </div>
              ))}
            </div>
          </div>

          <div className="prev-section1-container">
            <div className="prev-section1-left">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 16,
                }}
              >
                <p className="preview-main-title">B. AFFECTIVE TRAIT</p>

                <div style={traits_style}>
                  <p className="preview-text-style">General Conduct:</p>
                  <p
                    style={{
                      marginLeft: "60px",
                      fontWeight: 400,
                      fontSize: "1.2rem",
                    }}
                  >
                    {result.affective_traits["general_conduct"]}
                  </p>
                </div>

                <div style={traits_style}>
                  <p className="preview-text-style">Assignments:</p>
                  <p
                    style={{
                      marginLeft: "60px",
                      fontWeight: 400,
                      fontSize: "1.2rem",
                    }}
                  >
                    {result.affective_traits["assignments"]}
                  </p>
                </div>

                <div style={traits_style}>
                  <p className="preview-text-style">Class Participation:</p>
                  <p
                    style={{
                      marginLeft: "60px",
                      fontWeight: 400,
                      fontSize: "1.2rem",
                    }}
                  >
                    {result.affective_traits["class_participation"]}
                  </p>
                </div>

                <div style={traits_style}>
                  <p className="preview-text-style">Finishes work on time:</p>{" "}
                  <p
                    style={{
                      marginLeft: "60px",
                      fontWeight: 400,
                      fontSize: "1.2rem",
                    }}
                  >
                    {result.affective_traits["finishes_work_on_time"]}
                  </p>
                </div>

                <div style={traits_style}>
                  <p className="preview-text-style">Takes care of materials:</p>
                  <p
                    style={{
                      marginLeft: "60px",
                      fontWeight: 400,
                      fontSize: "1.2rem",
                    }}
                  >
                    {result.affective_traits["takes_care_of_materials"]}
                  </p>
                </div>

                <div style={traits_style}>
                  <p className="preview-text-style">Working independently:</p>
                  <p
                    style={{
                      marginLeft: "60px",
                      fontWeight: 400,
                      fontSize: "1.2rem",
                    }}
                  >
                    {result.affective_traits["working_independently"]}
                  </p>
                </div>
              </div>
            </div>

            <div className="prev-section1-right">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 16,
                }}
              >
                <p className="preview-main-title">C. PSYCHO-MOTIVE TRAIT</p>

                <div style={traits_style}>
                  <p className="preview-text-style">Regularity:</p>
                  <p
                    style={{
                      marginRight: 60,
                      fontWeight: 400,
                      fontSize: "1.2rem",
                    }}
                  >
                    {result.psycho_motive_traits["regularity"]}
                  </p>
                </div>

                <div style={traits_style}>
                  <p className="preview-text-style">Personal cleanliness:</p>
                  <p
                    style={{
                      marginRight: 60,
                      fontWeight: 400,
                      fontSize: "1.2rem",
                    }}
                  >
                    {result.psycho_motive_traits["personal_cleanliness"]}
                  </p>
                </div>

                <div style={traits_style}>
                  <p className="preview-text-style">Punctuality:</p>
                  <p
                    style={{
                      marginRight: 60,
                      fontWeight: 400,
                      fontSize: "1.2rem",
                    }}
                  >
                    {result.psycho_motive_traits["punctuality"]}
                  </p>
                </div>

                <div style={traits_style}>
                  <p className="preview-text-style">Completion of work:</p>
                  <p
                    style={{
                      marginRight: 60,
                      fontWeight: 400,
                      fontSize: "1.2rem",
                    }}
                  >
                    {result.psycho_motive_traits["completion_of_work"]}
                  </p>
                </div>

                <div style={traits_style}>
                  <p className="preview-text-style">Following instructions:</p>
                  <p
                    style={{
                      marginRight: 60,
                      fontWeight: 400,
                      fontSize: "1.2rem",
                    }}
                  >
                    {result.psycho_motive_traits["following_instructions"]}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            className="prev-section1-container"
            style={{ borderTop: "none" }}
          >
            <div
              className="prev-section1-left"
              style={{ padding: "30px 10px" }}
            >
              <div className="preview-column">
                <p className="preview-main-title">
                  CLASS TEACHER: {result.teachers_name}
                </p>
                <p style={{ fontWeight: 500, fontSize: "1.2rem" }}>
                  REMARK:
                  <span style={{ fontWeight: 400, fontSize: "1.2rem" }}>
                    {result.teachers_remark}
                  </span>
                </p>
              </div>
            </div>

            <div
              className="prev-section1-right"
              style={{ padding: "30px 10px" }}
            >
              <div className="preview-column">
                <p className="preview-main-title">
                  HEAD TEACHER: {result.is_validated_by}
                </p>
                <p style={{ fontWeight: 500, fontSize: "1.2rem" }}>
                  REMARK:
                  <span style={{ fontWeight: 400, fontSize: "1.2rem" }}>
                    {result.head_teachers_remark}
                  </span>
                </p>
              </div>
            </div>
          </div>

          {/* LAST */}

          <div className="preview-row-container" style={{ padding: "20px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "20vw",
                // backgroundColor: "grey",
              }}
            >
              <p
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  marginTop: 16,
                  marginBottom: 5,
                  textDecoration: "underline",
                }}
              >
                Next Term's Information
              </p>

              <div style={result_summary_style}>
                <p className="preview-text-style">Resumption Date:</p>
                <p className="preview-text-style">
                  {next_term_resumption_date}
                </p>
              </div>
              <div style={result_summary_style}>
                <p className="preview-text-style">Next Term Fee:</p>
                <p className="preview-text-style">
                  ₦{addThousandDelimiter(result.next_term_fee)}
                </p>
              </div>
              <div style={result_summary_style}>
                <p className="preview-text-style">Other Fee:</p>
                <p className="preview-text-style">
                  ₦{addThousandDelimiter(result.other_fees)}
                </p>
              </div>
              <div style={result_summary_style}>
                <p className="preview-text-style">Total Fee:</p>
                <p className="preview-text-style">
                  ₦{addThousandDelimiter(result.total_fees)}
                </p>
              </div>
            </div>

            <p
              style={{
                fontSize: "20px",
                paddingTop: "10px",
                fontWeight: "bold",
                color: result.verdict == "REPEATED" ? "red" : "green",
              }}
            >
              {result.verdict}
            </p>
          </div>
        </div>
        <Popup isOpen={generating_result}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <ClipLoader color={"orange"} size={60} />
            <p style={{ textAlign: "center" }}>
              Please hold on briefly as we generate and
              <br />
              deliver the child's results.
            </p>
          </div>
        </Popup>
      </div>
    </div>
  );
}
