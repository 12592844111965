import React, {useContext} from "react";
import "../NavBar/nav.css";
import Logo from "../../images/logo.png";
import Small from "../../images/responsive.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import AboutImg from "../../images/shola.png";
import Avatar from "../../images/avatar.png";

import "./loggednav.css";

import { adminContext } from "../../pages/context/adminContext";

export default function NavBar() {
  const [burgerState, setBurgerState] = useState("inactive-burger");

  const handleClick = () => {
    burgerState === "inactive-burger"
      ? setBurgerState("active-burger")
      : setBurgerState("inactive-burger");
  };

  const handleInactive = () => {
    setBurgerState("inactive-burger");
  };
  return (
    <nav className="main-nav">
      <div className="split-nav-logo">
        <div className="small-logo">
          <img src={Small} alt="OLHMS LOGO" />
        </div>
        <div className="split-logo-text">
          <p>Our Lady of Hope</p>
          <p className="top-p">Montessori Schools</p>
        </div>
      </div>
      <ul className="navbar" id={burgerState}>
        <li className="nav-li" onClick={handleInactive}>
          <NavLink
            to="/"
            style={({ isActive }) => {
              return {
                background: isActive ? "white" : "",
                borderRadius: "5px",
                color: isActive ? "#e05616" : "white",
                padding: "0px 5px 2px 5px",
              };
            }}
          >
            Home
          </NavLink>
        </li>
        <li className="nav-li" onClick={handleInactive}>
          <NavLink
            to="admission"
            style={({ isActive }) => {
              return {
                background: isActive ? "white" : "",
                borderRadius: "5px",
                color: isActive ? "#e05616" : "white",
                padding: "0px 5px 2px 5px",
              };
            }}
          >
            {" "}
            Admissions
          </NavLink>
        </li>
        <li className="nav-li" onClick={handleInactive}>
          <NavLink
            // to="/news"
            style={({ isActive }) => {
              return {
                background: isActive ? "white" : "",
                borderRadius: "5px",
                color: isActive ? "#e05616" : "white",
                padding: "0px 5px 2px 5px",
              };
            }}
          >
            News & Events
          </NavLink>
        </li>
        <li className="nav-li" onClick={handleInactive}>
          <NavLink
            to="/gallery"
            style={({ isActive }) => {
              return {
                background: isActive ? "white" : "",
                borderRadius: "5px",
                color: isActive ? "#e05616" : "white",
                padding: "0px 5px 2px 5px",
              };
            }}
          >
            Gallery
          </NavLink>
        </li>
        <li className="nav-li" onClick={handleInactive}>
          <NavLink
            to="/blog"
            style={({ isActive }) => {
              return {
                background: isActive ? "white" : "",
                borderRadius: "5px",
                color: isActive ? "#e05616" : "white",
                padding: "0px 5px 2px 5px",
              };
            }}
          >
            Blog
          </NavLink>
        </li>
        <li className="nav-li" onClick={handleInactive}>
          <NavLink
            to="/about"
            style={({ isActive }) => {
              return {
                background: isActive ? "white" : "",
                borderRadius: "5px",
                color: isActive ? "#e05616" : "white",
                padding: "0px 5px 2px 5px",
              };
            }}
          >
            About Us
          </NavLink>
        </li>
        <li className="nav-li" onClick={handleInactive}>
          <NavLink
            to="/contact"
            style={({ isActive }) => {
              return {
                background: isActive ? "white" : "",
                borderRadius: "5px",
                color: isActive ? "#e05616" : "white",
                padding: "0px 5px 2px 5px"
              };
            }}
          >
            Contact Us
          </NavLink>
        </li>
        <li className="nav-li" onClick={handleInactive}>
          <NavLink
            to="/portal"
            style={({ isActive }) => {
              return {
                background: isActive ? "white" : "",
                borderRadius: "5px",
                color: isActive ? "#e05616" : "white",
                padding: "0px 5px 2px 5px",
              };
            }}
          >
            Portal
          </NavLink>
        </li>
        <div className="logo-div">
          <NavLink
            to="/"
            // style={({ isActive }) => {
            //   return {
            //     background: isActive ? "white" : "",
            //     borderRadius: "5px",
            //     color: "#e05616",
            //     padding: "0px 5px 2px 5px",
            //   };
            // }}
          >
            <img className="nav-logo" src={Logo} alt="OLHMS LOGO" />
          </NavLink>
        </div>
      </ul>
      <div className="hamburger" id={burgerState} onClick={handleClick}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </nav>
  );
}

export function LoggedNavBar() {

  // const userValues = useContext(adminContext);

  // const {
  //   handleLogout, 
  // } = userValues;

  const [smallNav, setSmallNav] = useState(false);
  const navigate = useNavigate();

  let img = localStorage.getItem("img");
  let name = localStorage.getItem("name");

  useEffect(() => {
    function handleResize() {
      if (document.documentElement.clientWidth <= 800) {
        setSmallNav(true);
      } else {
        setSmallNav(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      {smallNav ? (
        <nav className="main-nav">
          <div className="split-small-logo">
          <div className="profile-img">
                <img src={img} alt="" placeholder={Avatar} style={{ objectFit: "cover"}}/>
              </div>
              <span className="actual-link" style={{color: 'white'}}>{name}</span>{" "}
              <span>|</span>{" "}
          </div>
          <ul> 
            <NavLink style={{textDecoration: 'none'}} to="/">
            {/* <NavLink style={{textDecoration: 'none'}} to="/" onClick={() => handleLogout}> */}
            <span className="actual-link">Log Out & Return to Home Page</span>
            </NavLink>
            
            {/* <NavLink style={{textDecoration: 'none'}} to="/">
            <span className="actual-link" style={{ color: "white" }}>
              Log Out
            </span>
            </NavLink> */}
            
          </ul>
          {/* <div className="hamburger" id={burgerState} onClick={handleClick}>
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div> */}
        </nav>
      ) : (
        <nav className="logged-nav">
          <ul className="logged-navbar">
            <div className="logged-profile">
              <div className="profile-img">
                <img src={img} alt="" placeholder={Avatar} style={{ objectFit: "cover"}}/>
              </div>
              <span className="actual-link">{name}</span>{" "}
              <span>|</span>{" "} 
            <NavLink style={{textDecoration: 'none'}} to="/">
            {/* <NavLink style={{textDecoration: 'none'}} to="/" onClick={() => handleLogout}> */}
              <span className="actual-link">Log Out & Return to Home Page</span>
              </NavLink>
            </div>
            <div className="logo-div">
              <NavLink to="/">
                <img className="nav-logo" src={Logo} alt="OLHMS LOGO" />
              </NavLink>
            </div>
          </ul>
        </nav>
      )}
    </>
  );
}

export function TeacherNavBar(props) {


  // const userValues = useContext(adminContext);

  // const {
  //   handleLogout, 
  // } = userValues;

  const [burgerState, setBurgerState] = useState("inactive-burger");

  const handleClick = () => {
    burgerState === "inactive-burger"
      ? setBurgerState("active-burger")
      : setBurgerState("inactive-burger");
      if(burgerState === "inactive-burger"){
        props.hamburgerPressed(false);
      }else{
        props.hamburgerPressed(true);
      }
  };

  const handleInactive = () => {
    setBurgerState("inactive-burger");
  };

  const [smallNav, setSmallNav] = useState(false);
  const navigate = useNavigate();

  let img = localStorage.getItem("img");
  let name = localStorage.getItem("name");

  useEffect(() => {
    function handleResize() {
      if (document.documentElement.clientWidth <= 800) {
        setSmallNav(true);
        props.hamburgerPressed(true);
        setBurgerState("inactive-burger")
      } else {
        setSmallNav(false);
        props.hamburgerPressed(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
    { smallNav ? (
        <nav className="main-nav">
          <div className="split-small-logo">
            <div className="hamburger" id={burgerState} onClick={handleClick}>
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </div>
          <div className="profile-img">
                <img src={props.img || img} alt="" placeholder={Avatar} style={{ objectFit: "cover"}}/>
              </div>
              <span className="actual-link" style={{color: 'white'}}>{props.name}</span>{" "}
              <span>|</span>{" "}
          </div>
          <ul>
            <NavLink style={{textDecoration: 'none'}} to="/">
            {/* <NavLink style={{textDecoration: 'none'}} to="/" onClick={() => handleLogout}> */}
            <span className="actual-link">Log Out & Return Home</span>
            </NavLink>
            
            {/* <NavLink style={{textDecoration: 'none'}} to="/">
            <span className="actual-link" style={{ color: "white" }}>
              Log Out
            </span>
            </NavLink> */}
            
          </ul>
          {/* <div className="hamburger" id={burgerState} onClick={handleClick}>
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div> */}
        </nav>
      ) :
    <nav className="logged-nav">
      {/* <img className="small-logo" src={Small} alt="OLHMS LOGO" /> */}
      <ul className="logged-navbar">
        <div className="logo-div">
          <NavLink to="/">
            <img className="nav-logo" src={Logo} alt="OLHMS LOGO" />
          </NavLink>
        </div>
        <div className="logged-profile">
          <div className="profile-img">
          <img src={props.img || img}/>
          </div>
          <span className="actual-link">{props.name}</span> <span>|</span>{" "}
            <NavLink style={{textDecoration: 'none'}} to="/">
            {/* <NavLink style={{textDecoration: 'none'}} to="/" onClick={() => handleLogout}> */}
            <span className="actual-link">Log Out & Return to Home Page</span>
          </NavLink>
        </div>
      </ul>
    </nav>}
    </>
  );
}
