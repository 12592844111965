import React, { useState, useRef, useContext, useEffect } from "react";
import video from "../../images/video.png";
import novideo from "../../images/novideo.png";
import nonote from "../../images/nonote.png";
import attach from "../../images/attach.png";
import pdf from "../../images/pdf.png";
import Edit from "../../images/edit.png";
import Add from "../../images/addition.png";
import Cancel from "../../images/cancel.png";
import vector from "../../images/Vector.png";
import { useNavigate, useLocation } from "react-router-dom";
import "../teacherPages/resources.css";
import { Triple, Triple2 } from "../teacherPages/ResultTeacher";
import { SubjectComponent } from "../teacherPages/FirstCat";
import { adminContext } from "../context/adminContext";
import { BackButton } from "../studentPages/PersonalData";
import Button from "../../components/Button/Button";
import { Button2 } from "../../components/Button/Button";
import Popup from "../../components/Popup/Popup";
import "./addgallery.css";
import axios from "axios";

export default function SettingCenter() {
    const navigate = useNavigate();
  const location = useLocation();

  const center = location.state.center;
  const className = location.state.className;
  const class_key = location.state.class_key;
  const overall_class_key = location.state.overall_class_key;
  const count = location.state.count;
  const tempAdminToken = location.state.tempAdminToken;

  const [editSub, setEditSub] = useState(false);
  const [subject, setSubject] = useState(false);
  const [loadsubject, setLoadSubject] = useState(true);
  const [deletable, setDeletable] = useState(false);
  const [subId, setSubId] = useState("");
  const [bookId, setBookId] = useState("");
  const [realClass, setRealClass] = useState(class_key);
  const [overallClass, setOverallClass] = useState(overall_class_key);
  const [formData, setFormData] = useState({
    booktitle: "",
    bookauthor: "",
    aboutbook: "",
  });

  const [books, setBooks] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedSrc, setSelectedSrc] = useState("");
  const [editBook, setEditBook] = useState(false);
  const [reader, setReader] = useState(false);
  const [classList, setClassList] = useState(false);
  const [loadbooks, setLoadBooks] = useState(true);

  const [pdfFile, setPdfFile] = useState(null);
  const handleReader = (e) => {
    const target = e.target;

    const { name, value } = target;
    setFormData({ ...formData, [name]: value });
    // console.log(formData);
  };

  // console.log(class_key);


  const [loading, setLoading] = useState(true);
  const [subjects, setSubjects] = useState([]);
  const [toggle, setToggle] = useState("subjects");
  // const videoRef = useRef(null);

  const values = useContext(adminContext);
  const { teacherData, teachertoken } = values;
  const dataT = JSON.parse(teacherData);
  const columns = 2;
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const colors = [
    "#AAD7F7",
    "#BDECCA",
    "#FBECA1",
    "#F8D6C7",
    "#DAD4F6",
    "FFC0CB",
    "#C4A484",
    "#D3D3D3",
    "#AAD7F7",
    "#BDECCA",
    "#FBECA1",
    "#F8D6C7",
    "#DAD4F6",
    "FFC0CB",
    "#C4A484",
    "#D3D3D3",
  ];

  const [subName, setSubName] = useState("");

  const handleSub = (e) => {
    setSubName(e.target.value);
  };
  

  const cancelDelete = (e) => {
    setDeletable(false);
    setEditSub(false);
    setEditBook(false);
  };

  const deleteSub = async (e) => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_subject/delete_subject",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: tempAdminToken,
          subject_id: subId,
        }),
      }
    );
    viewSub();
    setDeletable(false);
  };

  const deleteBook = async (e) => {
    // console.log(bookId);
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_book/delete_book",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: tempAdminToken,
          book_id: bookId,
        }),
      }
    );

    viewBooks();
    setDeletable(false);
  };

  const viewBooks = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_book/view_books",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: tempAdminToken,
          class_name: realClass.toLowerCase(),
        }),
      }
    );
    const data = await res.json();
    setLoadBooks(false);
    setBooks(data?.books);
    // console.log(data);
    // console.log(id);
  };

  const viewSub = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/teacher_subject/view_subjects",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: tempAdminToken,
          class_name: class_key,
        }),
      }
    );
    const data = await res.json();
    setLoading(false);
    setLoadSubject(false);
    setSubjects(data?.subjects);

    console.log(data);
  };

  useEffect(() => {
    viewSub();
    viewBooks();
  }, [realClass]);

  // basic responsiveness handling
  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (document.documentElement.clientWidth <= 800) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const addSubject = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(
        "https://server-olhms.onrender.com/admin_subject/create_subject",
        {
          method: "POST",

          body: JSON.stringify({
            token: tempAdminToken,
            subject_name: subName,
            class_name: realClass.toLowerCase(),
            school: overallClass.toLowerCase(),
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const data = await res.json();
      // console.log(data);

      if (res.status === 200) {
        setSubject(false);
        viewSub();
        setSubName("");
      } else {
        //
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
    }
  };

  const addReader = async (e) => {
    const files = [selectedFile, pdfFile];
    e.preventDefault();
    const bodyFormData = new FormData();

    bodyFormData.append("token", tempAdminToken);
    bodyFormData.append("title", formData.booktitle);
    bodyFormData.append("class_name", realClass.toLowerCase());
    bodyFormData.append("school", overallClass.toLowerCase());
    bodyFormData.append("author", formData.bookauthor);
    bodyFormData.append("about_book", formData.aboutbook);
    for (let i = 0; i < files.length; i++) {
      bodyFormData.append("books", files[i]);
    }

    axios
      .post("https://server-olhms.onrender.com/admin_book/create_book", bodyFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setReader(false);
          setFormData({
            booktitle: "",
            bookauthor: "",
            aboutbook: "",
          });
          setSelectedFile(null);
          viewBooks();
        } else {
          // setMessage("Error occured");
          // setLoading(false);
        }
        console.log(response.data);
      })
      .catch((error) => {
        // console.log(error);
        // setLoading(false);
      });
  };

  const [termToggle, setTermToggle] = useState('first');

  const [students, setStudents] = useState([]);

  const viewStudents = async () => {
    // console.log(`*** ${JSON.stringify({
    //   token: tempAdminToken, 
    //   class_name: class_key,
    //   center: center
    // })}`);


    const res = await fetch(
      "https://server-olhms.onrender.com/teacher_profile/students_list",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: tempAdminToken,
          class_name: class_key,
          center: center
        }),
      }
    );
    const data = await res.json();
    setStudents(data?.students);

    console.log(data?.students);
  };

  useEffect(()=>{
    viewStudents();
  }, []);

  return (
    <div className="set-questions">
      <div className="set-container">
      <BackButton click={() => navigate(-1)} />
      <div style={{display: 'flex', flexDirection: 'column', alignSelf: 'start', marginTop: 16}}>
      <p><strong style={{fontSize: 20}}>{center}</strong></p>
      <p style={{fontSize: 20}}>{className}</p>
      </div>

        <TripleC
          text1="Subjects/ Books"
          text2="Class Resources"
          text3="Class List"
          toggle={toggle}
          click1={() => {
            setToggle("subjects");
          }}
          click2={() => {
            setToggle("resources");
          }}
          click3={() => {
            setToggle("class");
          }}
        />

        {toggle == 'resources' ? <Triple2
          text1="1st Term"
          text2="2nd Term"
          text3="3rd Term"
          toggle={termToggle}
          click1={() => {
            setTermToggle("first");
          }}
          click2={() => {
            setTermToggle("second");
          }}
          click3={() => {
            setTermToggle("third");
          }}
        /> : null}

        {toggle == 'class' ? (
          <div className="class-list">
            <div className="split-list">
              <p>Class List</p>
            </div>
            <table>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Full Name</th>
                  <th>Student's ID</th>
                  <th>Gender</th>
                  <th>Admission Year</th>
                </tr>
              </thead>
              <tbody>

                {students.map((student, i) => (
                    <tr key={i} onClick={() => navigate("/admin/settings-view-student", { state: {
                        id: student?._id,
                        num: 0,
                        reg: student,
                        tempAdminToken,
                        center,
                        overClassName: className,
                        type: 1,
                        class_key,
                        className,
                        overall_class_key
                    } })}
                    style={{ cursor: "pointer" }}>
                  <td>{i + 1}</td>
                  <td>{student.fullname}</td>
                  <td>{student.student_id}</td>
                  <td>{student.gender}</td>
                  <td>{student.addmission_year}</td>
                </tr>
                  ))}

              </tbody>
            </table>

            
          </div>
        ) : null}

        
        {toggle == 'subjects' || toggle == 'resources' ? <div className={mobile ? '' : "set-subjects"}>
        <p><strong style={{fontSize: 20}}>Subjects</strong></p>
        {loadsubject ? null : subjects?.length > 0 ? 
          (<div className={mobile ? '' : "set-grid"} style={mobile ? {} : {}}>
            {subjects?.map((item, index) => {
              const row = Math.floor(index / columns);
              const backgroundColor = colors[Math.floor(Math.random() * colors.length)];
              return (
                <div key={index} style={mobile ? {marginTop: 16} : {display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <SubjectComponent
                  color={backgroundColor}
                  subject={item?.subject_name}
                  click={() => toggle == 'subjects' ? null : 
                    navigate("/admin/settings-term-weeks", {
                      state: {
                        term:
                            termToggle == "first"
                                ? "First Term"
                                : termToggle == "second"
                                ? "Second Term"
                                : termToggle == "third"
                                ? "Third Term"
                                : null,
                        subject: item?.subject_name,
                        id: item?._id,
                        className: realClass,
                        tempAdminToken,
                        center,
                        overClassName: className
                      },
                    })}
                />
                {editSub && toggle == 'subjects' ? (
                        <span
                          className="close-popup"
                          style={{marginLeft: 8, marginTop: -10}}
                          onClick={() => {
                            setDeletable(true);
                            setSubId(item?._id);
                          }}>
                          <img style={{height: 30, width: 30}} src={Cancel} alt="" />
                        </span>
                      ) : null}
                </div>
              );
            })}
            
          </div>) : null}
          
          {
            toggle == 'subjects' ? 
            (editSub == false ? (<div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            alignSelf: 'end'
            }}>

            <div className="edit-overview"
            style={{marginRight: 8, width: 50, height: 50}}>
                <img src={Add} onClick={() => setSubject(true)} />
            </div>

            <div
                className="edit-overview"
                style={{width: 50, height: 50}}
                onClick={() => setEditSub(true)}>
                <img src={Edit} />
            </div>

            </div>) : (
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'end'
                }}>
                <button style={{height: 40, width: 120}} onClick={() => setEditSub(false)}>
                    Save Changes
                  </button>
                </div>
            )) : null
          }
        </div> : null}
        

        {toggle == 'subjects' ? <div style={{
            backgroundColor: 'black',
            height: 1,
            width: '100%'
        }}></div> : null}


        {toggle == 'subjects' ? <div style={{display: 'flex', flexDirection: 'row', alignSelf: 'start'}}>
        <p><strong style={{fontSize: 20}}>Readers</strong></p>
        </div> : null}
            {toggle == 'subjects' ? <div className="reader-div" style={{display: 'flex', flexDirection: 'row', marginTop: 16}}>
                {loadbooks
                  ? null
                  : books?.length > 0
                  ? books?.map((item, i) => (
                      <div className="book-id">
                        <div className="readers" style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            marginRight: 24
                        }} key={i}>
                          <div className="reader-cover" style={{
                                height: 120,
                                width: 80,
                                marginBottom: 8
                            }}>
                            <img style={{}} src={item?.img_url} alt="" />
                          </div>
                          <div className="book-title">
                            <p>{item.title}</p>
                            <p className="book-author">{item.author}</p>
                          </div>
                        </div>
                        {editBook ? (
                          <span
                            className="close-popup"
                            style={{
                                marginRight: 24
                            }}
                            onClick={() => {
                              setDeletable(true);
                              setBookId(item?._id);
                            }}
                          >
                            <img style={{height: 30, width: 30}} src={Cancel} alt="" />
                          </span>
                        ) : null}
                      </div>
                    ))
                  : "No books created"}
              </div> : null}
              
              {toggle == 'subjects' ? <div className="overview-title" style={{
                display: 'flex',
                alignSelf: 'end'
              }}>
                {editBook == false ? (
                  <div className="split-editable">
                    <div
                        style={{marginRight: 8, width: 50, height: 50}}
                      className="edit-overview"
                      onClick={() => setEditBook(true)}
                    >
                      <img src={Edit} />
                    </div>
                    <div
                        style={{width: 50, height: 50}}
                        className="edit-overview">
                      <img src={Add} onClick={() => setReader(true)} />
                    </div>
                  </div>
                ) : (
                  <button onClick={() => setEditBook(false)}>
                    Save Changes
                  </button>
                )}
              </div> : null}

            </div> 
      <Popup isOpen={subject}>
          <form className="add-subject-form" onSubmit={addSubject}>
            <div className="add-remove">
              <p className="addition-text">Add Subject</p>
              <div className="close-popup" onClick={() => setSubject(false)}>
                <img src={Cancel} alt="" />
              </div>
            </div>
            <div className="subject-form">
              <label>Enter Subject Name</label>
              <input value={subName} onChange={handleSub} />
            </div>
            <div style={{ alignSelf: "center" }}>
              <Button name="Save and Update" />
            </div>
          </form>
        </Popup>
        <Popup isOpen={classList}>
          <form className="add-subject-form" onSubmit={addSubject}>
            <div className="add-remove">
              <div
                style={{ alignSelf: "flex-start" }}
                className="addition-text">

                {toggle ? "Topaz centre" : "Emerald centre"} &gt;&gt;{" "}
                {overallClass} &gt;&gt;{" "}
                <span style={{ color: "#E3560F" }}>
                  {realClass.toUpperCase()}
                </span>
              </div>

              <div className="close-popup" onClick={() => setClassList(false)}>
                <img src={Cancel} alt="" />
              </div>
            </div>

            <div className="subject-form">
              <p className="addition-text">Class List</p>
              <div className="database-table">
                <table>
                  <thead>
                    <tr style={{ borderBottom: "none" }}>
                      <th>S/N</th>
                      <th>Name</th>
                      <th>Gender</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Bunmi Ade</td>
                      <td>Male</td>
                      <td
                        onClick={() =>
                          navigate("/admin/view", { state: { num: 0 } })
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        &gt;&gt;
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </form>
        </Popup>
        <Popup isOpen={deletable}>
          <div className="deletable-div">
            <p>
              {subId != ""
                ? "Delete Subject"
                : bookId != ""
                ? "Delete Reader"
                : ""}
            </p>
            <div>
              {subId != ""
                ? "Are you sure you want to delete this subject? All data related to this subject would be deleted, including class notes, test questions and exams!"
                : bookId != ""
                ? "Are you sure you want to delete book?"
                : null}
            </div>
            <div>
              <Button2 name="No" click={cancelDelete} />
              <Button name="Yes" click={subId != "" ? deleteSub : deleteBook} />
            </div>
          </div>
        </Popup>
    </div>
  );
}

export function Toggle(props) {
  return (
    <div className="slider-button">
      <div
        onClick={props.click1}
        className="slider-option"
        id={props.toggle ? "active" : ""}
      >
        {props.text1}
      </div>
      <div
        className="slider-option"
        id={props.toggle ? "" : "active2"}
        onClick={props.click2}
      >
        {props.text2}
      </div>
    </div>
  );
}

export function Toggle2(props) {
  return (
    <div className="slider-button2">
      <div
        onClick={props.click1}
        className="slider-option"
        id={props.toggle ? "active-slide" : ""}
      >
        {props.text1}
      </div>
      <div
        className="slider-option"
        id={props.toggle ? "" : "active-slide"}
        onClick={props.click2}
      >
        {props.text2}
      </div>
    </div>
  );
}

export function Novideo(props) {
  return (
    <div className="no-video">
      <div className="novideo">
        <img src={props.img} alt="" />
      </div>
      <p>{props.text}</p>
    </div>
  );
}

export function NoteComponent(props) {
  return (
    <div className="fetched-note">
      <div className="pdf-div">
        <img src={pdf} alt="" />
      </div>
      <div className="title-divider">
        <div>
          <p>
            <strong>{props.title}</strong>
          </p>
          <p className="font-sub">{props.weekLes}</p>
        </div>
        <div className="dropdown">
          <p className="dropdown-btn">&#8942;</p>
          <div class="dropdown-content" onClick={props.onClick}>
            <p>Delete Note</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export function VideoComponent(props) {
  return (
    <div className="fetched-video">
      <div className="videodiv">
        <video
          poster={props.thumbnail_url}
          src={props.lesson_url}
          width="100%"
          height="100%"
          autoPlay={false}
          controls
        ></video>
      </div>
      <div className="title-divider">
        <div>
          <p>
            <strong>{props.title}</strong>
          </p>
          <p>{props.weekLes}</p>
        </div>
        <div className="dropdown">
          <p className="dropdown-btn">&#8942;</p>
          <div class="dropdown-content" onClick={props.onClick}>
            <p>Delete Video</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export function ClassContent(props) {
  return (
    <div className="class-content">
      <div className="video-flex">{props.children}</div>
    </div>
  );
}

export function TripleC(props) {
    return (
      <div className="slider-button">
        <div
          onClick={props.click1}
          className="slider-option"
          id={props.toggle === "subjects" ? "active4" : ""}
        >
          {props.text1}
        </div>
        <div
          className="slider-option"
          id={props.toggle === "resources" ? "active4" : ""}
          onClick={props.click2}
        >
          {props.text2}
        </div>
        <div
          onClick={props.click3}
          className="slider-option"
          id={props.toggle === "class" ? "active4" : ""}
        >
          {props.text3}
        </div>
      </div>
    );
  }