import React from "react";
import { useState, useContext, useEffect } from "react";
import { adminContext } from "../context/adminContext";
import { useLocation, useNavigate } from "react-router-dom";
import { BackButton } from "../studentPages/PersonalData";
import dark from "../../images/dark.png";
import Button, { Button2 } from "../../components/Button/Button";
import "./vlogs.css";
import axios from "axios";
import qs from "qs";
import Delete from "../../images/delete.png";
import { ClipLoader } from "react-spinners";

import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import "react-js-dropdavn/dist/index.css";
// import {SimpleDropdown} from 'react-js-dropdavn';

export default function AddItem() {
  const state = useLocation().state;
  const navigate = useNavigate();
  const values = useContext(adminContext);
  const { success, admintoken,teachertoken } = values;

  const shouldEdit = state.shouldEdit;

  const [itemId, setItemId] = useState(shouldEdit ? state._id : "");
  const [imgUrl, setImgUrl] = useState(shouldEdit ? state.img_url : "");
  const [productName, setProductName] = useState(
    shouldEdit ? state.product_name : ""
  );
  const [costPrice, setCostPrice] = useState(
    shouldEdit ? state.cost_price : ""
  );
  const [sellPrice, setSellPrice] = useState(
    shouldEdit ? state.sell_price : ""
  );
  const [category, setCategory] = useState(shouldEdit ? state.category : "Stationaries");
  const [stockLevel, setStockLevel] = useState(
    shouldEdit ? state.stock_level : 0
  );

  const [quantReceive, setQuantReceive] = useState(0);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedSrc, setSelectedSrc] = useState(
    shouldEdit ? state.img_url : ""
  );
  const [loading, setLoading] = useState(false);

  const [loadingEditDialogButton, setLoadingEditDialogButton] = useState(false);
  const [openEditDialog, handleEditDialog] = useState(false);
  const openEditDialogBox = () => {
    handleEditDialog(true);
  };
  const closeEditDialogBox = () => {
    handleEditDialog(false);
  };


  
  const [loadingDialogButton, setLoadingDialogButton] = useState(false);
  const [openDialog, handleDialog] = useState(false);
  const openDialogBox = () => {
    handleDialog(true);
  };
  const closeDialogBox = () => {
    handleDialog(false);
  };


  // category entries
  const categoryData = [
    { label: "Stationaries", value: "Stationaries" },
    { label: "Uniform", value: "Uniform" },
    { label: "Sport wear", value: "Sport wear" },
    { label: "Text books", value: "Text books" },
    { label: "Note books", value: "Note books" },
    { label: "School shoe", value: "School shoe" },
    { label: "Sport shoe", value: "Sport shoe" },
  ];

  const onFileChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState == 2) {
        setSelectedSrc(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
    // setIsSelected(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };


  const handleProductName = (e) => setProductName(e.target.value);
  const handleCostPrice = (e) => setCostPrice(e.target.value);
  const handleSellPrice = (e) => setSellPrice(e.target.value);
  const handleCategory = (e) => setCategory(e.target.value);
  const handleQuantReceive = (e) => setQuantReceive(e.target.value);

  // function to post product
  const postProduct = async () => {
    setLoading(true);

    const tempQuantReceive =
      (quantReceive == "" ? 0 : parseInt(quantReceive)) + parseInt(stockLevel);
    const formData = new FormData();

    formData.append("token", success == "teacher" ? JSON.parse(teachertoken) : JSON.parse(admintoken));
    // formData.append("token", JSON.parse(admintoken));
    formData.append("product_name", productName);
    formData.append("cost_price", costPrice);
    formData.append("sell_price", sellPrice);
    formData.append("category", category);
    formData.append("quant_receive", tempQuantReceive);
    formData.append("image", selectedFile);


    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: formData,
      url: "https://server-olhms.onrender.com/admin_store/add_product",
    };

    axios(options)
      .then((response) => {
        setLoading(false);
        if (response.status == 200) {
          console.log("response: :: :: ", response);
          // pop to previous page
          navigate(-1);
        }else{
          alert("An error occured. Please try again")
  
          }
      })
      .catch((e) => {
        console.log(`-------1--------> ${JSON.stringify(e.response.data)}`);
        setLoading(false);
      });
  };

  // endpoint to edit product
  const editProduct = async () => {
    setLoadingEditDialogButton(true);

    const tempQuantReceive =
      (quantReceive == "" ? 0 : parseInt(quantReceive)) + parseInt(stockLevel);
    const formData = new FormData();

    formData.append("token", success == "teacher" ? JSON.parse(teachertoken) : JSON.parse(admintoken));
    // formData.append("token", JSON.parse(admintoken));
    formData.append("store_item_id", itemId);
    formData.append("product_name", productName);
    formData.append("cost_price", costPrice);
    formData.append("sell_price", sellPrice);
    formData.append("category", category);
    formData.append("quant_receive", tempQuantReceive);
    formData.append("image", selectedFile);

    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: formData,
      url: "https://server-olhms.onrender.com/admin_store/edit_product",
    };

    axios(options)
      .then((response) => {
        setLoadingEditDialogButton(false);
        if (response.status == 200) {
          console.log("response: :: :: ", response);
          // pop to previous page
          navigate(-1);
        }else{
        alert("An error occured. Please try again")

        }
      })
      .catch((e) => {
        console.log("error: :: :: ", e);
        alert("oOps! An error occured. Please try again")
        setLoadingEditDialogButton(false);
      });
  };

  // function to delete product
  const deleteProduct = async () => {
    setLoadingDialogButton(true);

    const formData = new FormData();

    formData.append("token", success == "teacher" ? JSON.parse(teachertoken) : JSON.parse(admintoken));
    // formData.append("token", JSON.parse(admintoken));
    formData.append("store_item_id", itemId);

    const data = {
      token: success == "teacher" ? JSON.parse(teachertoken) : JSON.parse(admintoken),
      store_item_id: itemId,
    };

    const options = {
      method: "POST",
      headers: { "content-type": "application/json" },
      data: data,
      url: "https://server-olhms.onrender.com/admin_store/delete_product",
    };

    axios(options)
      .then((response) => {
        if (response.status == 200) {
          console.log("response: :: :: ", response);
          // pop to previous page
          navigate(-1);
        }
        setLoadingDialogButton(false);
      })
      .catch((e) => {
        console.log("error: :: :: ", e);
        setLoadingDialogButton(false);
      });
  };

  return (
    <div
      className="set-questions"
      style={{
        backgroundColor: "rgb(255, 248, 248)",
        height: "100%",
        alignItems: "flex-start",
      }}
    >
      <div className="set-container">
        <BackButton click={() => navigate(-1)} />

        <form className="vlog-form" onSubmit={handleSubmit}>
          <div className="vlog-split">
            <div className="form-element">
              <legend>Tap the icon to add Image</legend>
              {selectedSrc == "" ? (
                <div className="dark-div">
                  <label>
                    <div className="vector-dark">
                      <img src={dark} alt="" />
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg, .gif"
                        onChange={onFileChange}
                      />
                    </div>
                  </label>
                </div>
              ) : selectedSrc != "" ? (
                <div className="dark-div">
                  <img src={selectedSrc} alt="" className="dark-div-img" />
                </div>
              ) : null}
              {selectedSrc == "" ? null : (
                <div className="show-blog-img">
                  <label>
                    Change Image
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg, .gif"
                      onChange={onFileChange}
                    />
                  </label>
                </div>
              )}
            </div>
            <div className="blog-element">
              <label>Product Name</label>
              <input
                placeholder="Product Name"
                name="product_name"
                value={productName}
                onChange={handleProductName}
              />
            </div>

            <div className="blog-element">
              <label>Cost Price (₦)</label>
              <input
                placeholder="Cost Price"
                name="cost_price"
                value={costPrice}
                onChange={handleCostPrice}
              />
            </div>

            <div className="blog-element">
              <label>Selling Price (₦)</label>
              <input
                placeholder="Selling Price"
                name="sell_price"
                value={sellPrice}
                onChange={handleSellPrice}
              />
            </div>

            <div className="form-element">
              <label>Category</label>
              <div className="select-container">
                <select value={category} onChange={handleCategory}>
                  <option value="Stationaries">Stationaries</option>
                  <option value="Uniform">Uniform</option>
                  <option value="Sport wear">Sport wear</option>
                  <option value="Text books">Text books</option>
                  <option value="Note books">Note books</option>
                  <option value="School shoe">School shoe</option>
                  <option value="Sport shoe">Sport shoe</option>
                </select>
              </div>
            </div>

            {/* <SimpleDropdown
              options={categoryData}
              configs={{position: {y: 'down', x: 'center'}, fullWidthParent: true}}
              defaultValue='Stationaries'
            /> */}

            <div className="blog-element">
              <label>Quantity Received</label>
              <input
                placeholder="Quantity Received"
                name="quant_receive"
                value={quantReceive}
                onChange={handleQuantReceive}
              />
            </div>
          </div>
          <div className="split-vlog-button">
            <button
              disabled={loading}
              className="post-vlog"
              type="submit"
              onClick={() => {
                if (shouldEdit) {
                  openEditDialogBox();
                } else {
                  postProduct();
                }
              }}
            >
              <span>
                {loading ? (
                  <ClipLoader color={"white"} size={30} />
                ) : shouldEdit ? (
                  "Edit Product"
                ) : (
                  "Post Product"
                )}
              </span>
            </button>

            {shouldEdit ? (
              <>
                <button className="vlog-button" onClick={openDialogBox}>
                  <div className="delete-vlog">
                    <img src={Delete} />
                  </div>
                  Delete Product
                </button>
                <div
                  className="post-vlog"
                  style={{
                    backgroundColor: "#B7630E",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 70,
                  }}
                >
                  <p>Stock Level</p>
                  <p>{shouldEdit ? stockLevel : quantReceive}</p>
                </div>
              </>
            ) : null}

            {shouldEdit ? (
              <div
                className="post-vlog"
                style={{
                  backgroundColor: "#673401",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 70,
                }}
              >
                <p>New Stock Level</p>
                <p>
                  {(quantReceive == "" ? 0 : parseInt(quantReceive)) +
                    parseInt(stockLevel)}
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>
        </form>
      </div>

      
      <Dialog onClose={closeEditDialogBox} open={openEditDialog}>
          <DialogTitle>Edit Product?</DialogTitle>
          <div style={{ marginLeft: 100, marginRight: 100 }}>
            <div style={{ marginBottom: 30 }}>
              Are you sure you want to edit {productName}?
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 20,
              }}
            >
            <div >

              <Button2
                
                click={(e) => {
                  e.preventDefault();
                  closeEditDialogBox();
                }}
                style={{ marginBottom: 10,marginRight: 20, }}
                name={"Cancel"}
              />
              <Button
                disabled={loadingEditDialogButton}
                click={(e) => {
                  e.preventDefault();

                  if(loadingEditDialogButton == false){
                    editProduct();
                  }

                }}
                style={{ marginBottom: 10, marginLeft: 20, }}
                name={"Edit"}
              />
            </div>
            </div>
          </div>
        </Dialog>

      <Dialog onClose={closeDialogBox} open={openDialog}>
          <DialogTitle>Delete Product?</DialogTitle>
          <div style={{ marginLeft: 100, marginRight: 100 }}>
            <div style={{ marginBottom: 30 }}>
              Are you sure you want to delete {productName}?
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 20,
              }}
            >
            <div >

              <Button2
                
                click={(e) => {
                  e.preventDefault();
                  closeDialogBox();
                }}
                style={{ marginBottom: 10,marginRight: 20, }}
                name={"Cancel"}
              />
              <Button
                disabled={loadingDialogButton}
                click={(e) => {
                  e.preventDefault();

                  if(loadingDialogButton == false){
                    deleteProduct();
                  }

                }}
                style={{ marginBottom: 10, marginLeft: 20, }}
                name={"Delete"}
              />
            </div>
            </div>
          </div>
        </Dialog>
    </div>
  );
}
