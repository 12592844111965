import React from "react";
import NewsOne from "./news/NewsOne";
import NewsTwo from "./news/NewsTwo";

export default function News() {
  return (
    <div>
      <NewsOne />
      <NewsTwo />
    </div>
  );
}
