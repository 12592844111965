import React from "react";
import "./youtube.css";

export default function Youtube(props) {
  return (
    <div className="youtube-testimonial">
      <div className="youtube-section-testimonial">
        <video
          poster={props.poster_url}
          width="100%"
          height="100%"
          src={props.source}
          autoPlay={false}
          controls
        ></video>
      </div>
      <div className="youtube-testimonial-text">
        <p>
          {props.testimony} {props.testimony2 ? <br /> : ""}
          {props.testimony2}
          <br />
          <br />
          <br />
          <strong>
            <i>{props.name}</i>
          </strong>
        </p>
      </div>
    </div>
  );
}
