import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BackButton } from "../studentPages/PersonalData";
import { Toggle, VideoComponent } from "./Resources";
import { ClassContent } from "./Resources";
import { NoteComponent } from "./Resources";
import video from "../../images/video.png";
import novideo from "../../images/novideo.png";
import nonote from "../../images/nonote.png";
import noresource from "../../images/no-resources.png";
import attach from "../../images/attach.png";
import worksheet from "../../images/worksheet.png";
import { Novideo } from "./Resources";
import Addition from "../../images/addition.png";
import Edit from "../../images/edit.png";
import Delete from "../../images/Delete2.png";
import { adminContext } from "../context/adminContext";
import { Test } from "./SetQuestions";
import { toast } from "react-hot-toast";

import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Button, { Button2 } from "../../components/Button/Button";

export default function AllResources() {
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(true);

  const location = useLocation();
  const term = location.state.term;
  const week = location.state.week;
  const subject = location.state.subject;
  const id = location.state.id;

  const values = useContext(adminContext);
  const { teacherData, teachertoken } = values;
  const dataT = JSON.parse(teacherData);
  const [loading, setLoading] = useState(true);

  const [noteLessons, setNoteLessons] = useState([]);
  const [videoLessons, setVideoLessons] = useState([]);
  const [noteAddResources, setNoteAddResources] = useState([]);
  const [videoAddResources, setVideoAddResources] = useState([]);


  const [loadingDialogButton, setLoadingDialogButton] = useState(false);
  const [openDialog, handleDialog] = useState(false);
  const openDialogBox = () => {
    handleDialog(true);
  };
  const closeDialogBox = () => {
    handleDialog(false);
  };

  function capitalizeFirstLetters(sentence) {
    // Split the sentence into an array of words.
    const words = sentence.split(' ');
  
    // Capitalize the first letter of each word.
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
  
    // Join the words back into a sentence.
    return words.join(' ');
  }

  const [lessons, setLessons] = useState({
    noteLessons: [],
    videoLessons: [],
  });
  const [addResources, setAddResources] = useState({
    noteAddResources: [],
    videoAddResources: [],
  });

  const deleteWorksheet = async () => {
    setLoadingDialogButton(true);
  
    for (const worksheet of worksheetQuestions) {
      let worksheet_id = worksheet["_id"];
  
      try {
        const res = await fetch(
          "https://server-olhms.onrender.com/teacher_worksheet/delete_worksheet",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              token: JSON.parse(teachertoken),
              worksheet_id: worksheet_id,
            }),
          }
        );
  
        const data = await res.json();
  
        if (data?.msg === "success") {
          toast.success("Worksheet deleted", {
            position: "bottom-center",
          });
        } else {
          alert(data?.msg);
        }
  
        console.log(data);
      } catch (error) {
        console.error(error);
        // Handle any errors that occur during the fetch or processing here
      }
    }
  
    // toast.success("Completed", {
    //   position: "bottom-center",
    // });
    setLoadingDialogButton(false);
    closeDialogBox();
    navigate(-1);
  };
  

  const deleteLesson = async (lesson) => {
    const week_no = parseInt(week.substring(5));
    let lesson_id = lesson["_id"];
    let lesson_material_id = lesson.lesson_id;
    let thumbnail_id = lesson.thumbnail_id;

    // console.log({
    //   token: JSON.parse(teachertoken),
    //   subject_id: id,
    //   week_no: week_no,
    //   term: term,
    //   lesson_id: lesson_id,
    //   lesson_material_id: lesson_material_id,
    //   thumbnail_id: thumbnail_id,
    //   // lesson_url: lesson_url,
    // });

    const res = await fetch(
      "https://server-olhms.onrender.com/teacher_subject/delete_lesson_material",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(teachertoken),
          subject_id: id,
          week_no: week_no,
          term: term,
          lesson_id: lesson_id,
          lesson_material_id: lesson_material_id,
          thumbnail_id: thumbnail_id,
          // lesson_url: lesson_url,
        }),
      }
    );

    const data = await res.json();
    setLoading(false);
    // const mLessons = data?.msg.lesson;
    if (data?.msg == "success") {
      viewSub();
      viewAddResources();
      toast.success("Lesson successfully deleted", {
        position: "bottom-center",
      });
      // alert("Lesson successfully deleted");
    } else {
      alert(data?.msg);
    }

    console.log(data);
  };

  const deleteAdditionalResourceLesson = async (lesson) => {
    // const week_no = parseInt(week.substring(5));
    let lesson_id = lesson["_id"];
    let lesson_material_id= lesson["lesson_id"];

    // console.log(`--------------->${JSON.stringify(lesson)} `);

    // if (lesson.lesson_type == "video") {
    //   lesson_material_id = lesson.img_id;
    // } else {
    //   lesson_material_id = lesson.pdf_id;
    // }

    console.log({
      token: JSON.parse(teachertoken),
      additional_resources_id: lesson_id,
      additional_resources_material_id: lesson_material_id,
    });

    const res = await fetch(
      "https://server-olhms.onrender.com/teacher_additional_resources/delete_additional_resources",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(teachertoken),
          additional_resources_id: lesson_id,
          additional_resources_material_id: lesson_material_id,
        }),
      }
    );

    const data = await res.json();
    setLoading(false);
    // const mLessons = data?.msg.lesson;
    if (data?.msg == "success") {
      viewSub();
      viewAddResources();
      toast.success("Additional lesson resource successfully deleted", {
        position: "bottom-center",
      });
      // alert("Additional lesson resource successfully deleted");
    } else {
      alert(data?.msg);
    }

    console.log(data);
  };

  const viewSub = async () => {
    const week_no = parseInt(week.substring(5));
    // console.log(`----> ${week}`);
    // console.log(
    //   `----> ${JSON.stringify({
    //     token: JSON.parse(teachertoken),
    //     week_no: week_no,
    //     subject_id: id,
    //     term: term,
    //   })}`
    // );
    const res = await fetch(
      "https://server-olhms.onrender.com/teacher_subject/view_lesson",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(teachertoken),
          week_no: week_no,
          subject_id: id,
          term: term,
        }),
      }
    );
    const data = await res.json();
    setLoading(false);
    if (data["msg"] == "success") {
      const mLessons = data?.lesson.lesson;

      const tNoteLessons = [];
      const tVideoLessons = [];

      mLessons.map((les) => {
        if (les.lesson_type == "class note") {
          tNoteLessons.push(les);
        }

        if (les.lesson_type == "video") {
          tVideoLessons.push(les);
        }
      });

      const temp = { ...lessons };
      temp.noteLessons = tNoteLessons;
      temp.videoLessons = tVideoLessons;
      setLessons(temp);
    } else {
    }

    console.log(data);
  };

  useEffect(() => {
    viewSub();
  }, []);

  const viewAddResources = async () => {
    const week_no = parseInt(week.substring(5));
    // console.log(id);
    // console.log(
    //   `-----> add resources ${JSON.stringify({
    //     token: JSON.parse(teachertoken),
    //     week: week_no,
    //     subject_name: subject,
    //     class_name: dataT?.class_name,
    //     term: term,
    //   })}`
    // );

    let class_name = dataT?.class_name;

    if(class_name == "infant community 1"||class_name == "infant community 2"){
      class_name = capitalizeFirstLetters(dataT?.class_name);
    }

    const res = await fetch(
      "https://server-olhms.onrender.com/teacher_additional_resources/view_multiple_additional_resources_filter",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(teachertoken),
          week: week_no,
          subject_name: subject,
          class_name: class_name,
          term: term,
        }),
      }
    );
    const data = await res.json();
    setLoading(false);
    if (data["msg"] == "success") {
      const resources = data?.additional_resources;

      const tVideoResources = [];
      const tNoteResources = [];

      resources.map((res) => {
        if (res.lesson_type == "class note") {
          tNoteResources.push(res);
        }

        if (res.lesson_type == "video") {
          tVideoResources.push(res);
        }
      });

      const temp = { ...addResources };
      temp.noteAddResources = tNoteResources;
      temp.videoAddResources = tVideoResources;
      setAddResources(temp);
    }
    console.log(data);
  };

  useEffect(() => {
    viewAddResources();
  }, []);

  const [worksheetQuestions, setWorksheets] = useState([]);
  const viewWorksheetQuestions = async () => {
    // console.log(
    //   `-----> viewWorksheetQuestions ${JSON.stringify({
    //     token: JSON.parse(teachertoken),
    //     class_name: dataT?.class_name,
    //     subject: subject,
    //     week: parseInt(week.split(" ")[1]),
    //   })}`
    // );

    const res = await fetch(
      "https://server-olhms.onrender.com/teacher_worksheet/view_current_session_worksheets",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(teachertoken),
          class_name: dataT?.class_name,
          subject: subject,
          week: parseInt(week.split(" ")[1]),
        }),
      }
    );
    const data = await res.json();
    setLoading(false);
    if (data["msg"] == "success") {
      const worksheets = data?.worksheets;

      setWorksheets(worksheets);
    }
    console.log(data);
  };

  useEffect(() => {
    viewWorksheetQuestions();
  }, []);

  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (document.documentElement.clientWidth <= 800) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className={mobile ? "" : "set-questions"}
      style={mobile ? { margin: 24 } : {}}
    >
      <div
        className="set-container"
        style={{ rowGap: "50px", marginLeft: mobile ? 0 : 0 }}
      >
        <BackButton click={() => navigate(-1)} />
        <div
          className="session-flex"
          style={{
            alignSelf: "flex-start",
            alignItems: "flex-start",
            rowGap: "10px",
          }}
        >
          <div className="cat-session">
            <p>
              {term == "first term"
                ? "First Term"
                : term == "second term"
                ? "Second Term"
                : term == "thrid term"
                ? "Third Term"
                : null}
            </p>
          </div>
          <div style={{ fontSize: "20px" }}>
            {subject} -{" "}
            <span style={{ fontWeight: "300", fontSize: "20px" }}>{week}</span>
          </div>
        </div>
        <Toggle
          click1={() => setToggle(true)}
          click2={() => setToggle(false)}
          toggle={toggle}
          text1="Resources"
          text2="Worksheet"
        />

        {toggle ? null : worksheetQuestions.length == 0 ? (
          <>
            <div
              className="add-question"
              onClick={() =>
                navigate("/teacher/select", {
                  state: { week: week, term: term, subject: subject, id },
                })
              }
            >
              <img src={Addition} alt="" />
            </div>
          </>
        ) : (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div
              style={{ paddingRight: "20px" }}
              className="add-question"
              onClick={() =>
                navigate("/teacher/select", {
                  state: { week: week, term: term, subject: subject, id },
                })
              }
            >
              <img src={Addition} alt="" />
            </div>
            {/* <div
              style={{ paddingLeft: "20px" }}
              className="add-question"
              onClick={() => {
                toast.error("Feature is being worked upon...", {
                  icon: "⏳️",
                  position: "bottom-center",
                });

                // navigate("/teacher/select", {
                //   state: { week: week, term: term, subject: subject, id },
                // })
              }}
            >
              <img src={Edit} alt="" />
            </div> */}
            <div
              style={{ paddingLeft: "20px" }}
              className="add-question"
              onClick={() => {
                openDialogBox(true);
              }}
            >
              <img src={Delete} alt="" />
            </div>
          </div>
        )}

        {toggle ? (
          <>
            <>
              <div className="split-all-resources">
                <div className="cat-session">
                  <p>VIDEOS</p>
                </div>

                <div
                  className="add-video"
                  onClick={() =>
                    navigate("/teacher/upload", {
                      state: {
                        file: 0,
                        week: week,
                        term: term,
                        subject: subject,
                        id,
                      },
                    })
                  }
                >
                  <div className="video-icon">
                    <img src={video} alt="" />
                  </div>
                  <p>Add Video</p>
                </div>
              </div>

              <div
                className="split-all-resources"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: mobile ? "column" : "row",
                    justifyContent: "flexStart",
                    flexWrap: "wrap",
                  }}
                >
                  {lessons.videoLessons.map((vLes, index) => (
                    <ClassContent key={index}>
                      <div
                        style={{
                          marginLeft: mobile ? 16 : 50,
                          marginBottom: mobile ? 16 : 20,
                        }}
                      >
                        <VideoComponent
                          lesson_url={vLes.lesson_url}
                          thumbnail_url={vLes.thumbnail_url}
                          title={vLes.title}
                          weekLes={`${week}, Lesson ${vLes.lesson_no}`}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                          onDelete={(e) => {
                            e.preventDefault();
                            deleteLesson(vLes);
                          }}
                        />
                      </div>
                    </ClassContent>
                  ))}
                </div>
                {loading || lessons.videoLessons.length != 0 ? (
                  <></>
                ) : (
                  <Novideo
                    img={novideo}
                    text="No Video has been uploaded. Tap the icon to upload a new video"
                  />
                )}
              </div>
            </>

            <>
              <div
                className="split-all-resources"
                // style={{
                //   alignSelf: "flex-start",
                // }}
              >
                <div
                  className="cat-session"
                  // style={{
                  //   textDecoration: "underline",
                  //   fontSize: "20px",
                  // }}
                >
                  <p>NOTES</p>
                </div>

                <div
                  className="add-video"
                  onClick={() =>
                    navigate("/teacher/upload", {
                      state: {
                        file: 1,
                        week: week,
                        term: term,
                        subject: subject,
                        id,
                      },
                    })
                  }
                >
                  <div className="attach-icon">
                    <img src={attach} alt="" />
                  </div>
                  <p>Add Notes</p>
                </div>
              </div>

              <div
                className="split-all-resources"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: mobile ? "column" : "row",
                    justifyContent: "left",
                    flexWrap: "wrap",
                  }}
                >
                  {lessons.noteLessons.map((nLes, index) => (
                    <ClassContent key={index}>
                      <div
                        style={{
                          // marginRight: 32,
                          // marginTop: mobile ? 16 : 0

                          marginLeft: mobile ? 16 : 50,
                          marginBottom: mobile ? 16 : 20,
                        }}
                      >
                        <NoteComponent
                          title={nLes.title}
                          weekLes={`${week}, Lesson ${nLes.lesson_no}`}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                          onDelete={(e) => {
                            e.preventDefault();
                            deleteLesson(nLes);
                          }}
                        />
                      </div>
                    </ClassContent>
                  ))}
                </div>
                {loading || lessons.noteLessons.length != 0 ? (
                  <></>
                ) : (
                  <Novideo
                    img={nonote}
                    text="No Notes have been uploaded. Tap the icon to upload a new Note"
                  />
                )}
              </div>
            </>

            <>
              <div className="split-all-resources">
                {mobile ? (
                  <div className="cat-session">
                    <p>ADDITIONAL</p>
                    <p>RESOURCES</p>
                  </div>
                ) : (
                  <div className="cat-session">
                    <p>ADDITIONAL RESOURCES</p>
                  </div>
                )}

                <div
                  className="add-video"
                  onClick={() =>
                    navigate("/teacher/upload", {
                      state: {
                        file: 3,
                        week: week,
                        term: term,
                        subject: subject,
                        id,
                      },
                    })
                  }
                >
                  <div className="attach-icon">
                    <img src={attach} alt="" />
                  </div>
                  <p>Add File</p>
                </div>
              </div>

              <div
                className="split-all-resources"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {/* <div className="show-split-all"></div> */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: mobile ? "column" : "row",
                    justifyContent: "flexStart",
                    flexWrap: "wrap",
                  }}
                >
                  {addResources.noteAddResources.map((nLes, index) => (
                    <ClassContent key={index}>
                      <div
                        style={{
                          // marginRight: 32,
                          // marginTop: mobile ? 16 : 0
                          marginLeft: mobile ? 16 : 50,
                          marginBottom: mobile ? 16 : 20,
                        }}
                      >
                        <NoteComponent
                          title={nLes.title}
                          weekLes={`${week}, Lesson ${nLes.lesson_no}`}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                          onDelete={(e) => {
                            // console.log(nLes);
                            e.preventDefault();

                            if (nLes.pdf_url === "") {
                              console.log("No file to delete");
                            } else {
                              deleteAdditionalResourceLesson(nLes);
                            }

                            // if (nLes.pdf_url === "") {
                            //   if (nLes.img_url === "") {
                            //     console.log("No file to delete");
                            //   } else {
                            //     deleteAdditionalResourceLesson(nLes);
                            //   }
                            // } else {
                            //   deleteAdditionalResourceLesson(nLes);
                            // }
                            // console.log(nLes);
                            // deleteLesson(nLes.lesson_url);
                          }}
                        />
                      </div>
                    </ClassContent>
                  ))}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: mobile ? "column" : "row",
                      justifyContent: "flexStart",
                    }}
                  >
                    {addResources.videoAddResources.map((vLes, index) => (
                      <ClassContent key={index}>
                        <div
                          style={{
                            // marginRight: 32,
                            // marginTop: mobile ? 16 : 0
                            marginLeft: mobile ? 16 : 50,
                            marginBottom: mobile ? 16 : 20,
                          }}
                        >
                          <VideoComponent
                            lesson_url={vLes.img_url}
                            thumbnail_url={""}
                            title={vLes.title}
                            weekLes={`${week}, Lesson ${vLes.lesson_no}`}
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                            onDelete={(e) => {
                              e.preventDefault();
                              // console.log(vLes);

                              if (vLes.img_url === "") {
                                console.log("No file to delete");
                              } else {
                                deleteAdditionalResourceLesson(vLes);
                              }

                              // if (vLes.pdf_url === "") {
                              //   if (vLes.img_url === "") {
                              //     console.log("No file to delete");
                              //   } else {
                              //     // deleteLesson(vLes);
                              //   }
                              // } else {
                              //   // deleteLesson(vLes);
                              // }

                              // deleteLesson(vLes.lesson_url);
                            }}
                          />
                        </div>
                      </ClassContent>
                    ))}
                  </div>
                </div>
                {loading ||
                addResources.noteAddResources.length != 0 ||
                addResources.videoAddResources.length != 0 ? (
                  <></>
                ) : (
                  <Novideo
                    img={noresource}
                    text="No additional resource has been uploaded. Tap the icon to upload one"
                  />
                )}
              </div>
            </>
          </>
        ) : (
          <>
            {worksheetQuestions.map((question, i) => (
              <div key={i} className="questions-wrapper">
                <ul
                  className="input-list"
                  style={{
                    listStylePosition: "outside",
                    listStylequestion_type: "none",
                    columnGap: "30px",
                    display: mobile ? "block" : "inherit",
                  }}
                >
                  <li
                    className="input-item"
                    style={{
                      width: "",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>Q{i + 1}. </span>
                    <p>
                      {question.question_type === "German without image" ||
                      question.question_type === "Spell without image"
                        ? `${question.question}_______________`
                        : question.question}
                    </p>
                  </li>
                  <li
                    style={
                      mobile
                        ? { listStyleType: "none", marginTop: 8 }
                        : { listStyleType: "none" }
                    }
                  >
                    {
                      <p style={{ fontWeight: "bold" }}>{`(${question.mark} ${
                        question.mark == 1 ? "Mark" : "Marks"
                      })`}</p>
                    }
                  </li>
                </ul>

                {question.question_type ===
                "Multiple Choice Question without Image" ? (
                  <div>
                    <ul className="see-options">
                      <li>{question.options[0]}</li>
                      <li>{question.options[1]}</li>
                      <li>{question.options[2]}</li>
                      <li>{question.options[3]}</li>
                    </ul>
                    <p
                      style={{ color: "#E05615", marginLeft: 30 }}
                    >{`ANS: ${question.correct_answer.toUpperCase()}`}</p>
                  </div>
                ) : question.question_type ===
                  "Multiple Choice Question with Image" ? (
                  <div className="display-questions">
                    <div className="selected-image">
                      <div className="show-img">
                        <img src={question.img_urls[0]} alt="" />
                      </div>
                    </div>
                    {
                      <ul className="see-options">
                        <li>{question.options[0]}</li>
                        <li>{question.options[1]}</li>
                        <li>{question.options[2]}</li>
                        <li>{question.options[3]}</li>
                      </ul>
                    }
                    <p
                      style={{ color: "#E05615", marginLeft: 30 }}
                    >{`ANS: ${question.correct_answer.toUpperCase()}`}</p>
                  </div>
                ) : question.question_type === "True/False without image" ? (
                  <div>
                    <ul className="see-options">
                      <li>True</li>
                      <li>False</li>
                    </ul>
                    <p
                      style={{ color: "#E05615", marginLeft: 30 }}
                    >{`ANS: ${question.correct_answer.toUpperCase()}`}</p>
                  </div>
                ) : question.question_type === "True/False with image" ? (
                  <div className="display-questions">
                    <div className="selected-image">
                      <div className="show-img">
                        <img src={question.img_urls[0]} alt="" />
                      </div>
                    </div>
                    {
                      <ul className="see-options">
                        <li>True</li>
                        <li>False</li>
                      </ul>
                    }
                    <p
                      style={{ color: "#E05615", marginLeft: 30 }}
                    >{`ANS: ${question.correct_answer.toUpperCase()}`}</p>
                  </div>
                ) : question.question_type === "German with image" ||
                  question.question_type === "Spell with image" ? (
                  <>
                    <div className="selected-image">
                      <div className="show-img">
                        <img src={question.img_urls[0]} alt="" />
                      </div>
                    </div>
                    <div className="correct-answer-div">
                      {/* Enter Correct Answer <input className="correct-answer" name='correct_answer' onChange={(e) => handleCorrectAnswer(e, i)}/> */}
                      <p
                        style={{ color: "#E05615", marginLeft: 30 }}
                      >{`ANS: ${question.correct_answer.toUpperCase()}`}</p>
                    </div>
                  </>
                ) : question.question_type === "German without image" ||
                  question.question_type === "Spell without image" ? (
                  <div className="correct-answer-div">
                    {/* Enter Correct Answer <input className="correct-answer"  name='correct_answer' onChange={(e) => handleCorrectAnswer(e, i)}/> */}
                    <p
                      style={{ color: "#E05615", marginLeft: 30 }}
                    >{`ANS: ${question.correct_answer.toUpperCase()}`}</p>
                  </div>
                ) : question.question_type === "Multiple Images Question" ? (
                  <>
                    <div className="questions-wrapper">
                      <div className="display-questions">
                        <div className="selected-image">
                          {question.img_urls.map((item, index) => (
                            <div className="show-img" key={index}>
                              <img src={item} alt="" />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <p
                      style={{ color: "#E05615", marginLeft: 30 }}
                    >{`ANS: ${question.correct_answer.toUpperCase()}`}</p>
                    <div className="correct-answer-div">
                      {/* Enter Correct Answer{" "}
                      <select className="correct-answer" onChange={(e) => handleCorrectAnswer(e, i)}>
                        <option value="a">A</option>
                        <option value="b">B</option>
                        <option value="c">C</option>
                        <option value="d">D</option>
                      </select> */}
                    </div>
                  </>
                ) : null}
              </div>
            ))}
            {worksheetQuestions.length == 0 ? (
              <>
                <Novideo
                  img={worksheet}
                  text="No exercises have been added. Tap the icon to start setting questions."
                />
              </>
            ) : null}
          </>
        )}
      </div>


      <Dialog onClose={closeDialogBox} open={openDialog}>
          <DialogTitle>Delete Worksheet?</DialogTitle>
          <div style={{ marginLeft: 100, marginRight: 100 }}>
            <div style={{ marginBottom: 30 }}>
              Are you sure you want to delete this entire worksheet?
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 20,
              }}
            >
            <div >

              <Button2
                
                click={(e) => {
                  e.preventDefault();
                  closeDialogBox();
                }}
                style={{ marginBottom: 10,marginRight: 20, }}
                name={"Cancel"}
              />
              <Button
                disabled={loadingDialogButton}
                click={(e) => {
                  e.preventDefault();

                  if(loadingDialogButton == false){
                    deleteWorksheet();
                  }

                }}
                style={{ marginBottom: 10, marginLeft: 20, }}
                name={"Delete"}
              />
            </div>
            </div>
          </div>
        </Dialog>


    </div>
  );
}
