import React, { useState, useContext, useEffect } from "react";
import "./firstcat.css";
import { useNavigate, useLocation } from "react-router-dom";
import { BackButton } from "../studentPages/PersonalData";
import { Toggle } from "./Resources";
import { adminContext } from "../context/adminContext";

export default function Sections() {
  const navigate = useNavigate();
  const location = useLocation();
  const session = location.state.session;
  const subject = location.state.subject;
  const type = location.state.type;
  const class_name = location.state.class_name;

  const values = useContext(adminContext);
  const { teacherData, teachertoken } = values;
  const dataT = JSON.parse(teacherData);

  const [loading, setLoading] = useState(true);
  const [ca, setCa] = useState({});

  const [toggle, setToggle] = useState(true);


  const tableValues = ["S/N", "Name", "Student Id", "Section A", "Section B"];

  const setCaNo = (type) => {
    if(type == '1st Continuous Assessment Test'){
      return 1;
    }
    if(type == '2nd Continuous Assessment Test'){
      return 2;
    }else{
      return 3;
    }
  }

  const setAssessType = (type) => {
    if(type == '1st Continuous Assessment Test'){
      return 'ca';
    }
    if(type == '2nd Continuous Assessment Test'){
      return 'ca';
    }else{
      return 'exam';
    }
  }

  // check if there is a preexiting continous assessment for this session and term
  const viewContinousAssessment = async () => {
    setLoading(true);
    const res = await fetch(
      "https://server-olhms.onrender.com/teacher_continous_assessment/view_continous_assessments",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(teachertoken),
          session: session.session,
          term: session.current_term,
          subject: subject,
          ca: setCaNo(type),
          type: setAssessType(type),
          class_name: class_name
        }),
      }
    );
    const data = await res.json();
    setLoading(false);
    if(data?.msg == 'no continous_assessments at the moment'){
      setCa({
        submissions_obj: [],
        submissions_theory: []
      });
    }else{
      setCa(data?.continous_assessment);
    }
    console.log(JSON.parse(teachertoken));
    console.log(data);
  };

  useEffect(() => {
    viewContinousAssessment();
  }, []);

  const getSecBScore = (submissions, aStudId) => {
    let score = 'Nil';
    if(submissions.length > 0){
      submissions.map(sub => {
        if(sub.student_id == aStudId){
          score = sub.score;
        }
      });
      if(score == null){
        return 'NIL'
      }
      return score;
    }else{
      return 'Nil';
    }
  }

    // basic responsiveness handling
    const [mobile, setMobile] = useState(false);
    useEffect(() => {
      function handleResize() {
        if (document.documentElement.clientWidth <= 800) {
          setMobile(true);
        } else {
          setMobile(false);
        }
      }
  
      handleResize();
  
      window.addEventListener("resize", handleResize);
  
      return () => window.removeEventListener("resize", handleResize);
    }, []);

  return (
    <div className={mobile ? '' : 'set-questions'} style={mobile ? {margin: 24} : {}}>
      <div className="set-container">
        <BackButton click={() => navigate(-1)} />
        <div className="cat-session">
          <p>{session.current_term}</p>
          <p>{type}</p>
          <p>{session.session} Session</p>
        </div>
        <div className="cat-session">
          <p>
            <strong style={{ fontSize: "20px" }}>{subject}</strong>
          </p>
        </div>

        <Toggle
          click1={() => setToggle(true)}
          click2={() => setToggle(false)}
          toggle={toggle}
          text1="Questions"
          text2="Submissions"
        />
        {toggle ? <div className="set-subjects" style={{ width: "100%" }}>
          <p>Please select the question form/type you want to set.</p>
          <div
            style={{
              display: "flex",
              columnGap: "30px",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              alignSelf: "center",
              margin: "0 auto",
            }}
          >
            <div
              className="section-split"
              onClick={() => navigate("/teacher/check-subject", {
                state: {subject, session, ca_id: ca._id, section_present: ca.section_present, type}
              })}
            >
              <div
                className="sub-first"
                style={{ backgroundColor: "#E05615", color: "white" }}
              >
                A
              </div>
              <p>
                <strong style={{ fontSize: "14px" }}>Section A</strong>
              </p>
              <p
                style={{
                  maxWidth: "250px",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                This section enables you create{" "}
                <strong style={{ fontSize: "14px" }}>
                  objective questions
                </strong>{" "}
                for the students.
              </p>
            </div>
            <div className="section-split"
              onClick={() => navigate("/teacher/check-subject-theory", {
                  state: {subject, session, ca_id: ca._id, section_present: ca.section_present, type}
                })}
                >
              <div
                className="sub-first"
                style={{ backgroundColor: "#E05615", color: "white" }}
              >
                B
              </div>
              <p>
                <strong style={{ fontSize: "14px" }}>Section B</strong>
              </p>
              <p
                style={{
                  maxWidth: "250px",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                This section enables you create{" "}
                <strong style={{ fontSize: "14px" }}>theory questions</strong>{" "}
                for the students.
              </p>
            </div>
          </div>
        </div> : 
        (
          <div className="submitted-students">
            <p>Student List</p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "5px",
              }}
            >
              <Table columns={mobile ? "0.2fr 2fr 1fr 1fr 1fr" : "0.5fr 2fr 1fr 1fr 1fr"} data={tableValues} />

              {
                ca.submissions_obj.length > 0 ?

                ca.submissions_obj.map((sub, index) => (
                  <div
                    key={index}
                    style={{
                      display: "grid",
                      gridTemplateColumns: mobile ? "0.2fr 2fr 1fr 1fr 1fr" : "0.5fr 2fr 1fr 1fr 1fr",
                      fontWeight: "300",
                      gridGap: "20px",
                    }}
                  >
                    <div>{index + 1}</div>
                    <div>{sub.student_name}</div>
                    <div>{sub.student_id}</div>
                    <div onClick={(e) => {
                      e.preventDefault();
                      if(sub.score != undefined && sub.score != null && sub.score != ''){
                        navigate("/teacher/student-answers-obj", {
                          state: {subject, session, ca_id: ca._id, section_present: ca.section_present, type, sub}
                        })
                      }
                    }}>{sub.score}</div>

                    <div onClick={(e) => {
                      e.preventDefault();
                      // if(ca.submissions_theory[index].score != undefined && ca.submissions_theory[index].score != ''){
                      //   navigate("/teacher/student-answers-theory", {
                      //     state: {subject, session, ca_id: ca._id, section_present: ca.section_present, type, sub: ca.submissions_theory[index]}
                      //   })
                      // }
                      navigate("/teacher/student-answers-theory", {
                          state: {subject, session, ca_id: ca._id, section_present: ca.section_present, type, sub: ca.submissions_theory[index]}
                      });
                      
                    }}>{getSecBScore(ca.submissions_theory, sub.student_id)}</div>
                  </div>
                ))

                :
                
                ca.submissions_theory.map((sub, index) => (
                  <div
                    key={index}
                    style={{
                      display: "grid",
                      gridTemplateColumns: "0.5fr 2fr 1fr 1fr 1fr",
                      fontWeight: "300",
                      gridGap: "30px",
                    }}
                  >
                    <div>{index + 1}</div>
                    <div>{sub.student_name}</div>
                    <div>{sub.student_id}</div>
                    <div onClick={(e) => {
                      e.preventDefault();
                      if(ca.submissions_obj[index].score != undefined && ca.submissions_obj[index].score != null && ca.submissions_obj[index].score != ''){
                        navigate("/teacher/student-answers-obj", {
                          state: {subject, session, ca_id: ca._id, section_present: ca.section_present, type, sub: ca.submissions_obj[index]}
                        })
                      }
                      
                    }}>{getSecBScore(ca.submissions_obj, sub.student_id)}</div>

                    <div onClick={(e) => {
                      e.preventDefault();
                      if(sub.score != undefined && sub.score != null && sub.score != ''){
                        navigate("/teacher/student-answers-theory", {
                          state: {subject, session, ca_id: ca._id, section_present: ca.section_present, type, sub}
                        })
                      }
                    }}>{sub.score == null ? 'Nil' : sub.score}</div>
                  </div>
                ))
              }
            </div>
          </div>
        )
        }
      </div>
    </div>
  );
}

export function Table(props) {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: props.columns,
        fontWeight: "500",
        gridGap: "30px",
      }}
    >
      {props.data.map((item, index) => (
        <div key={index}>{item}</div>
      ))}
    </div>
  );
}

export function Table2(props) {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: props.columns,
        fontWeight: "500",
        gridGap: "30px",
      }}
    >
      {props.data.map((item, index) => (
        <div
          key={index}
          style={{ textAlign: item.center === true ? "center" : "" }}
        >
          {item.data}
        </div>
      ))}
    </div>
  );
}