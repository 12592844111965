import React, { useState, useContext } from "react";
// import Passport from "../../images/news1.png";
import { useNavigate } from "react-router-dom";
import { BackButton } from "../studentPages/PersonalData";
import Add from "../../images/add-gallery.png";
import Button, { Button2 } from "../../components/Button/Button";
import { adminContext } from "../context/adminContext";
import axios from "axios";
import Popup from "../../components/Popup/Popup";
import { toast } from "react-hot-toast"; // Import the Toaster component

export default function CreateStaff() {
  const navigate = useNavigate();
  const [create, setCreate] = useState(false);
  const values = useContext(adminContext);
  const [staffMail, setStaffMail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [staffPassword, setStaffPassword] = useState("");
  const [staffId, setStaffId] = useState("");
  const { admintoken } = values;
  const [loading, setLoading] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedSrc, setSelectedSrc] = useState("");
  const [formData, setFormData] = useState({
    firstname: "",
    middlename: "",
    lastname: "",
    country: "Nigeria",
    address: "",
    number1: "",
    number2: "",
    email: "",
    religion: "Christian",
    gender: "female",
    centre: "Emerald",
    role: "",
    year: 2019,
    class_name: "",
  });
  const [show, setShow] = useState(false);
  const handleChange = (e) => {
    const target = e.target;

    const { name, value } = target;
    setFormData({ ...formData, [name]: value });
    // console.log(formData);
  };
  const onFileChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState == 2) {
        setSelectedSrc(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
    setIsSelected(true);
  };

  // function to handle showing toast, after importing it on this file and placing it at the top level of your application which would allow you to use the toasts (pop-up notifications) throughout your application.
  const showErrorToast = (message) => {
    toast.error(message, {
      icon: "❗️",
      position: "bottom-center",
    });
  };

  // useEffect(() => {

  //   console.log(years[years.length - 1]);
  // }, []);

  const years = [
    // 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028
    2016,2017,2019, 2019,
    2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031,
    2032, 2033, 2034, 2035,
  ];

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  if (years[years.length - 1] != currentYear) {
    years.push(currentYear);
  }
  // console.log();
  const handleCreate = (e) => {
    e.preventDefault();
    setErrorMessage("");
    setLoading(true);
    // console.log(formData.class_name);
    const bodyFormData = new FormData();

    bodyFormData.append("token", JSON.parse(admintoken));
    bodyFormData.append("firstname", formData.firstname);
    bodyFormData.append("lastname", formData.lastname);
    bodyFormData.append("middlename", formData.middlename);
    bodyFormData.append("email", formData.email);
    bodyFormData.append("phone_no1", formData.number1);
    bodyFormData.append("phone_no2", formData.number2);
    bodyFormData.append("address", formData.address);
    bodyFormData.append("country", formData.country);
    bodyFormData.append("religion", formData.religion);
    bodyFormData.append("center", formData.centre);
    bodyFormData.append("gender", formData.gender);
    bodyFormData.append("employment_year", formData.year);
    bodyFormData.append("role", formData.role.toLowerCase());
    bodyFormData.append("staff-images", selectedFile);
    bodyFormData.append("class_name", formData.class_name);

    axios
      .post(
        "https://server-olhms.onrender.com/master_admin/add_staff",
        bodyFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        console.log(` ------> ${response.data}`);

        if (response.status === 200) {
          setCreate(false);
          setLoading(false);
          setShow(true);
          setStaffId(response.data.login_details.staff_id);
          setStaffPassword(response.data.login_details.password);
          setStaffMail(response.data.login_details.email);
        } else {
          // console.log(`mine ------> ${response.data.msg}`);
          setErrorMessage(response.data.msg);
          setLoading(false);
        }
        // console.log(response.data);
      })
      .catch((error) => {
        // 4tKnight: For some reason anything the response is not successful, Axios doesn't go to the .then block
        console.log(`mine ------> ${error}`);
        console.log(`error message ------> ${error.message}`);
        if (error == "AxiosError: Request failed with status code 400") {
          setErrorMessage(
            "A staff with this email/phone number already exists"
          );
        } else if (error == "AxiosError: Network Error") {
          setErrorMessage(
            "Please check your internet connection & try again"
          );
        } else {
          setErrorMessage("An error occured");
        }
        setLoading(false);
        // setLoading(false);
      });
  };
  const handleIgnore = (e) => {
      e.preventDefault();


    if (formData.firstname === "") {
      showErrorToast("First name cannot be empty");
    } else if (formData.middlename === "") {
      showErrorToast("Middle name is compulsory");
    }  else if (formData.lastname === "") {
      showErrorToast("Last name cannot be empty");
    } else if (formData.address === "") {
      showErrorToast("Address cannot be empty");
    } else if (formData.number1 === "") {
      showErrorToast("Phone number cannot be empty");
    } else if (formData.email === "") {
      showErrorToast("Email cannot be empty");
    } else if (formData.role === "") {
      showErrorToast("Staff role cannot be empty");
    }  else if (formData.role.toLowerCase().includes("teacher")&&formData.class_name==="") {
      showErrorToast(`Select a class for ${formData.firstname}`);
    } else if (selectedSrc === "") {
      showErrorToast("Please enter staff image");
    } else {
      setErrorMessage("");
      setCreate(true);
    }
  };
  return (
    <div
      className="set-questions"
      style={{
        alignItems: "flex-start",
      }}
    >
      <div className="set-container">
        <BackButton click={() => navigate(-1)} />
        <form
          style={{ marginTop: "50px" }}
          // onSubmit={handleCreate}
          className="show-details-form"
        >
          <div className="show-details">
            <div>First Name:</div>
            <input
              className="properties-input"
              name="firstname"
              onChange={handleChange}
              value={formData.firstname}
            />
            <div>Middle Name:</div>
            <input
              className="properties-input"
              name="middlename"
              onChange={handleChange}
              value={formData.middlename}
            />
            <div>Last Name:</div>
            <input
              className="properties-input"
              name="lastname"
              onChange={handleChange}
              value={formData.lastname}
            />
            <div>Permanent Address:</div>
            <input
              className="properties-input"
              name="address"
              onChange={handleChange}
              value={formData.address}
            />
            <div>Country:</div>
            <input
              className="properties-input"
              name="country"
              onChange={handleChange}
              value={formData.country}
            />
            <div>Phone Number 1:</div>
            <input
              className="properties-input"
              name="number1"
              onChange={handleChange}
              value={formData.number1}
              maxLength={11}
            />
            <div>Phone Number 2:</div>
            <input
              className="properties-input"
              name="number2"
              onChange={handleChange}
              value={formData.number2}
              maxLength={11}
            />
            <div>Email:</div>
            <input
              className="properties-input"
              name="email"
              onChange={handleChange}
              value={formData.email}
            />
            <div>Religion:</div>
            <div className="properties-input">
              <select
                value={formData.religion}
                name="religion"
                onChange={handleChange}
                // value={formData.firstname}
              >
                <option value="Christian">Christian</option>
                <option value="Muslim">Muslim</option>
                <option value="Other">Other</option>
              </select>
            </div>
            {/* <input
              className="properties-input"
              name="religion"
              onChange={handleChange}
              value={formData.religion}
            /> */}
            <div>Gender:</div>
            <div className="properties-input">
              <select
                value={formData.gender}
                name="gender"
                onChange={handleChange}
                // value={formData.firstname}
              >
                <option value="female">Female</option>
                <option value="male">Male</option>
              </select>
            </div>
            <div>Centre:</div>
            <div className="properties-input">
              <select
                value={formData.centre}
                name="centre"
                onChange={handleChange}
              >
                <option value="Emerald">Emerald</option>
                <option value="Topaz">Topaz</option>
              </select>
            </div>
            <div>Role:</div>
            <input
              className="properties-input"
              name="role"
              onChange={handleChange}
              value={formData.role}
            />
            {/* {formData.role.toLowerCase() === "teacher" ? ( */}
            {formData.role.toLowerCase().includes("teacher") ? (
              <>
                <div>Class:</div>
                <div className="properties-input">
                  <select
                    value={formData.class_name}
                    name="class_name"
                    onChange={handleChange}
                    // value={formData.firstname}
                  >
                    <option value="">Select a class</option>
                    <option value="Creche">Creche</option>
                    <option value="Toddlers">Toddlers</option>
                    <option value="Infant Community 1">
                      Infant Community 1
                    </option>
                    <option value="infant community 2">
                      Infant Community 2
                    </option>
                    <option value="Nursery 1">Nursery 1</option>
                    <option value="Nursery 2">Nursery 2</option>
                    <option value="Grade 1">Grade 1</option>
                    <option value="Grade 2">Grade 2</option>
                    <option value="Grade 3">Grade 3</option>
                    <option value="Grade 4">Grade 4</option>
                    <option value="Grade 5">Grade 5</option>
                  </select>
                </div>
              </>
            ) : null}
            <div>Employment Year:</div>
            <div className="properties-input">
              <select value={formData.year} name="year" onChange={handleChange}>
                {years.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </div>
            <div>Photo:</div>
            <div className="teacher-image">
              {isSelected ? (
                <div className="data-img">
                  <img
                    src={selectedSrc}
                    alt=""
                    style={{ objectFit: "cover", marginRight: "10px" }}
                  />
                </div>
              ) : null}
              <div className="add-image">
                <label>
                  <img src={Add} alt="" style={{ marginLeft: "10px" }} />
                  <input
                    // required
                    onChange={onFileChange}
                    type="file"
                    accept=".png, .jpg, .jpeg, .gif"

                    // name="selectedFile"
                  />
                </label>
              </div>
            </div>
          </div>

          <Button2
            name="Add to Staff Database and generate Login Details"
            click={handleIgnore}
          />
        </form>
        <Popup isOpen={create}>
          <div className="central-pop">
            <p>Create Staff Record</p>
            <div className="central-details">
              Are you sure you want to create a staff record for{" "}
              {formData.firstname +
                (formData.middlename ? " " + formData.middlename : "") +
                " " +
                formData.lastname}
              . Then subsequently generate login details?
            </div>
            <div className="pop-central">
              <Button2 name="No" click={() => setCreate(false)} />
              <Button name="Yes" click={handleCreate} loading={loading} />
            </div>
            <p style={{ fontSize: "12px", color: "red", padding: "5px" }}>
              {errorMessage}
            </p>
          </div>
        </Popup>

        <Popup isOpen={show}>
          <div className="central-pop">
            <p>Staff Login Details</p>
            <div className="central-details">
              <p>Email: {staffMail}</p>
              <p>Staff ID: {staffId}</p>
              <p>Password: {staffPassword}</p>
            </div>
            <div className="pop-central">
              <p
                style={{ color: "#E05615", cursor: "pointer" }}
                onClick={() => {
                  setShow(false);
                  navigate("/admin/staff");
                }}
              >
                Go to Staff Database
              </p>
            </div>
          </div>
        </Popup>
      </div>
    </div>
  );
}

export function Popcentral(props) {
  return (
    <Popup isOpen={props.isOpen}>
      <div className="central-pop">
        <p>{props.centralTitle}</p>
        <div className="central-details">{props.centraltext}</div>
        <div className="pop-central">
          <Button2 name="No" click={props.cancel} />
          <Button name="Yes" click={props.do} />
        </div>
      </div>
    </Popup>
  );
}
