import React from "react";
import { Toggle } from "./Resources";
import { useState, useEffect, useContext } from "react";
import Edit from "../../images/edit.png";
import Add from "../../images/addition.png";
import "./teacherprofile.css";
import "../studentPages/personaldata.css";
import Passport from "../../images/news1.png";
import { BackButton } from "../studentPages/PersonalData";
import Button, { Button2 } from "../../components/Button/Button";
import { adminContext } from "../context/adminContext";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";

export default function TeacherProfile() {
  const [edit, setEdit] = useState(false);
  const [toggle, setToggle] = useState(true);
  const values = useContext(adminContext);
  const { teacherData, teachertoken } = values;
  const dataT = JSON.parse(teacherData);
  const [loading, setLoading] = useState(false);

  const [teacher, setTeacher] = useState({
    address: dataT.address,
    phone_no1: dataT.phone_no1,
    phone_no2: dataT.phone_no2,
    email: dataT.email,
    religion: dataT.religion,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const temp = { ...teacher };
    temp[name] = value;
    setTeacher(temp);
  };

  // basic responsiveness handling
  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (document.documentElement.clientWidth <= 800) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function capitalizeWords(sentence) {
    // Split the sentence into an array of words.
    const words = sentence.split(' ');
  
    // Capitalize the first letter of each word.
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
  
    // Join the words back into a sentence.
    return words.join(' ');
  }

  const [students, setStudents] = useState([]);

  const viewStudents = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/teacher_profile/students_list",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(teachertoken),
          class_name: capitalizeWords(dataT.class_name),
          center: dataT.center + " Centre",
        }),
      }
    );
    const data = await res.json();
    setStudents(data?.students);

    // console.log(data?.students);
  };

  useEffect(() => {
    viewStudents();
  }, []);

  // function to update teacher profile
  const updateTeacherProfile = async () => {
    setLoading(true);
    const res = await fetch(
      "https://server-olhms.onrender.com/teacher_profile/edit_profile",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(teachertoken),
          staff_id: dataT._id,
          address: teacher.address,
          phone_no1: teacher.phone_no1,
          phone_no2: teacher.phone_no2,
          email: teacher.email,
          religion: teacher.religion,
        }),
      }
    );
    const data = await res.json();
    localStorage.setItem("teacherData", JSON.stringify(data?.staff));
    setTeacher(data?.staff);
    setEdit(false);
    setLoading(false);
    window.location.reload(false);
    console.log(data?.staff);
  };

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [match, setMatch] = useState(true);
  const [openDialog, handleDisplay] = useState(false);

  const handleClose = () => {
    handleDisplay(false);
    setConfirmPassword("");
    setOldPassword("");
    setNewPassword("");
  };

  const openDialogBox = () => {
    handleDisplay(true);
  };
  const dialogStyle = {
    padding: "20px",
  };

  const changePassword = async () => {
    setLoading(true);

    try {
      const res = await fetch(
        "https://server-olhms.onrender.com/teacher_profile/change_password_web",
        {
          method: "POST",
          body: JSON.stringify({
            token: JSON.parse(teachertoken),
            old_password: oldPassword,
            new_password: newPassword,
            confirm_password: confirmPassword,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const data = await res.json();

      if (data.msg == "Success") {
        setLoading(false);
        alert("password change successful");
        handleClose();
      } else {
        setLoading(false);
        console.log(data);
        alert("An error occured");
      }
    } catch (error) {
      setLoading(false);
      alert("An error occured");
      console.log(error);
    }
  };

  const handleOldPassword = (e) => setOldPassword(e.target.value);
  const handleNewPassword = (e) => setNewPassword(e.target.value);
  const handleConfirmPassword = (e) => setConfirmPassword(e.target.value);

  useEffect(() => {
    if (newPassword == confirmPassword) {
      setMatch(true);
    } else {
      setMatch(false);
    }
  }, [newPassword, confirmPassword]);

  return (
    <div
      className={mobile ? "" : "set-questions"}
      style={
        mobile
          ? {
              margin: 24,
              backgroundColor: toggle ? "" : "rgb(255, 248, 248)",
              height: toggle ? "" : "100%",
              alignItems: "flex-start",
            }
          : {
              backgroundColor: toggle ? "" : "rgb(255, 248, 248)",
              height: toggle ? "" : "100%",
              alignItems: "flex-start",
            }
      }
    >
      <div
        className="set-container"
        // style={{ padding: toggle ? "" : "0 120px" }}
      >
        <p
          style={{
            alignSelf: "flex-start",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          My Profile
        </p>
        <Toggle
          click1={() => setToggle(true)}
          click2={() => setToggle(false)}
          toggle={toggle}
          text1="Personal Data"
          text2="Class List"
        />
        {toggle ? (
          edit === false ? (
            <div
              style={{ alignSelf: "flex-end" }}
              className="edit-img"
              onClick={() => {
                setEdit(true)
              }}
            >
              <img src={Edit} alt="" />
            </div>
          ) : (
            <div style={{ alignSelf: "flex-end" }}>
              <BackButton click={() => setEdit(false)} />
            </div>
          )
        ) : null}

        {toggle ? (
          <div className="show-details">
            {edit ? null : <div>First Name:</div>}
            {edit ? null : (
              <div className="light-property">{dataT?.firstname}</div>
            )}
            {edit ? null : <div>Middle Name:</div>}
            {edit ? null : (
              <div className="light-property">{dataT?.middlename}</div>
            )}
            {edit ? null : <div>Last Name:</div>}
            {edit ? null : (
              <div className="light-property">{dataT?.lastname}</div>
            )}
            <div>Permanent Address:</div>
            {edit ? (
              <input
                className="properties-input"
                name="address"
                value={teacher.address}
                defaultValue={teacher.address}
                onChange={handleChange}
              />
            ) : (
              <div className="light-property">{dataT?.address}</div>
            )}
            {edit ? null : <div>Country:</div>}
            {edit ? null : (
              <div className="light-property">{dataT?.country}</div>
            )}
            <div>Phone Number 1:</div>
            {edit ? (
              <input
                className="properties-input"
                name="phone_no1"
                value={teacher.phone_no1}
                defaultValue={teacher.phone_no1}
                onChange={handleChange}
                maxLength={11}
              />
            ) : (
              <div className="light-property">{dataT?.phone_no1}</div>
            )}
            <div>Phone Number 2:</div>
            {edit ? (
              <input
                className="properties-input"
                name="phone_no2"
                value={teacher.phone_no2}
                defaultValue={teacher.phone_no2}
                onChange={handleChange}
                maxLength={11}
              />
            ) : (
              <div className="light-property">
                {dataT?.phone_no2 ? dataT?.phone_no2 : "nil"}
              </div>
            )}
            <div>Email:</div>
            {edit ? (
              <input
                className="properties-input"
                name="email"
                value={teacher.email}
                defaultValue={teacher.email}
                onChange={handleChange}
              />
            ) : (
              <div className="light-property">{dataT?.email}</div>
            )}
            <div>Religion:</div>
            {edit ? (
              <input
                className="properties-input"
                name="religion"
                value={teacher.religion}
                defaultValue={teacher.religion}
                onChange={handleChange}
              />
            ) : (
              <div className="light-property">{dataT?.religion}</div>
            )}
            <div>Gender:</div>
            <div className="light-property">{dataT?.gender}</div>
            <div>Staff ID:</div>
            <div className="light-property">{dataT?.staff_id}</div>
            <div>Centre:</div>
            <div className="light-property">{dataT?.center}</div>
            {edit ? null : <div>Role:</div>}
            {edit ? null : <div className="light-property">{dataT?.role}</div>}

            {/* {dataT?.role.toLowerCase() === "teacher" ? (
              <>
                <div>Staff ID:</div>
                <div className="light-property">{dataT?.staff_id}</div>
              </>
            ) : null} */}

            {/* {dataT?.role.toLowerCase() === "teacher" ? (
              <>
                <div>Class:</div>
                <div className="properties-input">
                  <select
                    value={formData.class_name}
                    name="class_name"
                    onChange={handleChange}
                    // value={formData.firstname}
                  >
                    <option value="">Select a class</option>
                    <option value="Creche">Creche</option>
                    <option value="Toddlers">Toddlers</option>
                    <option value="Infant Community 1">
                      Infant Community 1
                    </option>
                    <option value="infant community 2">
                      Infant Community 2
                    </option>
                    <option value="Nursery 1">Nursery 1</option>
                    <option value="Nursery 2">Nursery 2</option>
                    <option value="Grade 1">Grade 1</option>
                    <option value="Grade 2">Grade 2</option>
                    <option value="Grade 3">Grade 3</option>
                    <option value="Grade 4">Grade 4</option>
                    <option value="Grade 5">Grade 5</option>
                  </select>
                </div>
              </>
            ) : null} */}
            <div>Employment Year:</div>
            <div className="light-property">{dataT?.employment_year}</div>
            <div>Photo:</div>
            <div className="data-img">
              <img src={dataT?.img_url} alt="" />
            </div>
          </div>
        ) : (
          <div className="class-list">
            <div className="split-list">
              <p style={{margin:"5px 0 20px 10px"}}>Class List</p>
            </div>
            <table>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Name</th>
                  <th>Gender</th>
                  <th>Student ID</th>
                </tr>
              </thead>
              <tbody>
                {students.map((student, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{student.fullname}</td>
                    <td>{student.gender}</td>
                    <td>{student.student_id}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {toggle ? (
          edit ? (
            <Button
              name="Update & Save Personal Data"
              disabled={loading}
              click={(e) => {
                e.preventDefault();
                updateTeacherProfile();
              }}
            />
          ) : (
            <Button2
              name="Change Password"
              click={(e) => {
                e.preventDefault();
                openDialogBox();
              }}
            />
          )
        ) : null}
      </div>

      <Dialog onClose={handleClose} open={openDialog}>
        <DialogTitle>Change Password</DialogTitle>
        <div style={{ marginLeft: 100, marginRight: 100 }}>
          <div style={{ marginBottom: 12 }}>Old Password</div>
          <input
            className="properties-input"
            style={{ marginBottom: 20 }}
            value={oldPassword}
            name="old_password"
            placeholder="Enter Old Password"
            type="password"
            onChange={handleOldPassword}
          />

          <div style={{ marginBottom: 12 }}>New Password</div>
          <input
            className="properties-input"
            style={{ marginBottom: 20 }}
            value={newPassword}
            name="new_password"
            placeholder="Enter New Password"
            type="password"
            onChange={handleNewPassword}
          />

          <div style={{ marginBottom: 12 }}>Confirm Password</div>
          <input
            className="properties-input"
            style={{ marginBottom: 20 }}
            value={confirmPassword}
            name="confirm_password"
            placeholder="Confirm New Password"
            type="password"
            onChange={handleConfirmPassword}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              visibility: match ? "hidden" : "visible",
            }}
          >
            <div style={{ marginBottom: 10, color: "red" }}>
              Passwords do not match!
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 20,
            }}
          >
            <Button
              disabled={loading}
              click={(e) => {
                e.preventDefault();
                if (
                  newPassword == "" ||
                  oldPassword == "" ||
                  confirmPassword == ""
                )
                  return;

                if (match) {
                  changePassword();
                }
              }}
              style={{ marginBottom: 10 }}
              name={"Save and Update"}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}
