import React from "react";
import { BackButton } from "../studentPages/PersonalData";
import { useNavigate } from "react-router-dom";
import Edit from "../../images/edit.png";
import Delete from "../../images/delete.png";
import "./viewfaq.css";
import { useState, useEffect } from "react";

export default function ViewFaq() {
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const [faq, setFaq] = useState([]);
  const [loading, setLoading] = useState(true);

  const viewStaff = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_faqs/view_multiple_faqss",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        // body: JSON.stringify({}),
      }
    );
    const data = await res.json();
    setLoading(false);
    setFaq(data?.faqs);
    console.log(data);
  };

  useEffect(() => {
    viewStaff();
  }, []);

  if (loading === false)
    return (
      <div
        className="set-questions"
        style={{
          height: "100%",
          alignItems: "flex-start",
        }}
      >
        <div className="set-container">
          <BackButton click={() => navigate(-1)} />
          <p className="data-number">Frequently Asked Questions</p>
          <button
            type="button"
            className="add-button"
            onClick={() =>
              navigate("/admin/FAQ", {
                state: {
                  questionname: "",
                  answername: "",
                  id: "",
                },
              })
            }
          >
            + Add another question
          </button>
          <div className="faq-flex">
            {faq.map((item, i) => (
              <div className="faq-edit" key={i}>
                <div className="full-faq">
                  <p>{item.question}</p>
                  <div>{item.answer}</div>
                </div>
                <div
                  className="edit-img-more"
                  onClick={() =>
                    navigate("/admin/FAQ", {
                      state: {
                        questionname: item?.question,
                        answername: item?.answer,
                        id: item?._id,
                      },
                    })
                  }
                >
                  <img src={Edit} alt="" />
                </div>
                <div
                  className="edit-img-more"
                  //   onClick={() => navigate("/admin/FAQ")}
                >
                  <img src={Delete} alt="" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
}
