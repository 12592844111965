import React, { useState } from "react";
import Logo from "../../images/footer-logo.png";
import Twitter from "../../images/twitter.png";
import Instagram from "../../images/instagram.png";
import Linkedin from "../../images/linkedin.png";
import Facebook from "../../images/facebook.png";
import "../Footer/footer.css";
import Send from "../../images/send.png";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";

export default function Footer() {
  const [email, setEmail] = useState("");

  const handleNewsLetterChange = (e) => {
    setEmail(e.target.value);
  };

  const subscribe = async () => {
    toast.error("Please wait...", {
      icon: "⏳️",
      position: "bottom-center",
    });

    const res = await fetch(
      "https://server-olhms.onrender.com/guardian_newsletter/subscribe_newsletter",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
        }),
      }
    );

    const data = await res.json();
    if (data?.msg == "success") {
      toast.success("Subscription successful", {
                position: "bottom-center",
              });
      setEmail("");
    } else {
      alert(data);
    }
  };

  return (
    <div className="main-footer">
      <div className="footer-flex">
        <div className="merge-logo">
          <div className="footer-logo">
            <img src={Logo} alt="" />
          </div>
          <div id="link-list2">
            <strong>LINKS:</strong>
            <ul className="links">
              <li>
                <Link to="/"> Home</Link>
              </li>
              <li>
                <Link to="/admission">Admissions</Link>
              </li>
              <li>
                <Link to="/news">News & Events</Link>
              </li>
              <li>
                <Link to="/gallery">Gallery</Link>
              </li>
              <li>
                <Link to="/blog">Blog</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              <li>
                <Link to="/portal">Portal</Link>
              </li>
            </ul>
          </div>
          <div className="display-newsletter2">
            <strong>NEWSLETTER</strong>
            <div className="links">Signup for our latest News and Updates</div>
            <div className="footer-messenger">
            <input
              className="links footer-input"
              placeholder="Enter Email Address"
              name="newsletter"
              value={email}
              onChange={handleNewsLetterChange}
            />
              <div className="footer-send">
              <img
                onClick={(e) => {
                  e.preventDefault();
                  if (email === "") {
                    toast.error("Please add an email", {
                      icon: "❗️",
                      position: "bottom-center",
                    });
                  } else {
                    subscribe();
                  }
                }}
                src={Send}
                alt=""
              />
              </div>
            </div>
          </div>
          <div className="links">
            <div className="follow-on">
              {" "}
              <strong>FOLLOW US ON:</strong>
            </div>
            <div className="links links-logo">
              <div className="split-social-handle">
                <div className="social-logo">
                  <img src={Instagram} alt="" />
                </div>
                <a
                  href="https://www.instagram.com/ourladyofhope__benin/"
                  style={{
                    color: "white",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  target="_blank"
                >
                  <p>@Ourladyofhope_benin</p>
                </a>
              </div>
              <div className="split-social-handle">
                <div className="social-logo">
                  <img src={Facebook} alt="" />
                </div>
                <a
                  href="https://web.facebook.com/Ourladyofhopemontessori/?_rdc=1&_rdr"
                  style={{
                    color: "white",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  target="_blank"
                >
                  <p>@Our lady of hope montessori schools</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div id="link-list">
          <strong>LINKS</strong>
          <ul className="links grid-links">
            <li>
              <Link to="/"> Home</Link>
            </li>
            <li>
              <Link to="/admission">Admissions</Link>
            </li>
            <li>
              <Link to="/news">News & Events</Link>
            </li>
            <li>
              <Link to="/gallery">Gallery</Link>
            </li>
            <li>
              <Link to="/blog">Blog</Link>
            </li>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
            <li>
              <Link to="/portal">Portal</Link>
            </li>
          </ul>
        </div>
        <div className="display-newsletter">
          <strong>NEWSLETTER</strong>
          <div className="links">Signup for our latest News and Updates</div>
          <div className="footer-messenger">
            <input
              className="links footer-input"
              placeholder="Enter Email Address"
              name="newsletter"
              value={email}
              onChange={handleNewsLetterChange}
            />
            <div className="footer-send">
              <img
                onClick={(e) => {
                  e.preventDefault();
                  if (email === "") {
                    toast.error("Please add an email", {
                      icon: "❗️",
                      position: "bottom-center",
                    });
                  } else {
                    subscribe();
                  }
                }}
                src={Send}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="contact-footer-div">
          <strong>CONTACT US</strong>
          <ul className="links contact-footer">
            <li className="contact-number">+2348151707292, +2348140974206</li>
            <li className="contact-message">
              {/* <Link
                to="https://ourladyofhopeschool.com.ng/#"
                style={{
                  color: "white",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                target="_blank"
              > */}
              ourladyofhopemontessori@gmail.com
              {/* </Link> */}
            </li>
            <li className="contact-address">
              Our Lady Of Hope Montessori Schools,
              {/* <br /> */}
              (Emerald Centre) 131A&B 2ND East Circular
              {/* <br />  */}
              by wisdom street junction, Benin City. Nigeria.
              <br />
              Our Lady Of Montessori Schools,(Topaz Centre) 2. 41, Agbonwonba
              street off MM way by Ogiso junction, Benin City. Nigeria.
            </li>
          </ul>
        </div>
      </div>
      <div className="copyright">
        &#169; Copyright {new Date().getFullYear()} | OurLadyofHopeSchools.com |
        All rights reserved.
      </div>
    </div>
  );
}
