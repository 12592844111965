import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
// import Footer from "../../components/Footer/Footer";
import "./portal.css";
import { adminContext } from "../context/adminContext";
import { ClipLoader } from "react-spinners";
import { toast } from "react-hot-toast";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Button, { Button2 } from "../../components/Button/Button";

let shouldLoad = true;

export default function Portal() {
  const userValues = useContext(adminContext);
  // const [formData, setFormData] = useState({
  //   email: "",
  //   password: "",
  // });
  const {
    handleSubmit,
    formData,
    setFormData,
    success,
    student,
    setAdmin,
    setStudent,
    setTeacher,
    teacher,
    admin,
    loading,
    setLoading,
  } = userValues;

  // const [student, setStudent] = useState(true);
  // const [teacher, setTeacher] = useState(false);
  // const [admin, setAdmin] = useState(false);

  const [loadingRecoverPasswordButton, setLoadingRecoverPasswordButton] =
    useState(false);
  const [showRecoverPasswordDialog, setShowRecoverPasswordDialog] =
    useState(false);
  const showRecoverPasswordDialogBox = () => {
    setShowRecoverPasswordDialog(true);
  };
  const closeRecoverPasswordDialogBox = () => {
    setShowRecoverPasswordDialog(false);
  };

  const [email, setEmail] = useState("");

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const [studentID, setStudentID] = useState("");

  const handleStudentID = (e) => {
    setStudentID(e.target.value);
  };

  const navigate = useNavigate();
  if (success === "admin") {
    if (shouldLoad == true) {
      setTimeout(() => {
        navigate("/admin/dashboard");
      }, 500);
    }
    shouldLoad = false;
  } else if (success === "teacher") {
    if (shouldLoad == true) {
      setTimeout(() => {
        navigate("/teacher/resources");
      }, 500);
    }
    shouldLoad = false;
  } else if (success === "student") {
    navigate("/student/profile");
  }

  const handleChange = (e) => {
    const target = e.target;
    const { name, value } = target;
    setFormData({ ...formData, [name]: value });
  };

  const handleForm = (e) => {
    e.preventDefault();
    if (student) {
      navigate("/student/profile");
    } else if (teacher) {
      navigate("/teacher/resources");
    }
    // else if (admin) {
    //   navigate("/admin/dashboard");
    // }
  };
  // FOR PASSWORD VISIBILITY
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="portal-sign">
        <p className="portal-first-p check-first-p">
          Welcome to your{" "}
          {student ? "Personal" : teacher ? "Teacher" : "Administrative"} school
          portal
        </p>
        <div className="centralise-portal">
          <p className="portal-first-p">
            Welcome to your{" "}
            {student ? "Personal" : teacher ? "Teacher" : "Administrative"}{" "}
            school portal
          </p>
          <div className="sign-box">
            <form className="portal-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="student">
                  {student ? "Student ID" : "Email"}
                </label>
                <input
                  name={admin || teacher ? "email" : "id"}
                  type="text"
                  value={admin || teacher ? formData.email : formData.id}
                  placeholder={
                    admin || teacher ? "Enter Email" : "Enter Student ID"
                  }
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="student">Password</label>
                <input
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={formData.password}
                  placeholder="Enter Password"
                  onChange={handleChange}
                />

                <span
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "25px",
                    cursor: "pointer",
                  }}
                  className={`password-toggle ${
                    showPassword ? "visible" : "hidden"
                  }`}
                  onClick={togglePasswordVisibility}
                >
                  <p style={{ color: "grey" }}>
                    {showPassword ? "Hide" : "Show"}
                  </p>
                </span>
              </div>
              <button
                onClick={() => {
                  shouldLoad = true;
                }}
                disabled={loading}
              >
                <span>
                  {loading ? <ClipLoader color={"white"} size={30} /> : "Login"}
                </span>
              </button>
            </form>
            <p className="portal-second-p">
              Please Note:{" "}
              <ul className="portal-list">
                <li>
                  Don't forget to logout or exit your browser when you're done.
                </li>
                <li>
                  Can't remember your password?{" "}
                  <span
                    id="orange"
                    onClick={() => showRecoverPasswordDialogBox()}
                  >
                    Recover password
                  </span>
                </li>
              </ul>
            </p>
          </div>
          {student ? (
            <div className="portal-buttons">
              <button
                onClick={() => {
                  setTeacher(true);
                  setStudent(false);
                }}
              >
                <strong>Teachers</strong>
              </button>
              <button
                onClick={() => {
                  setAdmin(true);
                  setStudent(false);
                }}
              >
                <strong>Admin</strong>
              </button>
            </div>
          ) : null}
        </div>
      </div>

      <div className="centralise-portal-none">
        <div className="sign-box">
          <form className="portal-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="student">
                {student ? "Student ID" : "Email"}
              </label>
              <input
                name={admin || teacher ? "email" : "id"}
                type="text"
                value={admin || teacher ? formData.email : formData.id}
                placeholder={
                  admin || teacher ? "Enter Email" : "Enter Student ID"
                }
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="student">Password</label>
              <input
                name="password"
                type={showPassword ? "text" : "password"}
                value={formData.password}
                placeholder="Enter Password"
                onChange={handleChange}
              />
              <span
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "20px",
                  cursor: "pointer",
                }}
                className={`password-toggle ${
                  showPassword ? "visible" : "hidden"
                }`}
                onClick={togglePasswordVisibility}
              >
                {showPassword ? "Hide" : "Show"}
              </span>
            </div>
            <button disabled={loading}>
              <span>
                {loading ? <ClipLoader color={"white"} size={30} /> : "Login"}
              </span>
            </button>
          </form>
          <p className="portal-second-p">
            Please Note:{" "}
            <ul className="portal-list">
              <li>
                Don't forget to logout or exit your browser when you're done.
              </li>
              {student ? (
                <li>
                  If you forgot your Student id or password, use the{" "}
                  <span id="orange">
                    <Link to="/contact"> enquiries or get in touch form</Link>
                  </span>{" "}
                  to request a recovery?
                </li>
              ) : (
                <li>
                  Can't remember your password?{" "}
                  <span id="orange">Recover password</span>
                </li>
              )}
            </ul>
          </p>
          {student ? (
            <div className="portal-buttons">
              <button
                onClick={() => {
                  setTeacher(true);
                  setStudent(false);
                }}
              >
                <strong>Teachers</strong>
              </button>
              <button
                onClick={() => {
                  setAdmin(true);
                  setStudent(false);
                }}
              >
                <strong>Admin</strong>
              </button>
            </div>
          ) : null}
        </div>
      </div>
      {/* <Footer /> */}

      <Dialog
        onClose={closeRecoverPasswordDialogBox}
        open={showRecoverPasswordDialog}
      >
        <DialogTitle>Recover Account</DialogTitle>
        <div style={{ marginLeft: 50, marginRight: 50 }}>
          {student ? (
            <div style={{ marginBottom: 30 }}>
              Please provide your student ID and the guardian email address
              linked to your account on this platform. This information is
              needed to send you instructions for password recovery.
            </div>
          ) : (
            <div style={{ marginBottom: 30 }}>
              Please enter the email address associated with your account on
              this platform to receive password recovery instructions.
            </div>
          )}

          {/* <br /> */}

          {student ? (
            <div className="form-group">
              <label htmlFor="student_id">Student ID</label>
              <input
                name="student_id"
                placeholder="Enter Student ID"
                value={studentID}
                onChange={handleStudentID}
              />
            </div>
          ) : null}

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              name="email"
              placeholder="Enter Email"
              value={email}
              onChange={handleEmail}
            />
          </div>

          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 20,
            }}
          >
            <div>
              <Button
                disabled={loadingRecoverPasswordButton}
                click={async (e) => {
                  e.preventDefault();

                  // console.log(`----> ${student}`);
                  // console.log(`----> ${teacher}`);

                  if (loadingRecoverPasswordButton == false) {
                    //  deleteStaff(staff_details);
                    setLoadingRecoverPasswordButton(true);

                    let role = teacher ? "teacher" : "admin";
                    let res;

                    try {
                      if (student) {
                        res = await fetch(
                          "https://server-olhms.onrender.com/student_auth/forgot_password",
                          {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                              email: email,
                              student_id: studentID,
                            }),
                          }
                        );
                      } else {
                        res = await fetch(
                          "https://server-olhms.onrender.com/admin_auth/forgot_password",
                          {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                              email: email,
                              role: role,
                            }),
                          }
                        );
                      }

                      const data = await res.json();

                      setLoadingRecoverPasswordButton(false);
                      if (res.status == 200) {
                        toast.success(
                          `Password recovery instructions has been sent to ${email}`,
                          {
                            position: "bottom-center",
                            duration: 5000,
                          }
                        );
                        setEmail("");
                        setStudentID("");
                        closeRecoverPasswordDialogBox();
                      } else {
                        toast.error(data["msg"], {
                          icon: "❗️",
                          position: "bottom-center",
                        });
                      }
                    } catch (e) {
                      toast.error(
                        "An error occured with the server. Please try again later",
                        {
                          icon: "❗️",
                          position: "bottom-center",
                        }
                      );

                      // console.log(error);
                      setLoadingRecoverPasswordButton(false);
                    }
                  }
                }}
                style={{ marginBottom: 10, marginLeft: 20 }}
                name={"Send"}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
