import React from "react";
import "./admissionfour.css";
import AdvantageOne from "../../images/advantage1.png";
import AdvantageTwo from "../../images/advantage2.png";
import Advantage3 from "../../images/advantage3.png";
import Advantage4 from "../../images/advantage4.png";
import Advantage5 from "../../images/advantage5.png";
import Advantage6 from "../../images/advantage6.png";
import Advantage7 from "../../images/advantage7.png";
import Advantage8 from "../../images/advantage8.png";
import Advantage9 from "../../images/advantage9.png";
import Advantage10 from "../../images/advantage10.png";
import Advantage11 from "../../images/advantage11.png";
import Advantage12 from "../../images/advantage12.png";
import Advantage13 from "../../images/advantage13.png";
import Advantage14 from "../../images/advantage14.png";
import Advantage15 from "../../images/advantage15.png";
import Advantage16 from "../../images/advantage16.png";
import AdmissionEllipse from "../../images/admission-ellipse.png";
import AdmissionEllipse2 from "../../images/admission-ellipse2.png";
export default function AdmissionFour() {
  return (
    <div className="admission-four">
      <div className="admission-four-content">
        <p className="admission-four-header">
          <strong>The OLHMS Advantage</strong>
        </p>
        <div className="advantage-flex">
          <Advantage1
            header="Our Curriculum"
            details="A school's curriculum is the bedrock of any meaningful learning. At Our Lady of Hope Montessori Schools, our curriculum is a smooth blend of the Montessori curriculum and the Nigerian National curriculum infused with sound Christian faith and values. Our curriculum is designed to meet the needs of the 21st century learner with play having a major role in it's delivery."
            src1={AdvantageOne}
            src2={AdvantageTwo}
            src3={Advantage3}
            src4={Advantage4}
          />
          <Advantage2
            header="Our Learning Facilities"
            details="Our book bay and library is equipped with best resources for all ages. Our learners are inspired to become confident and avid readers through our robust literacy programmes like reading clinic and spelling bee."
            details2="We also have rich programmes such as History, Science and Entrepreneurship."
            src1={Advantage5}
            src2={Advantage6}
            src3={Advantage7}
            src4={Advantage8}
          />
          <Advantage1
            header="Co-curricular Activities"
            details="We appreciate that learning does not have to take place within the four walls of the classroom alone, we have designed an array of co-curricular activities that suits this purpose. They range from club subscription like ballet and dance clubs to music as well as karate clubs. We also have swimming time as well as undertake regular field trips."
            src1={Advantage9}
            src2={Advantage10}
            src3={Advantage11}
            src4={Advantage12}
          />
          <Advantage2
            header="Hybrid Learning"
            details="Technology as a classroom tool has become the pen and paper of our time, and the lens through which we experience much of our world. As education evolves due to the impact of the internet, teaching and learning must also evolve in like manner. At Our Lady Of Hope Montessori Schools, technology is a big part of our teaching methodology from class Lessons to assignments, exams,  holiday projects, newsletters and lots more."
            src1={Advantage13}
            src2={Advantage14}
            src3={Advantage15}
            src4={Advantage16}
          />
        </div>
        <div className="advantage-flex2">
          <AdvantageThree
            header="Our Curriculum"
            details="A school's curriculum is the bedrock of any meaningful learning. At Our Lady of Hope Montessori Schools, our curriculum is a smooth blend of the Montessori curriculum and the Nigerian National curriculum infused with sound Christian faith and values. Our curriculum is designed to meet the needs of the 21st century learner with play having a major role in it's delivery."
            src1={AdvantageOne}
            src2={AdvantageTwo}
            src3={Advantage3}
            src4={Advantage4}
          />
          <AdvantageThree
            header="Our Learning Facilities"
            details="Our book bay and library is equipped with best resources for all ages. Our learners are inspired to become confident and avid readers through our robust literacy programmes like reading clinic and spelling bee."
            details2="We also have rich programmes such as History, Science and Entrepreneurship."
            src1={Advantage5}
            src2={Advantage6}
            src3={Advantage7}
            src4={Advantage8}
          />
          <AdvantageThree
            header="Co-curricular Activities"
            details="We appreciate that learning does not have to take place within the four walls of the classroom alone, we have designed an array of co-curricular activities that suits this purpose. They range from club subscription like ballet and dance clubs to music as well as karate clubs. We also have swimming time as well as undertake regular field trips."
            src1={Advantage9}
            src2={Advantage10}
            src3={Advantage11}
            src4={Advantage12}
          />
          <AdvantageThree
            header="Hybrid Learning"
            details="Technology as a classroom tool has become the pen and paper of our time, and the lens through which we experience much of our world. As education evolves due to the impact of the internet, teaching and learning must also evolve in like manner. At Our Lady Of Hope Montessori Schools, technology is a big part of our teaching methodology from class Lessons to assignments, exams,  holiday projects, newsletters and lots more."
            src1={Advantage13}
            src2={Advantage14}
            src3={Advantage15}
            src4={Advantage16}
          />
        </div>
      </div>
      <div className="ellipse-one">
        <img src={AdmissionEllipse} alt="" />
      </div>
      <div className="ellipse-two">
        <img src={AdmissionEllipse2} alt="" />
      </div>
      <div className="right-semi"></div>
      <div className="rotated-semi"></div>
    </div>
  );
}

export function Advantage1(props) {
  return (
    <div className="advantage">
      <div className="advantage-text second-item">
        <p className="advantage-header">{props.header}</p>
        <p className="advantage-details">{props.details}</p>
      </div>
      <div className="advantage-image first-item">
        <img src={props.src1} alt="" />
      </div>
      <div className="advantage-image third-item">
        <img src={props.src2} alt="" />
      </div>
      <div className="advantage-image fourth-item">
        <img src={props.src3} alt="" />
      </div>
      <div className="advantage-image fifth-item">
        <img alt="" src={props.src4} />
      </div>
    </div>
  );
}
export function Advantage2(props) {
  return (
    <div className="advantage2">
      <div className="advantage-text second-item">
        <p className="advantage-header">{props.header}</p>
        <p className="advantage-details">
          {props.details} <br />
          {props.details2}
        </p>
      </div>
      <div className="advantage-image first-item">
        <img src={props.src1} alt="" />
      </div>
      <div className="advantage-image third-item">
        <img src={props.src2} alt="" />
      </div>
      <div className="advantage-image fourth-item">
        <img src={props.src3} alt="" />
      </div>
      <div className="advantage-image fifth-item">
        <img alt="" src={props.src4} />
      </div>
    </div>
  );
}

export function AdvantageThree(props) {
  return (
    <div className="advantage-2">
      <p>{props.header}</p>
      <div>{props.details}</div>
      <div className="advantage-2-grid">
        <div className="advantage-2-img">
          <img src={props.src1} />
        </div>
        <div className="advantage-2-img">
          <img src={props.src2} />
        </div>
        <div className="advantage-2-img">
          <img src={props.src3} />
        </div>
        <div className="advantage-2-img">
          <img src={props.src4} />
        </div>
      </div>
    </div>
  );
}
