import React, { useState } from "react";
import Mask from "../../images/Mask.png";
import "./component1.css";
// import from "../../images/component1.png";

export default function Component1(props) {
  const [classic, setClassic] = useState("section-five-text");
  const fade = () => {
    setClassic("section-five-text fade-in");
  };
  const fadeOut = () => {
    setClassic("section-five-text");
  };
  const styles = {
    backgroundImage: `url(${props.img})`,
  };
  return (
    <div style={styles} className="component-1">
      <div className={classic}>{props.text}</div>
      <div
        className="section-five-button"
        onMouseOver={fade}
        onMouseOut={fadeOut}
        onClick={props.click}
      >
        <span onMouseOver={fade} onMouseOut={fadeOut}>
          {props.span}
        </span>
        <img src={Mask} alt="" />
      </div>
    </div>
  );
}
