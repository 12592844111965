import React, { useState, useContext, useEffect } from "react";
import "./result.css";
import { useLocation, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";

export default function Result() {
  const location = useLocation();

  const student = JSON.parse(localStorage.getItem("studentData"));
  const studentToken = JSON.parse(localStorage.getItem("student_rubbish"));

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [results, setResults] = useState({
    creche: [],
    toddlers: [],
    infant1: [],
    infant2: [],
    nursery1: [],
    nursery2: [],
    grade1: [],
    grade2: [],
    grade3: [],
    grade4: [],
    grade5: [],
  });

  const viewResults = async () => {
    setLoading(true);
    const res = await fetch(
      "https://server-olhms.onrender.com/guardian_result/view_all_results",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: studentToken,
          student_id: student.student_id,
        }),
      }
    );
    const data = await res.json();
    setLoading(false);

    const temp = { ...results };
    data?.results.map((result) => {
      let class_name_lowercase = result.class_name.toLowerCase();
      if (class_name_lowercase == "creche") {
        temp["creche"].push(result);
      }

      if (class_name_lowercase == "toddlers") {
        temp["toddlers"].push(result);
      }

      if (class_name_lowercase == "infant community 1") {
        temp["infant1"].push(result);
      }

      if (class_name_lowercase == "infant community 2") {
        temp["infant2"].push(result);
      }

      if (class_name_lowercase == "nursery 1") {
        temp["nursery1"].push(result);
      }

      if (class_name_lowercase == "nursery 2") {
        temp["nursery2"].push(result);
      }

      if (class_name_lowercase == "grade 1") {
        temp["grade1"].push(result);
      }

      if (class_name_lowercase == "grade 2") {
        temp["grade2"].push(result);
      }

      if (class_name_lowercase == "grade 3") {
        temp["grade3"].push(result);
      }

      if (class_name_lowercase == "grade 4") {
        temp["grade4"].push(result);
      }

      if (class_name_lowercase == "grade 5") {
        temp["grade5"].push(result);
      }
    });

    setResults(temp);
    setLoading(false);
    console.log(data);
  };

  useEffect(() => {
    viewResults();
  }, []);

  if (loading) {
    return (
      <h1
        className="loading-pages"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "150px 0",
        }}
      >
        <ClipLoader color={"orange"} size={80} />
      </h1>
    );
  } else {
    return (
      <div className="result">
        <div className="result-wrapper">
          <p>Class</p>
          <div className="result-flex">
            {results.creche.length > 0 ? (
              <ResultComponent
                results={results.creche}
                class="Creche"
                tempAdminToken={studentToken}
              />
            ) : null}
            {results.toddlers.length > 0 ? (
              <ResultComponent
                results={results.toddlers}
                class="Toddlers"
                tempAdminToken={studentToken}
              />
            ) : null}
            {results.infant1.length > 0 ? (
              <ResultComponent
                results={results.infant1}
                class="Infant Community 1"
                tempAdminToken={studentToken}
              />
            ) : null}
            {results.infant2.length > 0 ? (
              <ResultComponent
                results={results.infant2}
                class="Infant Community 2"
                tempAdminToken={studentToken}
              />
            ) : null}
            {results.nursery1.length > 0 ? (
              <ResultComponent
                results={results.nursery1}
                class="Nursery 1"
                tempAdminToken={studentToken}
              />
            ) : null}
            {results.nursery2.length > 0 ? (
              <ResultComponent
                results={results.nursery2}
                class="Nursery 2"
                tempAdminToken={studentToken}
              />
            ) : null}
            {results.grade1.length > 0 ? (
              <ResultComponent
                results={results.grade1}
                class="Grade 1"
                tempAdminToken={studentToken}
              />
            ) : null}
            {results.grade2.length > 0 ? (
              <ResultComponent
                results={results.grade2}
                class="Grade 2"
                tempAdminToken={studentToken}
              />
            ) : null}
            {results.grade3.length > 0 ? (
              <ResultComponent
                results={results.grade3}
                class="Grade 3"
                tempAdminToken={studentToken}
              />
            ) : null}
            {results.grade4.length > 0 ? (
              <ResultComponent
                results={results.grade4}
                class="Grade 4"
                tempAdminToken={studentToken}
              />
            ) : null}
            {results.grade5.length > 0 ? (
              <ResultComponent
                results={results.grade5}
                class="Grade 5"
                tempAdminToken={studentToken}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export function ResultComponent(props) {
  const navigate = useNavigate();
  return (
    <div className="result-content">
      <div className="faint-result classname">{props.class}</div>
      <div className="terms">
        {props.results.some((result) => result.term == "First Term") ? (
          <div
            className="faint-result terms-container"
            onClick={() => {
              navigate("/student/result-details", {
                state: {
                  result: props.results.find(
                    (result) => result.term == "First Term"
                  ),
                  is_validated: props.results.find(
                    (result) => result.term == "First Term"
                  ).is_validated,
                  tempAdminToken: props.tempAdminToken,
                },
              });
            }}
          >
            First Term
          </div>
        ) : null}

        {props.results.some((result) => result.term == "Second Term") ? (
          <div
            className="faint-result terms-container"
            onClick={() => {
              navigate("/student/result-details", {
                state: {
                  result: props.results.find(
                    (result) => result.term == "Second Term"
                  ),
                  is_validated: props.results.find(
                    (result) => result.term == "Second Term"
                  ).is_validated,
                  tempAdminToken: props.tempAdminToken,
                },
              });
            }}
          >
            Second Term
          </div>
        ) : null}

        {props.results.some((result) => result.term == "Third Term") ? (
          <div
            className="faint-result terms-container"
            onClick={() => {
              navigate("/student/result-details", {
                state: {
                  result: props.results.find(
                    (result) => result.term == "Second Term"
                  ),
                  is_validated: props.results.find(
                    (result) => result.term == "Second Term"
                  ).is_validated,
                  tempAdminToken: props.tempAdminToken,
                },
              });
            }}
          >
            Third Term
          </div>
        ) : null}
      </div>
    </div>
  );
}
