import React, { useState, useEffect, useContext } from "react";
import { Table, Table2 } from "./CheckSubject";
import { useNavigate, useLocation } from "react-router-dom";
import { BackButton } from "../studentPages/PersonalData";
import Button from "../../components/Button/Button";
import { adminContext } from "../context/adminContext";

export default function SpecificResult() {

  const location = useLocation();
  const result = location.state.result;
  const session = location.state.session;
  const is_validated = location.state.is_validated;
  const navigate = useNavigate();
  const values = useContext(adminContext);
  const { teacherData, teachertoken } = values;
  const dataT = JSON.parse(teacherData);

  const [loading, setLoading] = useState(true);


  const [validated, setValidated] = useState(is_validated);
  const handleCheck = (e) => {
    setValidated(e.target.checked);
  };

  const [classTRemark, setClassTRemark] = useState(is_validated ? result.teachers_remark : '');
  const handleClassTRemarkChange = (e) => {
    setClassTRemark(e.target.value);
  };

  const [overallRemark, setOverallRemark] = useState(is_validated ? result.overall_remark : '');
  const handleOverallRemarkChange = (e) => {
    setOverallRemark(e.target.value);
  };

  const [attScore, setAttScore] = useState(is_validated ? result.attendance_score : '');
  const handleAttScoreChange = (e) => {
    setAttScore(e.target.value);
  };

  const [headTRemark, setHeadTRemark] = useState(is_validated ? result.head_teachers_remark : '');
  const handleheadTRemarkChange = (e) => {
    setHeadTRemark(e.target.value);
  };

  const [affective, setAffective] = useState({
    general_conduct: is_validated ? result.affective_traits.general_conduct : 'Satisfactory',
    assignments: is_validated ? result.affective_traits.assignments : 'Satisfactory',
    class_participation: is_validated ? result.affective_traits.class_participation : 'Satisfactory',
    finishes_work_on_time: is_validated ? result.affective_traits.finishes_work_on_time : 'Satisfactory',
    takes_care_of_materials: is_validated ? result.affective_traits.takes_care_of_materials : 'Satisfactory',
    working_independently: is_validated ? result.affective_traits.working_independently : 'Satisfactory'
  });

  const [psycho, setPsycho] = useState({
    regularity: is_validated ? result.psycho_motive_traits.regularity : 'Satisfactory',
    personal_cleanliness: is_validated ? result.psycho_motive_traits.personal_cleanliness : 'Satisfactory',
    punctuality: is_validated ? result.psycho_motive_traits.punctuality : 'Satisfactory',
    completion_of_work: is_validated ? result.psycho_motive_traits.completion_of_work : 'Satisfactory',
    // disturbing_others: is_validated ? result.psycho_motive_traits.disturbing_others : 'Satisfactory',
    following_instructions: is_validated ? result.psycho_motive_traits.following_instructions : 'Satisfactory'
  });

  const handleAffectiveDropdownChange = (e) => {
    const {name, value} = e.target;
    const tAffective = {...affective};
    tAffective[name] = value;
    setAffective(tAffective);
  }

  const handlePsychoDropdownChange = (e) => {
    const {name, value} = e.target;
    const tPsycho = {...psycho};
    tPsycho[name] = value;
    setPsycho(tPsycho);
  }


  // function to update student result for present session
  const updateResult = async () => {
    setLoading(true);

    const res = await fetch(
      "https://server-olhms.onrender.com/teacher_result/edit_result",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(teachertoken),
          result_id: result._id,
          attendance_score: attScore,
          affective_traits: affective,
          psycho_motive_traits: psycho,
          head_teachers_remark: headTRemark,
          overall_remark: overallRemark,
          teachers_remark: classTRemark,
          is_validated_by: dataT._id
        }),
      }
    );
    const data = await res.json();
    setLoading(false);
    console.log(data);
    navigate(-1);
  }

  const tableValues = [
    { data: "S/N", center: false },
    { data: "Subject", center: false },
    { data: "1st C.A.T (20)", center: true },
    { data: "2nd C.A.T (20)", center: true },
    { data: "Exam (60)", center: true },
    { data: "Total", center: true },
    { data: "Grade", center: true },
    { data: "Remark", center: true }
  ];

  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (document.documentElement.clientWidth <= 800) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={mobile ? '' : 'set-questions'} style={mobile ? {margin: 24} : {}}>
      <div className="set-container">
        <BackButton click={() => navigate(-1)} />
        <div className="session-flex">
          <div className="cat-session">
            <p>{session.session} Session</p>
          </div>
        </div>
        <div className="show-term">{result.term}</div>
        <div className="submitted-students">
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
          <div>
            <p>Name: {result.student_name}</p>
            <p>Student ID: {result.student_id}</p>
            <p style={{color: '#E05615', marginTop: 16, marginBottom: 16}}>A. COGNITIVE REPORT</p>
          </div>

          <div className="data-img">
                  <img src={result.student_img_url} alt="" />
                </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "5px",
            }}
          >
            <Table2
              columns="0.5fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr"
              data={tableValues}
            />

            {
              result.results.map((res, index)=>(
                <div
                  key={index}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "0.5fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr",
                    fontWeight: "300",
                    gridGap: "30px",
                    cursor: "pointer",
                  }}>

                  <div>{index + 1}</div>
                  <div>{res.subject}</div>
                  <div className="centered-text">{res.first_ca}</div>
                  <div className="centered-text">{res.second_ca}</div>
                  <div className="centered-text">{res.exam}</div>
                  <div className="centered-text">{res.total}</div>
                  <div className="centered-text">{res.grade}</div>
                  <div className="centered-text">{res.remark}</div>
            </div>
              ))
            }

          </div>
        </div>
        <form className="validate-form">

        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <div className="validate-form-div" style={{flex: 0.5}}>
            <label style={{ fontWeight: 500 }}>Class Teachers Remark</label>
            <input
              style={{ fontWeight: 300 }}
              value={classTRemark}
              onChange={handleClassTRemarkChange}
            />
          </div>

          <div className="validate-form-div" style={{flex: 0.2}}>
            <label style={{ fontWeight: 500, marginRight: 16}}>Overall Remark (0/80)</label>
            <input
              style={{ fontWeight: 300 }}
              value={overallRemark}
              onChange={handleOverallRemarkChange}
            />
          </div>

          <div className="validate-form-div"  style={{flex: 0.2}}>
            <label style={{ fontWeight: 500, marginRight: 16}}>Attendance Score</label>
            <input
              style={{ fontWeight: 300 }}
              value={attScore}
              onChange={handleAttScoreChange}
            />
          </div>
        </div>
          <div className="validate-box">
            <input
              type="checkbox"
              value="valid"
              checked={validated}
              onChange={handleCheck}
              style={{
                height: "20px",
                width: "20px",
              }}
            />
            <label>I hereby validate this result</label>
          </div>

          <div style={mobile ? {
            display: 'flex',
            flexDirection: 'column'
          } : 
          {display: 'flex', flex: '1 1', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
              <div style={{display: 'flex', flexDirection: 'column'}}>

              <p style={{fontSize: 16, fontWeight: 'bold', color: '#E05615', marginTop: 16, marginBottom: 16}}>B. AFFECTIVE TRAIT</p>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 16}}>

                  <p style={{marginRight: 60, fontWeight: 500}}>General Conduct</p>
                  <select
                      value={affective['general_conduct']}
                      name='general_conduct'
                      onChange={(e) => handleAffectiveDropdownChange(e)}
                      >

                      <option value="Satisfactory">
                        Satisfactory
                      </option>
                      <option value="Impressive">
                        Impressive
                      </option>
                      <option value="Poor">
                        Poor
                      </option>
                  
                    </select>
                </div>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 16}}>

                  <p style={{marginRight: 60, fontWeight: 500}}>Assignments</p>
                  <select
                      value={affective['assignments']}
                      name='assignments'
                      onChange={(e) => handleAffectiveDropdownChange(e)}
                      >

                      <option value="Satisfactory">
                        Satisfactory
                      </option>
                      <option value="Impressive">
                        Impressive
                      </option>
                      <option value="Poor">
                        Poor
                      </option>
                  
                    </select>
                </div>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 16}}>

                  <p style={{marginRight: 60, fontWeight: 500}}>Class Participation</p>
                  <select
                      value={affective['class_participation']}
                      name='class_participation'
                      onChange={(e) => handleAffectiveDropdownChange(e)}
                      >

                      <option value="Satisfactory">
                        Satisfactory
                      </option>
                      <option value="Impressive">
                        Impressive
                      </option>
                      <option value="Poor">
                        Poor
                      </option>
                  
                    </select>
                </div>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 16}}>

                  <p style={{marginRight: 60, fontWeight: 500}}>Finishes work on time</p>
                  <select
                      value={affective['finishes_work_on_time']}
                      name='finishes_work_on_time'
                      onChange={(e) => handleAffectiveDropdownChange(e)}
                      >

                      <option value="Satisfactory">
                        Satisfactory
                      </option>
                      <option value="Impressive">
                        Impressive
                      </option>
                      <option value="Poor">
                        Poor
                      </option>
                  
                    </select>
                </div>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 16}}>

                  <p style={{marginRight: 60, fontWeight: 500}}>Takes care of materials</p>
                  <select
                      value={affective['takes_care_of_materials']}
                      name='takes_care_of_materials'
                      onChange={(e) => handleAffectiveDropdownChange(e)}
                      >

                      <option value="Satisfactory">
                        Satisfactory
                      </option>
                      <option value="Impressive">
                        Impressive
                      </option>
                      <option value="Poor">
                        Poor
                      </option>
                  
                    </select>
                </div>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 16}}>

                  <p style={{marginRight: 60, fontWeight: 500}}>Working independently</p>
                  <select
                      value={affective['working_independently']}
                      name='working_independently'
                      onChange={(e) => handleAffectiveDropdownChange(e)}
                      >

                      <option value="Satisfactory">
                        Satisfactory
                      </option>
                      <option value="Impressive">
                        Impressive
                      </option>
                      <option value="Poor">
                        Poor
                      </option>
                  
                    </select>
                </div>
                

                <div className="validate-form-div" style={{display: 'flex', flexDirection: 'row', marginTop: 24}}>
                  <label style={{ fontWeight: 500, marginRight: 16}}>Head Teacher Remark</label>
                  <input
                    style={{ fontWeight: 300 }}
                    value={headTRemark}
                    onChange={handleheadTRemarkChange}
                    disabled={true}
                  />
                </div>
                
              </div>

              <div style={mobile ? {display: 'flex', flexDirection: 'column', marginTop: 16} : {display: 'flex', flexDirection: 'column'}}>

              <p style={{fontSize: 16, fontWeight: 'bold', color: '#E05615', marginTop: 16, marginBottom: 16}}>C. PSYCHO-MOTIVE TRAIT</p>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 16}}>
                <p style={{marginRight: 60, fontWeight: 500}}>Regularity</p>
                  <select
                      value={psycho['regularity']}
                      name='regularity'
                      onChange={(e) => handlePsychoDropdownChange(e)}
                      >

                      <option value="Satisfactory">
                        Satisfactory
                      </option>
                      <option value="Impressive">
                        Impressive
                      </option>
                      <option value="Poor">
                        Poor
                      </option>
                  
                    </select>
                </div>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 16}}>

                  <p style={{marginRight: 60, fontWeight: 500}}>Personal cleanliness</p>
                  <select
                      value={psycho['personal_cleanliness']}
                      name='personal_cleanliness'
                      onChange={(e) => handlePsychoDropdownChange(e)}
                      >

                      <option value="Satisfactory">
                        Satisfactory
                      </option>
                      <option value="Impressive">
                        Impressive
                      </option>
                      <option value="Poor">
                        Poor
                      </option>
                  
                    </select>
                </div>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 16}}>

                  <p style={{marginRight: 60, fontWeight: 500}}>Punctuality</p>
                  <select
                      value={psycho['punctuality']}
                      name='punctuality'
                      onChange={(e) => handlePsychoDropdownChange(e)}
                      >

                      <option value="Satisfactory">
                        Satisfactory
                      </option>
                      <option value="Impressive">
                        Impressive
                      </option>
                      <option value="Poor">
                        Poor
                      </option>
                  
                    </select>
                </div>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 16}}>

                  <p style={{marginRight: 60, fontWeight: 500}}>Completion of work</p>
                  <select
                      value={psycho['completion_of_work']}
                      name='completion_of_work'
                      onChange={(e) => handlePsychoDropdownChange(e)}
                      >

                      <option value="Satisfactory">
                        Satisfactory
                      </option>
                      <option value="Impressive">
                        Impressive
                      </option>
                      <option value="Poor">
                        Poor
                      </option>
                  
                    </select>
                </div>

                {/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 16}}>

                  <p style={{marginRight: 60, fontWeight: 500}}>Disturbing others</p>
                  <select
                      value={psycho['disturbing_others']}
                      name='disturbing_others'
                      onChange={(e) => handlePsychoDropdownChange(e)}
                      >

                      <option value="Satisfactory">
                        Satisfactory
                      </option>
                      <option value="Impressive">
                        Impressive
                      </option>
                      <option value="Poor">
                        Poor
                      </option>
                  
                    </select>
                </div> */}

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 16}}>

                  <p style={{marginRight: 60, fontWeight: 500}}>Following instructions</p>
                  <select
                      value={psycho['following_instructions']}
                      name='following_instructions'
                      onChange={(e) => handlePsychoDropdownChange(e)}
                      >

                      <option value="Satisfactory">
                        Satisfactory
                      </option>
                      <option value="Impressive">
                        Impressive
                      </option>
                      <option value="Poor">
                        Poor
                      </option>
                  
                    </select>
                </div>

                <div className="validate-form-div" style={{display: 'flex', flexDirection: 'row', marginTop: 24, visibility: 'hidden'}}>
                  <label style={{ fontWeight: 500, marginRight: 16}}>Head Teacher Remark</label>
                  <input
                    style={{ fontWeight: 300 }}
                    value={'validText'}
                  />
                </div>
              </div>
          </div>

          <div style={{ alignSelf: "center", marginTop: "20px" }}>
            <Button
              name="Finish and Save"
              disabled={validated === false || headTRemark.trim() === "" || classTRemark.trim() === "" || overallRemark.trim() === "" || attScore.trim() === "" || !loading}
              click={(e)=>{
                e.preventDefault();
                updateResult();
              }}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
