import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import { BackButton } from "../studentPages/PersonalData";
import { useLocation } from "react-router-dom";

export default function ViewMessage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [reply, setReply] = useState('');
  const [loading, setLoading] = useState(false);
  let adminToken = localStorage.getItem("admin_rubbish");
  let repMsg;

  console.log(state);

  const handleReply = (e) => {
    setReply(e.target.value);
  }

  // function to reply the message
  const replyMessage = async () => {
    setLoading(true);
    console.log("state:: :: ", state)

    const res = await fetch(
      "https://server-olhms.onrender.com/student_get_in_touch/reply_get_in_touch",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(adminToken),
          email: state.email,
          message: reply,
          get_in_touch_id: state._id
        })
      }
    );

    const data = await res.json();
    console.log(data?.msg);
    setLoading(false);
    if(data?.msg == 'Success'){
      navigate(-1)
    }
    

  };

  return (
    <div
      className="set-questions"
      style={{
        backgroundColor: "rgb(255, 248, 248)",
        height: "100%",
        alignItems: "flex-start",
      }}
    >
      <div className="git-wrap split-through">
        <BackButton click={() => navigate(-1)} />
        <div className="git-details">
          <p>{`Full Name: ${state.fullname}`}</p>
          <p>{`Phone Number: ${state.phone_no}`}</p>
          <p>{`Email address: ${state.email}`}</p>
          <p>Message:</p>
          <div className="git-div">
           {state.message}
          </div>
        </div>
        <div className="underline"></div>
        <div className="git-details">
          <p>Reply Message Below: </p>
          <form className="git-form">
            <textarea className="git-textarea" 
              placeholder="Type your reply"
              value={reply}
              onChange={handleReply}
              name="reply"
            />
            <Button disabled={loading} click={()=>{
              console.log("helloolol")
              replyMessage();
            }} name="Send your Reply" />
          </form>
        </div>
      </div>
    </div>
  );
}
