import React from "react";
import Portal from "./pages/portal/Portal";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Contact from "./pages/contact/Contact";
import Admission from "./pages/Admission";
import News from "./pages/News";
import Gallery from "./pages/Gallery";
import Blog from "./pages/Blog";
import About from "./pages/About";
import Home from "./pages/Home";
import Logged from "./pages/Logged";
import PersonalData from "./pages/studentPages/PersonalData";
import Result from "./pages/studentPages/Result";
import ResultDetails from "./pages/studentPages/ResultDetails";
import Payments from "./pages/studentPages/Payments";
import Teacher from "./pages/Teacher";
import Resources from "./pages/teacherPages/Resources";
import UploadResources from "./pages/teacherPages/UploadResources";
import FirstCat from "./pages/teacherPages/FirstCat";
import CheckSubject from "./pages/teacherPages/CheckSubject";
import CheckSubjectTheory from "./pages/teacherPages/CheckSubjectTheory";
import StudentAnswersObj from "./pages/teacherPages/StudentAnswersObj";
import StudentAnswersTheory from "./pages/teacherPages/StudentAnswersTheory";
import SetQuestions from "./pages/teacherPages/SetQuestions";
import SetQuestionsTheory from "./pages/teacherPages/SetQuestionsTheory";
import ResultTeacher from "./pages/teacherPages/ResultTeacher";
import SpecificResult from "./pages/teacherPages/SpecificResult";
import QuestionArchive from "./pages/teacherPages/QuestionArchive";
import ViewQuestionArchive from './pages/teacherPages/ViewQuestionArchive';
import ViewQuestions from "./pages/teacherPages/ViewQuestions";
import Worksheet from "./pages/teacherPages/Worksheet";
import SelectTopic from "./pages/teacherPages/SelectTopic";
import TeacherProfile from "./pages/teacherPages/TeacherProfile";
import Admin from "./pages/Admin";
import Dashboard from "./pages/adminPages/Dashboard";
import StaffDatabase from "./pages/adminPages/StaffDatabase";
import CreateStaff from "./pages/adminPages/CreateStaff";
import { UseTokenProviderAdmin } from "./pages/context/adminContext";
import StudentDatabase from "./pages/adminPages/StudentDatabase";
import View from "./pages/adminPages/View";
import ViewStudent from "./pages/adminPages/View_student";
import Vlogs from "./pages/adminPages/Vlogs";
import AddBlog from "./pages/adminPages/AddBlog";
import AddResource from "./pages/adminPages/AddResource";
import Event from "./pages/adminPages/Events";
import Readnews from "./pages/news/Readnews";
import Readblog from "./pages/blog/Readblog";
import Archive from "./pages/adminPages/Archive";
import Settings from "./pages/adminPages/Settings";
import SettingsCenter from "./pages/adminPages/SettingsCenter";
import SettingsViewStudent from "./pages/adminPages/SettingsViewStudent";
import FAQ from "./pages/admissionSections/FAQ";
import FrequentQuestions from "./pages/adminPages/FrequentQuestions";
import GetInTouch from "./pages/adminPages/GetInTouch";
import ViewMessage from "./pages/adminPages/ViewMessage";
import GalleryAdmin from "./pages/adminPages/GalleryAdmin";
import AdminProfile from "./pages/adminPages/AdminProfile";
import AddGallery from "./pages/adminPages/AddGallery";
import Chat from "./pages/adminPages/Chat";
import Store from "./pages/adminPages/Store";
import ViewFaq from "./pages/adminPages/ViewFaq";
import Apply from "./pages/admissionSections/Apply";
import Baby from "./pages/admissionSections/Baby";
import Infant from "./pages/admissionSections/Infant";
import Grade from "./pages/admissionSections/Grade";
import Form from "./pages/admissionSections/Form";
import AddItem from "./pages/adminPages/AddItem";
import ViewItem from "./pages/adminPages/ViewItem";
import SchoolStore from "./pages/studentPages/SchoolStore";
import Applications from "./pages/adminPages/Applications";
import NewForm from "./pages/adminPages/NewForm";
import ProApplications from "./pages/adminPages/ProApplications";
import AdminDatabase from "./pages/adminPages/AdminDatabase";
import ViewAdmin from "./pages/adminPages/ViewAdmin";
import CreateAdmin from "./pages/adminPages/CreateAdmin";
import TermWeeks from "./pages/teacherPages/TermWeeks";
import SettingsTermWeeks from "./pages/adminPages/SettingsTermWeeks";
import AllResources from "./pages/teacherPages/AllResources";
import SettingsAllResources from "./pages/adminPages/SettingsAllResources";
import SettingsResultDetails from "./pages/adminPages/SettingsResultDetails";
import SettingsSpecificResult from "./pages/adminPages/SettingsSpecificResult";
import SettingsPreviewResult from "./pages/adminPages/SettingsPreviewResult";
import Sections from "./pages/teacherPages/Sections";
import Viewcenter from "./pages/adminPages/Viewcenter";
import EmailStaff from "./pages/adminPages/EmailStaff";
import ScrollToTop from "./scrollToTop";
import User_Support from "./pages/adminPages/chat/User_Support";
import { Toaster } from 'react-hot-toast'; // Import the Toaster component

// import HomePage from "./pages/homepage/HomePage";

export default function App() {
  return (
    <UseTokenProviderAdmin>
      <BrowserRouter>
      {/* <BrowserRouter basename="/"> */}
        <Toaster /> {/* Add the Toaster component here */}
      <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home />}>
            <Route path="" element={<HomePage />} />
            <Route path="portal" element={<Portal />} />
            <Route path="contact" element={<Contact />} />
            <Route path="admission" element={<Admission />} />
            <Route path="baby" element={<Baby />} />
            <Route path="infant" element={<Infant />} />
            <Route path="grade" element={<Grade />} />
            <Route path="apply" element={<Apply />} />
            <Route path="news" element={<News />} />
            <Route path="gallery" element={<Gallery />} />
            <Route path="blog" element={<Blog />} />
            <Route path="about" element={<About />} />
            <Route path="news-content" element={<Readnews />} />
            <Route path="blog-content" element={<Readblog />} />
            <Route path="admission-form" element={<Form />} />
          </Route>
          <Route exact path="/student" element={<Logged />}>
            <Route path="profile" element={<PersonalData />} />
            <Route path="result" element={<Result />} />
            <Route path="result-details" element={<ResultDetails />} />
            <Route path="store" element={<SchoolStore />} />
          </Route>
          <Route exact path="/teacher" element={<Teacher />}>
            <Route path="resources" element={<Resources />} />
            <Route path="upload" element={<UploadResources />} />
            <Route path="subjectlist" element={<FirstCat />} />
            <Route path="term-weeks" element={<TermWeeks />} />
            <Route path="all-resources" element={<AllResources />} />
            <Route path="2ndCAT" element={<FirstCat />} />
            <Route path="exam" element={<FirstCat />} />
            <Route path="check-subject" element={<CheckSubject />} />
            <Route path="check-subject-theory" element={<CheckSubjectTheory/>} />
            <Route path="student-answers-theory" element={<StudentAnswersTheory/>} />
            <Route path="student-answers-obj" element={<StudentAnswersObj/>} />
            <Route path="set-questions" element={<SetQuestions />} />
            <Route path="set-questions-theory" element={<SetQuestionsTheory />} />
            <Route path="class-result" element={<ResultTeacher />} />
            <Route path="student-result" element={<SpecificResult />} />
            <Route path="archive" element={<QuestionArchive />} />
            <Route path="view-question-archive" element={<ViewQuestionArchive/>} />
            <Route path="view-questions" element={<ViewQuestions />} />
            <Route path="worksheet" element={<Worksheet />} />
            <Route path="select" element={<SelectTopic />} />
            <Route path="profile" element={<TeacherProfile />} />
            <Route path="sections" element={<Sections />} />
            <Route path="store" element={<Store />} />
            <Route path="add-item" element={<AddItem />} />
            <Route path="view-item" element={<ViewItem />} />
          </Route>
          <Route exact path="/admin" element={<Admin />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="staff" element={<StaffDatabase />} />
            <Route path="student" element={<StudentDatabase />} />
            <Route path="administrators" element={<AdminDatabase />} />
            <Route path="create-staff" element={<CreateStaff />} />
            <Route path="create-admin" element={<CreateAdmin />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="blog" element={<Vlogs />} />
            <Route path="adminview" element={<ViewAdmin />} />
            <Route path="view" element={<View />} />
            <Route path="view-student" element={<ViewStudent />} />
            <Route path="settings-view-student" element={<SettingsViewStudent />} />
            <Route path="check-subject" element={<CheckSubject />} />
            <Route path="check-subject-theory" element={<CheckSubjectTheory/>} />
            <Route path="add-blog" element={<AddBlog />} />
            <Route path="add-item" element={<AddItem />} />
            <Route path="add-resource" element={<AddResource />} />
            <Route path="news" element={<Event />} />
            <Route path="archive" element={<Archive />} />
            <Route path="view-question-archive" element={<ViewQuestionArchive/>} />
            <Route path="questions" element={<ViewQuestions />} />
            <Route path="settings" element={<Settings />} />
            <Route path="settings-center" element={<SettingsCenter/>} />
            <Route path="settings-term-weeks" element={<SettingsTermWeeks />} />
            <Route path="settings-all-resources" element={<SettingsAllResources />} />
            <Route path="settings-result-details" element={<SettingsResultDetails />} />
            <Route path="settings-specific-result" element={<SettingsSpecificResult />} />
            <Route path="settings-preview-result" element={<SettingsPreviewResult />} />
            <Route path="FAQ" element={<FrequentQuestions />} />
            <Route path="git" element={<GetInTouch />} />
            <Route path="view-message" element={<ViewMessage />} />
            <Route path="gallery" element={<GalleryAdmin />} />
            <Route path="profile" element={<AdminProfile />} />
            <Route path="add-gallery" element={<AddGallery />} />
            
            {/* <Route path="chat" element={<Chat />} /> */}
            <Route path="user_support" element={<User_Support />} />
            
            <Route path="store" element={<Store />} />
            <Route path="view-faq" element={<ViewFaq />} />
            <Route path='view-center' element={<Viewcenter/>} />
            <Route path="view-item" element={<ViewItem />} />
            <Route path="applications" element={<Applications />} />
            <Route path="new-applications" element={<NewForm />} />
            <Route path="email-staff" element={<EmailStaff />} />
            <Route
              path="processed-applications"
              element={<ProApplications />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </UseTokenProviderAdmin>
  );
}
