import React from "react";
import { useState, useEffect, useContext } from "react";
import "./setquestions.css";
import { BackButton } from "../studentPages/PersonalData";
import { useNavigate, useLocation } from "react-router-dom";
import Add from "../../images/addition.png";
import { Test } from "./SetQuestions";
import Button, { Button2 } from "../../components/Button/Button";
import axios from "axios";
import { adminContext } from "../context/adminContext";
import { red } from "@mui/material/colors";

export default function SelectTopic() {
  const navigate = useNavigate();
  const location = useLocation();
  //const term = location.state.term;
  const week = location.state.week;
  const subject = location.state.subject;
  const values = useContext(adminContext);
  const { teacherData, teachertoken } = values;
  const dataT = JSON.parse(teacherData);

  console.log(location.state);

  const [term, setTerm] = useState(location.state.term);

  const [loading, setLoading] = useState(false);

  const [questions, setQuestions] = useState([
    {
      question_type: "Multiple Choice Question without Image",
      mark: 1,
      question: "",
      correct_answer: 'a',
      answer_type: '',
      option1: "",
      option2: "",
      option3: "",
      option4: "",
      selectedSrc: "",
      selectedFile: null,
      isSelected: false,
      imgs: [],
    },
  ]);

  const [preview, setPreview] = useState(false);
  const handleQuestionChange = (e, index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].question = e.target.value;
    setQuestions(updatedQuestions);
  };
  const handleOption1Change = (e, index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].option1 = e.target.value;
    setQuestions(updatedQuestions);
  };
  const handleOption2Change = (e, index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].option2 = e.target.value;
    setQuestions(updatedQuestions);
  };
  const handleOption3Change = (e, index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].option3 = e.target.value;
    setQuestions(updatedQuestions);
  };
  const handleOption4Change = (e, index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].option4 = e.target.value;
    setQuestions(updatedQuestions);
  };
  const handleQuestionquestion_typeChange = (e, index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].question_type = e.target.value;
    // console.log(`-----> ${e.target.value}`);
    setQuestions(updatedQuestions);
  };

  const handleMarkChange = (e, index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].mark = e.target.value;
    setQuestions(updatedQuestions);
  };

  const handleCorrectAnswer = (e, index) => {
    console.log(e.target.value);
    const updatedQuestions = [...questions];
    updatedQuestions[index].correct_answer = e.target.value;
    setQuestions(updatedQuestions);
  }

  const handleAddQuestion = (e) => {
    e.preventDefault();
    setQuestions([
      ...questions,
      {
        question_type: "Multiple Choice Question without Image",
        mark: 1,
        question: "",
        correct_answer: 'a',
        answer_type: '',
        option1: "",
        option2: "",
        option3: "",
        option4: "",
        selectedSrc: "",
        selectedFile: null,
        isSelected: false,
        imgs: [],
      },
    ]);
  };

  const onFileChange = (index) => (e) => {
    console.log('got here')
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState == 2) {
        setQuestions(
          questions.map((question, i) => {
            if (i !== index) return question;
            return {
              ...question,
              selectedSrc: reader.result,
              selectedFile: e.target.files[0],
              isSelected: true,
            };
          })
        );
        console.log(e.target.files[0]);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    uploadWorksheetQuestions();
  };

  const [session, setSession] = useState({});
  const getSession = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_session/view_current_session",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(teachertoken)
        })
      }
    );

    const data = await res.json();
    setLoading(false);
    const session = data?.sessionM;
    setSession(session);
  }

  useEffect(()=>{
    getSession();
  }, []);

  const uploadWorksheetQuestions = async () => {
    setLoading(true);
    const week_no = parseInt(week.substring(5));

    for(let i = 0; i < questions.length; i++){
      
      let img_urls = [];
      let img_ids = [];

      if(questions[i].question_type != 'Multiple Images Question'){
        if((questions[i].selectedFile != null) && (questions[i].selectedFile != undefined)){
          const formData = new FormData();
          formData.append("token", JSON.parse(teachertoken));
          formData.append("worksheet-images", questions[i].selectedFile);

          const options = {
            method: 'POST',
            headers: { 'content-question_type': 'application/x-www-form-urlencoded' },
            data: formData,
            url: "https://server-olhms.onrender.com/teacher_worksheet/upload_files"
          }

          const response = await axios(options);
          if(response.status == 200){
            console.log("response: :: :: ", response);
            img_urls = response.data?.ca_imgs.urls;
            img_ids = response.data?.ca_imgs.ids;
          }
        }

        console.log(img_urls, img_ids);
        
      }else if(questions[i].question_type == 'Multiple Images Question'){
        for(let j = 0; j < questions[i].imgs.length; j++){
          if((questions[i].imgs[j] != null) && questions[i].imgs[j] != undefined){
            const formData = new FormData();
            formData.append("token", JSON.parse(teachertoken));
            formData.append("worksheet-images", questions[i].imgs[j]);
  
            const options = {
              method: 'POST',
              headers: { 'content-question_type': 'application/x-www-form-urlencoded' },
              data: formData,
              url: "https://server-olhms.onrender.com/teacher_worksheet/upload_files"
            }
  
            const response = await axios(options);
            if(response.status == 200){
              console.log("response: :: :: ", response);
              img_urls.push(response.data?.ca_imgs.urls[0]);
              img_ids.push(response.data?.ca_imgs.ids[0]);
            }
          }
        }
        console.log(img_urls, img_ids);
        
      }


      const res = await fetch(
        "https://server-olhms.onrender.com/teacher_worksheet/create_worksheet",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: JSON.parse(teachertoken),
            week: week_no,
            subject_name: subject,
            category: subject,
            class_name: dataT?.class_name,
            term: term,
            topic: subject,
            session: session.session,
            question: questions[i].question,
            question_type: questions[i].question_type,
            correct_answer: questions[i].correct_answer,
            img_urls: img_urls,
            img_ids: img_ids,
            options: [questions[i].option1, questions[i].option2, questions[i].option3, questions[i].option4],
            answer_type: questions[i].question_type,
            mark: questions[i].mark
          }),
        }
      );
      const data = await res.json();
      console.log(data);
      setLoading(false);
      navigate(-1);
    }

  }

  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (document.documentElement.clientWidth <= 800) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={mobile ? '' : 'set-questions'} style={mobile ? {margin: 24} : {}}>
      <div className="set-container" style={{ rowGap: "50px" }}>
        {preview === false ? <BackButton click={() => navigate(-1)} /> : null}
        <div
          className="session-flex"
          style={{
            alignSelf: "flex-start",
            alignItems: "flex-start",
            rowGap: "10px",
          }}
        >
          <div className="cat-session">
            <p>
              {term == "First Term"
                ? "First Term"
                : term == "Second Term"
                ? "Second Term"
                : term == "Third Term"
                ? "Third Term"
                : null}{" "}
              WORKSHEET
            </p>
          </div>
          <div style={{ fontSize: "20px" }}>
            {subject} -{" "}
            <span style={{ fontWeight: "300", fontSize: "20px" }}>{week}</span>
          </div>
        </div>
        <form className="questions-form">
          <div className="question-elements">
            {questions.map((question, i) => (
              <div className="questions-wrapper" key={i}>
                {preview === false ? (
                  
                  <div className="question-name" style={mobile ? {display: 'flex', flexDirection:'column', alignItems: 'start', marginBottom: 8} : {}}>

                    <div className="question-title" style={mobile ? {display: 'flex', flexDirection:'column', alignItems: 'start', marginBottom: 12} : {}}>
                      <p className="question_type-of" style={mobile ? {marginBottom: 12, fontWeight: 'bold'} : {fontWeight: "bold"}}>Question Type</p>
                      <div className="form-element">
                        <div className="select-question">
                          <select
                            value={question.question_type}
                            onChange={(e) => handleQuestionquestion_typeChange(e, i)}
                          >
                            <option value="Multiple Choice Question without Image">
                              Multiple Choice Question without Image
                            </option>
                            <option value="Multiple Choice Question with Image">
                              Multiple Choice Question with Image
                            </option>
                            <option value="True/False without image">
                              True/False without image
                            </option>
                            <option value="True/False with image">
                              True/False with image
                            </option>
                            <option value="German without image">
                              German without image
                            </option>
                            <option value="German with image">
                              German with image
                            </option>
                            <option value="Spell without image">
                              Spell without image
                            </option>
                            <option value="Spell with image">
                              Spell with image
                            </option>
                            <option value="Multiple Images Question">
                              Multiple Images Question
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="question-mark">
                      <div className="form-element">
                        <div className="select-mark">
                          <select
                            value={question.mark}
                            onChange={(e) => handleMarkChange(e, i)}
                          >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </select>
                        </div>
                      </div>
                      <p>Marks</p>
                    </div>
                    
                  </div>
                  
                ) : null}

                {question.question_type == "German without image" || question.question_type == "German with image" ?(<p style={{color: "red"}}>* Do not add German questions that are case sensitive</p>) :null}
                

                <ul
                  className="input-list"
                  style={{
                    listStylePosition: "outside",
                    listStylequestion_type: "none",
                    columnGap: preview ? "30px" : "",
                    display: mobile ? 'block' : 'inherit'
                  }}
                >
                  <li
                    className="input-item"
                    style={{
                      width: preview ? "" : "100%",
                    }}
                  >
                    <span style={{fontWeight: "bold"}}>Q{i + 1}.{" "}</span>
                    {preview === false ? (
                      <input
                        question_type="text"
                        placeholder="Enter Question"
                        value={question.question}
                        onChange={(e) => handleQuestionChange(e, i)}
                      />
                    ) : (
                      <p>
                        {question.question_type === "German without image" ||
                        question.question_type === "Spell without image"
                          ? `${question.question}_______________`
                          : question.question}
                      </p>
                    )}
                  </li>

                  <li style={mobile ? {listStyleType: "none", marginTop: 8} : {listStyleType: "none"}}>
                    {preview === true ? (
                      <p style={{fontWeight: 'bold'}}>{`(${question.mark} ${
                        question.mark == 1 ? "Mark" : "Marks"
                      })`}</p>
                    ) : null}
                  </li>
                </ul>

                {question.question_type === "Multiple Choice Question without Image" ? (
                  preview === false ? (
                    <ul className="options-list">
                      <li className="input-options">
                        <input
                          question_type="text"
                          placeholder="Enter Option 1"
                          value={question.option1}
                          onChange={(e) => handleOption1Change(e, i)}
                        />
                      </li>
                      <li className="input-options">
                        <input
                          question_type="text"
                          placeholder="Enter Option 2"
                          value={question.option2}
                          onChange={(e) => handleOption2Change(e, i)}
                        />
                      </li>
                      <li className="input-options">
                        <input
                          question_type="text"
                          placeholder="Enter Option 3"
                          value={question.option3}
                          onChange={(e) => handleOption3Change(e, i)}
                        />
                      </li>
                      <li className="input-options">
                        <input
                          question_type="text"
                          placeholder="Enter Option 4"
                          value={question.option4}
                          onChange={(e) => handleOption4Change(e, i)}
                        />
                      </li>
                      <div className="correct-answer-div">
                        Enter Correct Answer{" "}
                        <select className="correct-answer" onChange={(e) => handleCorrectAnswer(e, i)}>
                          <option value="a">A</option>
                          <option value="b">B</option>
                          <option value="c">C</option>
                          <option value="d">D</option>
                        </select>
                      </div>
                    </ul>
                  ) : (
                    <ul className="see-options">
                      <li>{question.option1}</li>
                      <li>{question.option2}</li>
                      <li>{question.option3}</li>
                      <li>{question.option4}</li>
                    </ul>
                  )
                ) : question.question_type === "Multiple Choice Question with Image" ? (
                  <div className="display-questions">
                    <div className="selected-image">
                      {question.isSelected ? (
                        <div className="show-img">
                          <img src={question.selectedSrc} alt="" />
                        </div>
                      ) : null}
                      {preview === false ? (
                        <div className="add-image">
                          <label>
                            <img src={Add} alt="" />
                            <input
                              required
                              onChange={onFileChange(i)}
                              type="file"
                              accept=".png, .jpg, .jpeg, .gif"
                              name="selectedFile"
                            />
                          </label>
                        </div>
                      ) : null}
                    </div>
                    {preview === false ? (
                      <ul className="options-list">
                        <li className="input-options">
                          <input
                            question_type="text"
                            placeholder="Enter Option 1"
                            value={question.option1}
                            onChange={(e) => handleOption1Change(e, i)}
                          />
                        </li>
                        <li className="input-options">
                          <input
                            question_type="text"
                            placeholder="Enter Option 2"
                            value={question.option2}
                            onChange={(e) => handleOption2Change(e, i)}
                          />
                        </li>
                        <li className="input-options">
                          <input
                            question_type="text"
                            placeholder="Enter Option 3"
                            value={question.option3}
                            onChange={(e) => handleOption3Change(e, i)}
                          />
                        </li>
                        <li className="input-options">
                          <input
                            question_type="text"
                            placeholder="Enter Option 4"
                            value={question.option4}
                            onChange={(e) => handleOption4Change(e, i)}
                          />
                        </li>
                        <div className="correct-answer-div">
                          Enter Correct Answer{" "}
                          <select className="correct-answer" onChange={(e) => handleCorrectAnswer(e, i)}>
                            <option value="a">A</option>
                            <option value="b">B</option>
                            <option value="c">C</option>
                            <option value="d">D</option>
                          </select>
                        </div>
                      </ul>
                    ) : (
                      <ul className="see-options">
                        <li>{question.option1}</li>
                        <li>{question.option2}</li>
                        <li>{question.option3}</li>
                        <li>{question.option4}</li>
                      </ul>
                    )}
                  </div>
                ) : question.question_type === "True/False without image" ? (
                  preview === false ? (
                    <ul className="options-list">
                      <li className="input-options">
                        <input question_type="text" value="True" disabled />
                      </li>
                      <li className="input-options">
                        <input question_type="text" value="False" disabled />
                      </li>
                      <div className="correct-answer-div">
                        Enter Correct Answer{" "}
                        <select className="correct-answer" onChange={(e) => handleCorrectAnswer(e, i)}>
                          <option value="a">A</option>
                          <option value="b">B</option>
                        </select>
                      </div>
                    </ul>
                  ) : (
                    <ul className="see-options">
                      <li>True</li>
                      <li>False</li>
                    </ul>
                  )
                ) : question.question_type === "True/False with image" ? (
                  <div className="display-questions">
                    <div className="selected-image">
                      {question.isSelected ? (
                        <div className="show-img">
                          <img src={question.selectedSrc} alt="" />
                        </div>
                      ) : null}
                      {preview === false ? (
                        <div className="add-image">
                          <label>
                            <img src={Add} alt="" />
                            <input
                              required
                              onChange={onFileChange(i)}
                              type="file"
                              accept=".png, .jpg, .jpeg, .gif"
                              name="selectedFile"
                            />
                          </label>
                        </div>
                      ) : null}
                    </div>
                    {preview === false ? (
                      <ul className="options-list">
                        <li className="input-options">
                          <input question_type="text" value="True" disabled />
                        </li>
                        <li className="input-options">
                          <input question_type="text" value="False" disabled />
                        </li>
                        <div className="correct-answer-div">
                          Enter Correct Answer{" "}
                          <select className="correct-answer" onChange={(e) => handleCorrectAnswer(e, i)}>
                            <option value="a">A</option>
                            <option value="b">B</option>
                          </select>
                        </div>
                      </ul>
                    ) : (
                      <ul className="see-options">
                        <li>True</li>
                        <li>False</li>
                      </ul>
                    )}
                  </div>
                ) : question.question_type === "German with image" ||
                  question.question_type === "Spell with image" ? (
                  <>
                    <div className="selected-image">
                      {question.isSelected ? (
                        <>
                          <div className="show-img">
                            <img src={question.selectedSrc} alt="" />
                          </div>
                        </>
                      ) : null}
                      {preview === false ? (
                        <>
                          <div className="add-image">
                            <label>
                              <img src={Add} alt="" />
                              <input
                                required
                                onChange={onFileChange(i)}
                                type="file"
                                accept=".png, .jpg, .jpeg, .gif"
                                name="selectedFile"
                              />
                            </label>
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div className="correct-answer-div">
                      Enter Correct Answer <input className="correct-answer" name='correct_answer' onChange={(e) => handleCorrectAnswer(e, i)}/>
                    </div>
                  </>
                ) : question.question_type === "German without image" ||
                  question.question_type === "Spell without image" ? (
                  <div className="correct-answer-div">
                    Enter Correct Answer <input className="correct-answer"  name='correct_answer' onChange={(e) => handleCorrectAnswer(e, i)}/>
                  </div>
                ) : question.question_type === "Multiple Images Question" ? (
                  <>
                    <Test
                      idx={i}
                      toggle={preview}
                      imgs={question.imgs}
                      setImgs={(imgs) => {
                        const newQuestions = [...questions];
                        newQuestions[i].imgs = imgs;
                        setQuestions(newQuestions);
                      }}
                    />
                    <div className="correct-answer-div">
                      Enter Correct Answer{" "}
                      <select className="correct-answer" onChange={(e) => handleCorrectAnswer(e, i)}>
                        <option value="a">A</option>
                        <option value="b">B</option>
                        <option value="c">C</option>
                        <option value="d">D</option>
                      </select>
                    </div>
                  </>
                ) : null}
              </div>
            ))}
          </div>

          <button
            question_type="button"
            className="add-button"
            onClick={handleAddQuestion}
          >
            + Add another question
          </button>
        </form>

        {preview === false ? (
          <Button name="Preview Questions" click={() => setPreview(true)} />
        ) : (
          <div className="split-button">
            <Button2
              name="Back to edit Questions"
              click={() => setPreview(false)}
            />
            <Button disabled={loading} name="Finish and upload" click={handleSubmit} />
          </div>
        )}
      </div>
    </div>
  );
}
