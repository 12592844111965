import React from "react";
import "./admissionthree.css";
import Num1 from "../../images/num1.png";
import Num2 from "../../images/num2.png";
import Num3 from "../../images/num3.png";
import Num4 from "../../images/num4.png";

export default function AdmissionThree() {
  return (
    <div className="admission-three">
      <div className="section-nine-div">
        <p className="section-nine-first">
          <strong>The Importance of Age-Appropriateness in Education</strong>
        </p>
        <p className="section-nine-second">
          Education starts from birth, but for it to be effective, it must
          mirror the developmental maturity of the child. At Our Lady of Hope
          Montessori Schools, age-appropriate class placement depends on both
          the month and year in which a child is born. Currently, for a child to
          be age-appropriately placed in our infant school which begins at
          infant community 1, the child must turn 2 by December 31st of that
          year, whereas for grade school, the child must turn 6 as by December
          31st of that year as well.
        </p>
      </div>
      <div className="numbers-img num1">
        <img src={Num1} alt="" />
      </div>
      <div className="numbers-img num2">
        <img src={Num2} alt="" />
      </div>
      <div className="numbers-img num3">
        <img src={Num3} alt="" />
      </div>
      <div className="numbers-img num4">
        <img src={Num4} alt="" />
      </div>
    </div>
  );
}
