import React from "react";
import { useNavigate } from "react-router-dom";
import { BackButton } from "../studentPages/PersonalData";
import Session from "../../images/Nosession.png";
import { Novideo } from "./Resources";

export default function ViewQuestions() {
  const navigate = useNavigate();
  return (
    <div className="set-questions">
      <div className="set-container">
        <BackButton click={() => navigate(-1)} />
        <div className="session-flex">
          <div className="cat-session">
            <p>1st TERM</p>
            <p>1st Continuous Assessment Test</p>
            <p>2011/2012 Session</p>
          </div>
          <div className="imported-sub">Mathematics</div>
        </div>

        {/* <Novideo
          img={Session}
          text="No Questions Exist for this Subject for this Term and Session."
        /> */}

        <div className="question-elements">
          <div className="questions-wrapper">
            <ul
              className="input-list"
              style={{
                listStylePosition: "outside",
                listStyleType: "none",
                columnGap: "30px",
              }}
            >
              <li
                className="input-item"
                style={{
                  width: "",
                }}
              >
                {/* Q{i + 1}.{" "} */}
                Q1
                <p>
                  {/* {question.type === "German without image" ||
                  question.type === "Spell without image"
                    ? `${question.question}_______________`
                    : question.question} */}
                  The addition of 4 + 16 gives
                </p>
              </li>

              <li>
                {/* <p>{`(${"1"} ${question.mark == 1 ? "Mark" : "Marks"})`}</p> */}
                <p>{`(${"1"} Mark)`}</p>
              </li>
            </ul>
            <ul className="see-options">
              <li>Cow</li>
              <li>Dog</li>
              <li>Elephant</li>
              <li>Lion</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
