import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
    <App />
);

///ISSUES:
///When setting questions (test/exam), you cannot edit a question, or delete a simple block you might have added by mistake
///You can not edit a single question
///You cannot delete a single qeustion
///You cannot delete all qeustions

// /teacher_continous_assessment/create_continous_assessment
// I created questions in section A for a subject. After I was done, I created another set of questions again for same section A, but instead of storing them in the questions_obj object (which is section A), by the second time i tried, the other 2 questions were added in questions_theory (which is section B)
// __________________


// let dioBaseUrl = "https://polar-spire-56590.herokuapp.com";
// let dioBaseUrl = "https://server-olhms.onrender.com"; 
// let primaryColor = "#e05616";


// ISSUES
//when admitting
//old students in NewForm.js 
//new students in ProApplication.js
//the following fields are hardcoded:  setCurrentSession, setCurrentTerm


// edithataije@ourladyofhopemontessorischools.com
// xMQWrx

// ADMIN
// "oendowed@gmail.com"
// "74Wkbu"

// {
//   "email": "ellaayo@ourladyofhopemontessorischools.com",
//   "password": "x2gAJV",
//   "staff_id": 1697031515504
// }

// TEACHER
// Email: obainoblast@gmail.com
// Staff ID: 1696933880774
// Password: NAdJsJ

// Staff Login Details
// Email: successnduka@ourladyofhopemontessorischools.com
// Staff ID: 1697005720667
// Password: lY3WlP

// Staff Login Details - CRECHE
// Email: obainoblast@gmail.com
// Staff ID: 1697086216252
// Password: KtBe4l

// student - emerald
// Email: keksitransportandlogistics@gmail.com
// Student ID: 1696185459903
// First Term Login Code: 1696185739414
// Password: 3niDs9, password

// MOBILE APP

