import React, { useEffect, useState, useContext } from "react";
import { BackButton } from "../studentPages/PersonalData";
import { useNavigate, useLocation } from "react-router-dom";
import Cap from "../../images/cap.png";
import Button from "../../components/Button/Button";
import { adminContext } from "../context/adminContext";
import { Toaster, toast } from "react-hot-toast"; // Import the Toaster component
export default function ViewItem() {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location.state.type;
  const values = useContext(adminContext);
  const { success, admintoken,teachertoken } = values;

  const [loading, setLoading] = useState(false);
  const [collectorName, setCollectorName] = useState("");

  const storeRequest = location.state.storeRequest;

  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const showErrorToast = (message) => {
    toast.error(message, {
      icon: "❗️",
      position: "bottom-center",
    });
  };

  const notifyRequester = async () => {
    setLoading(true);
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_store_requests/notify_requester",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: success == "teacher" ? JSON.parse(teachertoken) : JSON.parse(admintoken),
          store_request_id: storeRequest._id,
        }),
      }
    );
    const data = await res.json();
    setLoading(false);
    console.log(data?.msg);
    if (data?.msg == "Success") {
      navigate(-1);
      toast.success("Pick up notification sent successfully", {
        position: "bottom-center",
      });
    } else {
      alert(data?.msg);
    }
  };

  const handleCollectorNameChange = (e) => {
    setCollectorName(e.target.value);
  };

  // function to indicate collected
  const indicatedCollected = async () => {
    setLoading(true);
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_store_requests/indicate_collected",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: success == "teacher" ? JSON.parse(teachertoken) : JSON.parse(admintoken),
          store_request_id: storeRequest._id,
          collector_name: collectorName,
        }),
      }
    );
    const data = await res.json();
    setLoading(false);
    console.log(data?.msg);
    
    if (data?.msg == "Success") {
      navigate(-1);
      toast.success("Successful", {
        position: "bottom-center",
      });
    } else {
      alert(data?.msg);
    }
  };

  return (
    <div
      className="set-questions"
      style={{
        backgroundColor: "rgb(255, 248, 248)",
        height: "100%",
        alignItems: "flex-start",
      }}
    >
      <div className="set-container">
        <BackButton click={() => navigate(-1)} />
        <div className="student-info-admin">
          <p className="student-name">{storeRequest.student_name}</p>
          <p className="faint-info">{`Student ID: ${storeRequest.student_id}`}</p>
          <p className="faint-info">{`Admission Year: ${storeRequest.admission_year}`}</p>
          <p className="faint-info">{`Current Session: ${storeRequest.session}`}</p>
          <p className="faint-info">{`Current Term: ${storeRequest.term}`}</p>
          <p className="faint-info">{`Current Class: ${storeRequest.student_class}`}</p>
        </div>
        <div className="show-store-items">
          {storeRequest.store_items.map((storeItem, index) => (
            <OrderedItem
              key={index}
              img_url={storeItem.img_url}
              product_name={storeItem.product_name}
              price={storeItem.price}
              quantity={storeItem.quantity}
              size={storeItem.size}
            />
          ))}
        </div>
        {type == 1 ? (
          <div style={{ marginTop: "40px" }}>
            <Button
              disabled={loading}
              click={() => {
                notifyRequester();
              }}
              name="Notify Requester that items are ready for payment and collection"
            />
          </div>
        ) : type == 2 ? (
          <div className="check-admin-store">
            {/* <Button
              disabled={loading}
              onClick={() => {
                notifyRequester();
              }}
              name="Notify Requester that items are ready for payment and collection"
            /> */}
            <div
              className="store-check"
              style={{ marginTop: 16, marginBottom: 16 }}
            >
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <label>Items paid for and collected </label>
            </div>
            <div className="form-group-admin">
              <label htmlFor="student">Name of Collector</label>
              <input
                type="text"
                value={collectorName}
                onChange={handleCollectorNameChange}
              />
            </div>
            <Button disabled={loading}
              click={() => {
                if (isChecked == false) {
                  showErrorToast(
                    "Please check that the items have been paid for and collected first"
                  );
                } else {
                  if (collectorName != "") {
                    indicatedCollected();
                  } else {
                    showErrorToast("Please enter name of collector");
                  }
                }
              }}
              name="Done"
            />
          </div>
        ) : type == 3 ? (
          <div className="status-store-container" style={{ marginTop: "40px" }}>
            <div className="display-store-status">
              <p className="display-status-name">Status</p>
              <p className="display-status-title">
                <span>Items paid for and collected</span>
                <span></span>
                <span>{convertTime(storeRequest.collector_date)}</span>
              </p>
            </div>
            <div className="display-store-status">
              <p className="display-status-name">Name of Collector</p>
              <p className="display-status-title">
                {storeRequest.collector_name}
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export function OrderedItem(props) {
  return (
    <div className="store-item" onClick={props.click}>
      <div className="item-image-container">
        <img src={props.img_url} alt="" />
      </div>
      <div className="store-item-details">
        <p
          className="store-item-name-admin"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <span>{props.product_name}</span>
        </p>
        <p
          className="store-item-name-admin"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <span className="number-ordered">{props.quantity}</span>
        </p>
        <p className="store-item-name-admin">
          <span>{props.size}</span>
        </p>
        <div className="item-price-admin">&#8358;{props.price}</div>
      </div>
    </div>
  );
}

const convertTime = (timestamp) =>
  new Date(timestamp).toString().split("GMT")[0];
