import React from "react";
import Zoom from "../../components/Zoom/Zoom";
import PlayGround from "../../images/playground.png";
import Ipad from "../../images/ipad.png";
import Smiles from "../../images/smiles.png";
import zoomEllipse from "../../images/zoom-ellipse.png";
import zoomEllipses from "../../images/zoom-ellipse-2.png";
import "./sectionfour.css";

export default function SectionFour() {
  const zoomArray = [
    {
      image: PlayGround,
      header: "Personalized Education",
      body: "A unique child-centred school that provides an individualised education, tailored to meet all of your child's needs. We maintain efficiently managed class sizes to ensure that our experienced staff have time with each individual child.",
    },
    {
      image: Smiles,
      header: "Cocurricular Activities",
      body: "We think beyond traditional education to transform learning as we have a rich bouquet of Cocurricular activities designed to educate and prepare your child for the future.",
    },
    {
      image: Ipad,
      header: "Flexi-Hybrid Learning",
      body: "Our educational approach combines in-person and virtual learning. This method allows students to take control over their own learning, making them learners who can compete with their contemporaries the world over.",
    },
  ];
  return (
    <div className="section-four">
      <strong>What makes us exceptionally different</strong>
      <div className="zoom-container">
        {zoomArray.map((zoomObj, index) => (
          <Zoom
            classic={"zoom"}
            img={zoomObj.image}
            header={zoomObj.header}
            body={zoomObj.body}
            background={"rgba(196, 196, 196, 0.3)"}
            filter={"blur(50px)"}
            text={"zoom-text"}
            zoomBackground={"zoom-background"}
          />
        ))}
      </div>
      <div className="zoom-ellipse-container">
        <img src={zoomEllipse} alt="" />
      </div>
      <div className="zoom-ellipse-container-2">
        <img src={zoomEllipses} alt="" />
      </div>
    </div>
  );
}
