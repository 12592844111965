import React, { useState, useEffect } from "react";
import "./sectionseven.css";
import Frame1 from "../../images/frame1.png";
import Frame4 from "../../images/frame4.png";
import Frame3 from "../../images/frame3.png";
import Frame2 from "../../images/frame2.png";
import AdvantageOne from "../../images/class-1.png";
import AdvantageTwo from "../../images/class-2.png";
import Advantage3 from "../../images/class-3.png";
import Advantage4 from "../../images/class-4.png";
import Advantage5 from "../../images/class-5.png";
import Advantage6 from "../../images/class-6.png";
import Advantage7 from "../../images/class-7.png";
import Advantage8 from "../../images/class-8.png";
import Advantage9 from "../../images/co-1.png";
import Advantage10 from "../../images/co-2.png";
import Advantage11 from "../../images/co-3.png";
import Advantage12 from "../../images/co-4.png";
import Advantage13 from "../../images/co-5.png";
import Advantage14 from "../../images/co-6.png";
import Advantage15 from "../../images/co-7.png";
import Advantage16 from "../../images/co-8.png";
import More1 from "../../images/more1.jpg";
import More2 from "../../images/more2.jpg";
import More3 from "../../images/more3.jpg";
import More4 from "../../images/more4.jpg";
import More5 from "../../images/more5.jpg";
import More6 from "../../images/more6.jpg";
import More7 from "../../images/more7.jpg";
import { useNavigate } from "react-router-dom";

export default function SectionSeven() {
  const navigate = useNavigate();

  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);

  const [sliceCount, setSliceCount] = useState(8);

  const viewGallery = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_gallery/view_all_gallery",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(),
      }
    );
    const data = await res.json();
    // console.log(`---------------> ${JSON.stringify(data)}`);
    // setLoading(false);
    const gallery = data?.gallery;
    // console.log(`--------gallery-------> ${JSON.stringify(gallery)}`);
    
    if (gallery.length > 0) {
      let img = [];
      let vid = [];
      
      gallery.forEach((item) => {
        if (item.type == "image" && item.urls.length > 0) {
          item.urls.map((url) => img.push(url));
        }

        if (item.type == "video" && item.urls.length > 0) {
          item.urls.map((url) => vid.push(url));
        }
      });

      setImages(img);
      setVideos(vid);
      // setVlogs(data?.vlogs);
      //console.log(vid);
    }
  };

  useEffect(() => {
    viewGallery();
  }, []);

  const [currentImages, setCurrentImages] = useState(getRandomImages());
  function getRandomImages() {
    const imageSet = images.slice(0, 8);
    return imageSet.slice(
      0,
      getWindowWidth() >= 770
        ? 4
        : getWindowWidth() >= 600
        ? 3
        : getWindowWidth() <= 500
        ? 2
        : 2
    );
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const newImages = getRandomImages();
      setCurrentImages(newImages);
    }, 6000);

    return () => clearInterval(interval);
  }, [getWindowWidth()]);

  function getWindowWidth() {
    return typeof document !== "undefined"
      ? document.documentElement.clientWidth
      : 0;
  }

  useEffect(() => {
    function handleResize() {
      const newImages = getRandomImages();
      setCurrentImages(newImages);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="section-seven">
      <div className="section-seven-text">
        <p className="section-seven-p">
          <strong>Gallery</strong>
        </p>
        <p
          className="view-photos"
          onClick={() => {
            navigate("/gallery");
          }}
        >
          View all Photos
        </p>
      </div>
      <div className="homepage-gallery-container">
        {currentImages.map((image, index) => (
          <div className="homepage-gallery" id={`carousel-image${index}`}>
            <img src={image} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
}
