import React, { useState, useContext, useEffect } from "react";
import { BackButton } from "../studentPages/PersonalData";
import { useNavigate, useLocation } from "react-router-dom";
import { Toggle } from "./Resources";
import Question from "../../images/noquestion.png";
import Submission from "../../images/nosubmission.png";
import Addition from "../../images/addition.png";
import { Novideo } from "./Resources";
import "./checksubject.css";
import "./submitted.css";
import { adminContext } from "../context/adminContext";
import Edit from "../../images/edit.png";
import Delete from "../../images/Delete2.png";

import { Toaster, toast } from "react-hot-toast";
export default function CheckSubject() {
  const navigate = useNavigate();
  const location = useLocation();
  const session = location.state.session;
  const subject = location.state.subject;
  const ca_id = location.state.ca_id;
  const section_present = location.state.section_present;
  const type = location.state.type;
  const admin = location.state.admin;

  const values = useContext(adminContext);
  const { teacherData, teachertoken } = values;
  const dataT = JSON.parse(teacherData);

  const [loading, setLoading] = useState(true);
  const [ca, setCa] = useState({
    questions_obj: [],
  });

  const showCustomToast = (type, message) => {
    if (type == 0) {
      toast.error(message, {
        icon: "❗️",
        position: "bottom-center",
      });
    } else {
      toast.success(message, {
        position: "bottom-center",
      });
    }
  };

  const [toggle, setToggle] = useState(true);

  const tableValues = ["S/N", "Name", "Student Id", "Score", "Grade"];

  const setCaNo = (type) => {
    if (type == "1st Continuous Assessment Test") {
      return 1;
    }
    if (type == "2nd Continuous Assessment Test") {
      return 2;
    } else {
      return 3;
    }
  };

  const setAssessType = (type) => {
    if (type == "1st Continuous Assessment Test") {
      return "ca";
    }
    if (type == "2nd Continuous Assessment Test") {
      return "ca";
    } else {
      return "exam";
    }
  };

  // get continous assessment for particular session and term
  const viewContinousAssessment = async () => {
    setLoading(true);
    const res = await fetch(
      "https://server-olhms.onrender.com/teacher_continous_assessment/view_continous_assessments",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(teachertoken),
          session: session.session,
          term: session.current_term,
          subject: subject,
          ca: setCaNo(type),
          type: setAssessType(type),
        }),
      }
    );
    const data = await res.json();
    setLoading(false);
    if (data?.msg == "no continous_assessments at the moment") {
      setToggle(true);
    } else {
      if (data?.continous_assessment.questions_obj.length > 0) {
        setToggle(false);
        setCa(data?.continous_assessment);
      } else {
        setToggle(true);
      }
    }

    console.log(data);
  };

  useEffect(() => {
    viewContinousAssessment();
  }, []);

  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (document.documentElement.clientWidth <= 800) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className={mobile ? "" : "set-questions"}
      style={mobile ? { margin: 24 } : {}}
    >
      <div className="set-container">
        <BackButton click={() => navigate(-1)} />

        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            // style={{ paddingRight: "20px" }}
            className="add-question"
            onClick={() => {
              navigate("/teacher/set-questions", {
                state: { session, subject, ca_id, section_present, type },
              });
            }}
          >
            <img src={Addition} alt="" height={"50px"} width={"50px"} />
          </div>
          {/* <div
              style={{ paddingLeft: "20px" }}
              className="add-question"
              onClick={() => {
                toast.error("Feature is being worked upon...", {
                  icon: "⏳️",
                  position: "bottom-center",
                });

                // navigate("/teacher/select", {
                //   state: { week: week, term: term, subject: subject, id },
                // })
              }}
            >
              <img src={Edit} alt="" height={"50px"} width={"50px"}/>
            </div> */}
          <div
            style={{ paddingLeft: "20px" }}
            className="add-question"
            onClick={() => {
              // openDialogBox(true);
              showCustomToast(
                1,
                "Are you sure you want to delete all questions?"
              );
            }}
          >
            <img src={Delete} alt="" height={"50px"} width={"50px"} />
          </div>
        </div>

        <div className="session-flex">
          <div className="cat-session">
            <p>{session.current_term}</p>
            <p>{type}</p>
            <p>{session.session} Session</p>
          </div>
          <div className="imported-sub">{subject} - SECTION A</div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {admin || loading ? (
              <></>
            ) : (
              <p style={{ color: "#E05615" }}>{`Test time: ${
                ca.questions_obj.length > 0 ? ca.duration_a : "00 hrs 00 mins"
              }`}</p>
            )}
          </div>
        </div>

        {toggle ? (
          loading ? (
            <></>
          ) : (
            <Novideo
              img={Question}
              text={
                admin || loading
                  ? ""
                  : "No Questions have been set. Tap the icon to start setting questions"
              }
            />
          )
        ) : (
          ca.questions_obj.map((question, i) => (
            <div key={i} className="questions-wrapper">
              <ul
                className="input-list"
                style={{
                  listStylePosition: "outside",
                  listStylequestion_type: "none",
                  columnGap: "30px",
                  display: mobile ? "block" : "inherit",
                }}
              >
                <li
                  className="input-item"
                  style={{
                    width: "",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>Q{i + 1}. </span>
                  <p>
                    {question.question_type === "German without image" ||
                    question.question_type === "Spell without image"
                      ? `${question.question}_______________`
                      : question.question}
                  </p>
                </li>
                <li
                  style={
                    mobile
                      ? { listStyleType: "none", marginTop: 8 }
                      : { listStyleType: "none" }
                  }
                >
                  {
                    <p style={{ fontWeight: "bold" }}>{`(${question.mark} ${
                      question.mark == 1 ? "Mark" : "Marks"
                    })`}</p>
                  }
                </li>
              </ul>

              {question.question_type ===
              "Multiple Choice Question without Image" ? (
                <div>
                  <ul className="see-options">
                    <li>{question.options[0]}</li>
                    <li>{question.options[1]}</li>
                    <li>{question.options[2]}</li>
                    <li>{question.options[3]}</li>
                  </ul>
                  <p
                    style={{ color: "#E05615", marginLeft: 30 }}
                  >{`ANS: ${question.correct_answer.toUpperCase()}`}</p>
                </div>
              ) : question.question_type ===
                "Multiple Choice Question with Image" ? (
                <div className="display-questions">
                  <div className="selected-image">
                    <div className="show-img">
                      <img src={question.img_urls[0]} alt="" />
                    </div>
                  </div>
                  {
                    <ul className="see-options">
                      <li>{question.options[0]}</li>
                      <li>{question.options[1]}</li>
                      <li>{question.options[2]}</li>
                      <li>{question.options[3]}</li>
                    </ul>
                  }
                  <p
                    style={{ color: "#E05615", marginLeft: 30 }}
                  >{`ANS: ${question.correct_answer.toUpperCase()}`}</p>
                </div>
              ) : question.question_type === "True/False without image" ? (
                <div>
                  <ul className="see-options">
                    <li>True</li>
                    <li>False</li>
                  </ul>
                  <p
                    style={{ color: "#E05615", marginLeft: 30 }}
                  >{`ANS: ${question.correct_answer.toUpperCase()}`}</p>
                </div>
              ) : question.question_type === "True/False with image" ? (
                <div className="display-questions">
                  <div className="selected-image">
                    <div className="show-img">
                      <img src={question.img_urls[0]} alt="" />
                    </div>
                  </div>
                  {
                    <ul className="see-options">
                      <li>True</li>
                      <li>False</li>
                    </ul>
                  }
                  <p
                    style={{ color: "#E05615", marginLeft: 30 }}
                  >{`ANS: ${question.correct_answer.toUpperCase()}`}</p>
                </div>
              ) : question.question_type === "German with image" ||
                question.question_type === "Spell with image" ? (
                <>
                  <div className="selected-image">
                    <div className="show-img">
                      <img src={question.img_urls[0]} alt="" />
                    </div>
                  </div>
                  <div className="correct-answer-div">
                    {/* Enter Correct Answer <input className="correct-answer" name='correct_answer' onChange={(e) => handleCorrectAnswer(e, i)}/> */}
                    <p
                      style={{ color: "#E05615", marginLeft: 30 }}
                    >{`ANS: ${question.correct_answer.toUpperCase()}`}</p>
                  </div>
                </>
              ) : question.question_type === "German without image" ||
                question.question_type === "Spell without image" ? (
                <div className="correct-answer-div">
                  {/* Enter Correct Answer <input className="correct-answer"  name='correct_answer' onChange={(e) => handleCorrectAnswer(e, i)}/> */}
                  <p
                    style={{ color: "#E05615", marginLeft: 30 }}
                  >{`ANS: ${question.correct_answer.toUpperCase()}`}</p>
                </div>
              ) : question.question_type === "Multiple Images Question" ? (
                <>
                  <div className="questions-wrapper">
                    <div className="display-questions">
                      <div className="selected-image">
                        {question.img_urls.map((item, index) => (
                          <div className="show-img" key={index}>
                            <img src={item} alt="" />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <p
                    style={{ color: "#E05615", marginLeft: 30 }}
                  >{`ANS: ${question.correct_answer.toUpperCase()}`}</p>
                  <div className="correct-answer-div">
                    {/* Enter Correct Answer{" "}
                      <select className="correct-answer" onChange={(e) => handleCorrectAnswer(e, i)}>
                        <option value="a">A</option>
                        <option value="b">B</option>
                        <option value="c">C</option>
                        <option value="d">D</option>
                      </select> */}
                  </div>
                </>
              ) : null}
            </div>
          ))
        )}

        {/* (
          <div className="submitted-students">
            <p>Student List</p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "5px",
              }}
            >
              <Table columns="0.5fr 2fr 1fr 1fr 1fr" data={tableValues} />

              <div
                // key={index}
                style={{
                  display: "grid",
                  gridTemplateColumns: "0.5fr 2fr 1fr 1fr 1fr",
                  fontWeight: "300",
                  gridGap: "30px",
                }}
              >
                <div>1</div>
                <div>English Language</div>
                <div>odsjcnsfsf</div>
                <div>70</div>
                <div>Excellent</div>
              </div>
            </div>
          </div>
        ) */}

        {/* {toggle ? (
          <Novideo
            img={Question}
            text="No Questions have been set. Tap the icon to start setting questions"
          />
        ) : (
          <Novideo
            img={Submission}
            text="No Student submission has occurred at this time. Please check back after a test."
          />
        )} */}

        {toggle ? (
          admin || loading ? (
            <></>
          ) : (
            <div
              className="add-question"
              onClick={() =>
                navigate("/teacher/set-questions", {
                  state: { session, subject, ca_id, section_present, type },
                })
              }
            >
              <img src={Addition} alt="" />
            </div>
          )
        ) : null}
      </div>
    </div>
  );
}

export function Table(props) {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: props.columns,
        fontWeight: "500",
        gridGap: "30px",
      }}
    >
      {props.data.map((item, index) => (
        <div key={index}>{item}</div>
      ))}
    </div>
  );
}

export function Table2(props) {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: props.columns,
        fontWeight: "500",
        gridGap: "30px",
        borderBottom: "1px solid #000",
      }}
    >
      {props.data.map((item, index) => (
        <div
          key={index}
          style={{ textAlign: item.center === true ? "center" : "" }}
        >
          {item.data}
        </div>
      ))}
    </div>
  );
}
