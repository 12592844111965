import { useState, useEffect } from "react";
import "./faq.css";
import React from "react";

export default function FAQ() {
  const [faq, setFaq] = useState([]);
  const [loading, setLoading] = useState(true);

  const viewStaff = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_faqs/view_multiple_faqss",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        // body: JSON.stringify({}),
      }
    );
    const data = await res.json();
    setLoading(false);
    setFaq(data?.faqs);
    console.log(data);
  };

  useEffect(() => {
    viewStaff();
  }, []);
  return (
    <div className="faq-wrapper">
      <div className="faq-div">
        <p>
          <strong>Frequently Asked Questions (FAQs)</strong>
        </p>
        <div className="faq-questions">
          {faq?.map((item, i) => (
            <FaqComponent
              question={item.question}
              number={i}
              key={item._id}
              answer={item.answer}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export function FaqComponent(props) {
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };
  return (
    <div className="questions-container">
      <div className="question">
        <p>{props.question}</p>
        <i
          className={`fas fa-angle-down ${
            activeIndex === props.number ? "rotate" : "faq-point"
          }`}
          onClick={() => handleClick(props.number)}
        ></i>
      </div>
      <div className={`answer ${activeIndex === props.number ? "active" : ""}`}>
        <p>{props.answer}</p>
      </div>
    </div>
  );
}
