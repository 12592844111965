import React, { useState, useRef, useEffect } from "react";
import { Outlet, NavLink } from "react-router-dom";
import Button from "../../components/Button/Button";
import { StudentItem, StudentSelectedItem } from "../adminPages/Store";
import "./SchoolStore.css";
import { BackButton } from "./PersonalData";
import { useStepContext } from "@mui/material";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { toast } from "react-hot-toast";

export default function SchoolStore() {
  const [storeItems, setStoreItems] = useState([]);
  const [searchedStoreItems, setSearchedStoreItems] = useState([]);
  const [searchString, setSearchString] = useState("");

  const student = JSON.parse(localStorage.getItem("studentData"));
  const studentToken = localStorage.getItem("student_rubbish");

  const [loading, setLoading] = useState(false);
  const [confirmedState, setConfirmedState] = useState("base");
  let [selectedItems, setSelectedItems] = useState([]);

  const [filterIndexes, setFilterIndexes] = useState([
    true,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [filter, setFilter] = useState("all");

  const addToItems = (item) => {
    selectedItems.push(item);
    setSelectedItems(selectedItems);
  };

  const removeFromItems = (item_id) => {
    let itemIndex;

    for (let i = 0; i < selectedItems.length; i++) {
      if (selectedItems[i]._id == item_id) {
        itemIndex = i;
      }
    }

    if (itemIndex != undefined || itemIndex != null) {
      selectedItems.splice(itemIndex, 1);
      setSelectedItems(selectedItems);
      console.log(item_id);
    }
  };

  const pdfRef = useRef();

  const downloadPDF = () => {
    const currentDateTime = new Date();

    const input = pdfRef.current;
    html2canvas(input, {
      letterRendering: 1,
      allowTaint: false,
      useCORS: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save(`invoice_${currentDateTime.toLocaleString()}.pdf`);
    });
  };

  const storeRequest = async () => {
    
    setLoading(true);

    const res = await fetch(
      "https://server-olhms.onrender.com/admin_store_requests/store_request",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(studentToken),
          student_id: student._id,
          student_name: student.fullname,
          phone_no: student.guardian_info.phone_no,
          email: student.guardian_info.email,
          admission_year: student.admission_year || "2022/2023",
          session: student.current_session,
          term: student.current_term,
          student_class: student.class_name,
          store_items: selectedItems,
        }),
      }
    );
    const data = await res.json();
    setLoading(false);
    downloadPDF();

    if (data.msg == "Success") {
      setConfirmedState("base");
      selectedItems.length = 0; // Clears the array
    }
    console.log(data);
  };

  const viewStoreItems = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_store/get_products",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(studentToken),
          filter: filter,
        }),
      }
    );
    const data = await res.json();
    setLoading(false);
    setStoreItems(data?.storeItems);
    console.log(data);
  };

  useEffect(() => {
    viewStoreItems();
  }, [filter]);

  const handleSearchChange = (e) => setSearchString(e.target.value);

  // function to search for store items
  const searchStoreItems = async (currPage) => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_store/search_items",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(studentToken),
          search_string: searchString,
          pagec: currPage,
        }),
      }
    );
    const data = await res.json();
    setLoading(false);
    setSearchedStoreItems(data?.storeItems);
    console.log(data);
  };

  useEffect(() => {
    if (searchString != "") {
      searchStoreItems(1);
    }
  }, [searchString]);

  const filterSelect = (index, nFilter) => {
    for (let i = 0; i < filterIndexes.length; i++) {
      if (i == index) {
        filterIndexes[i] = true;
      } else {
        filterIndexes[i] = false;
      }
    }
    setFilterIndexes(filterIndexes);
    setFilter(nFilter);
  };

  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (document.documentElement.clientWidth <= 800) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {confirmedState == "confirmed" ? (
        // ----- STATE FOR WHEN USER HAS PRESSED THE CONFIRMED BUTTON ----

        <div ref={pdfRef}>
          {/* <TeacherNavBar /> */}
          <div
            className="sidebar-container-store"
            style={mobile ? { display: "flex", flexDirection: "column" } : {}}
          >
            <div
              className="sidebar-store"
              style={{ width: 340, marginRight: 20 }}
            >
              <nav className="navv">
                <div style={{ marginLeft: 50 }}>
                  <BackButton
                    style={{
                      marginTop: 20,
                      marginBottom: 30,
                    }}
                    click={() => {
                      setConfirmedState("base");
                      setSelectedItems([]);
                    }}
                  />
                </div>
                <ul className="sidebar-li-store">
                  {selectedItems.map((selectItem, index) => (
                    <StudentSelectedItem
                      img_url={selectItem.img_url}
                      product_name={selectItem.product_name}
                      sell_price={selectItem.price}
                      quantity={selectItem.quantity}
                      onUncheck={(storeItemId) => {
                        removeFromItems(storeItemId);
                      }}
                      _id={selectItem._id}
                    />
                  ))}
                </ul>
              </nav>
            </div>
            <div
              className="student-store-items"
              style={mobile ? { marginLeft: 30, marginRight: 30 } : {}}
            >
              <p className="store-shadow">
                Please{" "}
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  CONFIRM
                </span>{" "}
                the items you need for your kids. The invoice generated should
                be submitted to school with your raised bank draft to claim
                items. Items would be returned to the store inventory if not
                paid for after 10 days.
              </p>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: 20,
                  marginBottom: 30,
                }}
              >
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  INVOICE PREVIEW
                </p>
              </div>
              <div className="show-store-items"></div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 20,
              marginBottom: 30,
            }}
          >
            <Button
              disabled={loading}
              name="Confirm and Download Invoice"
              click={(e) => {
                e.preventDefault();
                storeRequest();
                //setConfirmedState('base');
              }}
            />
          </div>
        </div>
      ) : (
        // ---- BASE STATE FOR WHEN USER HAS NOT CONFIRMED ANY PURCHASES ----
        <div>
          {/* <TeacherNavBar /> */}
          <div
            className="sidebar-container-store"
            style={mobile ? { display: "flex", flexDirection: "column" } : {}}
          >
            <div className="sidebar-store">
              <nav className="navv">
                <ul className="sidebar-li-store">
                  <div className="store-profile">
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      Search
                    </p>

                    <input
                      // style={{ marginRight: "130px" }}
                      placeholder="Search for an item"
                      name="search"
                      value={searchString}
                      onChange={handleSearchChange}
                    />

                    <Button name="Search" />
                  </div>

                  <li
                    style={{
                      // marginLeft: "30px",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Shop Filter
                  </li>
                  <form>
                    <input
                      type="checkbox"
                      name="all"
                      id="all"
                      checked={filterIndexes[0]}
                      onChange={(e) => {
                        if (e.target.checked == true) {
                          filterSelect(0, "all");
                        } else {
                          filterSelect(0, "all");
                        }
                      }}
                    />
                    &nbsp; &nbsp; &nbsp;
                    <label for="all">All</label>
                    <br />
                    <input
                      type="checkbox"
                      name="stationaries"
                      id="stationaries"
                      checked={filterIndexes[1]}
                      onChange={(e) => {
                        if (e.target.checked == true) {
                          filterSelect(1, "Stationaries");
                        } else {
                          filterSelect(0, "all");
                        }
                      }}
                    />{" "}
                    &nbsp; &nbsp;
                    <label for="stationaries">Stationaries</label>
                    <br />
                    <input
                      type="checkbox"
                      name="Uniform"
                      id="Uniform"
                      checked={filterIndexes[2]}
                      onChange={(e) => {
                        if (e.target.checked == true) {
                          filterSelect(2, "Uniform");
                        } else {
                          filterSelect(0, "all");
                        }
                      }}
                    />{" "}
                    &nbsp; &nbsp;
                    <label for="Uniform">Uniform</label>
                    <br />
                    <input
                      type="checkbox"
                      name="sport_wear"
                      id="sport_wear"
                      checked={filterIndexes[3]}
                      onChange={(e) => {
                        if (e.target.checked == true) {
                          filterSelect(3, "Sport wear");
                        } else {
                          filterSelect(0, "all");
                        }
                      }}
                    />{" "}
                    &nbsp; &nbsp;
                    <label for="Shortwear">Sport wear</label>
                    <br />
                    <input
                      type="checkbox"
                      name="text_books"
                      id="text_books"
                      checked={filterIndexes[4]}
                      onChange={(e) => {
                        if (e.target.checked == true) {
                          filterSelect(4, "Text books");
                        } else {
                          filterSelect(0, "all");
                        }
                      }}
                    />{" "}
                    &nbsp; &nbsp;
                    <label for="Shortwear">Text books</label>
                    <br />
                    <input
                      type="checkbox"
                      name="note_books"
                      id="note_books"
                      checked={filterIndexes[5]}
                      onChange={(e) => {
                        if (e.target.checked == true) {
                          filterSelect(5, "Note books");
                        } else {
                          filterSelect(0, "all");
                        }
                      }}
                    />{" "}
                    &nbsp; &nbsp;
                    <label for="Shortwear">Note books</label>
                    <br />
                    <input
                      type="checkbox"
                      name="school_shoe"
                      id="school_shoe"
                      checked={filterIndexes[6]}
                      onChange={(e) => {
                        if (e.target.checked == true) {
                          filterSelect(6, "School shoe");
                        } else {
                          filterSelect(0, "all");
                        }
                      }}
                    />{" "}
                    &nbsp; &nbsp;
                    <label for="Shortwear">School shoe</label>
                    <br />
                    <input
                      type="checkbox"
                      name="sport_shoe"
                      id="sport_shoe"
                      checked={filterIndexes[7]}
                      onChange={(e) => {
                        if (e.target.checked == true) {
                          filterSelect(7, "Sport shoe");
                        } else {
                          filterSelect(0, "all");
                        }
                      }}
                    />{" "}
                    &nbsp; &nbsp;
                    <label for="Shortwear">Sport shoe</label>
                    <br />
                  </form>
                </ul>
              </nav>
            </div>
            <div
              className="student-store-items"
              style={mobile ? { marginLeft: 30, marginRight: 30 } : {}}
            >
              <p className="store-shadow">
                Please select the items you need for your kids. An invoice will
                be generated which you would submit to school with your raised
                bank draft to claim items. Items would be returned to the store
                inventory if not paid for after 10 days.
              </p>
              <div className="show-store-items">
                {searchString == ""
                  ? storeItems.map((item, index) => (
                      <StudentItem
                        img_url={item.img_url}
                        product_name={item.product_name}
                        sell_price={item.sell_price}
                        stock_level={item.stock_level}
                        _id={item._id}
                        onCheck={(storeItem) => {
                          addToItems(storeItem);
                          console.log(selectedItems);
                        }}
                        onUncheck={(storeItemId) => {
                          removeFromItems(storeItemId);
                          console.log(selectedItems);
                        }}
                      />
                    ))
                  : searchedStoreItems.map((item, index) => (
                      <StudentItem
                        img_url={item.img_url}
                        product_name={item.product_name}
                        sell_price={item.sell_price}
                        stock_level={item.stock_level}
                        onCheck={(storeItem) => {
                          addToItems(storeItem);
                          console.log(selectedItems);
                        }}
                        onUncheck={(storeItemId) => {
                          removeFromItems(storeItemId);
                          console.log(selectedItems);
                        }}
                      />
                    ))}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 20,
              marginBottom: 30,
            }}
          >
            <Button
              name="Confirm"
              click={(e) => {
                if (selectedItems.length < 1) {
                  toast.error("Select at least one item to proceed", {
                    icon: "❗️",
                    position: "bottom-center",
                  });
                } else {
                  e.preventDefault();
                  setConfirmedState("confirmed");
                }
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}
