import React from "react";
import "./aboutintersect.css";
import SectionThree from "../homepageSections/SectionThree";
import AboutOne from "./AboutOne";

export default function AboutIntersect() {
  return (
    <div className="about-intersect">
      <div className="intersect-one">
        <AboutOne />
      </div>
      <div className="hide-intersect"></div>
      <div className="intersect-two">
        <SectionThree />
      </div>
      <div className="intersecting-div">
        <p>
          <strong>Our Story</strong>
        </p>
        <p>
          Our Lady of Hope Montessori school is a Christian Montessori school
          dedicated to following Christ as well as the spiritual and academic
          development of the whole child through a smooth blend the Montessori
          method of education and the Nigerian national curriculum of education.
          Founded in 2016 by Mrs Sola Okunkpolor alongside other like minds
          committed to changing the face of education beginning for their
          locality with a vision to spread to as many other places as possible.
          The school is approved and registered under the law of the Federal Republic of Nigeria.
        </p>
      </div>
    </div>
  );
}
