import React, { useState, useEffect, useContext } from "react";
import { Table, Table2 } from "../teacherPages/CheckSubject";
import { useNavigate, useLocation } from "react-router-dom";
import { BackButton } from "../studentPages/PersonalData";
import Button from "../../components/Button/Button";
import { adminContext } from "../context/adminContext";
import ClipLoader from "react-spinners/ClipLoader";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Button2 } from "../../components/Button/Button";

export default function ResultDetails() {
  const location = useLocation();
  const result = location.state.result;
  const is_validated = location.state.is_validated;
  const tempAdminToken = location.state.tempAdminToken;

  const navigate = useNavigate();
  const values = useContext(adminContext);
  const { teacherData, admintoken } = values;
  const adminName = localStorage.getItem("name");
  const student = JSON.parse(localStorage.getItem("studentData"));

  const [affective, setAffective] = useState({
    general_conduct: result.affective_traits.general_conduct,
    assignments: result.affective_traits.assignments,
    class_participation: result.affective_traits.class_participation,
    finishes_work_on_time: result.affective_traits.finishes_work_on_time,
    takes_care_of_materials: result.affective_traits.takes_care_of_materials,
    working_independently: result.affective_traits.working_independently
  }); 

  const [psycho, setPsycho] = useState({
    regularity: result.psycho_motive_traits.regularity,
    personal_cleanliness: result.psycho_motive_traits.personal_cleanliness,
    punctuality: result.psycho_motive_traits.punctuality,
    completion_of_work: result.psycho_motive_traits.completion_of_work,
    // disturbing_others: result.psycho_motive_traits.disturbing_others,
    following_instructions: result.psycho_motive_traits.following_instructions
  });

  const [fees, setFees] = useState({
    nextTermFees:
      result.next_term_fee != "" && result.next_term_fee != undefined
        ? result.next_term_fee
        : "",
    otherFees:
      result.other_fees != "" && result.other_fees != undefined
        ? result.other_fees
        : "",
    totalFees:
      result.total_fees != "" && result.total_fees != undefined
        ? result.total_fees
        : "",
  });

  // get some settings data to display in some fields

  const [session, setSession] = useState({
    next_term_resumption_date: "",
  });

  const getSession = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_session/view_current_session",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: tempAdminToken,
        }),
      }
    );

    const data = await res.json();
    const session = data?.sessionM;
    setSession(session);
  };

  useEffect(() => {
    getSession();
  }, []);

  let termTotal = result.results.length * 100;
  let studentTermTotal = 0; // Term's total
  let noOfSubjectsPassed = 0; // No of subjects passed
  let noOfSubjectsFailed = 0; // No of subjects failed

  result.results.map((res) => {
    studentTermTotal += parseInt(res.total);
    if (parseInt(res.total) >= 40) {
      noOfSubjectsPassed++;
    } else {
      noOfSubjectsFailed++;
    }
  });

  let termAverage = ((studentTermTotal / termTotal) * 100).toFixed(2);

  const tableValues = [
    { data: "S/N", center: false },
    { data: "Subject", center: false },
    { data: "1st C.A.T (20)", center: true },
    { data: "2nd C.A.T (20)", center: true },
    { data: "Exam (60)", center: true },
    { data: "Total", center: true },
    { data: "Grade", center: true },
    { data: "Remark", center: true },
  ];

  const p_padding = { paddingBottom: "6px" };

  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (document.documentElement.clientWidth <= 800) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className={mobile ? "" : "set-questions"}
      style={mobile ? { margin: 24 } : {}}
    >
      <div className="set-container">
        <BackButton click={() => navigate(-1)} />
        <div className="submitted-students">
          <div
            style={
              mobile
                ? { display: "flex", flexDirection: "column-reverse" }
                : {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }
            }
          >
            <div style={mobile ? { marginTop: 16 } : {}}>
              <p style={p_padding}>Name: {student.fullname}</p>
              <p style={p_padding}>Student ID: {result.student_id}</p>
              <p style={p_padding}>Class: {result.class_name}</p>
              <p style={p_padding}>Term: {result.term}</p>
              <p style={p_padding}>Session: {result.session}</p>
              <p style={p_padding}>Validated by: {result.is_validated_by}</p>
            </div>

            <div className="data-img">
              <img src={student.img_url} alt="" />
            </div>
          </div>

          <div>
            <p style={{ color: "#E05615", marginTop: 16, marginBottom: 16 }}>
              A. COGNITIVE REPORT
            </p>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "5px",
            }}
          >
            <Table2
              columns="0.5fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr"
              data={tableValues}
            />

            {/* horizontal-line */}
            <div
              style={{
                height: "1px",
                width: "100%",
                backgroundColor: "black",
              }}
            ></div>

            {result.results.map((res, index) => (
              <div
                key={index}
                style={{
                  display: "grid",
                  gridTemplateColumns: "0.5fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr",
                  fontWeight: "300",
                  gridGap: "30px",
                  cursor: "pointer",
                }}
              >
                <div>{index + 1}</div>
                <div>{res.subject}</div>
                <div className="centered-text">{res.first_ca}</div>
                <div className="centered-text">{res.second_ca}</div>
                <div className="centered-text">{res.exam}</div>
                <div className="centered-text">{res.total}</div>
                <div className="centered-text">{res.grade}</div>
                <div className="centered-text">{res.remark}</div>
              </div>
            ))}
          </div>
        </div>

        <form className="validate-form">
          <div className="word-row">
            <span>Interpretation of grades</span>
            <span>100(A) Distinction</span>
            <span>70-99(A) Excellent</span>
            <span>60-69(B) Very Good</span>
            <span>50-59(C) Good</span>
            <span>40-49(D) Fair</span>
            <span>≤39(F) Fail</span>
          </div>

          <div
            style={
              mobile
                ? {
                    display: "flex",
                    flexDirection: "column",
                  }
                : {
                    display: "flex",
                    flex: "1 1",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginLeft: 100,
                    marginRight: 100,
                    alignItems: "center",
                  }
            }
          >
            {/* Add results summary and other stuffs */}

            <div
              style={
                mobile
                  ? { display: "flex", flexDirection: "column", marginTop: 16 }
                  : { display: "flex", flexDirection: "column" }
              }
            >
              <p style={{ fontWeight: "bold", marginBottom: 8 }}>
                Result Summary
              </p>

              <p>Terms Total: {studentTermTotal}</p>
              <p>Term's Average: {termAverage}%</p>
              <p>No of Subjects Passed: {noOfSubjectsPassed}</p>
              <p>No of Subjects Failed: {noOfSubjectsFailed}</p>
              <p>Attendance Score: {result.attendance_score}</p>
              <p>Remark: {result.overall_remark}</p>

              <p style={{ fontWeight: "bold", marginTop: 32 }}>
                Next Term's Information
              </p>

              <p>Resumption Date: {session.next_term_resumption_date}</p>
              <p>Next Term Fee: {result.next_term_fee}</p>
              <p>Other Fees: {result.other_fees}</p>
              <p>Total Fees: {result.total_fees}</p>

              <p style={{ fontWeight: "bold", marginTop: 32 }}>
                Teachers Information
              </p>

              <p>Class Teacher: {result.is_validated_by}</p>
              <p>Head Teacher: {result.head_teachers_name}</p>
            </div>

            <div
              style={
                mobile
                  ? { display: "flex", flexDirection: "column", marginTop: 16 }
                  : { display: "flex", flexDirection: "column" }
              }
            >
              <p
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#E05615",
                  marginTop: 16,
                  marginBottom: 16,
                }}
              >
                C. PSYCHO-MOTIVE TRAIT
              </p>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 16,
                }}
              >
                <p style={{ marginRight: 60, fontWeight: 500 }}>Regularity</p>
                <p>{psycho["regularity"]}</p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 8,
                }}
              >
                <p style={{ marginRight: 60, fontWeight: 500 }}>
                  Personal cleanliness
                </p>
                <p>{psycho["personal_cleanliness"]}</p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 8,
                }}
              >
                <p style={{ marginRight: 60, fontWeight: 500 }}>Punctuality</p>
                <p>{psycho["punctuality"]}</p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 8,
                }}
              >
                <p style={{ marginRight: 60, fontWeight: 500 }}>
                  Completion of work
                </p>
                <p>{psycho["completion_of_work"]}</p>
              </div>

              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 8,
                }}
              >
                <p style={{ marginRight: 60, fontWeight: 500 }}>
                  Disturbing others
                </p>
                <p>{psycho["disturbing_others"]}</p>
              </div> */}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 8,
                }}
              >
                <p style={{ marginRight: 60, fontWeight: 500 }}>
                  Following instructions
                </p>
                <p>{psycho["following_instructions"]}</p>
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "#E05615",
                    marginTop: 16,
                    marginBottom: 16,
                  }}
                >
                  B. AFFECTIVE TRAIT
                </p>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 16,
                  }}
                >
                  <p style={{ marginRight: 60, fontWeight: 500 }}>
                    General Conduct
                  </p>
                  <p>{affective["general_conduct"]}</p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 8,
                  }}
                >
                  <p style={{ marginRight: 60, fontWeight: 500 }}>
                    Assignments
                  </p>
                  <p>{affective["assignments"]}</p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 8,
                  }}
                >
                  <p style={{ marginRight: 60, fontWeight: 500 }}>
                    Class Participation
                  </p>
                  <p>{affective["class_participation"]}</p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 8,
                  }}
                >
                  <p style={{ marginRight: 60, fontWeight: 500 }}>
                    Finishes work on time
                  </p>
                  <p>{affective["finishes_work_on_time"]}</p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 8,
                  }}
                >
                  <p style={{ marginRight: 60, fontWeight: 500 }}>
                    Takes care of materials
                  </p>
                  <p>{affective["takes_care_of_materials"]}</p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 8,
                  }}
                >
                  <p style={{ marginRight: 60, fontWeight: 500 }}>
                    Working independently
                  </p>
                  <p>{affective["working_independently"]}</p>
                </div>
              </div>
            </div>
          </div>

          <div style={{ marginTop: 32 }}>
            <p>Class Teacher's Remark: {result.teachers_remark}</p>
            <p>Head Teacher's Remark: {result.head_teachers_remark}</p>
          </div>
        </form>
        <Button2
          click={(e) => {
            e.preventDefault();
          }}
          style={{ marginBottom: 10, marginRight: 20 }}
          name={"Download PDF Result"}
        />
      </div>
    </div>
  );
}
