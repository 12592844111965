import React, {useState, useEffect, useContext} from "react";
import "./viewcenter.css";
import { NavLink, Outlet } from "react-router-dom";
import TeacherIcon from "../../images/shola.png";
import plus from "../../images/plus.jpg";
import { BackButton } from "../studentPages/PersonalData";
import { useNavigate, useLocation } from "react-router-dom";
import { adminContext } from "../context/adminContext";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Button from "../../components/Button/Button";
import { Button2 } from "../../components/Button/Button";

export default function Viewcenter() {

  const navigate = useNavigate();
  const state = useLocation().state;
  const values = useContext(adminContext);
  const { admintoken } = values;

  const [centers, setCenters] = useState([]);
  const [loading, setLoading] = useState(false);

  const [openDialog, handleDisplay] = useState(false);

  const handleClose = () => {
     handleDisplay(false);
  };

  const openDialogBox = () => {
     handleDisplay(true);
  };
  const dialogStyle = {
     padding: "20px",
  };

  const viewCenter = async () => {
    setLoading(true);
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_center/view_centers",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: state.tempAdminToken,
        }),
      }
    );
    const data = await res.json();
    console.log(data);
    if(data.msg === 'success') {
      setLoading(false);
      setCenters(data.centers);
    }
  };

  useEffect(() => {
    viewCenter();
  }, []);

  return (
    <>
      <div className="sidebar-container">
      <BackButton click={() => navigate(-1)} />
      
        <div className="main-content">
          <Outlet />
        </div>
        <div
          className="set-questions"
          style={{
            backgroundColor: "rgb(255, 248, 248)",
            height: "100%",
            alignItems: "flex-start",
          }}
        >
        
          <div className="git-wrap">
            <div className="split-through">
              <p className="title">
                Our Lady of Hope Montessori Schools Centres
              </p>
              <div className="flexplusbtn">
                <button className="centerbutton">CENTERS</button>
                <img onClick={(e)=>{
                  e.preventDefault();
                  openDialogBox();
                }} src={plus} className="pluimg" />
              </div>
            </div>
            <div className="database-table git-table">
              <table>
                <thead>
                  <tr>
                    <th style={{ visibility: "hidden" }}>Name</th>
                    <th>
                      TOPAZ
                      <br />
                      {centers[0]?.no_of_students}
                    </th>
                    <th>
                      EMERALD
                      <br />
                      {centers[1]?.no_of_students}
                    </th>
                    <th style={{ visibility: "hidden" }}>Date </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      A. Babies and Toddlers <br />
                      . Creche <br />
                      . Toddlers <br />
                      <br />
                      B. Infant School
                      <br />
                      . Infant Community 1 <br />
                      . Infant Community 2<br />
                      . Nursery 1 <br />
                      . Nursery 2 <br />
                      <br />
                      C. Grade School
                      <br />
                      . Grade 1 <br />
                      . Grade 2<br />
                      . Grade 3<br />
                      . Grade 4 <br />
                      . Grade 5
                    </td>
                    <td>
                      <br />
                      <CountComp
                        count={centers[0]?.babies_and_toddlers.no_of_creche}
                        onClick={(e)=>{
                          navigate("/admin/settings-center", {state: {
                            center: 'Topaz Centre',
                            className: `Babies and Toddlers - Creche (${centers[0]?.babies_and_toddlers.no_of_creche})`,
                            class_key: 'Creche',
                            overall_class_key: 'Baby\'s and Toddlers',
                            count: centers[0]?.babies_and_toddlers.no_of_creche,
                            tempAdminToken: state.tempAdminToken
                          }})
                        }}
                      />
                      <br />
                      <CountComp
                        count={centers[0]?.babies_and_toddlers.no_of_toddlers}
                        onClick={(e)=>{
                          navigate("/admin/settings-center", {state: {
                            center: 'Topaz Centre',
                            className: `Babies and Toddlers - Toddlers (${centers[0]?.babies_and_toddlers.no_of_toddlers})`,
                            class_key: 'Toddlers',
                            overall_class_key: 'Baby\'s and Toddlers',
                            count: centers[0]?.babies_and_toddlers.no_of_toddlers,
                            tempAdminToken: state.tempAdminToken
                          }})
                        }}
                      />
                      <br />
                      <br />
                      <br />
                      <CountComp
                        count={centers[0]?.infant_school.infant_community_one}
                        onClick={(e)=>{
                          navigate("/admin/settings-center", {state: {
                            center: 'Topaz Centre',
                            className: `Infant School - Infant Community 1 (${centers[0]?.infant_school.infant_community_one})`,
                            class_key: 'Infant Community 1',
                            overall_class_key: 'Infant School',
                            count: centers[0]?.infant_school.infant_community_one,
                            tempAdminToken: state.tempAdminToken
                          }})
                        }}
                      />
                      <br />
                      <CountComp
                        count={centers[0]?.infant_school.infant_community_two}
                        onClick={(e)=>{
                          navigate("/admin/settings-center", {state: {
                            center: 'Topaz Centre',
                            className: `Infant School - Infant Community 2 (${centers[0]?.infant_school.infant_community_two})`,
                            class_key: 'Infant Community 2',
                            overall_class_key: 'Infant School',
                            count: centers[0]?.infant_school.infant_community_two,
                            tempAdminToken: state.tempAdminToken
                          }})
                        }}
                      />
                      <br />
                      <CountComp
                        count={centers[0]?.infant_school.nursery_one}
                        onClick={(e)=>{
                          navigate("/admin/settings-center", {state: {
                            center: 'Topaz Centre',
                            className: `Infant School - Nursery 1 (${centers[0]?.infant_school.nursery_one})`,
                            class_key: 'Nursery 1',
                            overall_class_key: 'Infant School',
                            count: centers[0]?.infant_school.nursery_one,
                            tempAdminToken: state.tempAdminToken
                          }})
                        }}
                      />
                      <br />
                      <CountComp
                        count={centers[0]?.infant_school.nursery_two}
                        onClick={(e)=>{
                          navigate("/admin/settings-center", {state: {
                            center: 'Topaz Centre',
                            className: `Infant School - Nursery 2 (${centers[0]?.infant_school.nursery_two})`,
                            class_key: 'Nursery 2',
                            overall_class_key: 'Infant School',
                            count: centers[0]?.infant_school.nursery_two,
                            tempAdminToken: state.tempAdminToken
                          }})
                        }}
                      />
                      <br />
                      <br />
                      <br />
                      <CountComp
                        count={centers[0]?.grade_school.grade_one}
                        onClick={(e)=>{
                          navigate("/admin/settings-center", {state: {
                            center: 'Topaz Centre',
                            className: `Grade School - Grade 1 (${centers[0]?.grade_school.grade_one})`,
                            class_key: 'Grade 1',
                            overall_class_key: 'Grade School',
                            count: centers[0]?.grade_school.grade_one,
                            tempAdminToken: state.tempAdminToken
                          }})
                        }}
                      /> <br />
                      <CountComp
                        count={centers[0]?.grade_school.grade_two}
                        onClick={(e)=>{
                          navigate("/admin/settings-center", {state: {
                            center: 'Topaz Centre',
                            className: `Grade School - Grade 2 (${centers[0]?.grade_school.grade_two})`,
                            class_key: 'Grade 2',
                            overall_class_key: 'Grade School',
                            count: centers[0]?.grade_school.grade_two,
                            tempAdminToken: state.tempAdminToken
                          }})
                        }}
                      /> <br />
                      <CountComp
                        count={centers[0]?.grade_school.grade_three}
                        onClick={(e)=>{
                          navigate("/admin/settings-center", {state: {
                            center: 'Topaz Centre',
                            className: `Grade School - Grade 3 (${centers[0]?.grade_school.grade_three})`,
                            class_key: 'Grade 3',
                            overall_class_key: 'Grade School',
                            count: centers[0]?.grade_school.grade_three,
                            tempAdminToken: state.tempAdminToken
                          }})
                        }}
                      /> <br />

                      <CountComp
                        count={centers[0]?.grade_school.grade_four}
                        onClick={(e)=>{
                          navigate("/admin/settings-center", {state: {
                            center: 'Topaz Centre',
                            className: `Grade School - Grade 4 (${centers[0]?.grade_school.grade_four})`,
                            class_key: 'Grade 4',
                            overall_class_key: 'Grade School',
                            count: centers[0]?.grade_school.grade_four,
                            tempAdminToken: state.tempAdminToken
                          }})
                        }}
                      /> <br />
                      <CountComp
                        count={centers[0]?.grade_school.grade_five}
                        onClick={(e)=>{
                          navigate("/admin/settings-center", {state: {
                            center: 'Topaz Centre',
                            className: `Grade School - Grade 5 (${centers[0]?.grade_school.grade_five})`,
                            class_key: 'Grade 5',
                            overall_class_key: 'Grade School',
                            count: centers[0]?.grade_school.grade_five,
                            tempAdminToken: state.tempAdminToken
                          }})
                        }}
                      />
                    </td>
                    <td>
                    <br />
                    <CountComp
                        count={centers[1]?.babies_and_toddlers.no_of_creche}
                        onClick={(e)=>{
                          navigate("/admin/settings-center", {state: {
                            center: 'Emerald Centre',
                            className: `Babies and Toddlers - Creche (${centers[1]?.babies_and_toddlers.no_of_creche})`,
                            class_key: 'Creche',
                            overall_class_key: 'Baby\'s and Toddlers',
                            count: centers[0]?.babies_and_toddlers.no_of_creche,
                            tempAdminToken: state.tempAdminToken
                          }})
                        }}
                      />
                      <br />
                      <CountComp
                        count={centers[1]?.babies_and_toddlers.no_of_toddlers}
                        onClick={(e)=>{
                          navigate("/admin/settings-center", {state: {
                            center: 'Emerald Centre',
                            className: `Babies and Toddlers - Toddlers (${centers[1]?.babies_and_toddlers.no_of_toddlers})`,
                            class_key: 'Toddlers',
                            overall_class_key: 'Baby\'s and Toddlers',
                            count: centers[0]?.babies_and_toddlers.no_of_toddlers,
                            tempAdminToken: state.tempAdminToken
                          }})
                        }}
                      />
                      <br />
                      <br />
                      <br />
                      <CountComp
                        count={centers[1]?.infant_school.infant_community_one}
                        onClick={(e)=>{
                          navigate("/admin/settings-center", {state: {
                            center: 'Emerald Centre',
                            className: `Infant School - Infant Community 1 (${centers[1]?.infant_school.infant_community_one})`,
                            class_key: 'Infant Community 1',
                            overall_class_key: 'Infant School',
                            count: centers[0]?.infant_school.infant_community_one,
                            tempAdminToken: state.tempAdminToken
                          }})
                        }}
                      />
                      <br />
                      <CountComp
                        count={centers[1]?.infant_school.infant_community_two}
                        onClick={(e)=>{
                          navigate("/admin/settings-center", {state: {
                            center: 'Emerald Centre',
                            className: `Infant School - Infant Community 2 (${centers[1]?.infant_school.infant_community_two})`,
                            class_key: 'Infant Community 2',
                            overall_class_key: 'Infant School',
                            count: centers[0]?.infant_school.infant_community_two,
                            tempAdminToken: state.tempAdminToken
                          }})
                        }}
                      />
                      <br />
                      <CountComp
                        count={centers[1]?.infant_school.nursery_one}
                        onClick={(e)=>{
                          navigate("/admin/settings-center", {state: {
                            center: 'Emerald Centre',
                            className: `Infant School - Nursery 1 (${centers[1]?.infant_school.nursery_one})`,
                            class_key: 'Nursery 1',
                            overall_class_key: 'Infant School',
                            count: centers[0]?.infant_school.nursery_one,
                            tempAdminToken: state.tempAdminToken
                          }})
                        }}
                      />
                      <br />
                      <CountComp
                        count={centers[1]?.infant_school.nursery_two}
                        onClick={(e)=>{
                          navigate("/admin/settings-center", {state: {
                            center: 'Emerald Centre',
                            className: `Infant School - Nursery 2 (${centers[1]?.infant_school.nursery_two})`,
                            class_key: 'Nursery 2',
                            overall_class_key: 'Infant School',
                            count: centers[0]?.infant_school.nursery_two,
                            tempAdminToken: state.tempAdminToken
                          }})
                        }}
                      />
                      <br />
                      <br />
                      <br />
                      <CountComp
                        count={centers[1]?.grade_school.grade_one}
                        onClick={(e)=>{
                          navigate("/admin/settings-center", {state: {
                            center: 'Emerald Centre',
                            className: `Grade School - Grade 1 (${centers[1]?.grade_school.grade_one})`,
                            class_key: 'Grade 1',
                            overall_class_key: 'Grade School',
                            count: centers[0]?.grade_school.grade_one,
                            tempAdminToken: state.tempAdminToken
                          }})
                        }}
                      /> <br />
                      <CountComp
                        count={centers[1]?.grade_school.grade_two}
                        onClick={(e)=>{
                          navigate("/admin/settings-center", {state: {
                            center: 'Emerald Centre',
                            className: `Grade School - Grade 2 (${centers[1]?.grade_school.grade_two})`,
                            class_key: 'Grade 2',
                            overall_class_key: 'Grade School',
                            count: centers[0]?.grade_school.grade_two,
                            tempAdminToken: state.tempAdminToken
                          }})
                        }}
                      /> <br />

                      <CountComp
                        count={centers[1]?.grade_school.grade_three}
                        onClick={(e)=>{
                          navigate("/admin/settings-center", {state: {
                            center: 'Emerald Centre',
                            className: `Grade School - Grade 3 (${centers[1]?.grade_school.grade_three})`,
                            class_key: 'Grade 3',
                            overall_class_key: 'Grade School',
                            count: centers[0]?.grade_school.grade_three,
                            tempAdminToken: state.tempAdminToken
                          }})
                        }}
                      /> <br />
                      <CountComp
                        count={centers[1]?.grade_school.grade_four}
                        onClick={(e)=>{
                          navigate("/admin/settings-center", {state: {
                            center: 'Emerald Centre',
                            className: `Grade School - Grade 4 (${centers[1]?.grade_school.grade_four})`,
                            class_key: 'Grade 4',
                            overall_class_key: 'Grade School',
                            count: centers[0]?.grade_school.grade_four,
                            tempAdminToken: state.tempAdminToken
                          }})
                        }}
                      /> <br />
                      <CountComp
                        count={centers[1]?.grade_school.grade_five}
                        onClick={(e)=>{
                          navigate("/admin/settings-center", {state: {
                            center: 'Emerald Centre',
                            className: `Grade School - Grade 5 (${centers[1]?.grade_school.grade_five})`,
                            class_key: 'Grade 5',
                            overall_class_key: 'Grade School',
                            count: centers[0]?.grade_school.grade_five,
                            tempAdminToken: state.tempAdminToken
                          }})
                        }}
                      />
                    </td>

                    <td style={{ visibility: "hidden" }}>November 19, 2013</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* <div className="pagination-git"> */}
            {/* <div>Showing 1 to 30 of 87 Messages</div> */}
            {/* <div className="view-more-git"> */}
            {/* <div className="shade-direction">
                  <i className="fas fa-angle-left"></i>
                </div>
                View more
                <div className="shade-direction">
                  <i className="fas fa-angle-right"></i>
                </div> */}
          </div>
        </div>
        <Dialog  onClose = {handleClose} open = {openDialog}>
            <DialogTitle>Create New Centre</DialogTitle>
            <p style = {dialogStyle}>
            Centre Name
            </p>

            <input
                    type="text"
                    disabled={false}
                    // onChange={handleChange}
                  />
           
           <div style={{marginTop: 16, marginBottom: 16, display: "flex", flexDirection: "row", justifyContent: 'space-evenly'}}>
                <Button click={(e)=>{
                  e.preventDefault();

                }} name="Save" />
            </div>
    
            
         </Dialog>
      </div>
      {/* </div> */}
      {/* </div> */}
    </>
  );
}

const CountComp = ({count, onClick, style}) => {
  return <span onClick={onClick} style={{ cursor: "pointer" }}>{count}</span>;
}
