import react, { createContext, useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  onSnapshot,
  getDoc,
  doc,
  getDocs,
  QuerySnapshot,
  setDoc,
} from "firebase/firestore";

import { db, storage } from "../../firebase_setup/firebase";
import audioFile from "../../images/new_noti.wav";

export const adminContext = createContext();

export const UseTokenProviderAdmin = (props) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const audio1 = new Audio(audioFile);

  const [student, setStudent] = useState(true);
  const [teacher, setTeacher] = useState(false);
  const [admin, setAdmin] = useState(false);

  const admintoken = localStorage.getItem("admin_rubbish");
  const adminId = localStorage.getItem("adminId");
  const adminStaffId = localStorage.getItem("adminStaff");
  const teacherData = localStorage.getItem("teacherData");
  const teachertoken = localStorage.getItem("teacher_rubbish");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  //for chat
  const [new_msg, setNewMsg] = useState(false);
  const [listen, setListen] = useState(true);
  const [adminRole, setAdminRole] = useState([]);

  const [fire, setFire] = useState(false);
  const [count1, setCount1] = useState(0);
  const [firstFire1, setFirstFire1] = useState(true);

  //4tKnight
  const Listener = () => {
    let countM1 = {};
    const check1 = onSnapshot(
      doc(db, "admin_notifiers", "hf_messages"),
      async (doc) => {
        countM1 = doc.data();
        if (firstFire1 == true) {
          setCount1(countM1.messages_count);
          setFirstFire1(false);
        } else {
          if (countM1.messages_count !== count1) {
            setCount1(countM1.messages_count);
            //console.log("played");
            audio1.play();
            setNewMsg(true);
          }
        }
      }
    );
  };
  //console.log('hehe');
  Listener();

  // want to trigger this anytime user logsout
  // const handleLogout = async => {

  //   console.log("LOGGED OUT");
  //   setSuccess("");
  // }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (admin)
      try {
        const res = await fetch(
          "https://server-olhms.onrender.com/admin_auth/login",
          {
            method: "POST",
            body: JSON.stringify({
              email: formData.email,
              password: formData.password,
            }),
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json, text/plain, */*",
            },
          }
        );
        const data = await res.json();

        if (data.msg == "Success") {
          localStorage.setItem("admin_rubbish", JSON.stringify(data?.token));
          localStorage.setItem("adminId", JSON.stringify(data?.admin._id));
          localStorage.setItem("img", data?.admin.img_url);
          localStorage.setItem(
            "name",
            `${data?.admin.firstname} ${data?.admin.lastname}`
          );
          localStorage.setItem(
            "adminStaff",
            JSON.stringify(data?.admin.staff_id)
          );

          let role = "";
          let firstTime = true;
          const adminRoles = data?.admin.roles;
          adminRoles.map((rol) => {
            if (rol == "master") {
              if (firstTime) {
                role = "Master Adminstrator";
              } else {
                role = role + ", " + "Master Adminstrator";
              }
            } else {
              if (firstTime) {
                role = rol;
              } else {
                role = role + ", " + rol;
              }
            }
            firstTime = false;
          });
          localStorage.setItem("role", role);

          //4tKnight added this
          setAdminRole(role);

          setSuccess("admin");
          console.log(data);
        } else if (data.msg == "incorrect password") {
          alert("Password is incorrect");
        } else if (data.msg == "account not found") {
          alert("Sorry, account not found");
        } else {
          alert("An error occured. Please try again later");
        }
        setLoading(false);
      } catch (error) {
        alert("oOps! An error occured. Please try again later");
        console.log(error);
        setLoading(false);
      }
    else if (teacher) {
      // console.log("lll");
      // console.log(formData.email, formData.password);
      try {
        const res = await fetch(
          "https://server-olhms.onrender.com/teacher_auth/login",
          {
            method: "POST",
            body: JSON.stringify({
              email: formData.email,
              password: formData.password,
            }),
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json, text/plain, */*",
            },
          }
        );
        const data = await res.json();
        console.log(data);

        if (data.msg == "Success") {
          localStorage.setItem("teacher_rubbish", JSON.stringify(data?.token));
          localStorage.setItem("teacherData", JSON.stringify(data?.staff));
          localStorage.setItem("img", data?.staff.img_url);
          localStorage.setItem("name", data?.staff.fullname);
          setSuccess("teacher");
        } else if (data.msg == "incorrect password") {
          alert("Password is incorrect");
        } else if (data.msg == "account not found") {
          alert("Sorry, account not found");
        } else {
          alert("An error occured. Please try again later");
        }
        setLoading(false);
      } catch (error) {
        alert("oOps! An error occured. Please try again later");

        console.log(error);
        setLoading(false);
      }
    } else if (student) {
      // console.log('hello student');
      console.log("student", formData.id, formData.password);

      try {
        const res = await fetch(
          "https://server-olhms.onrender.com/student_auth/login_web",
          {
            method: "POST",
            body: JSON.stringify({
              student_id: formData.id,
              password: formData.password,
            }),
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json, text/plain, */*",
            },
          }
        );
        const data = await res.json();
        console.log(data);

        if (data.msg == "Success") {
          localStorage.setItem("student_rubbish", JSON.stringify(data?.token));
          localStorage.setItem("studentData", JSON.stringify(data?.student));
          localStorage.setItem("img", data?.student.img_url);
          localStorage.setItem("name", data?.student.fullname);
          setSuccess("student");
        } else if (
          data.status == "error" &&
          data.msg == "some error occurred"
        ) {
          alert("An error occured. Please try again later");
        } else {
          alert(`${data.msg}`);
        }
        setLoading(false);
      } catch (error) {
        alert("oOps! An error occured. Please try again later");

        console.log(error);
        setLoading(false);
      }
    }
  };
  const values = {
    handleSubmit,
    formData,
    setFormData,
    success,
    admintoken,
    adminId,
    teacher,
    setTeacher,
    setAdmin,
    admin,
    setStudent,
    student,
    adminStaffId,
    loading,
    setLoading,
    teacherData,
    teachertoken,
    new_msg,
    setNewMsg,
    listen,
    setListen,
    adminRole,
    setAdminRole,
  };
  // 4tKnight added: new_msg,setNewMsg,listen,setListen,adminRole,setAdminRole
  return (
    <div>
      <adminContext.Provider value={values}>
        {props.children}
      </adminContext.Provider>
    </div>
  );
};
