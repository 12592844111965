import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BackButton } from "../studentPages/PersonalData";
import { Toggle } from "../teacherPages/Resources";
import Passport from "../../images/news1.png";
import { Button2 } from "../../components/Button/Button";
import Edit from "../../images/edit.png";
import Add from "../../images/add.png";
import { adminContext } from "../context/adminContext";
import { capitalizeFirstLetter } from "./StaffDatabase";
import User from "../../images/user.png";
import { formatDate } from "./AddBlog";

export default function ViewAdmin() {

  const location = useLocation();
  const id = location.state.id;
  const navigate = useNavigate();

  const aAdmin = location.state.admin;
  const [admin, setAdmin] = useState({
    gender: ''
  });

  const [toggle, setToggle] = useState(true);
  const [view, setView] = useState(true);
  const type = location.state.num;
  const [loading, setLoading] = useState(true);
  const values = useContext(adminContext);
  const { admintoken } = values;
  //   console.log(not);
  const [formData, setFormData] = useState({
    firstname: aAdmin.firstname,
    middlename: aAdmin.middlename == undefined ? '' : aAdmin.middlename,
    lastname: aAdmin.lastname,
    country: aAdmin.country,
    address: aAdmin.address,
    phone_no1: aAdmin.phone_no1,
    phone_no2: aAdmin.phone_no2,
    email: aAdmin.email,
    religion: aAdmin.religion,
    gender: aAdmin.gender,
    center: aAdmin.center,
    roles: aAdmin.role == undefined ? aAdmin.roles.toString() : aAdmin.role,
    employment_year: aAdmin.employment_year,
  });

  console.log(admin);
  console.log(formData);

  const [isSelected, setIsSelected] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedSrc, setSelectedSrc] = useState("");
  const handleChange = (e) => {
    const target = e.target;
    // const value = target.type === "select" ? target.selected : target.value;
    // const name = target.name;
    const { name, value } = target;
    setFormData({ ...formData, [name]: value });
    console.log(formData);
  };
  const onFileChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState == 2) {
        setSelectedSrc(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
    setIsSelected(true);
  };

  console.log('right page');

  const viewAdmin = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/master_admin/view_admin",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(admintoken),
          admin_id: id,
        }),
      }
    );

    const data = await res.json();
    setLoading(false);
    setAdmin(data.admin);
    console.log(data);
    // console.log(id);
  };

  useEffect(() => {
    viewAdmin();
  }, []);


  const editAdmin = async () => {

    let roles;
    if(formData.roles.includes(',')){
      roles = formData.roles.split(',');
    }
   
    const res = await fetch(
      "https://server-olhms.onrender.com/master_admin/edit_admin",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(admintoken),
          admin_id: id,
          firstname: formData.firstname,
          lastname: formData.lastname,
          country: formData.country,
          address: formData.address,
          phone_no1: formData.phone_no1,
          phone_no2: formData.phone_no2,
          email: formData.email,
          religion: formData.religion,
          gender: formData.gender,
          center: formData.center,
          roles: roles,
          employment_year: formData.employment_year
        })
      }
    );
    const data = await res.json();
    setLoading(false);
    setAdmin(data.admin);
    setFormData(data.admin);
    console.log(data.admin);
    navigate(-1)
    // console.log(data);
    // console.log(id);
  };


  return (
    <div
      className="set-questions"
      style={{
        backgroundColor: "rgb(255, 248, 248)",
        height: "100%",
        alignItems: "flex-start",
      }}
    >
      <div className="set-container">
        <BackButton
          click={() => (view || type === 2 ? navigate(-1) : setView(true))}
        />
        {type === 0 ? (
          <Toggle
            click1={() => setToggle(true)}
            click2={() => setToggle(false)}
            toggle={toggle}
            text1="Personal Data"
            text2="Result"
          />
        ) : type === 1 ? (
          <p
            style={{
              fontWeight: "600",
              fontSize: "20px",
              alignSelf: "flex-start",
              marginTop: "40px",
            }}
          >
            Personal Data
          </p>
        ) : null}
        {type === 0 ? (
          view ? (
            <div style={{ alignSelf: "flex-end" }}>
              <Button2 name="View Admission Form" />
            </div>
          ) : null
        ) : null}
        {type === 0 ? (
          <div className="show-details" style={{ marginTop: "30px" }}>
            <div>First Name:</div>
            {view ? (
              <div className="light-property">John</div>
            ) : (
              <input
                className="properties-input"
                name="firstname"
                onChange={handleChange}
                value={formData}
              />
            )}
            <div>Middle Name:</div>
            {view ? (
              <div className="light-property">John</div>
            ) : (
              <input
                className="properties-input"
                name="firstname"
                onChange={handleChange}
                value={formData.firstname}
              />
            )}
            <div>Last Name:</div>
            {view ? (
              <div className="light-property">John</div>
            ) : (
              <input
                className="properties-input"
                name="firstname"
                onChange={handleChange}
                value={formData.firstname}
              />
            )}
            <div>Permanent Address:</div>
            {view ? (
              <div className="light-property">John</div>
            ) : (
              <input
                className="properties-input"
                name="firstname"
                onChange={handleChange}
                value={formData.firstname}
              />
            )}
            <div>Country:</div>
            {view ? (
              <div className="light-property">John</div>
            ) : (
              <input
                className="properties-input"
                name="firstname"
                onChange={handleChange}
                value={formData.firstname}
              />
            )}
            <div>Phone Number 1:</div>
            {view ? (
              <div className="light-property">John</div>
            ) : (
              <input
                className="properties-input"
                name="firstname"
                onChange={handleChange}
                value={formData.firstname}
              />
            )}
            <div>Phone Number 2:</div>
            {view ? (
              <div className="light-property">John</div>
            ) : (
              <input
                className="properties-input"
                name="firstname"
                onChange={handleChange}
                value={formData.firstname}
              />
            )}
            <div> Email:</div>
            {view ? (
              <div className="light-property">John</div>
            ) : (
              <input
                className="properties-input"
                name="firstname"
                onChange={handleChange}
                value={formData.firstname}
              />
            )}
            <div>Religion:</div>
            {view ? (
              <div className="light-property">John</div>
            ) : (
              <input
                className="properties-input"
                name="firstname"
                onChange={handleChange}
                value={formData.firstname}
              />
            )}
            <div>Gender:</div>
            {view ? (
              <div className="light-property">John</div>
            ) : (
              <div className="properties-input">
                <select
                  value={formData.gender}
                  name="gender"
                  onChange={handleChange}
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
            )}

            <div>Passport Photo:</div>
            {view ? (
              <div className="data-img">
                <img src={Passport} alt="" style={{ objectFit: "cover"}}/>
              </div>
            ) : (
              <div className="teacher-image">
                {isSelected ? (
                  <div className="data-img">
                    <img src={selectedSrc} alt="" style={{ objectFit: "cover"}}/>
                  </div>
                ) : null}
                <div className="add-image">
                  <label>
                    <img src={Add} alt="" />
                    <input
                      required
                      onChange={onFileChange}
                      type="file"
                      accept=".png, .jpg, .jpeg, .gif"
                      name="selectedFile"
                    />
                  </label>
                </div>
              </div>
            )}
          </div>
        ) : type === 1 ? (
          <div className="show-details" style={{ marginTop: "30px" }}>
            <div>First Name:</div>
            <div className="light-property">{admin.firstname}</div>
            {/* <div>Middle Name:</div>
            <div className="light-property">{admin.middlename }</div> */}
            <div>Last Name:</div>
            <div className="light-property">{admin.lastname}</div>
            <div>Permanent Address:</div>
            <div className="light-property">
              {admin.address}
            </div>
            <div>Country:</div>
            <div className="light-property">{admin.country}</div>
            <div>Phone Number 1:</div>
            <div className="light-property">{admin.phone_no1}</div>
            <div>Phone Number 2:</div>
            <div className="light-property">{admin.phone_no2}</div>
            <div>Email:</div>
            <div className="light-property">{admin.email}</div>
            <div>Religion:</div>
            <div className="light-property">{admin.religion}</div>
            <div>Gender:</div>
            <div className="light-property">
              {capitalizeFirstLetter(formData.gender)}
            </div>
            <div>Staff ID:</div>
            <div className="light-property">{admin.staff_id}</div>
            <div>Centre:</div>
            <div className="light-property">
              {capitalizeFirstLetter(formData.center)}
            </div>
            <div>Admin Role:</div>
            <div className="light-property">
              {capitalizeFirstLetter(formData.roles.toString())}
            </div>
            <div>Employment Year:</div>
            <div className="light-property">{admin.employment_year}</div>
            <div>Photo:</div>
            <div className="data-img">
              <img src={admin.img_url} alt="" />
            </div>
          </div>
        ) : type === 2 ? (
          <div className="show-details" style={{ marginTop: "30px" }}>
            <div>First Name:</div>
            <input
              className="properties-input"
              name="firstname"
              onChange={handleChange}
              value={formData.firstname}
            />
            <div>Middle Name:</div>
            <input
              className="properties-input"
              name="middlename"
              onChange={handleChange}
              value={formData.middlename}
            />
            <div>Last Name:</div>
            <input
              className="properties-input"
              name="lastname"
              onChange={handleChange}
              value={formData.lastname}
            />
            <div>Permanent Address:</div>
            <input
              className="properties-input"
              name="address"
              onChange={handleChange}
              value={formData.address}
            />
            <div>Country:</div>
            <input
              className="properties-input"
              name="country"
              onChange={handleChange}
              value={formData.country}
            />
            <div>Phone Number 1:</div>
            <input
              className="properties-input"
              name="phone_no1"
              onChange={handleChange}
              value={formData.phone_no1}
            />
            <div>Phone Number 2:</div>
            <input
              className="properties-input"
              name="phone_no2"
              onChange={handleChange}
              value={formData.phone_no2}
            />
            <div>Email:</div>
            <input
              className="properties-input"
              name="email"
              onChange={handleChange}
              value={formData.email}
            />
            <div>Religion:</div>
            <input
              className="properties-input"
              name="religion"
              onChange={handleChange}
              value={formData.religion}
            />
            <div>Gender:</div>
            <div className="properties-input">
              <select
                value={formData.gender}
                name="gender"
                onChange={handleChange}
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
            <div>Staff ID:</div>
            <div className="light-property">{aAdmin.staff_id}</div>
            <div>Centre:</div>
            <div className="properties-input">
              <select
                value={formData.center}
                name="centre"
                onChange={handleChange}
              >
                <option value="Emerald">Emerald</option>
                <option value="Topaz">Topaz</option>
              </select>
            </div>
            <div>Role:</div>
            <input
              className="properties-input"
              name="roles"
              onChange={handleChange}
              value={formData.roles.toString()}
            />
            <div>Employment Year:</div>
            <div className="light-property">{formData.employment_year}</div>
            <div>Photo:</div>
            <div className="teacher-image">
              {isSelected ? (
                <div className="data-img">
                  <img src={selectedSrc} alt="" />
                </div>
              ) : null}
              <div className="add-image">
                <label>
                  <img src={aAdmin.img_url} alt="" />
                  <input
                    required
                    onChange={onFileChange}
                    type="file"
                    accept=".png, .jpg, .jpeg, .gif"
                    name="selectedFile"
                  />
                </label>
              </div>
            </div>
          </div>
        ) : null}
        {type === 0 ? (
          view ? (
            <div className="create-teacher" onClick={() => setView(false)}>
              <img src={Edit} />
            </div>
          ) : (
            <Button2 click={e => {
                e.preventDefault();
                
              }} name="Update & Save Personal Data" />
          )
        ) : type === 2 ? (
          <Button2 click={e => {
                e.preventDefault();
                editAdmin();
              }} name="Update & Save Personal Data" />
        ) : null}
      </div>
    </div>
  );
}
