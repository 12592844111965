import React from "react";
import "./aboutfour.css";
import Shola from "../../images/shola.png";
import Adaobi from "../../images/adaobi.png";
import Tracy from "../../images/tracie.png";
import Eunice from "../../images/eunice.png";

export default function AboutFour() {
  const team = [
    {
      image: Shola,
      header: "Dr. Mrs Sola Okunkpolor",
      text: "Executive Director",
    },
    {
      image: Adaobi,
      header: "Mrs Adaobi Akintomide",
      text: "Head of Grade School",
    },
    {
      image: Tracy, 
      header: "Miss Tracy Okogun",
      text: "School Admin",
    },
    {
      image: Eunice,
      header: "Miss Eunice Paul",
      text: "Head of Topaz School",
    },
  ];
  return (
    <div className="about-four">
      <div>
        <div className="about-text">
          <p className="about-first">
            <strong>Meet Our Top Leadership</strong>
          </p>
          <p className="about-second">Passionate and Compassionate leaders</p>
        </div>
        <div className="about-images-flex">
          {team.map((zoomObj, index) => (
            <AboutImages
              img={zoomObj.image}
              header={zoomObj.header}
              text={zoomObj.text}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export function AboutImages(props) {
  return (
    <div className="about-images">
      <div className="image-folder">
        <img src={props.img} alt="" />
      </div>
      <p className="about-second-p">
        <strong>{props.header}</strong>
      </p>
      <p className="image-people-p">{props.text}</p>
    </div>
  );
}
