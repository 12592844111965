import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "../../components/Button/Button";
import { BackButton } from "../studentPages/PersonalData";
import { adminContext } from "../context/adminContext";

export default function FrequentQuestions() {
  const location = useLocation();
  const id = location.state.id;
  let initialQ = location.state.questionname;
  let initialA = location.state.answername;
  const values = useContext(adminContext);
  const { admintoken } = values;

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState({
    question: initialQ,
    answer: initialA,
  });
  const handleQuestions = (e) => {
    const target = e.target;

    const { name, value } = target;
    setQuestions({ ...questions, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (id != "") {
      try {
        const res = await fetch(
          "https://server-olhms.onrender.com/admin_faqs/edit_faqs",
          {
            method: "POST",

            body: JSON.stringify({
              token: JSON.parse(admintoken),
              answer: questions.answer,
              question: questions.question,
              faqs_id: id,
            }),
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json, text/plain, */*",
            },
          }
        );
        const data = await res.json();
        console.log(data);

        if (res.status === 200) {
          navigate(-1);
        } else {
          //
        }
      } catch (error) {
        // setLoading(false);
        console.log(error);
      }
    } else if (id == "") {
      try {
        const res = await fetch(
          "https://server-olhms.onrender.com/admin_faqs/create_faqs",
          {
            method: "POST",

            body: JSON.stringify({
              token: JSON.parse(admintoken),
              answer: questions.answer,
              question: questions.question,
            }),
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json, text/plain, */*",
            },
          }
        );
        const data = await res.json();
        console.log(data);

        if (res.status === 200) {
          navigate(-1);
        } else {
          //
        }
      } catch (error) {
        // setLoading(false);
        console.log(error);
      }
    }
  };

  return (
    <div
      className="set-questions"
      style={{
        height: "100%",
        alignItems: "flex-start",
      }}
    >
      <div className="set-container">
        <BackButton click={() => navigate(-1)} />
        <p className="data-number">Frequently Asked Questions</p>
        <form className="questions-form" onSubmit={handleSubmit}>
          <div className="frequent-element">
            <div className="vlog-split">
              <div className="blog-element">
                <label>Question</label>
                <input
                  placeholder="Intro.."
                  value={questions.question}
                  name="question"
                  onChange={handleQuestions}
                />
              </div>

              <div className="blog-element">
                <label>Answer</label>
                <textarea
                  placeholder="Kids go home for the brief 3 days Easter celebration."
                  maxLength="500"
                  className="event-message"
                  style={{ height: "150px" }}
                  value={questions.answer}
                  name="answer"
                  onChange={handleQuestions}
                />
              </div>
            </div>
          </div>
          <div style={{ alignSelf: "center", marginTop: "50px" }}>
            <button className="post-vlog">Save</button>
          </div>
        </form>
      </div>
    </div>
  );
}
