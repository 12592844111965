import React from "react";
import "./admissiontwo.css";
import Zoom from "../../components/Zoom/Zoom";
import Admission1 from "../../images/admission1.png";
import Admission2 from "../../images/admission2.png";
import Admission3 from "../../images/admission3.png";
import { useNavigate } from "react-router-dom";

export default function AdmissionTwo() {
  const navigate = useNavigate();
  const zoomArray = [
    {
      image: Admission1,
      header: "Baby's and Toddlers",
      body: "Curriculum goals for the early childhood program involve creating an environment with many opportunities for growth in social emotional and cognitive development, emergent literacy, social studies, science and technology, mathematical thinking, physical development and health.",
      list1: "Creche",
      list2: "Toddlers",
      click: () => navigate("/baby", { state: { type: 0 } }),
      // list3: "Infant Community 2",
    },
    {
      image: Admission2,
      header: "Infant School",
      body: "Challenging. Inspiring. Engaging. These are a few words used to describe the unique Kindergarten curriculum. The academic experience of students is complemented with education in the arts, athletics and character - an empowering combination that results in kids who lead, volunteer, inspire and excel.",
      list1: "Infant Community 1",
      list2: "Infant Community 2",
      list3: "Nursery 1",
      list4: "Nursery 2",
      click: () => navigate("/infant", { state: { type: 1 } }),
    },
    {
      image: Admission3,
      header: "Grade School",
      body: "The success of our Lower School centers on creating the optimal foundation for each student's educational journey. Each teacher is committed to fostering a love of learning and to delivering engaging academic instruction.",
      list1: "Grade 1",
      list2: "Grade 2",
      list3: "Grade 3",
      list4: "Grade 4",
      list5: "Grade 5",
      click: () => navigate("/grade", { state: { type: 2 } }),
    },
  ];
  return (
    <div className="admission-two">
      <div className="zoom-container-admission">
        {zoomArray.map((zoomObj, index) => (
          <Zoom
            img={zoomObj.image}
            header={zoomObj.header}
            body={zoomObj.body}
            background={"#E05615"}
            classic={"zoom2"}
            list1={zoomObj.list1}
            list2={zoomObj.list2}
            list3={zoomObj.list3}
            list4={zoomObj.list4}
            list5={zoomObj.list5}
            list6={zoomObj.list6}
            text={"zoom-text2"}
            click={zoomObj.click}
            zoomBackground={"zoom-background2"}
          />
        ))}
      </div>
    </div>
  );
}
