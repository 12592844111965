import React from "react";
import { ClassContent, Novideo } from "./Resources";
import Topics from "../../images/notopics.png";
import Addition from "../../images/addition.png";
import { useNavigate } from "react-router-dom";
import "./worksheet.css";
import "./uploadresources.css";
import Numbers from "../../images/numbers.png";

export default function Worksheet() {
  const navigate = useNavigate();
  return (
    <div className="set-questions">
      <div className="set-container">
        <div className="session-flex">
          <div className="cat-session">
            <p>1st Term</p>
          </div>
          <div className="imported-sub">GRADE 1</div>
        </div>
        <div className="submitted-students">
          <p>Topics</p>
        </div>
        <div className="video-flex">
          <div
            className="topic-container"
            onClick={() => navigate("/teacher/set-questions")}
          >
            <div className="topic-view">
              <div className="topic-img">
                <img src={Numbers} />
              </div>
              <p>Numeracy</p>
            </div>
          </div>
        </div>

        {/* <Novideo
          img={Topics}
          text="No Topics have been set. Tap the icon to start adding topics and questions."
        /> */}
        <div
          className="add-question"
          onClick={() => navigate("/teacher/select")}
        >
          <img src={Addition} alt="" />
        </div>
      </div>
    </div>
  );
}
