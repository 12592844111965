import React from "react";
import dark from "../../images/dark.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import Button, { Button2 } from "../../components/Button/Button";
import { SmallFeature } from "../homepageSections/Feature";
import Logo from "../../images/form-logo.png";
import upload from "../../images/upload-child.png";
import { BackButton } from "../studentPages/PersonalData";
import { adminContext } from "../context/adminContext";
import Avatar from "../../images/aang.jpg";
import Popup from "../../components/Popup/Popup";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Mail from "../../images/mail.png";
import { Toaster, toast } from "react-hot-toast"; // Import the Toaster component

export default function NewForm() {
  const navigate = useNavigate();
  const location = useLocation();

  const reg = location.state.reg;

  const [isLoaded, setIsLoaded] = useState(false);
  const values = useContext(adminContext);
  const { admintoken } = values;

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedSrc, setSelectedSrc] = useState("");

  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [show, setShow] = useState(false);
  const [currentDialog, setCurrentDialog] = useState("skip"); // skip, centre, login_details

  const [loginEmail, setLoginEmail] = useState("");
  const [loginStudId, setLoginStudId] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  const [centre, setCentre] = useState("Topaz Centre"); //Topaz Centre ,Emerald Centre
  const [currentSession, setCurrentSession] = useState("2022/2023");
  const [currentTerm, setCurrentTerm] = useState("First Term");

  const handleDateChange = (e) => setDate(e.target.value);
  const handleTimeChange = (e) => setTime(e.target.value);

  const handleClose = () => {
    setShow(false);
  };

  // console.log(`----> ${JSON.stringify(reg.guardian_info)}`);

  // function to admit student
  const admitStudent = async () => {
    setIsLoaded(true);
    try {
      const response = await fetch(
        "https://server-olhms.onrender.com/admin_session/view_current_session",
        {
          method: "POST",
          body: JSON.stringify({
            token: JSON.parse(admintoken),
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const res_data = await response.json();
      if (res_data.status === "ok") {
        const current_session = res_data.sessionM["session"];
        const current_term = res_data.sessionM["current_term"];

        const res = await fetch(
          "https://server-olhms.onrender.com/admin_registration/admit_student",
          {
            method: "POST",
            body: JSON.stringify({
              token: JSON.parse(admintoken),
            }),
            body: JSON.stringify({
              token: JSON.parse(admintoken),
              center: centre,
              current_session: current_session,
              current_term: current_term,
              reg_doc_id: reg._id,
            }),
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json, text/plain, */*",
            },
          }
        );
        const data = await res.json();
        if (data.status === "ok") {
          const loginDetails = data?.login_details;
          setLoginEmail(loginDetails.email);
          setLoginStudId(loginDetails.student_id);
          setLoginPassword(loginDetails.password);
          setIsLoaded(false);
          setCurrentDialog("login_details");
        } else {
          setIsLoaded(false);
          alert("An error occurred while admitting student. Please try again");
        }
      } else {
        setIsLoaded(false);
        alert("An error occurred while admitting student. Please try again");
      }
    } catch (e) {
      console.log(e);
      setIsLoaded(false);
      alert("The server encountered an error. Please try again later");
    }
  };
  // const admitStudent = async () => {
  //   setIsLoaded(true);
  //   try {
  //     const res = await fetch(
  //       "https://server-olhms.onrender.com/admin_registration/admit_student",
  //       {
  //         method: "POST",
  //         body: JSON.stringify({
  //           token: JSON.parse(admintoken),
  //           center: centre,
  //           current_session: currentSession,
  //           current_term: currentTerm,
  //           reg_doc_id: reg._id,
  //         }),
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json, text/plain, */*",
  //         },
  //       }
  //     );
  //     const data = await res.json();
  //     if (data.status === "ok") {
  //       const loginDetails = data?.login_details;
  //       setLoginEmail(loginDetails.email);
  //       setLoginStudId(loginDetails.student_id);
  //       setLoginPassword(loginDetails.password);
  //       setIsLoaded(false);
  //       setCurrentDialog("login_details");
  //     }
  //   } catch (e) {
  //     console.log(e);
  //     setIsLoaded(false);
  //   }
  // };

  // function to send email to student containing their login details
  const sendEmail = async () => {
    toast.error("Sending login details via mail...", {
      icon: "⏳️",
      position: "bottom-center",
    });
    try {
      const res = await fetch(
        "https://server-olhms.onrender.com/admin_registration/send_login_details_mail",
        {
          method: "POST",
          body: JSON.stringify({
            token: JSON.parse(admintoken),
            student_name: reg.fullname,
            email: loginEmail,
            student_id: loginStudId,
            password: loginPassword,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const data = await res.json();
      if (data.status === "ok") {
        // setIsLoaded(false);
        // console.log("email sent successfully");
        setCurrentDialog("skip");
        navigate(-1);

        toast.success("Email sent successfully", {
          position: "bottom-center",
        });
      } else {
        toast.error(data.msg, {
          icon: "❗️",
          position: "bottom-center",
        });
      }
    } catch (e) {
      console.log(e);
      setIsLoaded(false);
      alert("Sending email failed");
    }
  };

  const setInterviewDateTime = async () => {
    setIsLoaded(true);
    try {
      const res = await fetch(
        "https://server-olhms.onrender.com/admin_registration/set_interview_date_time",
        {
          method: "POST",
          body: JSON.stringify({
            token: JSON.parse(admintoken),
            interview_date: date,
            interview_time: time,
            reg_doc_id: reg._id,
            rescheduled: false,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );

      const data = await res.json();
      if (data.status === "ok") {
        setIsLoaded(false);
        navigate(-1);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div
      className="set-questions"
      style={{
        backgroundColor: "rgb(255, 248, 248)",
        height: "100%",
        alignItems: "flex-start",
        marginLeft: 200,
      }}
    >
      <div className="set-container" style={{ height: "100%" }}>
        <BackButton click={() => navigate(-1)} />

        <div className="git-wrap">
          <form className="main-admission" style={{ maxWidth: "100%" }}>
            <div className="form-logo">
              <div className="our-lady-div">
                <div className="our-lady">OUR LADY OF HOPE</div>
                <div className="of-hope">MONTESSORI SCHOOLS</div>
              </div>
              <div className="montessori">APPLICATION FOR ADMISSION</div>
              <div className="form-logo-img">
                <img src={Logo} alt="" />
              </div>
              <div className="upload-child">
                {/* <img src={reg.img_url} alt="" /> */}
                {reg.img_url === "" ? (
                  <img src={Avatar} alt="" />
                ) : (
                  <img
                    src={reg.img_url}
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                )}
              </div>
            </div>
            <div className="real-form-apply">
              <legend>Child's Personal Details</legend>
              <div className="split-real-form">
                <div className="apply-form-group">
                  <label htmlFor="student">Family's name</label>
                  <input
                    type="text"
                    disabled={true}
                    value={reg.lastname}
                    // onChange={handleChange}
                  />
                </div>
                <div className="apply-form-group">
                  <label htmlFor="student">First name</label>
                  <input
                    type="text"
                    disabled={true}
                    value={reg.firstname}
                    // onChange={handleChange}
                  />
                </div>
              </div>

              <div className="split-real-form">
                <div className="apply-form-group">
                  <label htmlFor="student">Date of birth</label>
                  <input
                    type="text"
                    disabled={true}
                    value={reg.date_of_birth}
                    // onChange={handleChange}
                  />
                </div>
                <div className="apply-form-group">
                  <label htmlFor="student">Place of birth</label>
                  <input
                    type="text"
                    disabled={true}
                    value={reg.place_of_birth}
                    // onChange={handleChange}
                  />
                </div>
              </div>

              <div className="split-real-form">
                <div className="apply-form-group">
                  <label htmlFor="student">Nationality</label>
                  <input
                    type="text"
                    disabled={true}
                    value={reg.nationality}
                    // onChange={handleChange}
                  />
                </div>
                <div className="apply-form-group">
                  <label htmlFor="student">Gender</label>
                  <select
                    disabled
                    type="text"
                    value={reg.gender}
                    // onChange={handleChange}
                  >
                    <option>Male</option>
                    <option>Female</option>
                  </select>
                </div>
              </div>

              <div className="split-real-form">
                <div className="apply-form-group">
                  <label htmlFor="student">Special health needs</label>
                  <input
                    type="text"
                    disabled={true}
                    value={reg.special_needs}
                    // onChange={handleChange}
                  />
                </div>
                <div className="apply-form-group">
                  <label htmlFor="student">Intended class</label>
                  <select
                    disabled
                    type="text"
                    value={reg.class_name}
                    // onChange={handleChange}
                  >
                    <option>Creche</option>
                    <option>Toddlers</option>
                    <option>Infant community 1</option>
                    <option>Infant Community 2</option>
                    <option>Nursery 1</option>
                    <option>Nursery 2</option>
                    <option>Grade 1</option>
                    <option>Grade 2</option>
                    <option>Grade 3</option>
                    <option>Grade 4</option>
                    <option>Grade 5</option>
                  </select>
                </div>
              </div>

              <legend>Parent/ Guardian Details</legend>
              <div className="split-real-form">
                <div className="apply-form-group">
                  <label htmlFor="student">Full Name</label>
                  <input
                    type="text"
                    disabled={true}
                    value={reg.guardian_info.fullname}
                    // onChange={handleChange}
                  />
                </div>
                <div className="apply-form-group">
                  <label htmlFor="student">Email</label>
                  <input
                    type="text"
                    disabled={true}
                    value={reg.guardian_info.email}
                    // onChange={handleChange}
                  />
                </div>
              </div>

              <div className="split-real-form">
                <div className="apply-form-group">
                  <label htmlFor="student">Address</label>
                  <input
                    type="text"
                    disabled={true}
                    value={reg.guardian_info.address}
                    // onChange={handleChange}
                  />
                </div>
                <div className="apply-form-group">
                  <label htmlFor="student">Office Address</label>
                  <input
                    type="text"
                    disabled={true}
                    value={reg.guardian_info.office_address}
                    // onChange={handleChange}
                  />
                </div>
              </div>

              <div className="split-real-form">
                <div className="apply-form-group">
                  <label htmlFor="student">Occupation</label>
                  <input
                    type="text"
                    disabled={true}
                    value={reg.guardian_info.occupation}
                    // onChange={handleChange}
                  />
                </div>
                <div className="apply-form-group">
                  <label htmlFor="student">Phone Number</label>
                  <input
                    type="text"
                    disabled={true}
                    value={reg.guardian_info.phone_no}
                    // onChange={handleChange}
                  />
                </div>
              </div>

              <div className="split-passport">
                <div className="upload-apply">
                  <label>
                    <div className="apply-dark">
                      {/* <img src={reg.guardian_info.first_img_url} alt="" /> */}
                      {reg.guardian_info.first_img_url === "" ? (
                        <img src={Avatar} alt="" />
                      ) : (
                        <img src={reg.guardian_info.first_img_url} alt="" />
                      )}
                    </div>
                  </label>
                  {/* <span>Tap to upload parent 1 passport photo</span> */}
                </div>
                <div className="upload-apply">
                  <label>
                    <div className="apply-dark">
                      {reg.guardian_info.second_img_url === "" ? (
                        <img src={Avatar} alt="" />
                      ) : (
                        <img src={reg.guardian_info.second_img_url} alt="" />
                      )}
                    </div>
                  </label>
                  {/* <span>Tap to upload parent 2 passport photo</span> */}
                </div>
              </div>

              <legend>Academic Details</legend>
              <div className="split-real-form">
                <div className="apply-form-group">
                  <label htmlFor="student">Name of Previous school</label>
                  <input
                    type="text"
                    className="six-fourty"
                    disabled={true}
                    value={reg.academic_details.previous_school_one}
                    // onChange={handleChange}
                  />
                </div>
                <div className="apply-form-group-small">
                  <label htmlFor="student">Class</label>
                  <input
                    type="text"
                    className="three-hundred"
                    disabled={true}
                    value={reg.academic_details.class_one}
                    // onChange={handleChange}
                  />
                </div>
              </div>

              <div className="split-real-form">
                <div className="apply-form-group">
                  <label htmlFor="student">Name of Previous school</label>
                  <input
                    type="text"
                    className="six-fourty"
                    disabled={true}
                    value={reg.academic_details.previous_school_two}
                    // onChange={handleChange}
                  />
                </div>
                <div className="apply-form-group-small">
                  <label htmlFor="student">Class</label>
                  <input
                    type="text"
                    className="three-hundred"
                    disabled={true}
                    value={reg.academic_details.class_two}
                    // onChange={handleChange}
                  />
                </div>
              </div>

              <legend>
                Emergency Information
                <span>
                  Responsible adults to contact if Parents cannot be reached
                </span>
              </legend>
              <div className="split-real-form">
                <div className="apply-form-group">
                  <label htmlFor="student">Full Name</label>
                  <input
                    type="text"
                    className="six-fourty"
                    disabled={true}
                    value={reg.emergency_info.fullname}
                    // onChange={handleChange}
                  />
                </div>
                <div className="apply-form-group-small">
                  <label htmlFor="student">Phone Number</label>
                  <input
                    type="text"
                    className="three-hundred"
                    disabled={true}
                    value={reg.emergency_info.phone_no}
                    // onChange={handleChange}
                  />
                </div>
              </div>

              <legend>Health Matters</legend>
              <div className="split-real-form">
                <div className="apply-form-group">
                  <label htmlFor="student">Full Name</label>
                  <input
                    type="text"
                    className="six-fourty"
                    disabled={true}
                    value={reg.health_matters.fullname}
                    // onChange={handleChange}
                  />
                </div>
                <div className="apply-form-group-small">
                  <label htmlFor="student">Phone Number</label>
                  <input
                    type="text"
                    className="three-hundred"
                    disabled={true}
                    value={reg.health_matters.phone_no}
                    // onChange={handleChange}
                  />
                </div>
              </div>

              <div className="form-group-admin thousand">
                <label htmlFor="student">Disabilities (if any)</label>
                <input
                  type="text"
                  disabled={true}
                  value={reg.health_matters.disabilities}
                  // onChange={handleChange}
                />
              </div>

              {/* <legend>Declaration</legend>
              <div className="declaration">
                <input type={"checkbox"} />
                <p>
                  I confirm that to the best of my knowledge, the information in
                  this form is correct. I have understood and agreed to abide by
                  all school rules, including school discipline and tuition fee
                  payment. I also acknowledge that while the school does its
                  best to ensure the safety of each child’s life, health and
                  property, the school cannot be held responsible for any damage
                  to these.
                </p>
              </div>

              <p className="warning-apply">
                N/B: Please cross check to ensure that all fields have been
                filled with correct information before downloading and
                submitting the form.
              </p> */}
              <div className="flex-application-new">
                <label className="date-application">
                  Date for Interview{" "}
                  <input type="date" value={date} onChange={handleDateChange} />
                </label>
                <label className="date-application">
                  Time for Interview{" "}
                  <input type="time" value={time} onChange={handleTimeChange} />
                </label>
                <Button
                  disabled={isLoaded}
                  click={(e) => {
                    e.preventDefault();
                    setInterviewDateTime();
                  }}
                  name="Send Date and Time to Applicant"
                />
              </div>
              <p className="submit-apply" style={{ marginTop: "30px" }}>
                Old Student? Skip to
                <span
                  style={{ color: "rgb(224, 86, 21)", cursor: "pointer" }}
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  {" "}
                  Admit Student
                </span>{" "}
              </p>
            </div>
          </form>
        </div>
        {/* <Popup isOpen={show}>
          <div className="central-pop">
            <p>Admit old student?</p>
            <div className="central-details">
              Are you sure you want to admit{" "}
              {reg.firstname +
                (reg.middlename ? " " + reg.middlename : "") +
                " " +
                reg.lastname} {" "}
               and then proceed to generate login details?
            </div>
            <div className="pop-central">
              <Button2 name="No" click={() => setShow(false)} />
              <Button name="Yes" click={()=>console.log()} loading={loading} />
            </div>
          </div>
        </Popup> */}

        <Dialog onClose={handleClose} open={show}>
          <DialogTitle>
            {" "}
            {currentDialog == "skip"
              ? "Admit as an old student?"
              : currentDialog == "centre"
              ? "School Centre"
              : "Student Login Details"}{" "}
          </DialogTitle>
          <p
            style={{
              padding: "25px",
              fontSize: "16px",
            }}
          >
            {currentDialog == "skip" ? (
              `Are you sure you want to admit 
            "${
              reg.firstname +
              (reg.middlename ? " " + reg.middlename : "") +
              " " +
              reg.lastname
            }" and then proceed to generate login details?`
            ) : currentDialog == "centre" ? (
              <div
                onChange={(e) => {
                  setCentre(e.target.value);
                  console.log(centre);
                }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: 18,
                  paddingRight: 18,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "center",
                    paddingBottom: "10px",
                  }}
                >
                  <input
                    checked={centre == "Topaz Centre" ? true : false}
                    type="radio"
                    value="Topaz Centre"
                    name="centre"
                    defaultValue="Topaz Centre"
                    id="1"
                  />
                  <label for="1" style={{ marginLeft: 22 }}>
                    Topaz Centre
                  </label>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingBottom: "15px",
                  }}
                >
                  <input
                    checked={centre == "Emerald Centre" ? true : false}
                    type="radio"
                    value="Emerald Centre"
                    name="centre"
                    defaultValue="Emerald Centre"
                    id="2"
                  />
                  <label for="2" style={{ marginLeft: 22 }}>
                    Emerald Centre
                  </label>
                </div>
              </div>
            ) : (
              <div style={{ marginLeft: 30, marginRight: 30 }}>
                <span>{`Email: ${loginEmail}`}</span>
                <br></br>
                <span>{`Student ID: ${loginStudId}`}</span>
                <br></br>
                <span>{`Password: ${loginPassword}`}</span>
                <br></br>
                <br></br>

                <p style={{ color: "red" }}>
                  * Please tap the email icon below to send login details to the
                  parent. Otherwise, you must ensure these login details are
                  noted before leaving this page, as there won't be another way
                  to share them.
                </p>
              </div>
            )}
          </p>

          {currentDialog == "skip" ? (
            <>
              <div
                style={{
                  marginTop: 16,
                  marginBottom: 16,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <Button2
                  click={(e) => {
                    e.preventDefault();
                    setShow(false);
                  }}
                  name="No"
                />
                <Button
                  disabled={isLoaded}
                  click={(e) => {
                    e.preventDefault();
                    setCurrentDialog("centre");
                  }}
                  name="Yes"
                />
              </div>
              <p style={{ fontSize: "12px", color: "red", padding: "5px" }}>
                {errorMessage}
              </p>
            </>
          ) : currentDialog == "centre" ? (
            <div
              style={{
                marginTop: 16,
                marginBottom: 16,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Button
                disabled={isLoaded}
                click={(e) => {
                  e.preventDefault();
                  admitStudent();
                }}
                name="Proceed"
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img
                style={{ height: 40, width: 40, marginBottom: 30 }}
                src={Mail}
                onClick={() => {
                  // console.log("send mail");
                  sendEmail();
                }}
              />
            </div>
          )}
        </Dialog>
      </div>
    </div>
  );
}
