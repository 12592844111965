import React from "react";
import "./form.css";
import dark from "../../images/dark.png";
import { useNavigate } from "react-router-dom";
import { useState, useRef, useContext, useEffect } from "react";
import Button, { Button2 } from "../../components/Button/Button";
import Logo from "../../images/form-logo.png";
import upload from "../../images/upload-child.png";
import axios from "axios";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Toaster, toast } from "react-hot-toast"; // Import the Toaster component

export default function Form() {
  // student info
  const [famName, setFamName] = useState("");
  const [fName, setFName] = useState("");
  const [dob, setDob] = useState("");
  const [placeoFBirth, setPlaceOfBirth] = useState("");
  const [nationality, setNationality] = useState("");
  const [gender, setGender] = useState("male");
  const [specialNeeds, setSpecialNeeds] = useState("");
  const [intendClass, setIntendClass] = useState("Creche");

  // Guardian info
  const [guardianFName, setGuardianFName] = useState("");
  const [guardianEmail, setGuardianEmail] = useState("");
  const [guardianAddress, setGuardianAddress] = useState("");
  const [guardianOffAddress, setGuardianOffAddress] = useState("");
  const [guardianOccup, setGuardianOccup] = useState("");
  const [guardianPhone, setGuardianPhone] = useState("");

  // add fields to handle guardian images

  // Academic info
  const [namePrevSchool1, setNamePrevSchool1] = useState("");
  const [namePrevSchool2, setNamePrevSchool2] = useState("");
  const [prevClass1, setPrevClass1] = useState("");
  const [prevClass2, setPrevClass2] = useState("");

  // Emergency info
  const [eFName, setEFname] = useState("");
  const [ePhone, setEPhone] = useState("");

  // Health info
  const [hFname, setHFName] = useState("");
  const [hPhone, setHPhone] = useState("");
  const [disabilities, setDisabilities] = useState("");
  const [declaration, setDeclaration] = useState(false);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [openDialog, handleDisplay] = useState(false);

  const handleClose = () => {
    handleDisplay(false);
  };

  const openDialogBox = (repeat) => {
    handleDisplay(true);
  };
  const dialogStyle = {
    padding: "20px",
  };

  const handleFamName = (e) => setFamName(e.target.value);
  const handleFName = (e) => setFName(e.target.value);
  const handleDob = (e) => setDob(e.target.value);
  const handlePlaceOfBirth = (e) => setPlaceOfBirth(e.target.value);
  const handleNationality = (e) => setNationality(e.target.value);
  const handleGender = (e) => setGender(e.target.value);
  const handleSpecialNeeds = (e) => setSpecialNeeds(e.target.value);
  const handleIntendClass = (e) => setIntendClass(e.target.value);
  const handleGuardianFname = (e) => setGuardianFName(e.target.value);
  const handleGuardianEmail = (e) => setGuardianEmail(e.target.value);
  const handleGuardianAddress = (e) => setGuardianAddress(e.target.value);
  const handleGuardianOffAddress = (e) => setGuardianOffAddress(e.target.value);
  const handleGuardianOccup = (e) => setGuardianOccup(e.target.value);
  const handleGuardianPhone = (e) => setGuardianPhone(e.target.value);
  const handlePrevSchool1 = (e) => setNamePrevSchool1(e.target.value);
  const handlePrevSchool2 = (e) => setNamePrevSchool2(e.target.value);
  const handlePrevClass1 = (e) => setPrevClass1(e.target.value);
  const handlePrevClass2 = (e) => setPrevClass2(e.target.value);
  const handleEFname = (e) => setEFname(e.target.value);
  const handleEPhone = (e) => setEPhone(e.target.value);
  const handleHFname = (e) => setHFName(e.target.value);
  const handleHPhone = (e) => setHPhone(e.target.value);
  const handleDisabilities = (e) => setDisabilities(e.target.value);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedSrc, setSelectedSrc] = useState("");

  // function to handle showing toast, after importing it on this file and placing it at the top level of your application which would allow you to use the toasts (pop-up notifications) throughout your application.
  const showErrorToast = (message) => {
    toast.error(message, {
      icon: "❗️",
      position: "bottom-center",
    });
  };

  const onFileChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState == 2) {
        setSelectedSrc(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
    // setIsSelected(true);
  };

  const [selectedFileParent1, setSelectedFileParent1] = useState(null);
  const [selectedSrcParent1, setSelectedSrcParent1] = useState("");

  const onParentFileChanage1 = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState == 2) {
        setSelectedSrcParent1(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
    setSelectedFileParent1(e.target.files[0]);
    // setIsSelected(true);
  };

  const [selectedFileParent2, setSelectedFileParent2] = useState(null);
  const [selectedSrcParent2, setSelectedSrcParent2] = useState("");

  const onParentFileChanage2 = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState == 2) {
        setSelectedSrcParent2(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
    setSelectedFileParent2(e.target.files[0]);
    // setIsSelected(true);
  };

  // function to upload parent image
  const uploadParentImage = async () => {};

  // function to submit application
  const submitApplication = async () => {
    setLoading(true);

    let parentImgUrl1 = "";
    let parentImgUrl2 = "";

    let parentImgId1 = "";
    let parentImgId2 = "";

    // upload parent 1 image if it exists

    if (selectedSrcParent1 != "") {
      const parentFormData = new FormData();
      parentFormData.append("guardian-images", selectedFileParent1);

      const options = {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: parentFormData,
        url: "https://server-olhms.onrender.com/student_registration/upload_file",
      };

      try {
        const response = await axios(options);
        if (response.status == 200) {
          console.log("response: :: :: ", response);
          parentImgUrl1 = response.data?.img_details.url;
          parentImgId1 = response.data?.img_details.id;
        } else {
          setLoading(false);
          alert("An error occured while uploading Parent 1 photo. Please try again");
        }
      } catch (error) {
        if (error == "AxiosError: Request failed with status code 400") {
          alert("An unknown error occured while uploading Parent 1 photo. Please try again");
        } else if (error == "AxiosError: Network Error") {
          alert("Please check your internet connection & try again");
        } else {
          alert("An error occured while uploading Parent 1 photo");
        }
        setLoading(false);
      } 
    }

    // upload parent 2 image if it exits
    if (selectedSrcParent2 != "") {
      const parentFormData = new FormData();
      parentFormData.append("guardian-images", selectedFileParent2);

      const options = {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: parentFormData,
        url: "https://server-olhms.onrender.com/student_registration/upload_file",
      };

      const response = await axios(options); 
      try {
        const response = await axios(options);
        if (response.status == 200) {
          console.log("response: :: :: ", response);
          parentImgUrl2 = response.data?.img_details.url;
          parentImgId2 = response.data?.img_details.id;
        } else {
          setLoading(false);
          alert("An error occured while uploading Parent 2 photo. Please try again");
        }
      } catch (error) {
        if (error == "AxiosError: Request failed with status code 400") {
          alert("An unknown error occured while uploading Parent 2 photo. Please try again");
        } else if (error == "AxiosError: Network Error") {
          alert("Please check your internet connection & try again");
        } else {
          alert("An error occured while uploading Parent 2 photo");
        }
        setLoading(false);
      } 
    }

    // upload student form and student image also
    const formData = new FormData();

    formData.append("famname", famName);
    formData.append("firstname", fName);
    formData.append(
      "emergency_info",
      JSON.stringify({
        fullname: eFName,
        phone_no: ePhone,
      })
    );

    formData.append("gender", gender);
    formData.append("date_of_birth", dob);
    formData.append("place_of_birth", placeoFBirth);

    formData.append(
      "academic_details",
      JSON.stringify({
        previous_school_one: namePrevSchool1,
        class_one: prevClass1,
        previous_school_two: namePrevSchool2,
        class_two: prevClass2,
      })
    );

    formData.append("special_needs", specialNeeds);
    formData.append(
      "health_matters",
      JSON.stringify({
        fullname: hFname,
        phone_no: hPhone,
        disabilities: disabilities,
      })
    );

    formData.append("nationality", nationality);

    formData.append(
      "guardian_info",
      JSON.stringify({
        fullname: guardianFName,
        guardian_id: "",
        phone_no: guardianPhone,
        email: guardianEmail,
        address: guardianAddress,
        office_address: guardianOffAddress,
        occupation: guardianOccup,
        first_img_id: parentImgId1,
        first_img_url: parentImgUrl1,
        second_img_id: parentImgId2,
        second_img_url: parentImgUrl2,
      })
    );

    formData.append("class_name", intendClass);

    formData.append("student-images", selectedFile);

    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: formData,
      url: "https://server-olhms.onrender.com/student_registration/register",
    };

    axios(options)
      .then((response) => {
        setLoading(false);

        if (response.status == 200) {
          console.log("successful registration:: ", response);
          // navigate to next page
          // download application form and show pop up dialog

          toast.success(
            "You will be contacted via email, text and or call on the date of your entrance exam and interview.",
            {
              duration: 10000,
              position: "bottom-center",
            }
          );

          downloadPdfDocument();

          // openDialogBox();
        } else {
          alert("An error occured. Please try again");
        }
      })
      .catch((e) => {
        console.log("error: :: :: ", e);
        setLoading(false);
      });
  };

  const pdfRef = useRef();

  const downloadPdfDocument = () => {
    const currentDateTime = new Date();

    const input = pdfRef.current;
    html2canvas(input, {
      letterRendering: 1,
      allowTaint: false,
      useCORS: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save(`admission form_${currentDateTime.toLocaleString()}.pdf`);
    });

    navigate("/admission");
  };
  return (
    <div className="main-contact">
      <div className="form-comp"></div>
      <div className="admission-form" ref={pdfRef}>
        <p className="apply-first-p admission-p">
          Get a place for your child at Our Lady Hope Montessori Schools
        </p>
        <form className="main-admission" id="admission_form">
          <div className="form-logo">
            <div className="our-lady-div">
              <div className="our-lady">OUR LADY OF HOPE</div>
              <div className="of-hope">MONTESSORI SCHOOLS</div>
            </div>
            <div className="montessori">APPLICATION FOR ADMISSION</div>
            <div className="form-logo-img">
              <img src={Logo} alt="" />
            </div>
            {selectedSrc == "" ? (
              <div className="upload-child">
                <label>
                  <img src={upload} alt="" />
                  <input
                    style={{ display: "none" }}
                    type="file"
                    accept=".png, .jpg, .jpeg, .gif"
                    onChange={onFileChange}
                  />
                </label>
              </div>
            ) : selectedSrc != "" ? (
              <div className="upload-child">
                <img src={selectedSrc} alt="" style={{ objectFit: "cover" }} />
              </div>
            ) : null}

            {selectedSrc == "" ? null : (
              <div className="show-blog-img">
                <label>
                  Change Image
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg, .gif"
                    onChange={onFileChange}
                  />
                </label>
              </div>
            )}
          </div>
          <div className="real-form-apply">
            <legend>Child's Personal Details</legend>
            <div className="split-real-form">
              <div className="apply-form-group">
                <label htmlFor="student">Family's name *</label>
                <input type="text" value={famName} onChange={handleFamName} />
              </div>
              <div className="apply-form-group">
                <label htmlFor="student">First name *</label>
                <input type="text" value={fName} onChange={handleFName} />
              </div>
            </div>

            <div className="split-real-form">
              <div className="apply-form-group">
                {/* <label htmlFor="student">Date of birth (dd-mm-yy) *</label>
                <input type="text" value={dob} onChange={handleDob} /> */}
                <label htmlFor="student">Date for birth </label>
                <input type="date" value={dob} onChange={handleDob} />
              </div>
              <div className="apply-form-group">
                <label htmlFor="student">Place of birth *</label>
                <input
                  type="text"
                  value={placeoFBirth}
                  onChange={handlePlaceOfBirth}
                />
              </div>
            </div>

            <div className="split-real-form">
              <div className="apply-form-group">
                <label htmlFor="student">Nationality *</label>
                <input
                  type="text"
                  value={nationality}
                  onChange={handleNationality}
                />
              </div>
              <div className="apply-form-group">
                <label htmlFor="student">Gender *</label>
                <select type="text" value={gender} onChange={handleGender}>
                  <option value="male" defaultValue={gender}>
                    Male
                  </option>
                  <option value="female">Female</option>
                </select>
              </div>
            </div>

            <div className="split-real-form">
              <div className="apply-form-group">
                <label htmlFor="student">Special health needs *</label>
                <input
                  type="text"
                  value={specialNeeds}
                  onChange={handleSpecialNeeds}
                />
              </div>
              <div className="apply-form-group">
                <label htmlFor="student">Intended class</label>
                <select
                  type="text"
                  value={intendClass}
                  onChange={handleIntendClass}
                >
                  <option value="Creche">Creche</option>
                  <option value="Toddlers">Toddlers</option>
                  <option value="Infant Community 1">Infant community 1</option>
                  <option value="Infant Community 2">Infant Community 2</option>
                  <option value="Nursery 1">Nursery 1</option>
                  <option value="Nursery 2">Nursery 2</option>
                  <option value="Grade 1">Grade 1</option>
                  <option value="Grade 2">Grade 2</option>
                  <option value="Grade 3">Grade 3</option>
                  <option value="Grade 4">Grade 4</option>
                  <option value="Grade 5">Grade 5</option>
                </select>
              </div>
            </div>

            <legend>Parent/Guardian Details</legend>
            <div className="split-real-form">
              <div className="apply-form-group">
                <label htmlFor="student">Full Name *</label>
                <input
                  type="text"
                  value={guardianFName}
                  onChange={handleGuardianFname}
                />
              </div>
              <div className="apply-form-group">
                <label htmlFor="student">Email *</label>
                <input
                  type="text"
                  value={guardianEmail}
                  onChange={handleGuardianEmail}
                />
              </div>
            </div>

            <div className="split-real-form">
              <div className="apply-form-group">
                <label htmlFor="student">Address *</label>
                <input
                  type="text"
                  value={guardianAddress}
                  onChange={handleGuardianAddress}
                />
              </div>
              <div className="apply-form-group">
                <label htmlFor="student">Office Address</label>
                <input
                  type="text"
                  value={guardianOffAddress}
                  onChange={handleGuardianOffAddress}
                />
              </div>
            </div>

            <div className="split-real-form">
              <div className="apply-form-group">
                <label htmlFor="student">Occupation *</label>
                <input
                  type="text"
                  value={guardianOccup}
                  onChange={handleGuardianOccup}
                />
              </div>
              <div className="apply-form-group">
                <label htmlFor="student">Phone Number *</label>
                <input
                  type="text"
                  value={guardianPhone}
                  onChange={handleGuardianPhone}
                  maxLength={11}
                />
              </div>
            </div>

            <div
              className="split-passport"
              style={{ marginBottom: 60, marginTop: 40 }}
            >
              {selectedSrcParent1 == "" ? (
                <div className="upload-apply">
                  <label>
                    <div className="apply-dark">
                      <img src={dark} alt="" />
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg, .gif"
                        onChange={onParentFileChanage1}
                      />
                    </div>
                  </label>
                  <span>Tap to upload parent 1 passport photo</span>
                </div>
              ) : selectedSrcParent1 != "" ? (
                <div className="apply-dark">
                  <img src={selectedSrcParent1} alt="" />
                </div>
              ) : null}

              {selectedSrcParent1 == "" ? null : (
                <div className="show-blog-img">
                  <label>
                    Change Image
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg, .gif"
                      onChange={onParentFileChanage1}
                    />
                  </label>
                </div>
              )}

              {selectedSrcParent2 == "" ? (
                <div className="upload-apply">
                  <label>
                    <div className="apply-dark">
                      <img src={dark} alt="" />
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg, .gif"
                        onChange={onParentFileChanage2}
                      />
                    </div>
                  </label>
                  <span>Tap to upload parent 2 passport photo</span>
                </div>
              ) : selectedSrcParent2 != "" ? (
                <div className="apply-dark">
                  <img src={selectedSrcParent2} alt="" />
                </div>
              ) : null}

              {selectedSrcParent2 == "" ? null : (
                <div className="show-blog-img">
                  <label>
                    Change Image
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg, .gif"
                      onChange={onParentFileChanage2}
                    />
                  </label>
                </div>
              )}
            </div>

            <legend>Academic Details</legend>
            <div className="split-real-form">
              <div className="apply-form-group">
                <label htmlFor="student">Name of Previous school</label>
                <input
                  type="text"
                  className="six-fourty"
                  value={namePrevSchool1}
                  onChange={handlePrevSchool1}
                />
              </div>
              <div className="apply-form-group-small">
                <label htmlFor="student">Class</label>
                <input
                  type="text"
                  className="three-hundred"
                  value={prevClass1}
                  onChange={handlePrevClass1}
                />
              </div>
            </div>

            <div className="split-real-form">
              <div className="apply-form-group">
                <label htmlFor="student">Name of Previous school</label>
                <input
                  type="text"
                  className="six-fourty"
                  value={namePrevSchool2}
                  onChange={handlePrevSchool2}
                />
              </div>
              <div className="apply-form-group-small">
                <label htmlFor="student">Class</label>
                <input
                  type="text"
                  className="three-hundred"
                  value={prevClass2}
                  onChange={handlePrevClass2}
                />
              </div>
            </div>

            <legend>
              Emergency Information
              <span>
                Responsible adults to contact if Parents cannot be reached
              </span>
            </legend>
            <div className="split-real-form">
              <div className="apply-form-group">
                <label htmlFor="student">Full Name *</label>
                <input
                  type="text"
                  className="six-fourty"
                  value={eFName}
                  onChange={handleEFname}
                />
              </div>
              <div className="apply-form-group-small">
                <label htmlFor="student">Phone Number *</label>
                <input
                  type="text"
                  className="three-hundred"
                  value={ePhone}
                  onChange={handleEPhone}
                  maxLength={11}
                />
              </div>
            </div>

            <legend>Health Matters</legend>
            <div className="split-real-form">
              <div className="apply-form-group">
                <label htmlFor="student">Full Name *</label>
                <input
                  type="text"
                  className="six-fourty"
                  value={hFname}
                  onChange={handleHFname}
                />
              </div>
              <div className="apply-form-group-small">
                <label htmlFor="student">Phone Number *</label>
                <input
                  type="text"
                  className="three-hundred"
                  value={hPhone}
                  onChange={handleHPhone}
                  maxLength={11}
                />
              </div>
            </div>

            <div className="form-group-admin thousand">
              <label htmlFor="student">Disabilities (if any)</label>
              <input
                type="text"
                value={disabilities}
                onChange={handleDisabilities}
              />
            </div>

            <legend>Declaration</legend>
            <div className="declaration">
              <input
                type={"checkbox"}
                value={declaration}
                checked={declaration}
                onChange={(e) => {
                  setDeclaration(e.target.checked);
                }}
              />
              <p>
                I confirm that to the best of my knowledge, the information in
                this form is correct. I have understood and agreed to abide by
                all school rules, including school discipline and tuition fee
                payment. I also acknowledge that while the school does its best
                to ensure the safety of each child’s life, health and property,
                the school cannot be held responsible for any damage to these.
              </p>
            </div>

            <p className="warning-apply">
              N/B: Please cross check to ensure that all fields have been filled
              with correct information before downloading and submitting the
              form.
            </p>

            <div className="submit-apply">
              <Button
                disabled={loading}
                click={(e) => {
                  e.preventDefault();
                  // console.log(`---> ${intendClass}`)


                  if (selectedSrc === "") {
                    showErrorToast("Please add child passport photo");
                  } else if (famName === "") {
                    showErrorToast("Enter Family Name of Child");
                  } else if (fName === "") {
                    showErrorToast("Enter First name of Child");
                  } else if (dob === "") {
                    showErrorToast("Child's Date of birth cannot be empty");
                  } else if (placeoFBirth === "") {
                    showErrorToast("Child's Place of birth cannot be empty");
                  } else if (nationality === "") {
                    showErrorToast("Enter Child's Nationality detail");
                  } else if (specialNeeds === "") {
                    showErrorToast("Enter Child's Special needs");
                  } else if (guardianFName === "") {
                    showErrorToast("Enter Parent/Guardian full name");
                  } else if (guardianEmail === "") {
                    showErrorToast("Enter Parent/Guardian email");
                  } else if (guardianAddress === "") {
                    showErrorToast("Enter Parent/Guardian address");
                  } else if (guardianOccup === "") {
                    showErrorToast("Enter Parent/Guardian occupation");
                  } else if (guardianPhone === "") {
                    showErrorToast("Enter Parent/Guardian phone number");
                  } else if (
                    selectedSrcParent1 === "" &&
                    selectedSrcParent2 === ""
                  ) {
                    showErrorToast(
                      "Please add at least one parent's passport photo"
                    );
                  } else if (eFName === "") {
                    showErrorToast(
                      "Please enter a contact name for emergency purposes if parents cannot be reached"
                    );
                  } else if (setEPhone === "") {
                    showErrorToast(
                      "Please enter a contact phone number for emergency purposes if parents cannot be reached"
                    );
                  } else if (hFname === "") {
                    showErrorToast(
                      "Please enter a contact name for health matters"
                    );
                  } else if (hPhone === "") {
                    showErrorToast(
                      "Please enter a contact phone number for health matters"
                    );
                  } else if (declaration == false) {
                    showErrorToast("Please accept declaration to proceed");
                  } else {
                    submitApplication();
                  }

                  // if(declaration == true){
                  //   submitApplication();
                  // }
                  // downloadPdfDocument();
                }}
                name="Submit and Download Application Form"
              />
            </div>
          </div>
        </form>
      </div>
      <div className="white-form"></div>
      {/* <Dialog
        onClose={handleClose}
        open={openDialog}
        disableBackdropClick={true} // Prevents clicking outside the dialog from closing it
        disableEscapeKeyDown={true} // Prevents pressing the Escape key from closing it
      >
        <DialogTitle>Submission Successful</DialogTitle>
        <p style={dialogStyle}>
          You will be contacted via email, text and or call on the date of your
          entrance exam and interview.
        </p>
        <div
          style={{
            marginTop: 16,
            marginBottom: 16,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Button
            click={(e) => {
              e.preventDefault();
              // empty all fields
              // navigate to the admissions homepage
              navigate("/admission");
            }}
            name="Okay"
          />
        </div>
      </Dialog> */}
    </div>
  );
}
