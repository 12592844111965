import React, {useContext, useState, useEffect} from "react";
import { Triple } from "../teacherPages/ResultTeacher";
import { capitalizeFirstLetter } from "./StaffDatabase";
import { Novideo } from "../teacherPages/Resources";
import Session from "../../images/Nosession.png";
import { useNavigate } from "react-router-dom";
import { adminContext } from "../context/adminContext";
import { Toggle } from "../teacherPages/Resources";

export default function Archive() {
  const navigate = useNavigate();
  const values = useContext(adminContext);
  const { admintoken } = values;

  const [centerToggle, setCenterToggle] = useState(true);
  const [loading, setLoading] = useState(false);

  const [realClass, setRealClass] = useState("creche");
  const [toggle, setToggle] = useState("first");
  const [overallClass, setOverallClass] = useState("Baby's and Toddlers");

  const [selectedSession, setSelectedSession] = useState('2020/2021');
  const [selectedTerm, setSelectedTerm] = useState('First Term');

  const [centers, setCenters] = useState([]);

  const sessions = [
    '2019/2020',
    '2020/2021',
    '2021/2022',
    '2022/2023',
    '2023/2024',
    '2024/2025',
    '2025/2026',
    '2026/2027',
    '2027/2028',
    '2028/2029',
    '2029/2030'
];
  const terms = ["First Term", "Second Term", "Third Term"];


  const viewCenter = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_center/view_centers",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(admintoken),
        }),
      }
    );
    const data = await res.json();
    console.log(data);
    if(data.msg === 'success') {
      setLoading(false);
      setCenters(data.centers);
    }
  };

  useEffect(() => {
    viewCenter();
  }, []);

  const getSession = async () => {

    const res = await fetch(
      "https://server-olhms.onrender.com/admin_session/view_current_session",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(admintoken)
        })
      }
    );

    const data = await res.json();
    setLoading(false);
    const session = data?.sessionM;
    setSelectedSession(session.session);
  }

  useEffect(()=>{
    getSession();
  }, []);

  const [subjects, setSubjects] = useState([]);
  const getSubjects = async () => {
    // had to do this because the way the subjects was initially entered doesn't match (in caps) with
    // how this endpoint would be expecting the subject names
    let class_name = realClass;
    let temp_class_name = class_name.toLowerCase();
    if (temp_class_name == "creche") {
      class_name = "Creche";
    } else if (temp_class_name == "toddlers") {
      class_name = "Toddlers";
    } else if (temp_class_name == "infant community 1") {
      class_name = "Infant Community 1";
    } else if (temp_class_name == "infant community 2") {
      class_name = "Infant Community 2";
    } else if (temp_class_name == "nursery 1") {
      class_name = "Nursery 1";
    } else if (temp_class_name == "nursery 2") {
      class_name = "Nursery 2";
    } else if (temp_class_name == "grade 1") {
      class_name = "Grade 1";
    } else if (temp_class_name == "grade 2") {
      class_name = "Grade 2";
    } else if (temp_class_name == "grade 3") {
      class_name = "Grade 3";
    } else if (temp_class_name == "grade 4") {
      class_name = "Grade 4";
    } else if (temp_class_name == "grade 5") {
      class_name = "Grade 5";
    }


    const res = await fetch(
      "https://server-olhms.onrender.com/teacher_subject/view_subjects",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(admintoken),
          class_name: class_name,
          // class_name: realClass
        })
      }
    );

    const data = await res.json();
    setLoading(false);
    setSubjects(data.subjects);
  }

  useEffect(()=>{
    getSubjects();
  }, [realClass]);

  const handleSessionChange = e => {
    setSelectedSession(e.target.value);
  }

  const handleTermChange = e => {
    setSelectedTerm(e.target.value);
  }

  const getType = togg => {
    if(togg == 'first'){
      return '1st Continuous Assessment Test';
    }else if(togg == 'second'){
      return '2nd Continuous Assessment Test'
    }else if(togg == 'third'){
      return 'Examination';
    }
  }

  return (
    <div
      className="set-questions"
      style={{
        height: "100%",
        alignItems: "flex-start",
      }}
    >
      <div className="set-container">
      <Toggle
          click1={() => setCenterToggle(true)}
          click2={() => setCenterToggle(false)}
          toggle={centerToggle}
          text1="Topaz Centre"
          text2="Emerald Centre"
        />

        <div className="dash-viewer">
          <div
            className="class-view-div-dark"
            onClick={() => setOverallClass("Baby's and Toddlers")}
          >
            <div className="name-of-class">{`Baby's and Toddlers: ${centers[centerToggle ? 0 : 1]?.babies_and_toddlers.total}`}</div>
            <div className="class-viewer">
              <li
                onClick={() => setRealClass("creche")}
                className={realClass == "creche" ? "active-class-moment" : ""}
              >
                Creche
              </li>
              <div>{centers[centerToggle ? 0 : 1]?.babies_and_toddlers.no_of_creche}</div>
              <li
                onClick={() => setRealClass("toddlers")}
                className={realClass == "toddlers" ? "active-class-moment" : ""}
              >
                {" "}
                Toddlers
              </li>
              <div>{centers[centerToggle ? 0 : 1]?.babies_and_toddlers.no_of_toddlers}</div>
            </div>
          </div>
          <div
            className="class-view-div-dark"
            onClick={() => setOverallClass("Infant School")}
          >
            <div className="name-of-class">{`Infant School: ${centers[centerToggle ? 0 : 1]?.infant_school.total}`}</div>
            <div className="class-viewer">
              <li
                onClick={() => setRealClass("infant community 1")}
                className={
                  realClass == "infant community 1" ? "active-class-moment" : ""
                }
              >
                Infant Community 1
              </li>
              <div>{centers[centerToggle ? 0 : 1]?.infant_school.infant_community_one}</div>
              <li
                onClick={() => setRealClass("infant community 2")}
                className={
                  realClass == "infant community 2" ? "active-class-moment" : ""
                }
              >
                Infant Community 2
              </li>
              <div>{centers[centerToggle ? 0 : 1]?.infant_school.infant_community_two}</div>
              <li
                onClick={() => setRealClass("nursery 1")}
                className={
                  realClass == "nursery 1" ? "active-class-moment" : ""
                }
              >
                Nursery 1
              </li>
              <div>{centers[centerToggle ? 0 : 1]?.infant_school.nursery_one}</div>
              <li
                onClick={() => setRealClass("nursery 2")}
                className={
                  realClass == "nursery 2" ? "active-class-moment" : ""
                }
              >
                Nursery 2
              </li>
              <div>{centers[centerToggle ? 0 : 1]?.infant_school.nursery_two}</div>
            </div>
          </div>
          <div
            className="class-view-div-dark"
            onClick={() => setOverallClass("Grade School")}
          >
            <div className="name-of-class">{`Grade School: ${centers[centerToggle ? 0 : 1]?.grade_school.total}`}</div>
            <div className="class-viewer">
              <li
                onClick={() => setRealClass("grade 1")}
                className={realClass == "grade 1" ? "active-class-moment" : ""}
              >
                Grade 1
              </li>
              <div>{centers[centerToggle ? 0 : 1]?.grade_school.grade_one}</div>
              <li
                onClick={() => setRealClass("grade 2")}
                className={realClass == "grade 2" ? "active-class-moment" : ""}
              >
                Grade 2
              </li>
              <div>{centers[centerToggle ? 0 : 1]?.grade_school.grade_two}</div>
              <li
                onClick={() => setRealClass("grade 3")}
                className={realClass == "grade 3" ? "active-class-moment" : ""}
              >
                Grade 3
              </li>
              <div>{centers[centerToggle ? 0 : 1]?.grade_school.grade_three}</div>
              <li
                onClick={() => setRealClass("grade 4")}
                className={realClass == "grade 4" ? "active-class-moment" : ""}
              >
                Grade 4
              </li>
              <div>{centers[centerToggle ? 0 : 1]?.grade_school.grade_four}</div>
              <li
                onClick={() => setRealClass("grade 5")}
                className={realClass == "grade 5" ? "active-class-moment" : ""}
              >
                Grade 5
              </li>
              <div>{centers[centerToggle ? 0 : 1]?.grade_school.grade_five}</div>
            </div>
          </div>
        </div>
        <div className="underline"></div>
        <div style={{ alignSelf: "flex-start" }}>
          {overallClass} &gt;&gt;{" "}
          <span style={{ color: "#E3560F" }}>{realClass.toUpperCase()}</span>
        </div>
        <Triple
          text1="1st C.A.T"
          text2="2nd  C.A.T"
          text3="Exam"
          toggle={toggle}
          click1={() => {
            setToggle("first");
          }}
          click2={() => {
            setToggle("second");
          }}
          click3={() => {
            setToggle("third");
          }}
        />

        <div className="database-table">
          <table>
            <thead>
              <tr className="no-underline">
                <th>S/N</th>
                <th>Subjects</th>
                <th>Session</th>
                <th>Term</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {subjects.map((subject, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>

                  <td>{capitalizeFirstLetter(subject.subject_name)}</td>
                  <td>
                    <div className="form-element" style={{ margin: "0 auto" }}>
                      <div
                        className="select-mark"
                        style={{
                          height: "30px",
                          width: "max-content",
                        }}
                      >
                        <select
                          placeholder={selectedSession}
                          name='session'
                          value={selectedSession}
                          defaultValue={selectedSession}
                          onChange={handleSessionChange}
                          style={{
                            height: "30px",
                            width: "max-content",
                            paddingRight: "20px",
                          }}
                        >
                          {sessions.map((item, index) => (
                            <option value={item} key={index}>
                              {item}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="form-element" style={{ margin: "0 auto" }}>
                      <div
                        className="select-mark"
                        style={{ height: "30px", width: "max-content" }}
                      >
                        <select
                          placeholder={selectedTerm}
                          name='term'
                          value={selectedTerm}
                          defaultValue={selectedTerm}
                          onChange={handleTermChange}
                          style={{
                            height: "30px",
                            width: "max-content",
                            paddingRight: "20px",
                          }}
                        >
                          {terms.map((item, index) => (
                            <option value={item} key={index}>
                              {item}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </td>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/admin/view-question-archive", {state: {
                      term: selectedTerm,
                      session: selectedSession,
                      class_name: realClass,
                      type: getType(toggle),
                      subject: subject.subject_name,
                      admin: true
                    }})}
                  >
                    view
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
