import React, {useState, useEffect} from "react";
import "./contact.css";

export default function Contact() {
  const [fname, setFname] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleFname = (e) => {
    setFname(e.target.value);
  }

  const handlePhone = (e) => {
    setPhone(e.target.value);
  }

  const handleEmail = (e) => {
    setEmail(e.target.value);
  }

  const handleMessage = (e) => {
    setMessage(e.target.value);
  }

  const sendMessage = async () => {
    console.log(fname, phone, email, message);
    setLoading(true);
    const res = await fetch(
      "https://server-olhms.onrender.com/student_get_in_touch/get_in_touch",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fullname: fname,
          phone_no: phone,
          email: email,
          message: message
        })
      }
    );

    const data = await res.json();
    console.log(data?.msg);
    setLoading(false);
    if(data?.msg == 'Success'){
      setFname('');
      setPhone('');
      setEmail('');
      setMessage('');
      alert('Message sent');
    }
  }

  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (document.documentElement.clientWidth <= 800) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="main-contact">
      <div className="contact-comp">
        <p className="portal-first-p no-show-contact1">
          We would love to hear from you
        </p>
      </div>
      <div className="contact-container">
        <p className="portal-first-p no-show-contact">
          We would love to hear from you
        </p>
        <div className="split-contact">
          <div className="contact-form">
            <p>Get in Touch</p>
            <form>
              <div className="form-group-contact">
                <label htmlFor="fullName">Full Name</label>
                <input name="fname"
                  placeholder="Enter Full Name"
                  value={fname}
                  onChange={handleFname}
                />
              </div>
              <div className="form-group-contact">
                <label htmlFor="phoneNo">Phone Number</label>
                <input name="phone"
                  placeholder="Enter Phone No"
                  value={phone}
                  maxLength={11}
                  onChange={handlePhone}
                />
              </div>
              <div className="form-group-contact">
                <label htmlFor="email">Email</label>
                <input name="email"
                  placeholder="Enter Email"
                  value={email}
                  onChange={handleEmail}
                />
              </div>
              <div className="form-group-contact">
                <label htmlFor="message">Your Message</label>
                <textarea name="message"
                  placeholder="Type Message"
                  value={message}
                  onChange={handleMessage}
                />
              </div> 
              <button disabled={loading} onClick={(e)=>{
                  e.preventDefault();
                sendMessage();
              }}>Send Message</button>
            </form>
          </div>
          <div className="contact-media">
            <div>
              <p>
                <strong>Contact Us</strong>
              </p>
              <ul className="contact-links">
                <li className="contact-number-main">
                  +2348151707292, +2348140974206
                </li>
                <li className="contact-message-main">
                  ourladyofhopemontessori@gmail.com
                </li>
                <li className="contact-address-main">
                  Our Lady Of Hope Montessori Schools, (Emerald Centre) 131 2nd East Circular Road by Wisdom Street
                Junction, Benin City. Nigeria.
                  <br />
                  <br />
                  Our Lady Of Montessori Schools,(Topaz Centre) 41, Agbonwonba
                Street off MM way by Ogiso junction, Benin City. Nigeria.
                </li>
              </ul>
            </div>
            <div>
              <p style={{ textAlign: "left", marginTop: 30 }}>
                <strong>Follow Us</strong>
              </p>
              <ul className="contact-links padding-links">
                <li className="contact-instagram">
                  <a
                    href="https://www.instagram.com/ourladyofhope__benin/"
                    style={{
                      color: "black",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                    target="_blank"
                  >
                    @Ourladyofhope_benin
                  </a>
                </li>

                <li className="contact-facebook">
                  <a
                    href="https://web.facebook.com/Ourladyofhopemontessori/?_rdc=1&_rdr"
                    style={{
                      color: "black",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                    target="_blank"
                  >
                    @Our lady of hope montessori schools
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="white-contact"></div>
    </div>
  );
}
