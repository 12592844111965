import * as firebase from 'firebase/app';
import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {getFirestore, FieldValue, serverTimestamp} from "firebase/firestore";
import {getStorage} from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAHNWOQxPSkE7GBmQSqAS-YoQopvQjSE3E",
  authDomain: "olhms-app.firebaseapp.com",
  projectId: "olhms-app",
  storageBucket: "olhms-app.appspot.com",
  messagingSenderId: "504490221263",
  appId: "1:504490221263:web:457b4c6b3e75de99cee1ba",
  measurementId: "G-8VGQ02GYBF"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app)
export {FieldValue, serverTimestamp};