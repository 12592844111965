import React, {useEffect, useState} from "react";
import "./studentnav.css";
import { NavLink, useNavigate, Link, json } from "react-router-dom";
// import { type } from "@testing-library/user-event/dist/type";

export default function StudentNav() {
  const studentToken = localStorage.getItem('student_rubbish');
  const [student, setStudent] = useState({});
  
  // get student data here
  const getStudent = async () => {
    console.log(JSON.parse(studentToken));
    try {
      const res = await fetch(
        "https://server-olhms.onrender.com/student_profile/view_profile",
        {
          method: "POST",
          body: JSON.stringify({
            token: JSON.parse(studentToken)
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const data = await res.json();
      console.log(data);

      if (data.msg == "Success") {
        setStudent(data?.student);
        localStorage.setItem("studentData", JSON.stringify(data?.student));
        localStorage.setItem("img", data?.student.img_url);
        localStorage.setItem("name", data?.student.fullname);
      } else {
        alert("error logging in");
        
      }
    } catch (error) {
      alert("error logging in");
      console.log(error);
    }
  }

  useEffect(()=>{
    //getStudent();
  }, []);
  
  return (
    <div className="student-nav">
      <ul className="student-nav-list">
        <li className="student-list">
          <NavLink to={{
            pathname: "/student/profile"
          }} state={student}>Personal Data</NavLink>
        </li>
        <li className="student-list">
          <NavLink  to={{
            pathname: "/student/result"
          }} state={student}>Result</NavLink>
        </li>
        <li className="student-list">
          <NavLink  to={{
            pathname: "/student/store"
          }} state={student}>School Store</NavLink>
        </li>
      </ul>
    </div>
  );
}

export function AdmissionNav(props) {
  const navigate = useNavigate;
  return (
    <div className="student-nav">
      <ul className="student-nav-list">
        <li
          className="student-list"
          // style={{ fontWeight: "500", fontSize: "16px" }}
          // onClick={props.click1}
        >
          <NavLink
            to={{
              pathname: "/baby",
              state: { type: 0 },
            }}
            style={({ isActive }) => {
              return {
                color: isActive ? "#e05616" : "black",
                // marginBottom: "20px",
              };
            }}
          >
            BABIES AND TODDLERS
          </NavLink>
        </li>
        <li
          className="student-list"
          // style={{ fontWeight: "500", fontSize: "16px" }}
          // onClick={props.click2}
        >
          <NavLink
            to={{
              pathname: "/infant",
              // state: { type: 1 },
            }}
            style={({ isActive }) => {
              return {
                color: isActive ? "#e05616" : "black",
                // marginBottom: "20px",
              };
            }}
          >
            INFANT SCHOOL
          </NavLink>
        </li>
        <li
          className="student-list"
          // style={{ fontWeight: "500", fontSize: "16px" }}
        >
          <NavLink
            to={{
              pathname: "/grade",
              state: { type: 2 },
            }}
            style={({ isActive }) => {
              return {
                color: isActive ? "#e05616" : "black",
                // marginBottom: "20px",
              };
            }}
          >
            GRADE SCHOOL
          </NavLink>
        </li>
      </ul>
    </div>
  );
}
