import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Add from "../../images/addition.png";
import Cap from "../../images/cap.png";
import { Toggle2 } from "../teacherPages/Resources";
import { adminContext } from "../context/adminContext";
import "./store.css";
import Subtract from "../../images/subtract.png";
import Addition from "../../images/add-item.png";
import Delete from "../../images/Delete2.png";

export default function Store() {
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(true);
  const values = useContext(adminContext);
  const { success, admintoken,teachertoken } = values;
  const [triple, setTriple] = useState("first");


  const [storeItems, setStoreItems] = useState([]);
  const [searchedStoreItems, setSearchedStoreItems] = useState([]);
  const [storeRequests, setStoreRequests] = useState([]);

  const [loading, setLoading] = useState(false);

  const [searchString, setSearchString] = useState("");
  const [searchStringStoreRequest, setSearchStringStoreRequest] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageStoreRequests, setCurrentPageStoreRequests] = useState(1);

  const [storeRequestStatus, setStoreRequestStatus] = useState('new');

  const [count, setCount] = useState(0);

  const [stats, setStats] = useState({});

  const viewStoreItems = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_store/get_products",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: success == "teacher" ? JSON.parse(teachertoken) : JSON.parse(admintoken),
          filter: 'all'
        }),
      }
    );
    const data = await res.json();
    setLoading(false);
    setStoreItems(data?.storeItems);
    console.log(data);
  };

  useEffect(() => {
    viewStoreItems();
  }, []);


  const handleSearchChange = e => setSearchString(e.target.value);
  
  // function to search for store items
  const searchStoreItems = async (currPage) => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_store/search_items",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: success == "teacher" ? JSON.parse(teachertoken) : JSON.parse(admintoken),
          search_string: searchString,
          pagec: currPage
        }),
      }
    );
    const data = await res.json();
    setLoading(false);
    setSearchedStoreItems(data?.storeItems);
    console.log(data);
  };

  useEffect(() => {
    if(searchString != ''){
      searchStoreItems(currentPage);
      getStats();
    }
  }, [searchString]);

  // function to fetch store requests
  const viewStoreRequests = async (storeRequestStatus, currPage) => {
      const res = await fetch(
        "https://server-olhms.onrender.com/admin_store_requests/get_store_requests",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: success == "teacher" ? JSON.parse(teachertoken) : JSON.parse(admintoken),
            request_status: storeRequestStatus,
            pagec: currPage
          }),
        }
      );
      const data = await res.json();
      setLoading(false);
      setStoreRequests(data?.storeRequests);
      setCount(data?.count);
      console.log("store requests:: :: ", data);
  }

  useEffect(() => {
    viewStoreRequests(storeRequestStatus, currentPageStoreRequests);
    getStats();
  }, [storeRequestStatus, currentPageStoreRequests]);
  
  // function to fetch searched store requests
  // function to fetch searched store requests
  const handleStoreRequestSearchChange = e => setSearchStringStoreRequest(e.target.value);
  const searchStoreRequests = async (storeRequestStatus, currPage) => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_store_requests/get_store_requests",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: success == "teacher" ? JSON.parse(teachertoken) : JSON.parse(admintoken),
          request_status: storeRequestStatus,
          search_string: searchStringStoreRequest,
          pagec: currPage
        }),
      }
    );
    const data = await res.json();
    setLoading(false);
    setStoreRequests(data?.storeRequests);
    setCount(data?.count);
    console.log(data);
}

useEffect(() => {
  if(searchStringStoreRequest != ''){
    searchStoreRequests(storeRequestStatus, currentPageStoreRequests);
    getStats();
  }
}, [storeRequestStatus, currentPageStoreRequests]);

// function to get total store requests
const getStats = async () => {
  const res = await fetch(
    "https://server-olhms.onrender.com/admin_statistics/view_statistics",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({token: JSON.parse(admintoken)}),
    }
  );

  const data = await res.json();
  setStats(data?.statistics);
  // console.log("statistics: ; :;; :: ", stats);
};

  return (
    <div
      className="set-questions"
      style={{
        backgroundColor: "rgb(255, 248, 248)",
        height: "100%",
        alignItems: "flex-start",
      }}
    >
      <div
        className="set-container"
        style={{
          height: toggle ? "" : "100%",
        }}
      >
        <Toggle2
          click1={() => setToggle(true)}
          click2={() => setToggle(false)}
          toggle={toggle}
          text1="Store"
          text2="Store Requests"
        />
        {toggle ? (
          <div className="search-input">
            <input placeholder="Search Store" name='search'
                  value={searchString}
                  onChange={handleSearchChange}/>
          </div>
        ) : null}

        {toggle ? (
          <div
            className="create-teacher"
            onClick={() =>
              navigate(success == "teacher" ?"/teacher/add-item" :"/admin/add-item", {
                state: {
                  img: "",
                  name: "",
                  price: "",
                  id: "",
                  category: "stationaries",
                },
              })
            }
          >
            <img src={Add} />
          </div>
        ) : null}

        {toggle ? (
          <div className="show-store-items">
            {searchString == '' ? storeItems.map((item, index) => (<StoreItem 
              img_url={item.img_url}
              product_name={item.product_name}
              sell_price={item.sell_price}
              stock_level={item.stock_level}
              click={()=>{
                  navigate(success == "teacher" ?"/teacher/add-item" :"/admin/add-item", {
                  state: {
                    img_url: item.img_url,
                    _id: item._id,
                    product_name: item.product_name,
                    cost_price: item.cost_price,
                    sell_price: item.sell_price,
                    category: item.category,
                    stock_level: item.stock_level,
                    shouldEdit: true
                  },
                });
              }}
            />)) : searchedStoreItems.map((item, index) => (<StoreItem 
              img_url={item.img_url}
              product_name={item.product_name}
              sell_price={item.sell_price}
              stock_level={item.stock_level}
              click={()=>{
                  navigate(success == "teacher" ?"/teacher/add-item" :"/admin/add-item", {
                  state: {
                    img_url: item.img_url,
                    _id: item._id,
                    product_name: item.product_name,
                    cost_price: item.cost_price,
                    sell_price: item.sell_price,
                    category: item.category,
                    stock_level: item.stock_level,
                    shouldEdit: true
                  },
                });
              }}
            />))}
          </div>
        ) : 
        
        (
          <div>

            <div className="split-through">
              <div className="type-of-item">
                <p
                  onClick={() => {
                    setTriple("first");
                    setStoreRequestStatus('new');
                    }}
                  className={triple == "first" ? "active-pay" : "inactive-pay"}
                >
                  New Requests
                </p>
                <p
                  onClick={() => {
                    setTriple("second");
                    setStoreRequestStatus('pending');
                    }}
                  className={triple == "second" ? "active-pay" : "inactive-pay"}
                >
                  Pending Payment & Collection
                </p>
                <p
                  onClick={() => {
                    setTriple("third");
                    setStoreRequestStatus('collected');
                    }}
                  className={triple == "third" ? "active-pay" : "inactive-pay"}
                >
                  Collected Items
                </p>
              </div>
              <div className="search-input">
                <input
                  placeholder="Search Store"
                  style={{ backgroundColor: "#F3F2F2" }}
                  value={searchStringStoreRequest}
                  onChange={handleStoreRequestSearchChange}/>
                
              </div>
              <p>{`All (${storeRequestStatus == 'new' ? stats.no_new_store_requests : storeRequestStatus == 'pending' ? stats.no_pending_store_requests : stats.no_collected_store_requests})`}</p>
            </div>
            <div className="database-table git-table">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Phone Number</th>
                    <th>Email Address</th>
                    <th>Request Date </th>
                  </tr>
                </thead>
                <tbody>
                {
                    storeRequests.map((storeRequest, index) => (
                      <tr
                      key={index}
                    onClick={() =>
                      navigate(success == "teacher" ?"/teacher/view-item" :"/admin/view-item", {
                        state: {
                          type:
                            triple == "first"
                              ? 1
                              : triple == "second"
                              ? 2
                              : triple == "third"
                              ? 3
                              : null,
                          storeRequest
                        },
                      })
                    }
                    style={{
                      cursor: "pointer",
                      borderBottom: "1px solid rgba(10, 10, 10, 0.2)",
                    }}
                  >
                    <td>{storeRequest.student_name}</td>
                    <td>{storeRequest.phone_no}</td>
                    <td>{storeRequest.email}</td>
                    <td>{convertTime(storeRequest.timestamp)}</td>
                  </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
                <div className="pagination-git">
              <div>{`Showing 1 to ${count} of ${storeRequestStatus == 'new' ? stats.no_new_store_requests : storeRequestStatus == 'pending' ? stats.no_pending_store_requests : stats.no_collected_store_requests } Requests`}</div>
              <div className="view-more-git">
                <div onClick={()=>{
                  let cPage = currentPageStoreRequests;
                  if(currentPageStoreRequests >= 2){
                    setCurrentPageStoreRequests(--cPage);

                    if(searchStringStoreRequest == ''){
                      viewStoreRequests(storeRequestStatus, cPage);
                    }else{
                      searchStoreRequests(storeRequestStatus, cPage);
                    }
                    
                  }
                }} className="shade-direction">
                  <i className="fas fa-angle-left"></i>
                </div>
                View more
                <div onClick={()=>{
                  let cPage = currentPageStoreRequests;
                  setCurrentPageStoreRequests(++cPage);
                  
                  if(searchStringStoreRequest == ''){
                    viewStoreRequests(storeRequestStatus, cPage);
                  }else{
                    searchStoreRequests(storeRequestStatus, cPage);
                  }
                }} className="shade-direction">
                  <i className="fas fa-angle-right"></i>
                </div>
              </div>
            </div>
          </div>
        )
        }
      </div>
    </div>
  );
}

export function StoreItem(props) {
  return (
    <div className="store-item" onClick={props.click}>
      <div className="item-image-container">
        <img src={props.img_url} alt="" />
      </div>
      <div className="store-item-details">
        <p className="store-item-name">{props.product_name}</p>
        <p className="store-item-name">{`${props.stock_level} Left`}</p>
        <div className="store-item-price">
          <p>&#8358;{props.sell_price}</p>
        </div>
      </div>
    </div>
  );
}

export function StudentItem(props) {
  let [itemCount, setItemCount] = useState(1);
  return (
    <div className="store-item" onClick={props.click}>
      <div className="item-image-container">
        <img src={props.img_url} alt="" />
      </div>
      <div className="store-item-details">
        <div className="store-item-name-student">
          <p>{props.product_name}</p>
          <p className="increase-order">
            <div onClick={()=>{
              if(itemCount >= 2){setItemCount(--itemCount);}
              
            }} className="change-order">
              <img src={Subtract} alt="" />
            </div>
              <span className="number-ordered">{itemCount}</span>
            <div onClick={()=>{
              setItemCount(++itemCount);
            }} className="change-order">
              <img src={Addition} alt="" />
            </div>
          </p>
        </div>
        <div className="store-item-price">
          <input type={"checkbox"} onChange={e=>{
            console.log(e.target.checked);
            if(e.target.checked == true){
              props.onCheck({
                product_name: props.product_name,
                price: props.sell_price,
                img_url: props.img_url,
                _id: props._id,
                size: '',
                quantity: `${itemCount}`
              });
            }else{
              props.onUncheck(props._id);
            }
          }}/>
          <p className="student-price">&#8358;{props.sell_price}</p>
        </div>
      </div>
    </div>
  );
}

export function StudentSelectedItem(props) {
  let [itemCount, setItemCount] = useState(props.quantity);

  return (
    <div className="store-item" onClick={props.click}>
      <div className="item-image-container">
        <img src={props.img_url} alt="" />
      </div>
      <div className="store-item-details">
        <div className="store-item-name-student">
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <p>{props.product_name}</p>
          {/* <img onClick={()=>{
            props.onUncheck(props._id);
          }} style={{height: 30, width: 30}} src={Delete}></img> */}
          </div>
          <p className="increase-order">
            
              <span className="number-ordered">{itemCount}</span>
            
          </p>
        </div>
        <div style={{color: '#E05615', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
          <p>&#8358;{props.sell_price}</p>
        </div>
      </div>
    </div>
  );
}

export function FinalItem(props) {
  return (
    <div className="store-item" onClick={props.click}>
      <div className="item-image-container">
        <img src={Cap} alt="" />
      </div>
      <div className="store-item-details">
        <div className="store-item-name-student">
          <p className="split-delete-item">
            <span className="delete-item-name">Kiddies Stationary</span>{" "}
            <span className="delete-store-item">
              <img src={Delete} alt="" />
            </span>
          </p>
          <p className="increase-order">
            <div onClick={props.increase} className="change-order">
              <img src={Subtract} alt="" />
            </div>
            <span className="number-ordered">100</span>
            <div onClick={props.decrease} className="change-order">
              <img src={Addition} alt="" />
            </div>
          </p>
        </div>
        {props.size ? (
          <div className="store-item-name-student">
            <p className="delete-item-name">
              Select Size <span className="item-caution">!</span>
            </p>
            <p className="increase-order">
              <div onClick={props.increase} className="change-order">
                <img src={Subtract} alt="" />
              </div>
              <span className="number-ordered">100</span>
              <div onClick={props.decrease} className="change-order">
                <img src={Addition} alt="" />
              </div>
            </p>
          </div>
        ) : null}
        <div className="item-price-admin-2">&#8358;65,000</div>
      </div>
    </div>
  );
}

const convertTime = timestamp => new Date(timestamp).toString().split('GMT')[0];