import React, { useState, useEffect } from "react";
import "./blogtwo.css";
import Blog1 from "../../images/blog-1.png";
import Dyk from "../../images/dyk.png";
import { Pagination } from "../news/NewsTwo";
import { useNavigate } from "react-router-dom";
import { SmallFeature } from "../homepageSections/Feature";
import Featured from "../../videos/featured.mp4";
import Learn1 from "../../videos/learn-1.mp4";
import Learn2 from "../../videos/learn-2.mp4";
import Learn3 from "../../videos/learn-3.mp4";
import Learn4 from "../../videos/learn-4.mp4";
import { truncateString } from "../adminPages/Vlogs";

export default function BlogTwo() {
  const videos = [Learn1, Learn2, Learn3, Learn4, Learn1];
  const videosI = [Learn1, Learn2, Learn3, Learn4, Learn1, Learn2];
  const [blogs, setBlogs] = useState([]);
  const [searchedBlogs, setSearchedBlogs] = useState([]);
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(true);

  const viewBlogs = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_blog/view_blogs",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(),
      }
    );

    const data = await res.json();
    setLoading(false);
    setBlogs(data?.blogs);
    console.log(data);
  };

  const viewLearning = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_video_learning/view_video_learnings",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pagec: 1 }),
      }
    );
    const data = await res.json();
    // setLoading(false);
    setResources(data?.video_learnings);
    console.log(data);
  };
  useEffect(() => {
    viewBlogs();
    viewLearning();
  }, []);

  const handleSearchChange = (e) => setSearchString(e.target.value);
  const [searchString, setSearchString] = useState("");

  // function to search for store items
  const searchBlogPosts = async (currPage) => {
    const res = await fetch("http://localhost:5000/student_blog/search_blogs", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        search_string: searchString,
        pagec: currPage,
      }),
    });
    const data = await res.json();
    setLoading(false);
    setSearchedBlogs(data?.blogs);
  };

  useEffect(() => {
    if (searchString != "") {
      searchBlogPosts(1);
    }
  }, [searchString]);

  return (
    <div className="blog-2">
      <div className="blog-pagination">
        <div className="blog-2-content">
          <div className="blog-search">
            <input
              placeholder="Search Blog Stories"
              name="search"
              value={searchString}
              onChange={handleSearchChange}
            />
          </div>
          <div className="blog-read-flex">
            <div className="blog-read-flex-grid">
              {searchString == ""
                ? blogs
                    ?.slice(0, 4)
                    .map((item, i) => (
                      <BlogComponent
                        header={truncateString(item?.title, 36)}
                        x={i + 1}
                        key={i}
                        text={item?.body}
                        img={item?.img_url}
                        timestamp={item?.timestamp}
                        view_count={item?.view_count}
                        comment_count={item?.comment_count}
                        like_count={item?.like_count}
                        blog_id={item?._id}
                      />
                    ))
                : searchedBlogs.map((item, i) => (
                    <BlogComponent
                      header={truncateString(item?.title, 36)}
                      x={i + 1}
                      key={i}
                      text={item?.body}
                      img={item?.img_url}
                      timestamp={item?.timestamp}
                      view_count={item?.view_count}
                      comment_count={item?.comment_count}
                      like_count={item?.like_count}
                      blog_id={item?._id}
                    />
                  ))}
            </div>
            <div className="blog-read-column">
              {blogs?.slice(0, 3).map((item, i) => (
                <BlogColumn
                  title={truncateString(item?.title, 36)}
                  details={truncateString(item?.body, 100)}
                  img={item?.img_url}
                  timestamp={item?.timestamp}
                  view_count={item?.view_count}
                  comment_count={item?.comment_count}
                  like_count={item?.like_count}
                  blog_id={item?._id}
                />
              ))}
            </div>
          </div>

          <div className="popular-grid-container">
            <p className="popular-grid-p">
              <strong>Popular Reads</strong>
            </p>
            <div className="popular-grid">
              {blogs?.slice(0, 8).map((item, i) => (
                <PopularReads
                  x={i + 1}
                  title={item?.title}
                  details={item?.body}
                  img={item?.img_url}
                  timestamp={item?.timestamp}
                  view_count={item?.view_count}
                  comment_count={item?.comment_count}
                  like_count={item?.like_count}
                  blog_id={item?._id}
                />
              ))}
            </div>
          </div>
          <div className="blog-featured">
            <p className="popular-grid-p">
              <strong>Video Learning Series</strong>
            </p>
            <div className="split-blog-featured">
              <div>
                <div className="second_feature">
                  <div className="main-featured-vid">
                    <video
                      poster="https://res.cloudinary.com/dxcguwdyh/image/upload/v1686649236/temp_thumbs/Screenshot_2023-06-13_001049_x57wap.jpg"
                      width="370"
                      height="360"
                      src={Featured}
                      autoPlay={false}
                      controls
                    ></video>
                    <div className="absolute-feature">Featured Video</div>
                  </div>
                  <div className="split-learn">
                    <div>Most Viewed</div>
                  </div>

                  <div className="feature-small">
                    {resources?.slice(0, 5).map((vid, index) => (
                      <SmallFeature
                        src={vid?.video_url}
                        poster_url={vid?.img_url}
                        key={index}
                        details={vid?.summary}
                        // details={truncateString(vid?.summary, 100)}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="blog-featured-container">
                {resources?.map((vid, index) => (
                  <FeatureBlog
                    poster_url={vid?.img_url}
                    src={vid?.video_url}
                    key={index}
                    x={index + 1}
                    text={vid?.summary}
                    // text={truncateString(vid?.summary, 100)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <Pagination />
      </div>
    </div>
  );
}

export function FeatureBlog(props) {
  return (
    <div className={`feature-text-blog grid-blog${props.x}`}>
      <div className="feature-text-container">
        <video
          width="100%"
          height="100%"
          src={props.src}
          controls
          autoPlay={false}
          poster={props.poster_url}
        ></video>
      </div>
      <p>{props.text}</p>
    </div>
  );
}

export function BlogComponent(props) {
  const navigate = useNavigate();
  return (
    <div
      className={`blog-two-read main-blog${props.x}`}
      onClick={() =>
        navigate("/blog-content", {
          state: {
            img: props.img,
            title: props.header,
            body: props.text,
            timestamp: props.timestamp,
            view_count: props.view_count,
            comment_count: props.comment_count,
            like_count: props.like_count,
            blog_id: props.blog_id,
          },
        })
      }
    >
      <div className="blog-two-read-img">
        <img src={props.img} alt="" />
      </div>
      <div className="blog-two-read-text">
        <p>
          <strong>{props.header}</strong>
        </p>
        <p className="split-comments-like">
          <span className="show-days-ago">{`${timeAgo(props.timestamp)}, ${
            props.view_count
          } views`}</span>
          <span>
            {`${props.like_count}`}
            <span className="heart-emoji">&#x2764;</span>{" "}
            {`${props.comment_count}`}{" "}
            <i class="fas fa-comment" style={{ color: "grey" }}></i>
          </span>
        </p>
        <p className="blog-faint">{truncateString(props.text, 100)}</p>
        <button className="blog-faint-button">Read article</button>
      </div>
    </div>
  );
}

export function BlogColumn(props) {
  const navigate = useNavigate();
  return (
    <div
      className="blog-read-div"
      onClick={() => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
        navigate("/blog-content", {
          state: {
            img: props.img,
            title: props.header,
            body: props.body,
            timestamp: props.timestamp,
            view_count: props.view_count,
            comment_count: props.comment_count,
            like_count: props.like_count,
            blog_id: props.blog_id,
          },
        });
      }}
    >
      <p>
        <strong>{props.title}</strong>
      </p>
      <p className="blog-read-column-p">{props.details}</p>
      <span className="read-in">Read in 2 minutes</span>
    </div>
  );
}

export function PopularReads(props) {
  const navigate = useNavigate();
  return (
    <div
      className={`popular-reads-blog popular${props.x}`}
      onClick={() => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);

        navigate("/blog-content", {
          state: {
            img: props.img,
            title: props.title,
            body: props.details,
            timestamp: props.timestamp,
            view_count: props.view_count,
            comment_count: props.comment_count,
            like_count: props.like_count,
            blog_id: props.blog_id,
          },
        });
      }}
    >
      <div className="popular-reads-img">
        <img src={props.img} alt="" style={{ objectFit: "cover" }} />
      </div>
      <div className="popular-read-text">
        <p>
          <strong>{truncateString(props.title, 30)}</strong>
        </p>
        <p className="split-comments-like">
          <span className="show-days-ago">{`${timeAgo(props.timestamp)}, ${
            props.view_count
          } views`}</span>
          <span>
            {`${props.like_count}`}
            <span className="heart-emoji">&#x2764;</span>{" "}
            {`${props.comment_count}`}{" "}
            <i class="fas fa-comment" style={{ color: "grey" }}></i>
          </span>
        </p>
        <p className="read-faint">{truncateString(props.details, 60)}</p>
        <span className="read-in">Read in 2 minutes</span>
      </div>
    </div>
  );
}

export const timeAgo = (timestamp) => {
  let date = new Date(timestamp);
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = Math.floor(seconds / 31536000);
  if (interval > 1) {
    return interval + " years ago";
  }

  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + " months ago";
  }

  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + " days ago";
  }

  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + " hours ago";
  }

  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + " minutes ago";
  }

  if (seconds < 10) return "just now";

  return Math.floor(seconds) + " seconds ago";
};
