import React, { useEffect, useState } from "react";
import img from "../../images/content-img.png";
import "./read.css";
import NewsOne from "../../images/news1.png";
import { EventComponent } from "../homepageSections/SectionSix";
import Calendar from "../../images/Calendar.png";
import Shola2 from "../../images/shola2.png";
import { useNavigate } from "react-router-dom";
import { NewsComponent } from "./NewsTwo";
import { truncateString } from "../adminPages/Vlogs";
import { SmallFeature } from "../homepageSections/Feature";
import Featured from "../../videos/featured.mp4";
import Learn1 from "../../videos/learn-1.mp4";
import Learn2 from "../../videos/learn-2.mp4";
import Learn3 from "../../videos/learn-3.mp4";
import Learn4 from "../../videos/learn-4.mp4";
import { Toaster, toast } from "react-hot-toast"; // Import the Toaster component

import { useLocation } from "react-router-dom";

export default function Readnews() {

  const {state} = useLocation()

  const [news, setNews] = useState([]);
  const [events, setEvents] = useState([]);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const videos = [Learn1, Learn2, Learn3, Learn4, Learn1];
  const [selectedMonth, setSelectedMonth] = useState(
    new Date().toLocaleString("default", { month: "long" })
  );
  const [resources, setResources] = useState([]);

  const viewEvents = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_events/view_events",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        // body: JSON.stringify({ token: JSON.parse(admintoken) }),
      }
    );
    const data = await res.json();
    setLoading(false);
    console.log(data);
    const allNews = data?.events;
    // setPendingDeliveries(results?.deliveries);
    let news = [];
    let events = [];
    let monthEvents = [];

    allNews.forEach((item) =>
      item.category === "news"
        ? news.push(item)
        : item.category === "events"
        ? events.push(item)
        : null
    );
    allNews.forEach((item) =>
      item.category === "events" &&
      item.month === new Date().toLocaleString("default", { month: "long" })
        ? monthEvents.push(item)
        : null
    );

    setNews(
      news
        .slice()
        .reverse()
        .map((item) => {
          return item;
        })
    );
    setEvents(events);
    setCurrentEvents(monthEvents);
  };

  const viewLearning = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_video_learning/view_video_learnings",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pagec: 1 }),
      }
    );
    const data = await res.json();
    // setLoading(false);
    setResources(data?.video_learnings);
    console.log(data);
  };

  // endpoint to view a news post so that the view count
  // can increase

  const [view_count, setViewCount] = useState(state.view_count);
  const viewAndIncreaseCountEvent = async () => {
    console.log(state.event_id);
    const res = await fetch(
      "https://server-olhms.onrender.com/guardian_news/view_single_news",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ news_id: state.event_id }),
      }
    );
    const data = await res.json();
    let nNews = data?.news;
    setViewCount(nNews.view_count);
    //console.log("event:  ::: ", nEvent);
  };

  useEffect(()=>{
    viewEvents();
  }, []);

  useEffect(() => {
    viewLearning();
  }, []);

  useEffect(()=>{
    viewAndIncreaseCountEvent();
  }, []);

  const [email, setEmail] = useState('');

  const handleNewsLetterChange = (e) => {
    setEmail(e.target.value);
  }

  const subscribe = async ()=> {
    toast.error("Please wait while we sign you up for our newsletter", {
      icon: "⏳",
      position: "bottom-center",
    });
    setLoading(true);
    const res = await fetch(
      "https://server-olhms.onrender.com/guardian_newsletter/subscribe_newsletter",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email
        })
      }
    );

    const data = await res.json();
    if(data?.msg == 'success'){
      alert('Successful Subscription');
      setEmail('');
      setLoading(false);
    }else{
      alert(data);
      setLoading(false);
    }
  }

  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (document.documentElement.clientWidth <= 800) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="news-read">
      <div className="content-image">
        <img src={state.img} alt="" />
      </div>
      <div className="news-two-wrapper">
        <div className="news-section-one">
          <p className="news-section-one-p">
            <strong>{state.title}</strong>
            <span>{`${state.date}, ${state.time}, ${view_count} views`}</span>
          </p>
          <div className="real-content-news">
            {state.details}
          </div>

          <p className="more-stories">More Stories</p>

          <div className="news-content-container-flex" style={mobile ? {display: 'flex', flexDirection: 'column', alignItems: 'center'}: {}}>
            {/* {news?.reverse().map((item, i) => ( */}
            {news?.map((item, i) => (
              
              <NewsComponent
                img={item.img_url}
                key={item._id}
                title={truncateString(item.event_name, 1000)}
                details={truncateString(item.description, 6000)}
                date={item.event_date}
                time={item.event_time}
                view_count={item.view_count}
                event_id={item._id}
              />
            ))}
          </div>
        </div>
        <div className="news-section-two">
          <div className="subscribe-section">
            <div className="news-section-two-p">
              <p>
                <strong>Never miss an Update</strong>
              </p>
              <p className="light-letter">Subscribe to our News letter</p>
            </div>
            <div className="news-input-section">
              <input
                placeholder="Enter your email address"
                className="news-input"
                name='newsletter'
                value={email}
                onChange={handleNewsLetterChange}
                />
                <button disabled={loading} onClick={(e)=>{
                e.preventDefault();
                if (email === "") {
                    toast.error("Please add an email address", {
                      icon: "❗️",
                      position: "bottom-center",
                    });
                  } else {
                    subscribe();
                  }

              }}>Subscribe</button>
            </div>
          </div>

          <div className="big-events-section">
            <p className="big-events">
              <strong>Upcoming Events- {new Date().getFullYear()}</strong>
            </p>
            <div className="news-calendar">
              <div
                className="months-year"
                onClick={() => setSelectedMonth("January")}
                style={{
                  backgroundColor:
                    selectedMonth == "January"
                      ? "rgba(196, 80, 25, 0.12)"
                      : "#673401",
                  color: selectedMonth == "January" ? "#673401" : "white",
                }}
              >
                January
              </div>
              <div
                className="months-year"
                onClick={() => setSelectedMonth("February")}
                style={{
                  backgroundColor:
                    selectedMonth == "February"
                      ? "rgba(196, 80, 25, 0.12)"
                      : "#673401",
                  color: selectedMonth == "February" ? "#673401" : "white",
                }}
              >
                February
              </div>
              <div
                className="months-year"
                onClick={() => setSelectedMonth("March")}
                style={{
                  backgroundColor:
                    selectedMonth == "March"
                      ? "rgba(196, 80, 25, 0.12)"
                      : "#673401",
                  color: selectedMonth == "March" ? "#673401" : "white",
                }}
              >
                March
              </div>
              <div
                className="months-year"
                onClick={() => setSelectedMonth("April")}
                style={{
                  backgroundColor:
                    selectedMonth == "April"
                      ? "rgba(196, 80, 25, 0.12)"
                      : "#673401",
                  color: selectedMonth == "April" ? "#673401" : "white",
                }}
              >
                April
              </div>
              <div
                className="months-year"
                onClick={() => setSelectedMonth("May")}
                style={{
                  backgroundColor:
                    selectedMonth == "May"
                      ? "rgba(196, 80, 25, 0.12)"
                      : "#673401",
                  color: selectedMonth == "May" ? "#673401" : "white",
                }}
              >
                May
              </div>
              <div
                className="months-year"
                onClick={() => setSelectedMonth("June")}
                style={{
                  backgroundColor:
                    selectedMonth == "June"
                      ? "rgba(196, 80, 25, 0.12)"
                      : "#673401",
                  color: selectedMonth == "June" ? "#673401" : "white",
                }}
              >
                June
              </div>
              <div
                className="months-year"
                onClick={() => setSelectedMonth("July")}
                style={{
                  backgroundColor:
                    selectedMonth == "July"
                      ? "rgba(196, 80, 25, 0.12)"
                      : "#673401",
                  color: selectedMonth == "July" ? "#673401" : "white",
                }}
              >
                July
              </div>
              <div
                className="months-year"
                onClick={() => setSelectedMonth("August")}
                style={{
                  backgroundColor:
                    selectedMonth == "August"
                      ? "rgba(196, 80, 25, 0.12)"
                      : "#673401",
                  color: selectedMonth == "August" ? "#673401" : "white",
                }}
              >
                August
              </div>
              <div
                className="months-year"
                onClick={() => setSelectedMonth("September")}
                style={{
                  backgroundColor:
                    selectedMonth == "September"
                      ? "rgba(196, 80, 25, 0.12)"
                      : "#673401",
                  color: selectedMonth == "September" ? "#673401" : "white",
                }}
              >
                September
              </div>
              <div
                className="months-year"
                onClick={() => setSelectedMonth("October")}
                style={{
                  backgroundColor:
                    selectedMonth == "October"
                      ? "rgba(196, 80, 25, 0.12)"
                      : "#673401",
                  color: selectedMonth == "October" ? "#673401" : "white",
                }}
              >
                October
              </div>
              <div
                className="months-year"
                onClick={() => setSelectedMonth("November")}
                style={{
                  backgroundColor:
                    selectedMonth == "November"
                      ? "rgba(196, 80, 25, 0.12)"
                      : "#673401",
                  color: selectedMonth == "November" ? "#673401" : "white",
                }}
              >
                November
              </div>
              <div
                className="months-year"
                onClick={() => setSelectedMonth("December")}
                style={{
                  backgroundColor:
                    selectedMonth == "December"
                      ? "rgba(196, 80, 25, 0.12)"
                      : "#673401",
                  color: selectedMonth == "December" ? "#673401" : "white",
                }}
              >
                December
              </div>
            </div>
            <div className="event-flex">
              {events &&
              events.filter((item) => item.month === selectedMonth).length >
                0 ? (
                events
                  ?.filter((item) => item.month === selectedMonth)
                  .map((item, i) => (
                    <EventComponent
                      month={item.month}
                      title={truncateString(item.event_name, 36)}
                      details={truncateString(item.description, 50)}
                      time={item.event_time}
                      day={item.event_date.slice(0, 2)}
                    />
                  ))
              ) : (
                <p>No events yet for {selectedMonth}</p>
              )}
            </div>
          </div>

          <div className="shola-section">
            <img src={Shola2} alt="Dr. Sola"/>
            <div className="shola-section-text">
              <div className="shola-wrapper">
                <p className="shola-italic">
                  “Our confidence, class, and style makes us exceptionally
                  different.”
                </p>
                <p className="shola-normal">-Dr. (Mrs) Sola Okunkpolor</p>
              </div>
            </div>
          </div>

          <div>
            <div className="second_feature">
              <div className="main-featured-vid">
                <video
                poster="https://res.cloudinary.com/dxcguwdyh/image/upload/v1686649236/temp_thumbs/Screenshot_2023-06-13_001049_x57wap.jpg"
                  width="370"
                  height="360"
                  src={Featured}
                  autoPlay={false}
                  controls
                ></video>
                <div className="absolute-feature">Featured Video</div>
              </div>
              <div className="split-learn">
                <div className="vid-learn">Video Learning Series</div>
                <p>View all...</p>
              </div>

              <div className="feature-small">
                {resources?.slice(0, 5).map((vid, index) => (
                  <SmallFeature
                    src={vid?.video_url}
                    key={index}
                    details={truncateString(vid?.summary, 100)}
                    poster_url={vid?.img_url}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
