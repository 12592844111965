import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import { LoggedNavBar } from "../components/NavBar/NavBar";
import NameComponent from "../components/Student/NameComponent";
import StudentNav from "../components/Student/StudentNav";

export default function Logged() {
  return (
    <div>
      <LoggedNavBar />
      <NameComponent />
      <StudentNav />
      <Outlet />
      <Footer />
    </div>
  );
}