import React, { useEffect, useState } from "react";
import "./newstwo.css";
import NewsOne from "../../images/news1.png";
import { EventComponent } from "../homepageSections/SectionSix";
import Calendar from "../../images/Calendar.png";
import Shola2 from "../../images/shola2.png";
import { truncateString } from "../adminPages/Vlogs";
import { SmallFeature } from "../homepageSections/Feature";
import Featured from "../../videos/featured.mp4";
import Learn1 from "../../videos/learn-1.mp4";
import Learn2 from "../../videos/learn-2.mp4";
import Learn3 from "../../videos/learn-3.mp4";
import Learn4 from "../../videos/learn-4.mp4";
import { Toaster, toast } from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";

export default function NewsTwo() {
  const navigate = useNavigate();
  const [resources, setResources] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total_news_docs, set_total_news_docs] = useState(1);

  const viewLearning = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_video_learning/view_video_learnings",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pagec: 1 }),
      }
    );
    const data = await res.json();
    // setLoading(false);
    setResources(data?.video_learnings);
    console.log(data);
  };

  const [news, setNews] = useState([]);
  const [events, setEvents] = useState([]);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const videos = [Learn1, Learn2, Learn3, Learn4, Learn1];
  const [selectedMonth, setSelectedMonth] = useState(
    new Date().toLocaleString("default", { month: "long" })
  );

  const viewNews = async (currPage) => {
    console.log(currPage);
    const res = await fetch(
      "https://server-olhms.onrender.com/guardian_news/view_multiple_news",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pagec: currPage }),
      }
    );
    const data = await res.json();
    let nNews;
    if (data?.count == 0) {
      console.log("here");
      nNews = [];
      setNews([]);
    } else {
      console.log("---> gotten news list");
      nNews = data?.news;
      setNews(
        nNews
          // .reverse()
          .slice()
          .map((item) => {
            return item;
          })
      );
      set_total_news_docs(data?.total_length);
    }
  }; 

  const viewEvents = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_events/view_events",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();
    setLoading(false);
    console.log(data);
    const allNews = data?.events;
    // setPendingDeliveries(results?.deliveries);
    let news = [];
    let events = [];
    let monthEvents = [];

    allNews.forEach((item) =>
      item.category === "news"
        ? news.push(item)
        : item.category === "events"
        ? events.push(item)
        : null
    );
    allNews.forEach((item) =>
      item.category === "events" &&
      item.month === new Date().toLocaleString("default", { month: "long" })
        ? monthEvents.push(item)
        : null
    );

    setEvents(events);
    setCurrentEvents(monthEvents);
  };

  useEffect(() => {
    viewEvents();
    viewLearning();
  }, []);

  useEffect(() => {
    viewNews(currentPage);
  }, [currentPage]);

  const [email, setEmail] = useState("");

  const handleNewsLetterChange = (e) => {
    setEmail(e.target.value);
  };

  const subscribe = async () => {
    toast.error("Please wait while we sign you up for our newsletter", {
      icon: "⏳",
      position: "bottom-center",
    });
    setLoading(true);
    const res = await fetch(
      "https://server-olhms.onrender.com/guardian_newsletter/subscribe_newsletter",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
        }),
      }
    );

    const data = await res.json();
    if (data?.msg == "success") {
      alert("Successful Subscription");
      setEmail("");
      setLoading(false);
    } else {
      alert(data);
      setLoading(false);
    }
  };

  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (document.documentElement.clientWidth <= 800) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="news-two">
      <div className="blog-pagination">
        <div
          className="news-two-wrapper"
          style={mobile ? { display: "flex", flexDirection: "column" } : {}}
        >
          <div className="news-section-one">
            <p className="news-section-one-p">
              <strong>Our Latest News and Events Updates</strong>
            </p>
            <div className="news-content-container-flex">
              {news?.map((item, i) => (
                <NewsComponent
                  img={item.img_url}
                  key={item._id}
                  title={truncateString(item.event_name, 1000)}
                  details={item.description}
                  date={item.event_date}
                  time={item.event_time}
                  view_count={item.view_count}
                  event_id={item._id}
                />
              ))}
              {/* <NewsComponent
                img={NewsOne}
                // key={item._id}
                title={truncateString(
                  "5th Grade School Graduation Ceremony",
                  36
                )}
                details={truncateString(
                  "Our Lady of Hope Montessori group of Schools, graduated 29 outstanding music scholars on Tuesday, May 5, 2022, after an impactful two-year music programme. Speaking at the graduation ceremony, Executive Secretary, MTN Foundation, Odunayo Sanya said, “We are dedicated to fostering and celebrating.........",
                  100
                )}
                date="9 days ago"
                time="2.5M views"
              /> */}
            </div>
          </div>
          <div
            className="news-section-two"
            style={mobile ? { marginTop: 0 } : {}}
          >
            <div className="subscribe-section">
              <div className="news-section-two-p">
                <p>
                  <strong>Never miss an Update</strong>
                </p>
                <p className="light-letter">Subscribe to our News letter</p>
              </div>
              <div className="news-input-section">
                <input
                  placeholder="Enter your email address"
                  className="news-input"
                  name="newsletter"
                  value={email}
                  onChange={handleNewsLetterChange}
                />
                <button
                  disabled={loading}
                  onClick={(e) => {
                    e.preventDefault();
                    if (email === "") {
                      toast.error("Please add an email address", {
                        icon: "❗️",
                        position: "bottom-center",
                      });
                    } else {
                      subscribe();
                    }
                  }}
                >
                  Subscribe
                </button>
              </div>
            </div>

            <div className="big-events-section">
              <p className="big-events">
                <strong>Upcoming Events- {new Date().getFullYear()}</strong>
              </p>
              <div className="news-calendar">
                <div
                  className="months-year"
                  onClick={() => setSelectedMonth("January")}
                  style={{
                    backgroundColor:
                      selectedMonth == "January"
                        ? "rgba(196, 80, 25, 0.12)"
                        : "#673401",
                    color: selectedMonth == "January" ? "#673401" : "white",
                  }}
                >
                  January
                </div>
                <div
                  className="months-year"
                  onClick={() => setSelectedMonth("February")}
                  style={{
                    backgroundColor:
                      selectedMonth == "February"
                        ? "rgba(196, 80, 25, 0.12)"
                        : "#673401",
                    color: selectedMonth == "February" ? "#673401" : "white",
                  }}
                >
                  February
                </div>
                <div
                  className="months-year"
                  onClick={() => setSelectedMonth("March")}
                  style={{
                    backgroundColor:
                      selectedMonth == "March"
                        ? "rgba(196, 80, 25, 0.12)"
                        : "#673401",
                    color: selectedMonth == "March" ? "#673401" : "white",
                  }}
                >
                  March
                </div>
                <div
                  className="months-year"
                  onClick={() => setSelectedMonth("April")}
                  style={{
                    backgroundColor:
                      selectedMonth == "April"
                        ? "rgba(196, 80, 25, 0.12)"
                        : "#673401",
                    color: selectedMonth == "April" ? "#673401" : "white",
                  }}
                >
                  April
                </div>
                <div
                  className="months-year"
                  onClick={() => setSelectedMonth("May")}
                  style={{
                    backgroundColor:
                      selectedMonth == "May"
                        ? "rgba(196, 80, 25, 0.12)"
                        : "#673401",
                    color: selectedMonth == "May" ? "#673401" : "white",
                  }}
                >
                  May
                </div>
                <div
                  className="months-year"
                  onClick={() => setSelectedMonth("June")}
                  style={{
                    backgroundColor:
                      selectedMonth == "June"
                        ? "rgba(196, 80, 25, 0.12)"
                        : "#673401",
                    color: selectedMonth == "June" ? "#673401" : "white",
                  }}
                >
                  June
                </div>
                <div
                  className="months-year"
                  onClick={() => setSelectedMonth("July")}
                  style={{
                    backgroundColor:
                      selectedMonth == "July"
                        ? "rgba(196, 80, 25, 0.12)"
                        : "#673401",
                    color: selectedMonth == "July" ? "#673401" : "white",
                  }}
                >
                  July
                </div>
                <div
                  className="months-year"
                  onClick={() => setSelectedMonth("August")}
                  style={{
                    backgroundColor:
                      selectedMonth == "August"
                        ? "rgba(196, 80, 25, 0.12)"
                        : "#673401",
                    color: selectedMonth == "August" ? "#673401" : "white",
                  }}
                >
                  August
                </div>
                <div
                  className="months-year"
                  onClick={() => setSelectedMonth("September")}
                  style={{
                    backgroundColor:
                      selectedMonth == "September"
                        ? "rgba(196, 80, 25, 0.12)"
                        : "#673401",
                    color: selectedMonth == "September" ? "#673401" : "white",
                  }}
                >
                  September
                </div>
                <div
                  className="months-year"
                  onClick={() => setSelectedMonth("October")}
                  style={{
                    backgroundColor:
                      selectedMonth == "October"
                        ? "rgba(196, 80, 25, 0.12)"
                        : "#673401",
                    color: selectedMonth == "October" ? "#673401" : "white",
                  }}
                >
                  October
                </div>
                <div
                  className="months-year"
                  onClick={() => setSelectedMonth("November")}
                  style={{
                    backgroundColor:
                      selectedMonth == "November"
                        ? "rgba(196, 80, 25, 0.12)"
                        : "#673401",
                    color: selectedMonth == "November" ? "#673401" : "white",
                  }}
                >
                  November
                </div>
                <div
                  className="months-year"
                  onClick={() => setSelectedMonth("December")}
                  style={{
                    backgroundColor:
                      selectedMonth == "December"
                        ? "rgba(196, 80, 25, 0.12)"
                        : "#673401",
                    color: selectedMonth == "December" ? "#673401" : "white",
                  }}
                >
                  December
                </div>
              </div>
              <div className="event-flex">
                {events &&
                events.filter((item) => item.month === selectedMonth).length >
                  0 ? (
                  events
                    ?.filter((item) => item.month === selectedMonth)
                    .map((item, i) => (
                      <EventComponent
                        month={item.month}
                        title={truncateString(item.event_name, 36)}
                        details={truncateString(item.description, 50)}
                        time={item.event_time}
                        day={item.event_date.slice(0, 2)}
                        event_id={item._id}
                      />
                    ))
                ) : (
                  <p>
                    <strong>No events yet for {selectedMonth}</strong>
                  </p>
                )}
              </div>
            </div>

            <div className="shola-section">
              <img src={Shola2} />
              <div className="shola-section-text">
                <div className="shola-wrapper">
                  <p className="shola-italic">
                    “Our confidence, class, and style makes us exceptionally
                    different.”
                  </p>
                  <p className="shola-normal">-Dr. (Mrs) Sola Okunkpolor</p>
                </div>
              </div>
            </div>

            {/* <div> */}
            <div className="second_feature">
              <div className="news-featured-vid">
                <video
                  // width="370"
                  // height="360"
                  poster="https://res.cloudinary.com/dxcguwdyh/image/upload/v1686649236/temp_thumbs/Screenshot_2023-06-13_001049_x57wap.jpg"
                  src={Featured}
                  autoPlay={false}
                  controls
                ></video>
                <div className="absolute-feature">Featured Video</div>
              </div>
              <div className="split-learn">
                <div className="vid-learn">Video Learning Series</div>

                <p
                  onClick={() =>
                    navigate("/blog", { state: { view_all: true } })
                  }
                >
                  View all...
                </p>
              </div>

              <div className="feature-small">
                {resources?.slice(0, 5).map((vid, index) => (
                  <SmallFeature
                    src={vid?.video_url}
                    key={index}
                    details={truncateString(vid?.summary, 100)}
                    poster_url={vid?.img_url}
                  />
                ))}
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
        <Pagination
          gotoPage={(page) => {
            setCurrentPage(page);
          }}
          currentPage={currentPage}
          viewNews={viewNews}
          totalNewsItems={total_news_docs}
        />
      </div>
    </div>
  );
}

export function NewsComponent(props) {
  const navigate = useNavigate();

  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (document.documentElement.clientWidth <= 800) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    // <div onClick={() => navigate("/news-content", {state: props})} className="news-content-container" style={mobile ? {display: "flex", flexDirection: 'column', height: 550} : {}}>
    <div
      onClick={() => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
        navigate("/news-content", { state: props });
      }}
      className="news-content-container"
      style={
        mobile ? { display: "flex", flexDirection: "column", height: 550 } : {}
      }
    >
      <div
        className="news-imgs"
        style={mobile ? { marginRight: 0, height: 200, width: 350 } : {}}
      >
        <img src={props.img} alt="" />
      </div>
      <div className="news-content">
        <div
          className="news-content-flex"
          onClick={() => navigate("/news-content", { state: props })}
        >
          <p className="news-content-p">{props.title}</p>
          <p className="medium-font">
            {props.date}, {props.time}, {props.view_count} views
          </p>
          {/* <button>News</button> */}
        </div>
        <p className="small-font">
          {truncateString(props.details, 400)}
          <span
            className="read-news"
            onClick={() => navigate("/news-content", { state: props })}
          >
            read more
          </span>
        </p>
      </div>
    </div>
  );
}

export function Pagination({
  gotoPage,
  currentPage,
  viewNews,
  totalNewsItems,
}) {
  function scroll_after_tapping_on_pagination() {
    var screenHeight = window.innerHeight; // Get the height of the viewport
    var scrollYPosition = screenHeight * 0.45; // Scroll to 50% of the screen height

    window.scrollTo(0, scrollYPosition);
  }

  const itemsPerPage = 6;

  const generatePaginationNumbers = () => {
    const numberOfPages = Math.ceil(totalNewsItems / itemsPerPage);
    const paginationNumbers = [];
    for (let i = 1; i <= numberOfPages; i++) {
      paginationNumbers.push(i);
    }
    return paginationNumbers;
  };

  const renderPaginationNumbers = () => {
    const paginationNumbers = generatePaginationNumbers();
    return (
      <ul className="pagination" style={{ listStyleType: "none" }}>
        {paginationNumbers.map((pageNumber) => (
          <li key={pageNumber}>
            <button
              //  className={currentPage === pageNumber ? "active-span" : ""}

              style={{
                padding: "4px 10px",
                borderRadius: "12px",
                backgroundColor:
                  currentPage === pageNumber ? "#e05615" : "#f4f4f4",
                color: currentPage === pageNumber ? "white" : "black",
              }}
              onClick={() => {
                gotoPage(pageNumber);
                viewNews(pageNumber);
              }}
            >
              {pageNumber}
            </button>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="pagination">
      <button
        onClick={() => {
          if (currentPage >= 2) {
            gotoPage(--currentPage);
            viewNews(currentPage);
          }
          scroll_after_tapping_on_pagination();
        }}
      >
        <i className="fas fa-angle-left"></i> Previous
      </button>

      {/* <span onClick={()=>{gotoPage(1); viewNews(currentPage);}} className={currentPage == 1 ? "active-span" : ""}>1 </span> */}
      {/* <span className={currentPage == 1 ? "active-span" : ""}>1 </span>
      <span className={currentPage == 2 ? "active-span" : ""}>2</span>
      <span className={currentPage == 3 ? "active-span" : ""}>3</span>
      <span className={currentPage == 4 ? "active-span" : ""}>4</span>
      <span className={currentPage == 5 ? "active-span" : ""}>5</span>
      <span className={currentPage > 5 ? "active-span" : ""}>...</span> */}

      <div>{renderPaginationNumbers()}</div>

      <button
        onClick={() => {
          // check if currentPage is the last

          const pagination_numbers = generatePaginationNumbers();

          if (currentPage < pagination_numbers[pagination_numbers.length - 1]) {
            gotoPage(++currentPage);
            viewNews(currentPage);

            scroll_after_tapping_on_pagination();
          }
        }}
      >
        Next <i className="fas fa-angle-right"></i>
      </button>
    </div>
  );
}
