import React from "react";
import { useState, useContext, useEffect } from "react";
import { adminContext } from "../context/adminContext";
import { BackButton } from "../studentPages/PersonalData";
import { useNavigate, useLocation } from "react-router-dom";
import dark from "../../images/dark.png";
import "./addgallery.css";
import axios from "axios";
import qs from "qs";
import Popup from "../../components/Popup/Popup";
import Button, { Button2 } from "../../components/Button/Button";
import { ClipLoader } from "react-spinners";

export default function AddGallery() {
  const navigate = useNavigate();
  const location = useLocation();
  const values = useContext(adminContext);
  const { admintoken } = values;
  const [loadButton, setLoadButton] = useState(false);
  const [fail, setFail] = useState(false);
  const type = location.state.type;
  const [fileLimit, setFileLimit] = useState(false);
  const [selected, setSelected] = useState(false);
  const [imgs, setImgs] = useState([]);
  const [fire, setFire] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedSrc, setSelectedSrc] = useState("");
  const [buttonName, setButtonName] = useState("Add to Video Gallery");
  const [error, setError] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailsrc, setThumbnailscr] = useState("");

  const [videoLink, setVideoLink] = useState("");

  const [category, setCategory] = useState("Classroom/Teaching");
  const categories = [
    "Classroom/Teaching",
    "Co-curricular Activities",
    "Excursions",
    "Graduation Ceremonies",
    "Others",
  ];
  const handleRadio = (e) => {
    setCategory(e.target.value);
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setSelectedSrc(URL.createObjectURL(file));
    setSelected(true);
  };

  const onThumbnail = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState == 2) {
        setThumbnailscr(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
    setThumbnail(e.target.files[0]);
  };

  const popUpStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (type != 2 && selected == false) {
      alert("Choose a file");
    } else {
      if (type == 0) {
        if (imgs.length == 0) {
          return;
        } else {
          setLoadButton(true);
          const bodyFormData = new FormData();

          bodyFormData.append("token", JSON.parse(admintoken));
          bodyFormData.append("gallery_name", category);
          bodyFormData.append("type", "image");
          for (let i = 0; i < imgs.length; i++) {
            bodyFormData.append("gallery", imgs[i]);
          }

          axios
            .post(
              "https://server-olhms.onrender.com/admin_gallery/create_gallery",
              bodyFormData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )

            .then((response) => {
              if (response.status === 200) {
                // navigate(formData.fleet_id !== "" ? "/success" : "/account", {
                //   state: { id: id, token: token },
                // });
                navigate("/admin/gallery");
                setLoadButton(false);
              } else {
                // setMessage("Error occured");
                // setLoading(false);
                setLoadButton(false);
                setFail(true);
              }
              console.log(response.data);
            })
            .catch((error) => {
              console.log(error);
              // setLoading(false);
              setLoadButton(false);
              setFail(true);
            });
        }
      } else if (type == 1) {
        if (selectedFile == null) {
          return;
        } else {
          setLoadButton(true);
          const bodyFormData = new FormData();
          setButtonName("Uploading Video");

          // console.log(selectedFile);

          bodyFormData.append("token", JSON.parse(admintoken));
          bodyFormData.append("type", "video");

          bodyFormData.append("gallery", selectedFile);

          axios
            .post(
              "https://server-olhms.onrender.com/admin_gallery/create_video_gallery",
              bodyFormData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )

            .then((response) => {
              if (response.status === 200) {
                // navigate(formData.fleet_id !== "" ? "/success" : "/account", {
                //   state: { id: id, token: token },
                // });
                navigate(-1);
                // navigate("/admin/gallery");
                setLoadButton(false);
              } else {
                // setMessage("Error occured");
                // setLoading(false);
                setLoadButton(false);
                setFail(true);
              }
              console.log(response.data);
            })
            .catch((error) => {
              console.log(error);
              setButtonName("Add to Video Gallery");
              // setError("An error occured");
              setLoadButton(false);
              setFail(true);
              // setLoading(false);
            });
        }
      } else if (type == 2) {

        if (videoLink === "" && thumbnail === null && selectedFile === null) {
          alert("Add a link or upload a video file")
        }
        else if (videoLink !== "") {
          setLoadButton(true);
          fetch(
            "https://server-olhms.onrender.com/admin_featured_video/create_featured_video",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                token: JSON.parse(admintoken),
                video_link: videoLink,
              }),
            }
          )
            .then((response) => {
              console.log(JSON.stringify(response));
              if (response.status === 200) {
                // navigate(formData.fleet_id !== "" ? "/success" : "/account", {
                //   state: { id: id, token: token },
                // });
                navigate(-1);
                // navigate("/admin/gallery");
                setLoadButton(false);
              } else {
                // setMessage("Error occured");
                // setLoading(false);
                setLoadButton(false);
                setFail(true);
              }
              console.log(response.data);
            })
            .catch((error) => {
              console.log(error);
              // setError("An error occured");
              setLoadButton(false);
              setFail(true);
              // setLoading(false);
            });
        } else {
          if(thumbnail === null || selectedFile === null){
            alert("A video and a video thumbnail are required")

          }else{
            setLoadButton(true);

            const bodyFormData = new FormData();
            // setButtonName("Uploading Video");
  
            // console.log(selectedFile);
  
            bodyFormData.append("token", JSON.parse(admintoken));
            // bodyFormData.append("type", "video");
  
            bodyFormData.append("featured_video", selectedFile); 
            bodyFormData.append("featured_video", thumbnail); 
  
            axios
              .post(
                "https://server-olhms.onrender.com/admin_featured_video/create_featured_video",
                bodyFormData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
  
              .then((response) => {
                if (response.status === 200) {
                  // navigate(formData.fleet_id !== "" ? "/success" : "/account", {
                  //   state: { id: id, token: token },
                  // });
                  navigate(-1);
                  // navigate("/admin/gallery");
                  setLoadButton(false);
                } else {
                  // setMessage("Error occured");
                  // setLoading(false);
                  setLoadButton(false);
                  setFail(true);
                }
                console.log(response.data);
              })
              .catch((error) => {
                console.log(error);
                // setError("An error occured");
                setLoadButton(false);
                setFail(true);
                // setLoading(false);
              });
          }
        }
      } else return;
    }
  };

  useEffect(() => {
    handleDelete();
    setFire(false);
  }, [fire === true]);

  const picUploaded = [...imgs];

  const handleMulti = (files) => {
    let limitExceeded = false;
    files.some((file) => {
      if (picUploaded.findIndex((f) => f.name === file.name) === -1) {
        picUploaded.push(file);
        if (picUploaded.length === 100) setFileLimit(true);
        if (picUploaded.length > 100) {
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setImgs(picUploaded);
  };
  const handleDisplay = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleMulti(chosenFiles);
    setSelected(true);
  };

  const handleDelete = (url) => {
    if (imgs.length === 100) {
      setFileLimit(false);
    }
    let i = -1;
    const found = imgs.some((element) => {
      i++;
      return element === url;
    });
    if (found) {
      imgs.splice(i, 1);
      setFire(true);
    }
  };

  const inputStyles = {
    position: "relative",
    border: "none",
    outline: "none",
    height: "32px",
    fontWeight: 200,
    backgroundColor: "#e9e9e9",
    width: "100%",
    maxWidth: "800px",
    marginTop: "10px",
    padding: "0 10px",
  };

  return (
    <div
      className="set-questions"
      style={{
        backgroundColor: "rgb(255, 248, 248)",
        height: "100%",
        alignItems: "flex-start",
      }}
    >
      {type === 2 ? (
        <>
          <div className="set-container">
            <BackButton click={() => navigate(-1)} />

            <div
              style={{
                // display: "flex",
                fontWeight: "400",
                fontSize: "16px",
                alignSelf: "flex-start",
                marginTop: "20px",
              }}
            >
              <p>Enter video link</p>
              <input
                style={inputStyles}
                name="title"
                onChange={(e) => setVideoLink(e.target.value)}
                value={videoLink}
              />
            </div>

            <p className="data-number">OR</p>
            <p
              style={{
                display: "flex",
                fontWeight: "400",
                fontSize: "16px",
                alignSelf: "flex-start",
                marginTop: "20px",
              }}
            >
              Tap to upload video
            </p>
            <div className="display-images">
              <div className="vector-div-gallery">
                <label>
                  <div className="vector">
                    <img src={dark} alt="" />
                    <input
                      type="file"
                      accept=".mp4, .mov, .avi, .wmv, .flv, .mkv, .m4v, .mpeg, .mpg, .webm"
                      onChange={onFileChange}
                      disabled={null}
                    />
                  </div>
                </label>
              </div>
              {selected === true && type == 2 ? (
                <div className="dark-div">
                  <video
                    src={selectedSrc}
                    controls
                    width="100%"
                    height="100%"
                    title={"video"}
                  ></video>
                </div>
              ) : null}
            </div>
            <p
              style={{
                display: "flex",
                fontWeight: "400",
                fontSize: "16px",
                alignSelf: "flex-start",
                marginTop: "20px",
              }}
            >
              Tap to upload video thumbnail
            </p>

            <div className="display-images">
              <div className="vector-div-gallery">
                <label>
                  <div className="vector">
                    <img src={dark} alt="" />
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg, .gif"
                      onChange={onThumbnail}
                      // disabled={fileLimit}
                    />
                  </div>
                </label>
              </div>
              {thumbnailsrc == "" ? null : (
                <div className="vector-div">
                  <img src={thumbnailsrc} alt="" className="dark-div-img" />
                </div>
              )}
            </div>
            <Button
              disabled={loadButton}
              name={"Post Video"}
              click={handleSubmit}
            />
            <p>{error}</p>
          </div>
        </>
      ) : (
        <>
          <div className="set-container">
            <BackButton click={() => navigate(-1)} />

            <p className="data-number">Add {type === 0 ? "Image" : "Video"}</p>
            <div className="display-images">
              <div className="vector-div-gallery">
                <label>
                  <div className="vector">
                    <img src={dark} alt="" />
                    <input
                      type="file"
                      accept={
                        type === 0
                          ? ".png, .jpg, .jpeg, .gif"
                          : ".mp4, .mov, .avi, .wmv, .flv, .mkv, .m4v, .mpeg, .mpg, .webm"
                      }
                      onChange={type === 0 ? handleDisplay : onFileChange}
                      disabled={type === 0 ? fileLimit : null}
                    />
                  </div>
                </label>
              </div>
              {selected === true && type == 0 ? (
                imgs.map((item, index) => (
                  <div className="show-img" key={index}>
                    <img src={URL.createObjectURL(item)} alt="" />

                    <p
                      onClick={(e) => {
                        e.preventDefault();
                        handleDelete(item);
                      }}
                      className="remove-img"
                    >
                      x
                    </p>
                  </div>
                ))
              ) : selected === true && type == 1 ? (
                <div className="dark-div">
                  <video
                    src={selectedSrc}
                    controls
                    width="100%"
                    height="100%"
                    title={"video"}
                  ></video>
                </div>
              ) : selected === true && type == 2 ? (
                <div className="dark-div">
                  <video
                    src={selectedSrc}
                    controls
                    width="100%"
                    height="100%"
                    title={"video"}
                  ></video>
                </div>
              ) : null}
            </div>
            {type === 0 ? (
              <div className="category-split">
                <p>
                  Choose Category to add image (Please note that your image can
                  appear under one category only)
                </p>
                <div className="radio-row">
                  {categories.map((item) => (
                    <div className="category-radio">
                      <input
                        type="radio"
                        name="category"
                        value={item}
                        onChange={handleRadio}
                        checked={category == item}
                      />
                      <label>{item}</label>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
            <Button
              name={
                type === 0
                  ? "Add to Image Gallery"
                  : type === 1
                  ? buttonName
                  : buttonName
              }
              click={handleSubmit}
            />
            <p>{error}</p>
          </div>
        </>
      )}

      <Popup isOpen={loadButton}>
        <div style={popUpStyle}>
          <ClipLoader color={"orange"} size={30} />
          <p>Upload in progress...</p>
        </div>
      </Popup>
      <Popup isOpen={fail}>
        <div style={popUpStyle}>
          <p style={{ paddingBottom: "20px" }}>Upload Failed. Try Again</p>
          <Button name="Okay" click={() => setFail(false)} />
        </div>
      </Popup>
    </div>
  );
}
