import { useState, useContext, useEffect } from "react";
import React from "react";
import { Triple } from "../teacherPages/ResultTeacher";
import { useNavigate } from "react-router-dom";
import Add from "../../images/addition.png";
import image from "../../images/novideo.png";
import "./vlogs.css";
import video from "../../images/video.png";
import Pdf from "../../images/pdf.png";
import { adminContext } from "../context/adminContext";
// import truncateString from "./"

export default function Vlogs() {
  const navigate = useNavigate();
  const values = useContext(adminContext);
  const { admintoken } = values;
  const [toggle, setToggle] = useState("first");
  const [loading, setLoading] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const [vlogs, setVlogs] = useState([]);
  const [resources, setResources] = useState([]);

  const [searchedBlogs, setSearchedBlogs] = useState([]);
  const [searchedVlogs, setSearchedVlogs] = useState([]);
  const [searchedResources, setSearchedResources] = useState([]);

  const [searchString, setSearchString] = useState("");

  const viewBlogs = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_blog/view_blogs",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: JSON.parse(admintoken) }),
      }
    );
    const data = await res.json();
    setLoading(false);
    setBlogs(data?.blogs);
    console.log(data);
  };

  const handleSearchChange = e => setSearchString(e.target.value);

  const searchBlogs = async (currPage) => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_blog/search_blogs",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(admintoken),
          search_string: searchString,
          pagec: currPage
        }),
      }
    );
    const data = await res.json();
    setLoading(false);
    setSearchedBlogs(data?.blogs);
    console.log(data);
  };

  useEffect(() => {
    if(searchString != ''){
      if(toggle == 'first'){
        searchBlogs(1);
      }

      if(toggle == 'second'){
        searchVlogs(1);
      }

      if(toggle == 'third'){
        searchResources(1);
      }
    }
  }, [searchString]);

  const viewVlogs = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_vlog/view_vlogs",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: JSON.parse(admintoken) }),
      }
    );
    const data = await res.json();
    // setLoading(false);
    setVlogs(data?.vlogs);
    console.log(data);
  };

  const searchVlogs = async (currPage) => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_vlog/search_vlogs",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(admintoken),
          search_string: searchString,
          pagec: currPage
        }),
      }
    );
    const data = await res.json();
    setLoading(false);
    setSearchedVlogs(data?.vlogs);
    console.log(data);
  };

  // const viewResources = async () => {
  //   const res = await fetch(
  //     "https://server-olhms.onrender.com/admin_additional_resources/view_multiple_additional_resources",
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ token: JSON.parse(admintoken) }),
  //     }
  //   );
  //   const data = await res.json();
  //   // setLoading(false);
  //   setResources(data?.additional_resources);
  //   // console.log(data);
  // };

  const viewLearning = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_video_learning/view_video_learnings",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: JSON.parse(admintoken), pagec: 1 }),
      }
    );
    const data = await res.json();
    // setLoading(false);
    setResources(data?.video_learnings);
    console.log(data);
  };
  useEffect(() => {
    viewBlogs();
    viewVlogs();
    viewLearning();
  }, []);

  const searchResources = async (currPage) => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_video_learning/search_video_learnings",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(admintoken),
          search_string: searchString,
          pagec: currPage
        }),
      }
    );
    const data = await res.json();
    setLoading(false);
    setSearchedResources(data?.video_learnings);
    console.log(data);
  };

  return (
    <div
      className="set-questions"
      style={{
        backgroundColor: "rgb(255, 248, 248)",
        height: "100%",
        alignItems: "flex-start",
      }}
    >
      <div className="set-container">
        <Triple
          text1="Blog"
          text2="Vlog"
          text3="Video Learning Series"
          toggle={toggle}
          click1={() => {
            setToggle("first");
            setSearchString('');
          }}
          click2={() => {
            setToggle("second");
            setSearchString('');
          }}
          click3={() => {
            setToggle("third");
            setSearchString('');
          }}
        />

        <div className="search-input">
          <input
            placeholder={`Search ${
              toggle == "first"
                ? "Blog Stories"
                : toggle == "second"
                ? "Vlog"
                : "Video Learning Series"
            }`}
            value={searchString}
            onChange={handleSearchChange}

          />
        </div>

        {toggle === "first" ? (
          <div
            className="create-teacher"
            onClick={() =>
              navigate("/admin/add-blog", {
                state: {
                  type: 0,
                  img: "",
                  title: "",
                  details: "",
                  id: "",
                  category: "",
                  thumburl: "",
                },
              })
            }
          >
            <img src={Add} />
          </div>
        ) : toggle === "second" || toggle === "third" ? (
          <div
            className="add-video"
            onClick={() =>
              toggle == "second"
                ? navigate("/admin/add-blog", {
                    state: {
                      type: 1,
                      img: "",
                      title: "",
                      details: "",
                      id: "",
                      category: "",
                      thumburl: "",
                    },
                  })
                : navigate("/admin/add-blog", {
                    state: {
                      type: 3,
                      img: "",
                      title: "",
                      details: "",
                      id: "",
                      category: "",
                      thumburl: "",
                    },
                  })
            }
          >
            <div className="video-icon">
              <img src={video} alt="" />
            </div>
            <p>Add Video</p>
          </div>
        ) : null}

        {toggle === "first" ? (
          <div className="show-small-blog">
            {searchString != '' ? searchedBlogs?.map((item, i) => (
              <Smallblog
                key={i}
                img={item?.img_url}
                title={truncateString(item?.title, 36)}
                details={truncateString(item?.body, 170)}
                click={() =>
                  navigate("/admin/add-blog", {
                    state: {
                      type: 0,
                      img: item?.img_url,
                      title: item?.title,
                      details: item?.body,
                      id: item?._id,
                      category: "",
                      thumburl: ""
                    },
                  })
                }
              />
            )) : blogs?.map((item, i) => (
              <Smallblog
                key={i}
                img={item?.img_url}
                title={truncateString(item?.title, 36)}
                details={truncateString(item?.body, 170)}
                click={() =>
                  navigate("/admin/add-blog", {
                    state: {
                      type: 0,
                      img: item?.img_url,
                      title: item?.title,
                      details: item?.body,
                      id: item?._id,
                      category: "",
                      thumburl: "",
                    },
                  })
                }
              />
            ))}
            {/* <SmallResource /> */}
          </div>
        ) : toggle === "second" ? (
          <div className="show-small-blog">
            {searchString != '' ? searchedVlogs?.map((item, i) => (
              <Smallvlog
                key={i}
                src={item?.video_url}
                title={truncateString(item?.title, 36)}
                details={truncateString(item?.summary, 170)}
                click={() =>
                  navigate("/admin/add-blog", {
                    state: {
                      type: 1,
                      img: item?.video_url,
                      title: item?.title,
                      details: item?.summary,
                      id: item?._id,
                      category: item?.category,
                      thumburl: item?.img_url ? item?.img_url : "",
                    },
                  })
                }
              />
            )) : vlogs?.map((item, i) => (
              <Smallvlog
                key={i}
                src={item?.video_url}
                title={truncateString(item?.title, 36)}
                details={truncateString(item?.summary, 170)}
                click={() =>
                  navigate("/admin/add-blog", {
                    state: {
                      type: 1,
                      img: item?.video_url,
                      title: item?.title,
                      details: item?.summary,
                      id: item?._id,
                      category: item?.category,
                      thumburl: item?.img_url ? item?.img_url : "",
                    },
                  })
                }
              />
            ))}
          </div>
        ) : (
          <div className="show-small-blog">
            {searchString != '' ? searchedResources?.map((item, i) => (
              <Smallvlog
                key={i}
                src={item?.video_url}
                details={truncateString(item?.summary, 170)}
                click={() =>
                  navigate("/admin/add-blog", {
                    state: {
                      type: 3,
                      img: item?.video_url,
                      title: item?.title,
                      details: item?.summary,
                      id: item?._id,
                      category: item?.category,
                      thumburl: item?.img_url ? item?.img_url : "",
                    },
                  })
                }
              />
            )) : resources?.map((item, i) => (
              <Smallvlog
                key={i}
                src={item?.video_url}
                details={truncateString(item?.summary, 170)}
                click={() =>
                  navigate("/admin/add-blog", {
                    state: {
                      type: 3,
                      img: item?.video_url,
                      title: item?.title,
                      details: item?.summary,
                      id: item?._id,
                      category: item?.category,
                      thumburl: item?.img_url ? item?.img_url : "",
                    },
                  })
                }
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export function Smallblog(props) {
  return (
    <div
      className="split-small-blog"
      style={{ cursor: "pointer" }}
      onClick={props.click}
    >
      <div className="small-blog-image">
        <img src={props.img} alt="" />
      </div>
      <div className="small-blog-content">
        <p className="small-blog-title">{props.title}</p>
        <div className="small-blog-text">{props.details}</div>
      </div>
    </div>
  );
}
export function Smallvlog(props) {
  return (
    <div
      className="split-small-blog"
      style={{ cursor: "pointer" }}
      onClick={props.click}
    >
      <div className="small-blog-image">
        {/* <iframe
          src={props.src}
          title=""
          //   frameborder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe> */}
        <video
          src={props.src}
          controls
          width="100%"
          // height="100%"
          title=""
        ></video>
      </div>
      <div className="small-blog-content">
        {props.title ? <p className="small-blog-title">{props.title}</p> : null}
        <div className="small-blog-text">{props.details}</div>
      </div>
    </div>
  );
}

export function SmallResource(props) {
  return (
    <div className="small-resource" onClick={props.click}>
      <div className="small-resource-img">
        <img src={props.img} alt="" />
      </div>
      <p>{props.title}</p>
      <div className="dropdown">
        <p className="dropdown-btn">&#8942;</p>
        <div class="dropdown-content">
          <p onClick={props.delete}>Delete Video</p>
        </div>
      </div>
    </div>
  );
}

export function truncateString(title, num) {
  if (title?.length > num) {
    return title.slice(0, num) + "....";
  } else {
    return title;
  }
}
