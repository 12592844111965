import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BackButton } from "../studentPages/PersonalData";
import { Toggle, VideoComponent } from "../teacherPages/Resources";
import { ClassContent } from "../teacherPages/Resources";
import { NoteComponent } from "../teacherPages/Resources";
import video from "../../images/video.png";
import novideo from "../../images/novideo.png";
import nonote from "../../images/nonote.png";
import noresource from "../../images/no-resources.png";
import attach from "../../images/attach.png";
import worksheet from "../../images/worksheet.png";
import { Novideo } from "../teacherPages/Resources";
import Addition from "../../images/addition.png";
import { adminContext } from "../context/adminContext";

export default function SettingsAllResources() {
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(true);

  const location = useLocation();
  const term = location.state.term;
  const week = location.state.week;
  const subject = location.state.subject;
  const id = location.state.id;
  const className = location.state.className;
  const tempAdminToken = location.state.tempAdminToken;
  const center = location.state.center;
  const overClassName = location.state.overClassName;

  const values = useContext(adminContext);
  const { teacherData, teachertoken } = values;
  const dataT = JSON.parse(teacherData);
  const [loading, setLoading] = useState(true);

  const [lessons, setLessons] = useState({
    noteLessons: [],
    videoLessons: []
  });
  
  const [addResources, setAddResources] = useState({
    noteAddResources: [],
    videoAddResources: []
  });

  const viewSub = async () => {
    const week_no = parseInt(week.substring(5));
    
    console.log(id);
    const res = await fetch(
      "https://server-olhms.onrender.com/teacher_subject/view_lesson",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: tempAdminToken,
          week_no: week_no,
          subject_id: id,
          term: term,
        }),
      }
    );
    const data = await res.json();
    setLoading(false);
    const mLessons = data?.lesson.lesson;

    const tNoteLessons = [];
    const tVideoLessons = [];

    mLessons.map(les => {
      if(les.lesson_type == 'class note'){
        tNoteLessons.push(les);
      }

      if(les.lesson_type == 'video'){
        tVideoLessons.push(les);
      }
    });

    const temp = {...lessons};
    temp.noteLessons = tNoteLessons;
    temp.videoLessons = tVideoLessons;
    setLessons(temp);

    console.log(data);
  };

  useEffect(() => {
    viewSub();
  }, []);


  const deleteLesson = async (lesson_url) => {
    const week_no = parseInt(week.substring(5));
    console.log(id);
    const res = await fetch(
      "https://server-olhms.onrender.com/teacher_subject/delete_lesson_material",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: tempAdminToken,
          week_no: week_no,
          subject_id: id,
          term: term,
          lesson_url: lesson_url
        }),
      }
    );

    const data = await res.json();
    setLoading(false);
    // const mLessons = data?.msg.lesson;
    if(data?.msg == 'success'){
      viewSub();
      viewAddResources();
      alert('Delete Success');
    }

    console.log(data);
  };


  const viewAddResources = async () => {
    const week_no = parseInt(week.substring(5));
    console.log(id);
    const res = await fetch(
      "https://server-olhms.onrender.com/teacher_additional_resources/view_multiple_additional_resources_filter",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: tempAdminToken,
          week: week_no,
          subject_name: subject,
          class_name: className,
          term: term,
        }),
      }
    );
    const data = await res.json();
    setLoading(false);
    const resources = data?.additional_resources;

    const tVideoResources = [];
    const tNoteResources = [];

    resources.map(res => {
      if(res.lesson_type == 'class note'){
        tNoteResources.push(res);
      }

      if(res.lesson_type == 'video'){
        tVideoResources.push(res);
      }
    });

    const temp = {...addResources};
    temp.noteAddResources = tNoteResources;
    temp.videoAddResources = tVideoResources;
    setAddResources(temp);
    console.log(data);
  };

  useEffect(()=>{
    viewAddResources();
  }, []);


  const [worksheetQuestions, setWorksheets] = useState([]);
  const viewWorksheetQuestions = async () => {

    console.log(id);
    const res = await fetch(
      "https://server-olhms.onrender.com/teacher_worksheet/view_current_session_worksheets",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: tempAdminToken,
          class_name: className,
          subject: subject,
          week: parseInt(week.split(' ')[1])
        }),
      }
    );
    const data = await res.json();
    setLoading(false);
    const worksheets = data?.worksheets;

    setWorksheets(worksheets);

    console.log(data);
  };

  useEffect(()=>{
    viewWorksheetQuestions();
  }, []);

  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (document.documentElement.clientWidth <= 800) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={mobile ? '' : 'set-questions'} style={mobile ? {margin: 24} : {}}>
      <div className="set-container" style={{ rowGap: "50px", marginLeft: mobile ? 0 : 0}}>
        <BackButton click={() => navigate(-1)} />
        <div
          className="session-flex"
          style={{
            alignSelf: "flex-start",
            alignItems: "flex-start",
            rowGap: "10px",
          }}
        >
          <div className="cat-session">
            <p>
              {term == "first term"
                ? "First Term"
                : term == "second term"
                ? "Second Term"
                : term == "thrid term"
                ? "Third Term"
                : null}
            </p>
          </div>
          <div style={{ fontSize: "20px", marginTop: mobile ? 30 : 0, fontWeight: 'bold' }}>{center}</div>
          <div style={{ fontSize: "20px", marginTop: mobile ? 30 : 0 }}>{overClassName}</div>
          <div style={{ fontSize: "20px", marginTop: mobile ? 30 : 0 }}>{term.toUpperCase()}</div>
          <div style={{ fontSize: "20px" }}>
            {subject} -{" "}
            <span style={{ fontWeight: "300", fontSize: "20px" }}>{week}</span>
          </div>
        </div>
        <Toggle
          click1={() => setToggle(true)}
          click2={() => setToggle(false)}
          toggle={toggle}
          text1="Resources"
          text2="Worksheet"
        />
        {toggle ? (
          <>
            <>
              <div
                className="session-flex"
                style={{
                  alignSelf: "flex-start",
                }}
              >
                <div className="cat-session">
                  <p>VIDEOS</p>
                </div>
              </div>

              <div className="split-all-resources">
                <div style={{display: 'flex', flexDirection: mobile ? 'column' : 'row', justifyContent: 'left'}}>
                {
                  lessons.videoLessons.map((vLes, index) => (
                    <ClassContent key={index}>
                      <div style={{marginRight: mobile ? 16 : 120, marginTop: mobile ? 16 : 0}}>
                      <VideoComponent 
                        lesson_url={vLes.lesson_url}
                        thumbnail_url={vLes.thumbnail_url}
                        title={vLes.title}
                        weekLes={`${week}, Lesson ${vLes.lesson_no}`}
                        onClick={(e)=>{
                          e.preventDefault();
                        }}
                        onDelete={(e)=>{
                          e.preventDefault();
                          deleteLesson(vLes.lesson_url);
                        }}
                      />
                      </div>
                    </ClassContent>
                  ))
                }
                </div>
                {loading || (lessons.videoLessons.length != 0) ? <></> : <Novideo
                  img={novideo}
                  text="No Video has been uploaded. Tap the icon to upload a new video"
                />}

                <div>
                </div>
              </div>
            </>

            <>
              <div
                className="session-flex"
                style={{
                  alignSelf: "flex-start",
                }}
              >
                <div className="cat-session">
                  <p>NOTES</p>
                </div>
              </div>

              <div className="split-all-resources">
                <div style={{display: 'flex', flexDirection: mobile ? 'column' : 'row', justifyContent: 'left'}}>
                {
                  lessons.noteLessons.map((nLes, index) => (
                    <ClassContent key={index}>
                      <div style={{marginRight: 32, marginTop: mobile ? 16 : 0}}>
                      <NoteComponent
                        title={nLes.title}
                        weekLes={`${week}, Lesson ${nLes.lesson_no}`}
                        onClick={(e)=>{
                          e.preventDefault();
                        }}
                        onDelete={(e)=>{
                          e.preventDefault();
                          deleteLesson(nLes.lesson_url);
                        }}
                      />
                      </div>
                    </ClassContent>
                  ))
                }
                </div>
                {loading || (lessons.noteLessons.length != 0) ? <></> : <Novideo
                  img={nonote}
                  text="No Notes have been uploaded. Tap the icon to upload a new Note"
                />}

                <div>
                </div>
              </div>
            </>

            <>
              <div
                className="session-flex"
                style={{
                  alignSelf: "flex-start",
                }}
              >
                <div className="cat-session">
                  <p>ADDITIONAL RESOURCES</p>
                </div>
              </div>

              <div className="split-all-resources">
                <div className="show-split-all"></div>
                <div style={{display: 'flex', flexDirection: mobile ? 'column' : 'row', justifyContent: 'left'}}>
                {
                  addResources.noteAddResources.map((nLes, index) => (
                    <ClassContent key={index}>
                    <div style={{marginRight: 32, marginTop: mobile ? 16 : 0}}>
                      <NoteComponent
                        title={nLes.title}
                        weekLes={`${week}, Lesson ${nLes.lesson_no}`}
                        onClick={(e)=>{
                          e.preventDefault();
                        }}
                        onDelete={(e)=>{
                          e.preventDefault();
                          deleteLesson(nLes.lesson_url);
                        }}
                      />
                      </div>
                    </ClassContent>
                  ))
                }
                <div style={{display: 'flex', flexDirection: mobile ? 'column' : 'row', justifyContent: 'left'}}>
                {
                  addResources.videoAddResources.map((vLes, index) => (
                    <ClassContent key={index}>
                      <div style={{marginRight: 16}}>
                      <VideoComponent 
                        lesson_url={vLes.img_url}
                        thumbnail_url={''}
                        title={vLes.title}
                        weekLes={`${week}, Lesson ${vLes.lesson_no}`}
                        onClick={(e)=>{
                          e.preventDefault();
                        }}
                        onDelete={(e)=>{
                          e.preventDefault();
                          deleteLesson(vLes.lesson_url);
                        }}
                      />
                      </div>
                    </ClassContent>
                  ))
                }
                </div>
                </div>
                {loading || ((addResources.noteAddResources.length != 0) || (addResources.videoAddResources.length != 0)) ? <></> : <Novideo
                  img={noresource}
                  text="No additional resource has been uploaded. Tap the icon to upload one"
                />}

                <div>
                </div>
              </div>
            </>
          </>
        ) : (
          
          <>
            {
              worksheetQuestions.map((question, i) => (
                <div key={i} className='questions-wrapper'>
          <ul className="input-list"
                  style={{
                    listStylePosition: "outside",
                    listStylequestion_type: "none",
                    columnGap: "30px",
                    display: mobile ? 'block' : 'inherit'
                  }}>

            
            <li
              className="input-item"
              style={{
                width: "",
              }}>
                  <span style={{fontWeight: "bold"}}>Q{i + 1}.{" "}</span>
                  <p>
                    {question.question_type === "German without image" ||
                    question.question_type === "Spell without image"
                      ? `${question.question}_______________`
                      : question.question}
                  </p>
              </li>
                    <li style={mobile ? {listStyleType: "none", marginTop: 8} : {listStyleType: "none"}}>
                    {(
                      <p style={{fontWeight: 'bold'}}>{`(${question.mark} ${
                        question.mark == 1 ? "Mark" : "Marks"
                      })`}</p>
                    )}
                  </li>
          </ul>

          {question.question_type === "Multiple Choice Question without Image" ? (
                  <div>
                    <ul className="see-options">
                      <li>{question.options[0]}</li>
                      <li>{question.options[1]}</li>
                      <li>{question.options[2]}</li>
                      <li>{question.options[3]}</li>
                    </ul>
                    <p style={{color: '#E05615', marginLeft: 30}}>{`ANS: ${question.correct_answer.toUpperCase()}`}</p>
                  </div>
                ) : question.question_type === "Multiple Choice Question with Image" ? (
                  <div className="display-questions">
                    <div className="selected-image">
                    <div className="show-img">
                          <img src={question.img_urls[0]} alt="" />
                        </div>
                    </div>
                    {(
                      <ul className="see-options">
                      <li>{question.options[0]}</li>
                      <li>{question.options[1]}</li>
                      <li>{question.options[2]}</li>
                      <li>{question.options[3]}</li>
                      </ul>
                    )}
                    <p style={{color: '#E05615', marginLeft: 30}}>{`ANS: ${question.correct_answer.toUpperCase()}`}</p>
                  </div>
                ) : question.question_type === "True/False without image" ? (
                  
                  <div>
                    <ul className="see-options">
                      <li>True</li>
                      <li>False</li>
                    </ul>
                    <p style={{color: '#E05615', marginLeft: 30}}>{`ANS: ${question.correct_answer.toUpperCase()}`}</p>
                  </div>
                ) : question.question_type === "True/False with image" ? (
                  <div className="display-questions">
                    <div className="selected-image">
                    <div className="show-img">
                          <img src={question.img_urls[0]} alt="" />
                        </div>
                      
                    </div>
                    {(
                      <ul className="see-options">
                        <li>True</li>
                        <li>False</li>
                      </ul>
                    )}
                    <p style={{color: '#E05615', marginLeft: 30}}>{`ANS: ${question.correct_answer.toUpperCase()}`}</p>
                  </div>
                ) : question.question_type === "German with image" ||
                  question.question_type === "Spell with image" ? (
                  <>
                    <div className="selected-image">
                    <div className="show-img">
                      <img src={question.img_urls[0]} alt="" />
                    </div>
                      
                    </div>
                    <div className="correct-answer-div">
                      {/* Enter Correct Answer <input className="correct-answer" name='correct_answer' onChange={(e) => handleCorrectAnswer(e, i)}/> */}
                      <p style={{color: '#E05615', marginLeft: 30}}>{`ANS: ${question.correct_answer.toUpperCase()}`}</p>
                    </div>
                  </>
                ) : question.question_type === "German without image" ||
                  question.question_type === "Spell without image" ? (
                  <div className="correct-answer-div">
                    {/* Enter Correct Answer <input className="correct-answer"  name='correct_answer' onChange={(e) => handleCorrectAnswer(e, i)}/> */}
                    <p style={{color: '#E05615', marginLeft: 30}}>{`ANS: ${question.correct_answer.toUpperCase()}`}</p>
                  </div>
                ) : question.question_type === "Multiple Images Question" ? (
                  <>
                  <div className="questions-wrapper">
                  <div className="display-questions">
                    <div className="selected-image">
                      {question.img_urls.map((item, index) => (
                            <div className="show-img" key={index}>
                              <img src={item} alt="" />
                            </div>
                          ))
                        }
                    </div>
                  </div>
                  </div>
                  <p style={{color: '#E05615', marginLeft: 30}}>{`ANS: ${question.correct_answer.toUpperCase()}`}</p>
                    <div className="correct-answer-div">
                      {/* Enter Correct Answer{" "}
                      <select className="correct-answer" onChange={(e) => handleCorrectAnswer(e, i)}>
                        <option value="a">A</option>
                        <option value="b">B</option>
                        <option value="c">C</option>
                        <option value="d">D</option>
                      </select> */}
                    </div>
                  </>
                ) : null}
          </div>
              ))
            }
            {worksheetQuestions.length == 0 ? 
              <>
                <Novideo
                img={worksheet}
                text="No exercises have been added. Tap the icon to start setting questions."
              />
              </> : null
            }
            <div
              className="add-question"
              onClick={() =>
                navigate("/teacher/select", {
                  state: { week: week, term: term, subject: subject, id, },
                })
              }
            >
              <img src={Addition} alt="" />
              </div>
          </>
        )}
      </div>
    </div>
  );
}
