import React from "react";
import { useState, useRef, useEffect, useContext } from "react";
import "./dashboard.css";
import { Toggle } from "../teacherPages/Resources";
import Edit from "../../images/edit.png";
import Add from "../../images/addition.png";
import Pdf from "../../images/pdf.png";
import { useNavigate } from "react-router-dom";
import Popup from "../../components/Popup/Popup";
import Button, { Button2 } from "../../components/Button/Button";
import Cancel from "../../images/cancel.png";
import vector from "../../images/Vector.png";
import "./addgallery.css";
import axios from "axios";
import { adminContext } from "../context/adminContext";

export default function Dashboard() {
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(true);
  const [subject, setSubject] = useState(false);
  const [loadsubject, setLoadSubject] = useState(true);
  const [loadbooks, setLoadBooks] = useState(true);
  const [realClass, setRealClass] = useState("Creche");
  const [subjects, setSubjects] = useState([]);
  const [reader, setReader] = useState(false);
  const [classList, setClassList] = useState(false);
  const [loading, setLoading] = useState(true);
  const [books, setBooks] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedSrc, setSelectedSrc] = useState("");
  const [editBook, setEditBook] = useState(false);
  const [editSub, setEditSub] = useState(false);
  const [deletable, setDeletable] = useState(false);
  const [loadingButton, setloadingButton] = useState(false);
  const [formData, setFormData] = useState({
    booktitle: "",
    bookauthor: "",
    aboutbook: "",
  });
  const [subId, setSubId] = useState("");
  const [bookId, setBookId] = useState("");
  // const [defaultSchool, setDefaultSchool]=useState("")
  const textAreaRef = useRef(null);
  const experienceRef = useRef(null);
  const requirementRef = useRef(null);
  const [changeOverview, setChangeOverview] = useState(true);
  const [changeExperience, setChangeExperience] = useState(true);
  const [changeRequirement, setChangeRequirement] = useState(true);
  const [overallClass, setOverallClass] = useState("Baby's and Toddlers");
  const [stats, setStats] = useState({});
  const [centers, setCenters] = useState([]);
  // let [schoolId, setSchoolId] = useState("")
  // overallClass == "Baby's and Toddlers"
  //   ? "642b9a5c94b988f5dd1daa03"
  //   : overallClass == "Infant School"
  //   ? "642b9ad594b988f5dd1daa09"
  //   : overallClass == "Grade School"
  //   ? "642b9b0594b988f5dd1daa0f"
  //   : "";
  const [schoolDetails, setSchoolDetails] = useState({});
  const [pdfFile, setPdfFile] = useState(null);
  const handleReader = (e) => {
    const target = e.target;

    const { name, value } = target;
    setFormData({ ...formData, [name]: value });
    // console.log(formData);
  };
  const [overview, setOverview] = useState("");
  const [experience, setExperience] = useState("");
  const [requirement, setRequirement] = useState("");
  const [chosenCentre, setChosenCentre] = useState("Emerald centre");

  // const [mainData, setMainData] = useState({
  //   overview: schoolDetails?.overview,
  //   experience: schoolDetails?.experience,
  //   requirement: schoolDetails?.entry_requirements,
  // });

  const viewSchool = async () => {
    try {
      const res = await fetch(
        "https://server-olhms.onrender.com/admin_school/view_school",
        {
          method: "POST",

          body: JSON.stringify({
            token: JSON.parse(admintoken),
            school_id:
              overallClass == "Baby's and Toddlers"
                ? "642b9a5c94b988f5dd1daa03"
                : overallClass == "Infant School"
                ? "642b9ad594b988f5dd1daa09"
                : overallClass == "Grade School"
                ? "642b9b0594b988f5dd1daa0f"
                : "",
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const data = await res.json();
      console.log(data);
      setOverview(data.school.overview);
      setExperience(data.school.experience);
      setRequirement(data.school.entry_requirements);
      // setSchoolDetails(data);
    } catch (error) {
      // setLoading(false);
      console.log(error);
    }
  };

  // const handleData = (e) => {
  //   const target = e.target;

  //   const { name, value } = target;
  //   setMainData({ ...mainData, [name]: value });
  //   // console.log(formData);
  // };

  const editClick = () => {
    setChangeOverview(false);
    // textAreaRef.current.focus();
  };

  const experienceClick = () => {
    setChangeExperience(false);
  };
  const requirementClick = () => {
    setChangeRequirement(false);
  };

  // useEffect(() => {
  //   if (!changeOverview && mainData.overview !== "") {
  //     textAreaRef.current.focus();
  //     textAreaRef.current.value = mainData.overview;
  //     textAreaRef.current.selectionStart = mainData.overview?.length;
  //     textAreaRef.current.selectionEnd = mainData.overview?.length;
  //   } else return;
  // }, [changeOverview]);
  // useEffect(() => {
  //   if (!changeExperience) {
  //     experienceRef.current.focus();
  //     experienceRef.current.value = mainData.experience;
  //     experienceRef.current.selectionStart = mainData.experience?.length;
  //     experienceRef.current.selectionEnd = mainData.experience?.length;
  //   }
  // }, [changeExperience]);
  // useEffect(() => {
  //   if (!changeRequirement) {
  //     requirementRef.current.focus();
  //     requirementRef.current.value = mainData.requirement;
  //     requirementRef.current.selectionStart = mainData.requirement?.length;
  //     requirementRef.current.selectionEnd = mainData.requirement?.length;
  //   }
  // }, [changeRequirement]);

  const [subName, setSubName] = useState("");

  const handleSub = (e) => {
    setSubName(e.target.value);
  };
  const values = useContext(adminContext);
  const { admintoken } = values;

  const onFileChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState == 2) {
        setSelectedSrc(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
    setIsSelected(true);
  };

  const viewSubjects = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_subject/view_subjects",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(admintoken),
          class_name: realClass,
        }),
      }
    );
    const data = await res.json();
    setLoadSubject(false);
    setSubjects(data?.subjects);
    // console.log(data);
    // console.log(id);
  };

  const cancelDelete = (e) => {
    setDeletable(false);
    setEditSub(false);
    setEditBook(false);
  };

  const viewBooks = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_book/view_books",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(admintoken),
          class_name: realClass,
        }),
      }
    );
    const data = await res.json();
    setLoadBooks(false);
    setBooks(data?.books);
    // console.log(data);
    // console.log(id);
  };

  const deleteSub = async (e) => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_subject/delete_subject",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(admintoken),
          subject_id: subId,
        }),
      }
    );
    viewSubjects();
    setDeletable(false);
  };

  const deleteBook = async (e) => {
    // console.log(bookId);
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_book/delete_book",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(admintoken),
          book_id: bookId,
        }),
      }
    );

    viewBooks();
    setDeletable(false);
  };

  const addSubject = async (e) => {
    e.preventDefault();
    setloadingButton(true);

    try {
      const res = await fetch(
        "https://server-olhms.onrender.com/admin_subject/create_subject",
        {
          method: "POST",

          body: JSON.stringify({
            token: JSON.parse(admintoken),
            subject_name: subName,
            class_name: realClass,
            school: overallClass.toLowerCase(),
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const data = await res.json();
      // console.log(data);

      if (res.status === 200) {
        setSubject(false);
        viewSubjects();
        setSubName("");
      }else{
      console.log(JSON.stringify(data));

      }
      setloadingButton(false);
    } catch (error) {
      setloadingButton(false);

      console.log(error);
    }
  };

  const addReader = async (e) => {
    const files = [selectedFile, pdfFile];
    e.preventDefault();
    const bodyFormData = new FormData();

    bodyFormData.append("token", JSON.parse(admintoken));
    bodyFormData.append("title", formData.booktitle);
    bodyFormData.append("class_name", realClass);
    bodyFormData.append("school", overallClass.toLowerCase());
    bodyFormData.append("author", formData.bookauthor);
    bodyFormData.append("about_book", formData.aboutbook);
    for (let i = 0; i < files.length; i++) {
      bodyFormData.append("books", files[i]);
    }

    axios
      .post(
        "https://server-olhms.onrender.com/admin_book/create_book",
        bodyFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setReader(false);
          setFormData({
            booktitle: "",
            bookauthor: "",
            aboutbook: "",
          });
          setSelectedFile(null);
          viewBooks();
        } else {
          // setMessage("Error occured");
          // setLoading(false);
        }
        console.log(response.data);
      })
      .catch((error) => {
        // console.log(error);
        // setLoading(false);
      });
  };

  // const viewSchools = async () => {
  //   // e.preventDefault();
  //   try {
  //     const res = await fetch(
  //       "https://server-olhms.onrender.com/admin_school/view_schools",
  //       {
  //         method: "POST",

  //         body: JSON.stringify({
  //           token: JSON.parse(admintoken),
  //           school_name: "Grade School",
  //         }),
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json, text/plain, */*",
  //         },
  //       }
  //     );
  //     const data = await res.json();
  //     console.log(data);
  //   } catch (error) {
  //     // setLoading(false);
  //     console.log(error);
  //   }
  // };

  const addOverview = async () => {
    try {
      const res = await fetch(
        "https://server-olhms.onrender.com/admin_school/edit_school",
        {
          method: "POST",

          body: JSON.stringify({
            token: JSON.parse(admintoken),
            school_id:
              overallClass == "Baby's and Toddlers"
                ? "642b9a5c94b988f5dd1daa03"
                : overallClass == "Infant School"
                ? "642b9ad594b988f5dd1daa09"
                : overallClass == "Grade School"
                ? "642b9b0594b988f5dd1daa0f"
                : "",
            overview: overview,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const data = await res.json();
      viewSchool();
      console.log(data);
    } catch (error) {
      // setLoading(false);
      console.log(error);
    }
  };

  const addExperience = async () => {
    try {
      const res = await fetch(
        "https://server-olhms.onrender.com/admin_school/edit_school",
        {
          method: "POST",

          body: JSON.stringify({
            token: JSON.parse(admintoken),
            school_id:
              overallClass == "Baby's and Toddlers"
                ? "642b9a5c94b988f5dd1daa03"
                : overallClass == "Infant School"
                ? "642b9ad594b988f5dd1daa09"
                : overallClass == "Grade School"
                ? "642b9b0594b988f5dd1daa0f"
                : "",
            experience: experience,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const data = await res.json();
      viewSchool();
      // console.log(data);
    } catch (error) {
      // setLoading(false);
      console.log(error);
    }
  };

  const addRequirement = async () => {
    try {
      const res = await fetch(
        "https://server-olhms.onrender.com/admin_school/edit_school",
        {
          method: "POST",
          body: JSON.stringify({
            token: JSON.parse(admintoken),
            school_id:
              overallClass == "Baby's and Toddlers"
                ? "642b9a5c94b988f5dd1daa03"
                : overallClass == "Infant School"
                ? "642b9ad594b988f5dd1daa09"
                : overallClass == "Grade School"
                ? "642b9b0594b988f5dd1daa0f"
                : "",
            entry_requirements: requirement,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const data = await res.json();
      viewSchool();
      // console.log(data);
    } catch (error) {
      // setLoading(false);
      console.log(error);
    }
  };

  const viewStatistics = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_statistics/view_statistics",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(admintoken),
        }),
      }
    );
    const data = await res.json();
    console.log(data);
    if (data.msg === "success") {
      setLoading(false);
      setStats(data.statistics);
    }
  };

  const viewCenter = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_center/view_centers",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(admintoken),
        }),
      }
    );
    const data = await res.json();
    console.log(data);
    if (data.msg === "success") {
      setLoading(false);
      setCenters(data.centers);
    }
  };

  const [students, setStudents] = useState([]);

  const viewStudents = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/teacher_profile/students_list",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(admintoken),
          class_name: realClass,
          center: chosenCentre,
        }),
      }
    );
    const data = await res.json();
    setStudents(data?.students);
    // console.log(`---------_> ${students}`)
  };

  useEffect(() => {
    viewSubjects();
    viewBooks();
    // console.log(JSON.parse(admintoken));

    // addExperience();
  }, [realClass]);

  useEffect(() => {
    viewSchool();
  }, [overallClass]);

  useEffect(() => {
    viewCenter();
    viewStudents();
    viewStatistics();
  }, []);

  // if (loading === false)
  return (
    <div
      className="set-questions"
      style={{
        backgroundColor: "rgb(255, 248, 248)",
        height: "100%",
        alignItems: "flex-start",
      }}
    >
      <div className="set-container">
        <div className="dash-viewer">
          <div
            className="view-total"
            onClick={() => navigate("/admin/student")}
          >
            <div className="set-font">Students Database</div>
            <div className="databaseName">{stats?.no_of_students}</div>
            <li className="set-font">Topaz centre</li>
            <div className="set-font">{centers[0]?.no_of_students}</div>
            <li className="set-font">Emerald centre</li>
            <div className="set-font">{centers[1]?.no_of_students}</div>
          </div>
          <div className="view-total" onClick={() => navigate("/admin/staff")}>
            <div className="set-font">Staff Database</div>
            <div className="databaseName">{stats?.no_of_staff}</div>
            <li className="set-font">Topaz centre</li>
            <div className="set-font">{centers[0]?.no_of_staff}</div>
            <li className="set-font">Emerald centre</li>
            <div className="set-font">{centers[1]?.no_of_staff}</div>
          </div>
          <div className="split-dash">
            <div
              className="view-admin"
              onClick={() => navigate("/admin/applications")}
            >
              <div className="set-font">New Applications</div>
              <div className="databaseName">{stats?.no_of_applications}</div>
            </div>
            <div
              className="view-admin"
              onClick={() => navigate("/admin/administrators")}
            >
              <div className="set-font">Administrators</div>
              <div className="databaseName">{stats?.no_of_admins}</div>
            </div>
          </div>
        </div>
        <Toggle
          click1={() => {
            setToggle(true);
            setChosenCentre("Topaz Centre");
          }}
          click2={() => {
            setToggle(false);
            setChosenCentre("Emerald Centre");
          }}
          toggle={toggle}
          text1="Topaz Centre"
          text2="Emerald Centre"
        />
        <div className="dash-viewer">
          <div
            className="class-view-div"
            onClick={() => {
              setOverallClass("Baby's and Toddlers");
            }}
          >
            <div className="name-of-class">{`Baby's and Toddlers: ${
              centers[toggle ? 0 : 1]?.babies_and_toddlers.total
            }`}</div>
            <div className="class-viewer">
              <li
                onClick={() => setRealClass("Creche")}
                className={realClass == "Creche" ? "active-class-moment" : ""}
              >
                Creche
              </li>
              <div>
                {centers[toggle ? 0 : 1]?.babies_and_toddlers.no_of_creche}
              </div>
              <li
                onClick={() => setRealClass("Toddlers")}
                className={realClass == "Toddlers" ? "active-class-moment" : ""}
              >
                {" "}
                Toddlers
              </li>
              <div>
                {centers[toggle ? 0 : 1]?.babies_and_toddlers.no_of_toddlers}
              </div>
            </div>
          </div>
          <div
            className="class-view-div"
            onClick={() => {
              setOverallClass("Infant School");
            }}
          >
            <div className="name-of-class">{`Infant School: ${
              centers[toggle ? 0 : 1]?.infant_school.total
            }`}</div>
            <div className="class-viewer">
              <li
                onClick={() => setRealClass("Infant Community 1")}
                className={
                  realClass == "Infant Community 1" ? "active-class-moment" : ""
                }
              >
                Infant Community 1
              </li>
              <div>
                {centers[toggle ? 0 : 1]?.infant_school.infant_community_one}
              </div>
              <li
                onClick={() => setRealClass("Infant Community 2")}
                className={
                  realClass == "Infant Community 2" ? "active-class-moment" : ""
                }
              >
                Infant Community 2
              </li>
              <div>
                {centers[toggle ? 0 : 1]?.infant_school.infant_community_two}
              </div>
              <li
                onClick={() => setRealClass("Nursery 1")}
                className={
                  realClass == "Nursery 1" ? "active-class-moment" : ""
                }
              >
                Nursery 1
              </li>
              <div>{centers[toggle ? 0 : 1]?.infant_school.nursery_one}</div>
              <li
                onClick={() => setRealClass("Nursery 2")}
                className={
                  realClass == "Nursery 2" ? "active-class-moment" : ""
                }
              >
                Nursery 2
              </li>
              <div>{centers[toggle ? 0 : 1]?.infant_school.nursery_two}</div>
            </div>
          </div>
          <div
            className="class-view-div"
            onClick={() => {
              setOverallClass("Grade School");
            }}
          >
            <div className="name-of-class">{`Grade School: ${
              centers[toggle ? 0 : 1]?.grade_school.total
            }`}</div>
            <div className="class-viewer">
              <li
                onClick={() => setRealClass("Grade 1")}
                className={realClass == "Grade 1" ? "active-class-moment" : ""}
              >
                Grade 1
              </li>
              <div>{centers[toggle ? 0 : 1]?.grade_school.grade_one}</div>
              <li
                onClick={() => setRealClass("Grade 2")}
                className={realClass == "Grade 2" ? "active-class-moment" : ""}
              >
                Grade 2
              </li>
              <div>{centers[toggle ? 0 : 1]?.grade_school.grade_two}</div>
              <li
                onClick={() => setRealClass("Grade 3")}
                className={realClass == "Grade 3" ? "active-class-moment" : ""}
              >
                Grade 3
              </li>
              <div>{centers[toggle ? 0 : 1]?.grade_school.grade_three}</div>
              <li
                onClick={() => setRealClass("Grade 4")}
                className={realClass == "Grade 4" ? "active-class-moment" : ""}
              >
                Grade 4
              </li>
              <div>{centers[toggle ? 0 : 1]?.grade_school.grade_four}</div>
              <li
                onClick={() => setRealClass("Grade 5")}
                className={realClass == "Grade 5" ? "active-class-moment" : ""}
              >
                Grade 5
              </li>
              <div>{centers[toggle ? 0 : 1]?.grade_school.grade_five}</div>
            </div>
          </div>
        </div>
        <div className="underline"></div>
        <div style={{ alignSelf: "flex-start" }}>
          {toggle ? "Topaz centre" : "Emerald centre"} &gt;&gt; {overallClass}{" "}
          &gt;&gt;{" "}
          <span style={{ color: "#E3560F" }}>{realClass.toUpperCase()}</span>
        </div>
        <div className="split-dashboard">
          <div className="split-overview">
            <div className="overview-container">
              <div className="overview-title">
                <p>Overview</p>
                {changeOverview ? (
                  <div className="edit-overview" onClick={editClick}>
                    <img src={Edit} />
                  </div>
                ) : (
                  <button
                    onClick={() => {
                      addOverview();
                      setChangeOverview(true);
                    }}
                  >
                    Save Changes
                  </button>
                )}
              </div>
              <textarea
                value={overview}
                disabled={changeOverview}
                onChange={(e) => setOverview(e.target.value)}
                // ref={textAreaRef}
                name="overview"
                placeholder="Add overview"
              />
            </div>
            <div className="overview-container">
              <div className="overview-title">
                <p>Learning Experience</p>
                {changeExperience ? (
                  <div className="edit-overview" onClick={experienceClick}>
                    <img src={Edit} />
                  </div>
                ) : (
                  <button
                    onClick={() => {
                      addExperience();
                      setChangeExperience(true);
                    }}
                  >
                    Save Changes
                  </button>
                )}
                {/*  */}
              </div>
              <textarea
                value={experience}
                disabled={changeExperience}
                onChange={(e) => setExperience(e.target.value)}
                // ref={experienceRef}
                maxLength={500}
                name="experience"
                placeholder="Add experience"
              />
              <div className="max-length">{experience?.length}/500</div>
            </div>
          </div>
          <div className="split-requirement">
            <div className="edit-requirement">
              <div className="overview-title">
                <p>Entry Requirements</p>
                {changeRequirement ? (
                  <div className="edit-overview" onClick={requirementClick}>
                    <img src={Edit} />
                  </div>
                ) : (
                  <button
                    onClick={() => {
                      addRequirement();
                      setChangeRequirement(true);
                    }}
                  >
                    Save Changes
                  </button>
                )}
              </div>
              <textarea
                value={requirement}
                disabled={changeRequirement}
                onChange={(e) => setRequirement(e.target.value)}
                // ref={requirementRef}
                name="requirement"
                placeholder="Add requirement"
                // maxLength={500}
              />
            </div>
            <div className="sub-list">
              <div className="overview-title">
                <p>Subjects</p>
                {editSub == false ? (
                  <div className="split-editable">
                    <div
                      className="edit-overview"
                      onClick={() => setEditSub(true)}
                    >
                      <img src={Edit} />
                    </div>

                    <div className="edit-overview">
                      <img src={Add} onClick={() => setSubject(true)} />
                    </div>
                  </div>
                ) : (
                  <button onClick={() => setEditSub(false)}>
                    Save Changes
                  </button>
                )}
              </div>
              {loadsubject ? null : subjects?.length > 0 ? (
                <ol className="listed-sub">
                  {subjects?.map((item, i) => (
                    <li key={i}>
                      <span>{item?.subject_name}</span>

                      {editSub ? (
                        <span
                          className="close-popup"
                          onClick={() => {
                            setDeletable(true);
                            setSubId(item?._id);
                          }}
                        >
                          <img src={Cancel} alt="" />
                        </span>
                      ) : null}
                    </li>
                  ))}
                </ol>
              ) : (
                "No subjects created"
              )}
            </div>
            <div className="sub-list">
              <div className="overview-title">
                <p>Readers</p>
                {editBook == false ? (
                  <div className="split-editable">
                    <div
                      className="edit-overview"
                      onClick={() => setEditBook(true)}
                    >
                      <img src={Edit} />
                    </div>
                    <div className="edit-overview">
                      <img src={Add} onClick={() => setReader(true)} />
                    </div>
                  </div>
                ) : (
                  <button onClick={() => setEditBook(false)}>
                    Save Changes
                  </button>
                )}
              </div>
              <div className="reader-div">
                {loadbooks
                  ? null
                  : books?.length > 0
                  ? books?.map((item, i) => (
                      <div className="book-id">
                        <div className="readers" key={i}>
                          <div className="reader-cover">
                            <img src={item?.img_url} alt="" />
                          </div>
                          <div className="book-title">
                            <p>{item.title}</p>
                            <p className="book-author">{item.author}</p>
                          </div>
                        </div>
                        {editBook ? (
                          <span
                            className="close-popup"
                            onClick={() => {
                              setDeletable(true);
                              setBookId(item?._id);
                            }}
                          >
                            <img src={Cancel} alt="" />
                          </span>
                        ) : null}
                      </div>
                    ))
                  : "No books created"}
              </div>
            </div>
            <div
              className="view-student-list"
              onClick={() => setClassList(true)}
            >
              <p>View Class List</p>
              <div>{students.length}</div>
            </div>
          </div>
        </div>
        <Popup isOpen={subject}>
          <form className="add-subject-form" onSubmit={addSubject}>
            <div className="add-remove">
              <p className="addition-text">Add Subject</p>
              <div className="close-popup" onClick={() => setSubject(false)}>
                <img src={Cancel} alt="" />
              </div>
            </div>
            <div className="subject-form">
              <label>Enter Subject Name</label>
              <input value={subName} onChange={handleSub} />
            </div>
            <div style={{ alignSelf: "center" }}>
              <Button disabled={loadingButton} name="Save and Update" />
            </div>
          </form>
        </Popup>
        <Popup isOpen={reader}>
          <form className="add-subject-form" onSubmit={addReader}>
            <div className="add-remove">
              <div>
                <p className="addition-text">Add Reader</p>
                <p className="addition-text">
                  For: Babies and Toddlers- CRECHE
                </p>
              </div>
              <div className="close-popup" onClick={() => setReader(false)}>
                <img src={Cancel} alt="" />
              </div>
            </div>

            <div className="subject-form">
              <label>Book Title ({formData.booktitle.length}/100)</label>
              <input
                value={formData.booktitle}
                onChange={handleReader}
                maxLength="100"
                name="booktitle"
              />
            </div>

            <div className="subject-form">
              <label>Book Author ({formData.bookauthor.length}/100)</label>
              <input
                value={formData.bookauthor}
                onChange={handleReader}
                maxLength="100"
                name="bookauthor"
              />
            </div>

            <div className="subject-form">
              <label>About Book ({formData.aboutbook.length}/200)</label>
              <textarea
                value={formData.aboutbook}
                onChange={handleReader}
                maxLength="100"
                name="aboutbook"
              />
            </div>

            <div className="display-images" style={{ alignItems: "center" }}>
              <div className="form-element" style={{ width: "max-content" }}>
                <legend>Add Book Cover Image</legend>
                <div className="vector-div-gallery">
                  <label>
                    <div className="vector">
                      <img src={vector} alt="" />
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg, .gif"
                        onChange={onFileChange}
                      />
                    </div>
                  </label>
                </div>
              </div>
              {isSelected ? (
                <div className="data-img">
                  <img src={selectedSrc} alt="" />
                </div>
              ) : null}
              <div className="form-element" style={{ width: "max-content" }}>
                <legend>
                  Add Pdf{" "}
                  {pdfFile && (
                    <span>Selected file: {pdfFile ? pdfFile.name : ""}</span>
                  )}
                </legend>
                <div className="vector-div-gallery">
                  <label>
                    /
                    <div className="vector">
                      <img src={vector} alt="" />
                      <input
                        type="file"
                        accept="application/pdf"
                        onChange={(event) => setPdfFile(event.target.files[0])}
                      />
                    </div>
                  </label>
                </div>
              </div>
            </div>

            <div style={{ alignSelf: "center" }}>
              <Button name="Finish and Upload" />
            </div>
          </form>
        </Popup>
        <Popup isOpen={classList}>
          <form className="add-subject-form" onSubmit={addSubject}>
            <div className="add-remove">
              <div
                style={{ alignSelf: "flex-start" }}
                className="addition-text"
              >
                {toggle ? "Topaz centre" : "Emerald centre"} &gt;&gt;{" "}
                {overallClass} &gt;&gt;{" "}
                <span style={{ color: "#E3560F" }}>
                  {realClass.toUpperCase()}
                </span>
              </div>

              <div className="close-popup" onClick={() => setClassList(false)}>
                <img src={Cancel} alt="" />
              </div>
            </div>

            <div className="subject-form">
              <p className="addition-text">Class List</p>
              <div className="database-table">
                <table>
                  <thead>
                    <tr style={{ borderBottom: "none" }}>
                      <th>S/N</th>
                      <th>Name</th>
                      <th>Gender</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {students.map((student, i) => (
                      <tr
                        key={i}
                        onClick={() => {
                          // NOTE:::: The fields don't match totally, the way it is in settingscenter, so navigating to payments leads to white page
                          //   navigate("/admin/settings-view-student", { state: {
                          //     id: student?._id,
                          //     num: 0,
                          //     reg: student,
                          //     admintoken,
                          //     chosenCentre,
                          //     overClassName: realClass,
                          //     type: 1,
                          //     realClass,
                          //     realClass,
                          //     overallClass
                          // } })
                        }}
                        // style={{ cursor: "pointer" }}
                      >
                        <td>{i + 1}</td>
                        <td>{student.fullname}</td>
                        <td>{student.gender}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </form>
        </Popup>
        <Popup isOpen={deletable}>
          <div className="deletable-div">
            <p>
              {subId != ""
                ? "Delete Subject"
                : bookId != ""
                ? "Delete Reader"
                : ""}
            </p>
            <div>
              {subId != ""
                ? "Are you sure you want to delete this subject? All data related to this subject would be deleted, including class notes, test questions and exams!"
                : bookId != ""
                ? "Are you sure you want to delete book?"
                : null}
            </div>
            <div>
              <Button2 name="No" click={cancelDelete} />
              <Button name="Yes" click={subId != "" ? deleteSub : deleteBook} />
            </div>
          </div>
        </Popup>
      </div>
    </div>
  );
}
