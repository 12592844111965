import React, { useState } from "react";
import CarouselView from './gallery/Carousel'
import GalleryFive from "./gallery/GalleryFive";
import GalleryFour from "./gallery/GalleryFour";
import GalleryOne from "./gallery/GalleryOne";
import GalleryThree from "./gallery/GalleryThree";
import GalleryTwo from "./gallery/GalleryTwo";

export default function Gallery() {
  return (
    <div>
      <GalleryOne />
      <CarouselView/>
      <GalleryTwo />
      <GalleryThree />
      <GalleryFour />
      <GalleryFive />
    </div>
  );
}
