import React, { useState, useEffect } from "react";
import Button from "../../components/Button/Button";
import "./gallerythree.css";
import AdvantageOne from "../../images/co-1.png";
import AdvantageTwo from "../../images/co-2.png";
import Advantage3 from "../../images/co-3.png";
import Advantage4 from "../../images/co-4.png";
import Advantage5 from "../../images/co-5.png";
import Advantage6 from "../../images/co-6.png";
import Advantage7 from "../../images/co-7.png";
import Advantage8 from "../../images/co-8.png";
import Advantage9 from "../../images/co-1.png";
import Advantage10 from "../../images/co-2.png";
import Advantage11 from "../../images/co-3.png";
import Advantage12 from "../../images/co-4.png";
import Advantage13 from "../../images/co-5.png";
import Advantage14 from "../../images/co-6.png";
import Advantage15 from "../../images/co-7.png";
import Advantage16 from "../../images/co-8.png";
// import { useState } from "react";

export default function GalleryFour() {

  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);

  const [sliceCount, setSliceCount] = useState(8);

  function handleShowMore() {
    let temp = sliceCount;
    temp+=8;
    setSliceCount(temp);
  }
  function handleShowLess() {
    if(sliceCount > 8){
      let temp = sliceCount;
      temp-=8;
      setSliceCount(temp);
    }
  }

const viewGallery = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_gallery/view_all_gallery",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(),
      }
    );
    const data = await res.json();
    // setLoading(false);
    const gallery = data?.gallery;

    let img = [];
    let vid = [];

    gallery.forEach((item) => {
      if(item.type == "image" && item.urls.length > 0){
        if(item.gallery_name == 'Co-curricular Activities'){
          item.urls.map(url => img.push(url));
        }
      }

      if(item.type == "video" && item.urls.length > 0){
        item.urls.map(url => vid.push(url));
      }
    });

    setImages(img);
    setVideos(vid);
    // setVlogs(data?.vlogs);
    //console.log(vid);
  };

  useEffect(() => {
    viewGallery();
  }, []);

  const renderImages = () => {

    const imageSet = images.slice(0, sliceCount);
    return imageSet.map((src, index) => (
      <div className="gallery-images-grid" key={index}>
        <img src={src} alt={`Image ${index}`} />
      </div>
    ));
  };

  return (
    <div className="gallery-two" style={{ backgroundColor: "#FCEEE8" }}>
      <div><strong>Co-curricular Activities</strong></div>
      <div className="gallery-grid">{renderImages()}</div>
      {images.length > 8 ? (
        <Button name="View more" click={handleShowMore} />
      ) : sliceCount > 8 ? (
        <Button name="Show less" click={handleShowLess} />
      ) : null}
    </div>
  );
}
