import React, { useState, useContext, useEffect } from "react";
import { Triple } from "./ResultTeacher";
import { useNavigate } from "react-router-dom";
import { Table2 } from "./CheckSubject";
import "./checksubject.css";
import "./submitted.css";
import "./setquestions.css";
import { adminContext } from "../context/adminContext";

export default function QuestionArchive() {
  const [toggle, setToggle] = useState("first");

  const values = useContext(adminContext);
  const { teacherData, teachertoken } = values;
  const dataT = JSON.parse(teacherData);

  const [loading, setLoading] = useState(true);

  const tableValues = [
    { data: "S/N", center: false },
    { data: "Subject", center: false },
    { data: "Session", center: true },
    { data: "Term", center: true },
    { data: "", center: true },
  ];

  const [selectedSession, setSelectedSession] = useState('2020/2021');
  const [selectedTerm, setSelectedTerm] = useState('First Term');

  const sessions = [
    '2019/2020',
    '2020/2021',
    '2021/2022',
    '2022/2023',
    '2023/2024',
    '2024/2025',
    '2025/2026',
    '2026/2027',
    '2027/2028',
    '2028/2029',
    '2029/2030'
];
  const terms = ["First Term", "Second Term", "Third Term"];
  const navigate = useNavigate();

  const getSession = async () => {

    const res = await fetch(
      "https://server-olhms.onrender.com/admin_session/view_current_session",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(teachertoken)
        })
      }
    );

    const data = await res.json();
    setLoading(false);
    const session = data?.sessionM;
    setSelectedSession(session.session);
  }

  useEffect(()=>{
    getSession();
  }, []);

  const [subjects, setSubjects] = useState([]);
  const getSubjects = async () => {

    // had to do this because the way the subjects was initially entered doesn't match (in caps) with
    // how this endpoint would be expecting the subject names
    let class_name = dataT?.class_name;
    let temp_class_name = class_name.toLowerCase();
    if (temp_class_name == "creche") {
      class_name = "Creche";
    } else if (temp_class_name == "toddlers") {
      class_name = "Toddlers";
    } else if (temp_class_name == "infant community 1") {
      class_name = "Infant Community 1";
    } else if (temp_class_name == "infant community 2") {
      class_name = "Infant Community 2";
    } else if (temp_class_name == "nursery 1") {
      class_name = "Nursery 1";
    } else if (temp_class_name == "nursery 2") {
      class_name = "Nursery 2";
    } else if (temp_class_name == "grade 1") {
      class_name = "Grade 1";
    } else if (temp_class_name == "grade 2") {
      class_name = "Grade 2";
    } else if (temp_class_name == "grade 3") {
      class_name = "Grade 3";
    } else if (temp_class_name == "grade 4") {
      class_name = "Grade 4";
    } else if (temp_class_name == "grade 5") {
      class_name = "Grade 5";
    }


    const res = await fetch(
      "https://server-olhms.onrender.com/teacher_subject/view_subjects",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(teachertoken),
          class_name: class_name,
          // class_name: dataT?.class_name,
        })
      }
    );

    const data = await res.json();
    setLoading(false);
    setSubjects(data.subjects);
  }

  useEffect(()=>{
    getSubjects();
  }, []);

  const handleSessionChange = e => {
    setSelectedSession(e.target.value);
  }

  const handleTermChange = e => {
    setSelectedTerm(e.target.value);
  }


  const previousSession = (currentSession) => {
    const [part1, part2] = currentSession.split('/');
    return `${part1 -1}/${part2 -1}`;
  }

  const getType = togg => {
    if(togg == 'first'){
      return '1st Continuous Assessment Test';
    }else if(togg == 'second'){
      return '2nd Continuous Assessment Test'
    }else if(togg == 'third'){
      return 'Examination';
    }
  }

  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (document.documentElement.clientWidth <= 800) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={mobile ? '' : 'set-questions'} style={mobile ? {margin: 24} : {}}>
      <div className="set-container">
        <Triple
          text1="1st C.A.T"
          text2="2nd  C.A.T"
          text3="Exam"
          toggle={toggle}
          click1={() => {
            setToggle("first");
          }}
          click2={() => {
            setToggle("second");
          }}
          click3={() => {
            setToggle("third");
          }}
        />
        <div className="submitted-students">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "5px",
            }}
          >
            <Table2 columns={mobile ? "0.1fr 1fr 1fr 1fr 1fr" : "0.5fr 2fr 1fr 1fr 1fr"} data={tableValues} />

            {
              subjects.map((subject, index) => (
                <div
              key={index}
              style={{
                display: "grid",
                gridTemplateColumns: mobile ? "0.1fr 1fr 1fr 1fr 1fr" : "0.5fr 2fr 1fr 1fr 1fr",
                fontWeight: "300",
                gridGap: "30px",
              }}
            >
              <div>{index + 1}</div>
              <div>{subject.subject_name}</div>
              <div className="form-element" style={{ margin: "0 auto" }}>
                <div
                  className="select-mark"
                  style={{
                    height: "30px",
                    width: "max-content",
                  }}
                >
                  <select
                    placeholder={selectedSession}
                    name='session'
                    value={selectedSession}
                    defaultValue={selectedSession}
                    onChange={handleSessionChange}
                    style={{
                      height: "30px",
                      width: "max-content",
                      paddingRight: "20px",
                    }}
                  >
                    {sessions.map((item, index) => (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="form-element" style={{ margin: "0 auto" }}>
                <div
                  className="select-mark"
                  style={{ height: "30px", width: "max-content" }}
                >
                  <select
                    placeholder={selectedTerm}
                    name='term'
                    value={selectedTerm}
                    defaultValue={selectedTerm}
                    onChange={handleTermChange}
                    style={{
                      height: "30px",
                      width: "max-content",
                      paddingRight: "20px",
                    }}
                  >
                    {terms.map((item, index) => (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div
                style={{ fontWeight: 300, cursor: "pointer" }}
                className="centered-text"
                onClick={() => navigate("/teacher/view-question-archive", {state: {
                  term: selectedTerm,
                  session: selectedSession,
                  class_name: dataT.class_name,
                  type: getType(toggle),
                  subject: subject.subject_name
                }})}
              >
                view
              </div>
            </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
}
