import React from "react";
import "./aboutone.css";

export default function AboutOne() {
  return (
    <div className="about-one">
      <div className="about-one-wrapper">
        <p>
          Leading <span>Innovative</span> Learning
        </p>
        <div className="intersecting-one">
          <p>
            <strong>Our Story</strong>
          </p>
          <p>
            Our Lady of Hope Montessori school is a Christian Montessori school
            dedicated to following Christ as well as the spiritual and academic
            development of the whole child through a smooth blend the Montessori
            method of education and the Nigerian national curriculum of
            education. Founded in 2016 by Mrs Sola Okunkpolor alongside other
            like minds committed to changing the face of education beginning for
            their locality with a vision to spread to as many other places as
            possible, the school is approved and registered under the law of the
            federal republic of Nigeria.
          </p>
        </div>
      </div>
    </div>
  );
}
