import React, { useState, useContext, useEffect } from "react";
import "./explore.css";
import Featured from "../../videos/featured.mp4";
import Learn1 from "../../videos/learn-1.mp4";
import Learn2 from "../../videos/learn-2.mp4";
import Learn3 from "../../videos/learn-3.mp4";
import Learn4 from "../../videos/learn-4.mp4";
import { truncateString } from "../adminPages/Vlogs";
import { useNavigate } from "react-router-dom";

export default function Feature() {
  const [resources, setResources] = useState([]);
  const navigate = useNavigate();

  const viewLearning = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_video_learning/view_video_learnings",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pagec: 1 }),
      }
    );
    const data = await res.json();
    // setLoading(false);
    setResources(data?.video_learnings);
    console.log(data);
  };
  useEffect(() => {
    viewLearning();
  }, []);
  const videos = [Learn1, Learn2, Learn3, Learn4, Learn1];
  return (
    <div className="section-eleven">
      <div className="feature-div">
        <div className="feature-vids">
          <div className="main-featured-vid featured-size">
            <video
              poster="https://res.cloudinary.com/dxcguwdyh/image/upload/v1686649236/temp_thumbs/Screenshot_2023-06-13_001049_x57wap.jpg"
              width="100%"
              height="100%"
              src={Featured}
              autoPlay={false}
              controls
            ></video>
            <div className="absolute-feature">Featured Video</div>
          </div>
          <div className="second_feature">
            <div className="split-learn">
              <div className="vid-learn">Video Learning Series</div>
              <p onClick={()=> navigate("/blog", {state: {view_all: true}})}>View all...</p>
            </div>
            <div className="feature-small">
              {resources?.slice(0, 5).map((vid, index) => (
                <SmallFeature
                  src={vid?.video_url}
                  poster_url={vid?.img_url}
                  key={index}
                  details={vid?.summary}
                  // details={truncateString(vid?.summary, 100)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function SmallFeature(props) {
  return (
    <div className="split-feature">
      <div className="small-feature-container small-feature-size">
        <video
          poster={props.poster_url}
          width="100%"
          height="100%"
          autoPlay={false}
          controls
          src={props.src}
          // poster={Dyk}
        ></video>
      </div>
      <div className="feature-text">{props.details}</div>
    </div>
  );
}
