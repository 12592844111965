import React, { useState, useEffect, useContext } from "react";
import { BackButton } from "../studentPages/PersonalData";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./staff.css";
import "../teacherPages/resources.css";
import Add from "../../images/addition.png";
import Passport from "../../images/news1.png";
import { Pagination } from "../news/NewsTwo";
import { adminContext } from "../context/adminContext";
import { ClipLoader } from 'react-spinners';

export default function StudentDatabase() {
  const navigate = useNavigate();

  const [studentsData, setStudentsData] = useState([]);
  const [searchedStudentsData, setSearchedStudentsData] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [loading, setLoading] = useState(true);

  const [isLoaded, setIsLoaded] = useState(false);
  const [count, setCount] = useState(0);
  const values = useContext(adminContext);
  const { admintoken } = values;

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const res = await fetch(
          "https://server-olhms.onrender.com/admin_students/view_students",
          {
            method: "POST",
            body: JSON.stringify({
              token: JSON.parse(admintoken)
            }),
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json, text/plain, */*",
            }
          }
        )
        const data = await res.json();
        console.log(data);
        if(data.msg === 'success') {
          setIsLoaded(true)
          setStudentsData(data.students);
          setCount(data.count);
        }
      }catch(e) {
        console.log(e);
      }
    }      
    fetchStudents();
  }, []);

  const handleSearchChange = e => setSearchString(e.target.value);
  
  // function to search for students
  const searchStudents = async (currPage) => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_students/search_students",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(admintoken),
          search_string: searchString,
          pagec: currPage
        }),
      }
    );
    const data = await res.json();
    setLoading(false);
    if(data?.count != 0){
      setSearchedStudentsData(data?.students);
    }
    console.log(data);
  };

  useEffect(() => {
    if(searchString != ''){
      searchStudents(1);
    }
  }, [searchString]);

  if(!isLoaded) {
     return <h1
        className="loading-pages"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "250px"
        }}
      >
        <ClipLoader color={"orange"} size={100} />
      </h1>
  }else if(studentsData?.length > 0) {
    return (
      <div
        className="set-questions"
        style={{
          backgroundColor: "rgb(255, 248, 248)",
          height: "100%",
          alignItems: "flex-start",
        }}
      >
        <div className="set-container">
          <BackButton click={() => navigate(-1)} />
          <p className="data-number">Students Database: {count}</p>
          <div className="search-input">
            <input placeholder="Search Students Database"
            onChange={handleSearchChange}
            value={searchString}/>
          </div>
  
          <div className="database-table">
            <table>
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Full name</th>
                  <th>Student's ID</th>
                  <th>Centre</th>
                  <th>Class</th>
                  <th>Admission Year</th>
                </tr>
              </thead>
              <tbody>
                {searchString != '' ? searchedStudentsData.map((item, i) => (
                  <tr key = {i}
                    onClick={() => navigate("/admin/view-student", { state: { id: item?._id, num: 0, reg: item} })}
                    style={{ cursor: "pointer" }}
                  >
                    <td>{++i}</td>
                    <td>{item?.fullname}</td>
                    <td>{item?.student_id}</td>
                    <td>{item?.center}</td>
                    <td>{item?.class_name}</td>
                    <td>{item?.addmission_year}</td>
                  </tr>                
                )) : studentsData.map((item, i) => (
                  <tr key = {i}
                    onClick={() => navigate("/admin/view-student", { state: { id: item?._id, num: 0, reg: item} })}
                    style={{ cursor: "pointer" }}
                  >
                    <td>{++i}</td>
                    <td>{item?.fullname}</td>
                    <td>{item?.student_id}</td>
                    <td>{item?.center}</td>
                    <td>{item?.class_name}</td>
                    <td>{item?.addmission_year}</td>
                  </tr>                
                ))}
              </tbody>
            </table>
          </div>
          <Pagination />
        </div>
      </div>
    );
  }
}
