import React from "react";
import "./explore.css";
import Explore1 from "../../videos/explore-1.mp4";
import Explore2 from "../../videos/explore-2.mp4";
import Explore3 from "../../videos/explore-3.mp4";
import Explore4 from "../../videos/explore-4.mp4";

export default function Explore() {
  return (
    <div className="section-seven">
      <div className="explore-div">
        <p className="six-p">
          <strong>Explore our School</strong>
        </p>
        <div className="explore-videos">
          <div className="explore-first">
            <video
              width="100%"
              height="100%"
              src={Explore1}
              autoPlay={false}
              controls
            ></video>
          </div>
          <div className="explore-second">
            <div className="explore-second-vid">
              <video
                poster="https://res.cloudinary.com/dxcguwdyh/image/upload/v1686660383/temp_thumbs/Screenshot_2023-06-13_134530_ejaq56.jpg"
                width="100%"
                height="100%"
                src={Explore2}
                autoPlay={false}
                controls
              ></video>
            </div>
            <div className="explore-third">
              <div>
                <video
                  width="100%"
                  height="100%"
                  src={Explore3}
                  autoPlay={false}
                  controls
                ></video>
              </div>
              {/* <div>
                <video
                  width="100%"
                  height="100%"
                  src={Explore4}
                  autoPlay={false}
                  controls
                ></video>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
