import React, { useState, useEffect, useContext } from "react";
import { capitalizeFirstLetter } from "./StaffDatabase";
import { BackButton } from "../studentPages/PersonalData";
import { useNavigate } from "react-router-dom";
import Add from "../../images/addition.png";
import Passport from "../../images/news1.png";
import { adminContext } from "../context/adminContext";
import Popup from "reactjs-popup";
import { IoMdMore } from "react-icons/io";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Button, { Button2 } from "../../components/Button/Button";

export default function AdminDatabase() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [showView, setShowView] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const [admins, setAdmins] = useState([]);
  const [searchedAdmins, setSearchedAdmins] = useState([]);
  const [searchString, setSearchString] = useState("");
  
  const [stats, setStats] = useState({});

  const values = useContext(adminContext);
  const { admintoken } = values;
  const [openDialog, handleDisplay] = useState(false);
  const openDialogBox = () => {
    handleDisplay(true);
  };
  const handleCloseDialogBox = () => {
    handleDisplay(false);
  };
  const [admin_details, set_admin_details] = useState({});


  const details = [
    "1",
    "Bunmi Ade",
    "F",
    "BH8901KI",
    "Topaz",
    "Master",
    "2011",
  ];

  
  const Menu = (details) => (
    <div className="optionsMenu">
      <Popup
        trigger={
          <button className="optionsMenu-details">
            {" "}
            <IoMdMore />{" "}
          </button>
        }
        position="right top"
        on="click"
        closeOnDocumentClick
        mouseLeaveDelay={300}
        mouseEnterDelay={0}
        contentStyle={{ padding: "0px", border: "none" }}
        // arrow={false}
      >
        <ul className="optionsMenu-item">
          <li
            onClick={() =>
              navigate("/admin/adminview", {
                state: { num: 2, id: details?._id, admin: details },
              })
            }
            
          >
            Edit
          </li>
          <li
            onClick={() => {
              // console.log(details.email);
              // deleteStaff(details);
              set_admin_details(details);
              openDialogBox();
            }}
          >
            Delete
          </li>
          <li
            onClick={() => {
              navigate("/admin/email-staff", {
                state: { staff: details },
              });
            }}
          >
            Send Email
          </li>
        </ul>
      </Popup>
    </div>
  );

  const viewAdmins = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/master_admin/view_admins",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(admintoken),
        }),
      }
    );
    const data = await res.json();
    setLoading(false);
    setAdmins(data.admins);
    console.log(data);
  };

  useEffect(() => {
    viewAdmins();
  }, []);

  const deleteAdmin = async (admin_id) => {
    setLoadingButton(true);
    const res = await fetch(
      "https://server-olhms.onrender.com/master_admin/delete_admin",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(admintoken),
          admin_id: admin_id
        }),
      }
    );
    const data = await res.json();
    setLoadingButton(false);
    handleCloseDialogBox();
    viewAdmins();
    console.log(data);
  };


  const handleSearchChange = e => setSearchString(e.target.value);

  const searchAdmins = async (currPage) => {
    const res = await fetch(
      "https://server-olhms.onrender.com/master_admin/search_admins",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(admintoken),
          search_string: searchString,
          pagec: currPage
        }),
      }
    );
    const data = await res.json();
    setLoading(false);
    setSearchedAdmins(data?.admins);
    console.log(data);
  };

  useEffect(() => {
    if(searchString != ''){
      searchAdmins(1);
    }
  }, [searchString]);

  const viewStatistics = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_statistics/view_statistics",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(admintoken),
        }),
      }
    );
    const data = await res.json();
    console.log(data);
    if(data.msg === 'success') {
      setLoading(false);
      setStats(data.statistics);
    }
  };

  useEffect(() => {
    viewStatistics();
  }, []);

  return (
    <div
      className="set-questions"
      style={{
        backgroundColor: "rgb(255, 248, 248)",
        height: "100%",
        alignItems: "flex-start",
      }}
    >
      <div className="set-container">
        <BackButton click={() => navigate(-1)} />
        <p className="data-number">Administrators Database: {stats.no_of_admins}</p>
        <div className="search-input">
          <input placeholder="Search Administrative Database" value={searchString} onChange={handleSearchChange} />
        </div>
        <div
          className="create-teacher"
          onClick={() => navigate("/admin/create-admin")}
        >
          <img src={Add} />
        </div>
        <div className="database-table">
          <table>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Full name</th>
                <th>Gender</th>
                <th>Staff ID</th>
                <th>Centre</th>
                <th>Admin Role</th>
                <th>Employment Year</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
            {searchString != '' ? searchedAdmins.map((item, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate("/admin/adminview", {
                          state: { num: 1, id: item?._id, admin: item   },
                        })
                      }
                    >
                      {item?.firstname +
                        " " +
                        item?.lastname}
                    </td>
                    <td>{capitalizeFirstLetter(item?.gender[0])}</td>
                    <td>{item?.staff_id}</td>
                    <td>{capitalizeFirstLetter(item?.center)}</td>
                    <td>{item?.roles.toString()}</td>
                    <td>{item?.employment_year}</td>

                    <td>
                      <div className="dropdown" style={{padding: 8}}>
                        <p className="dropdown-btn">&#8942;</p>
                        <div className="dropdown-content">
                          <p
                            onClick={() =>
                              navigate("/admin/adminview", {
                                state: { num: 2, id: item?._id, admin: item },
                              })
                            }
                          >
                            Edit
                          </p>
                          <p onClick={() => {
                            deleteAdmin(item?._id);
                          }}>Delete</p>
                          <p onClick={() => {
                            navigate("/admin/email-staff", {
                                state: {staff: item },
                              })
                          }}>Send Email</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                )) : admins.map((item, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate("/admin/adminview", {
                          state: { num: 1, id: item?._id, admin: item  },
                        })
                      }
                    >
                      {item?.firstname +
                        " " +
                        item?.lastname}
                    </td>
                    <td>{capitalizeFirstLetter(item?.gender[0])}</td>
                    <td>{item?.staff_id}</td>
                    <td>{capitalizeFirstLetter(item?.center)}</td>
                    <td>{item?.roles.toString()}</td>
                    <td>{item?.employment_year}</td>
                    
                    {
                          <td>
                            <div className="popup-click">{Menu(item)}</div>
                          </td>
                        }

                    {/* <td>
                      <div className="dropdown">
                        <p className="dropdown-btn">&#8942;</p>
                        <div className="dropdown-content">
                          <p
                            onClick={() =>
                              navigate("/admin/adminview", {
                                state: { num: 2, id: item?._id, admin: item   },
                              })
                            }
                          >
                            Edit
                          </p>
                          <p onClick={() => {
                            deleteAdmin(item?._id);
                          }}>Delete</p>
                          <p onClick={() => {
                            navigate("/admin/email-staff", {
                                state: {staff: item },
                              })
                          }}>Send Email</p>
                        </div>
                      </div>
                    </td> */}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
        <Dialog onClose={handleCloseDialogBox} open={openDialog}>
          <DialogTitle>Delete Admin?</DialogTitle>
          <div style={{ marginLeft: 100, marginRight: 100 }}>
            <div style={{ marginBottom: 12 }}>
              Are you sure you want to delete{" "}
              {admin_details?.firstname +
                        " " +
                        admin_details?.lastname}
              ?
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 20,
              }}
            >
            <div >

              <Button2
                
                click={(e) => {
                  e.preventDefault();
                  handleCloseDialogBox();
                }}
                style={{ marginBottom: 10,marginRight: 20, }}
                name={"Cancel"}
              />
              <Button
                disabled={loadingButton}
                click={(e) => {
                  e.preventDefault();

                  if(loadingButton == false){
                    deleteAdmin(admin_details);

                  }

                }}
                style={{ marginBottom: 10, marginLeft: 20, }}
                name={"Delete"}
              />
            </div>
            </div>
          </div>
        </Dialog>
    </div>
  );
}
