import React from "react";
import { AdmissionComp } from "../../components/Student/NameComponent";
import { AdmissionNav } from "../../components/Student/StudentNav";
import GradeI from "../../images/grade.png";
import Rec1 from "../../images/rec1.png";
import Rec2 from "../../images/rec2.png";
import Rec3 from "../../images/rec3.png";
import Rec4 from "../../images/rec4.png";
import Rec5 from "../../images/rec5.png";
import Rec6 from "../../images/rec6.png";
import { ClassDetails } from "./Apply";

export default function Grade() {
  const Gradeclasses = ["Grade 1", "Grade 2", "Grade 3", "Grade 4", "Grade 5"];
  const images = [Rec1, Rec2, Rec3, Rec4, Rec5, Rec6];
  return (
    <div>
      <AdmissionComp
        style={{ backgroundImage: `url(${GradeI})` }}
        title="Grade School"
        classes={Gradeclasses.map((item, i) => (
          <p key={i}>&#x2022; {item}</p>
        ))}
      />
      <AdmissionNav />
      <ClassDetails images={images} school='grade' school_id='642b9b0594b988f5dd1daa0f'/>
    </div>
  );
}
