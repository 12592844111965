import React, { useState, useEffect } from "react";
import Edit from "../../images/edit.png";
import Add from "../../images/addition.png";
import { BackButton } from "../studentPages/PersonalData";
import Passport from "../../images/shola.png";
import Button, { Button2 } from "../../components/Button/Button";
import { useLocation } from "react-router-dom";
import axios from "axios";
import qs from 'qs';
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";

export default function AdminProfile(props) {

  let adminToken = localStorage.getItem("admin_rubbish");

  const admin = useLocation().state;

  const [firstname, setFirstname] = useState(admin.firstname);
  const [lastname, setLastname] = useState(admin.lastname);
  const [address, setAddress] = useState(admin.address);
  const [country, setCountry] = useState(admin.country);
  const [phone_no1, setPhone1] = useState(admin.phone_no1);
  const [phone_no2, setPhone2] = useState(admin.phone_no2);
  const [email, setEmail] = useState(admin.email);
  const [religion, setReligion] = useState(admin.religion);

  const [edit, setEdit] = useState(false);

  const handleFName = (e) => {
    setFirstname(e.target.value);
  }

  const handleLName = (e) => {
    setLastname(e.target.value);
  }

  const handleAddress = (e) => {
    setAddress(e.target.value);
  }

  const handleCountry = (e) => {
    setCountry(e.target.value);
  }

  const handlePhone1 = (e) => {
    setPhone1(e.target.value);
  }

  const handlePhone2 = (e) => {
    setPhone2(e.target.value);
  }

  const handleEmail = (e) => {
    setEmail(e.target.value);
  }

  const handleReligion = (e) => {
    setReligion(e.target.value);
  }

  // function to edit profile
  const [loading, setLoading] = useState(false);
  const updateProfile = async () => {

    setLoading(true);

    const data = {
      "firstname": firstname,
      "lastname": lastname,
      "address": address,
      "country": country,
      "phone_no1": phone_no1,
      "phone_no2": phone_no2,
      "email": email,
      "religion": religion,
      "token": JSON.parse(adminToken)
    }

    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: "https://server-olhms.onrender.com/admin_profile/edit_profile"
    }

    axios(options).then(response => {
      if(response.status == 200){
        console.log("response: :: :", response);
        let admin = response?.data.admin;
        console.log("admin: :: :", admin);
        localStorage.setItem("name", `${firstname} ${lastname}`);
        //admin.updateData(`${firstname} ${lastname}`);
        setEdit(false);
      }

      setLoading(false);
    }).catch(e => {
      console.log('an error occured:  :  ', e);

      setLoading(false);
    });

  }

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [match, setMatch] = useState(true)

  const [openDialog, handleDisplay] = useState(false);

  const handleClose = () => {
    handleDisplay(false);
    setConfirmPassword('');
    setOldPassword('');
    setNewPassword('');
  };

  const openDialogBox = () => {
     handleDisplay(true);
  };
  const dialogStyle = {
     padding: "20px",
  };

  const changePassword = async () => {
    setLoading(true);

    try {
      const res = await fetch(
        "https://server-olhms.onrender.com/admin_auth/change_password_web",
        {
          method: "POST",
          body: JSON.stringify({
            token: JSON.parse(adminToken),
            old_password: oldPassword,
            new_password: newPassword,
            confirm_password: confirmPassword
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const data = await res.json();
      console.log(data);

      if (data.msg == "Success") {
        setLoading(false)
        alert("password change successful");
        handleClose();
      } else {
        setLoading(false)
        console.log(data);
        alert("An error occured");

      }
    } catch (error) {
      setLoading(false)
      alert("An error occured");
      console.log(error);
    }
  }

  const handleOldPassword = e => setOldPassword(e.target.value);
  const handleNewPassword = e => setNewPassword(e.target.value);
  const handleConfirmPassword = e => setConfirmPassword(e.target.value);

  useEffect(()=>{
    if(newPassword == confirmPassword){
      setMatch(true)
    }else{
      setMatch(false);
    }
  }, [newPassword, confirmPassword]);

  return (
    <div
      className="set-questions"
      style={{
        backgroundColor: "rgb(255, 248, 248)",
        height: "100%",
        alignItems: "flex-start",
      }}
    >
      <div
        className="set-container"
        // style={{ padding: toggle ? "" : "0 120px" }}
      >
        {edit ? (
          <div style={{ alignSelf: "flex-start", marginBottom: "20px" }}>
            <BackButton click={() => setEdit(false)} />
          </div>
        ) : null}
        <p
          style={{
            alignSelf: "flex-start",
            fontSize: "20px",
            fontWeight: "600",
            marginBottom: "60px",
          }}
        >
          My Profile
        </p>
        {edit === false ? (
          <div
            style={{ alignSelf: "flex-end" }}
            className="edit-img"
            onClick={() => setEdit(true)}
          >
            <img src={Edit} alt="" />
          </div>
        ) : null}

        <div className="show-details">
          <div>First Name:</div>
          {edit ? (
            <input className="properties-input"
                placeholder=""
                name='fname'
                value={firstname}
                onChange={handleFName}/>
          ) : (
            <div className="light-property">{firstname}</div>
          )}
          <div>Middle Name:</div>
          {edit ? (
            <input className="properties-input" />
          ) : (
            <div className="light-property">Nil</div>
          )}
          <div>Last Name:</div>
          {edit ? (
            <input className="properties-input"
              placeholder=""
                name='lname'
                value={lastname}
                onChange={handleLName}
            />
          ) : (
            <div className="light-property">{lastname}</div>
          )}
          <div>Permanent Address:</div>
          {edit ? (
            <input className="properties-input"
              placeholder=""
                name='address'
                value={address}
                onChange={handleAddress}
            />
          ) : (
            <div className="light-property">{address}</div>
          )}
          <div>Country:</div>
          {edit ? (
            <input className="properties-input" 
              placeholder=""
                name='country'
                value={country}
                onChange={handleCountry}
            />
          ) : (
            <div className="light-property">{country}</div>
          )}
          <div>Phone Number 1:</div>
          {edit ? (
            <input className="properties-input"
              placeholder=""
                name='phone1'
                value={phone_no1}
                onChange={handlePhone1}
            />
          ) : (
            <div className="light-property">{phone_no1}</div>
          )}
          <div>Phone Number 2:</div>
          {edit ? (
            <input className="properties-input" 
              placeholder=""
                name='phone2'
                value={phone_no2}
                onChange={handlePhone2}
            />
          ) : (
            <div className="light-property">{phone_no2 == '' ? "Nil" : phone_no2}</div>
          )}
          <div>Email:</div>
          {edit ? (
            <input className="properties-input" 
              placeholder=""
                name='email'
                value={email}
                onChange={handleEmail}
            />
          ) : (
            <div className="light-property">{email}</div>
          )}
          <div>Religion:</div>
          {edit ? (
            <input className="properties-input"
              placeholder=""
                name='religion'
                value={religion}
                onChange={handleReligion}
            />
          ) : (
            <div className="light-property">{religion}</div>
          )}
          <div>Gender:</div>
          <div className="light-property">{admin.gender}</div>
          <div>Staff ID:</div>
          <div className="light-property">{admin.staff_id}</div>
          <div>Centre:</div>
          <div className="light-property">{admin.center}</div>
          <div>Admin Role:</div>

          <div className="light-property">{admin.roles.toString()}</div>

          <div>Employment Year:</div>
          <div className="light-property">{admin.employment_year}</div>
          <div>Photo:</div>
          <div className="teacher-icon">
            <img src={admin.img_url} alt="" />
          </div>
        </div>

        {edit ? (
          <Button name="Update & Save Personal Data" click={()=>{
            updateProfile();
          }} disabled={loading}/>
        ) : (
          <Button2 name="Change Password" click={(e)=>{
            e.preventDefault();
            openDialogBox();
          }}/>
        )}
      </div>

      <Dialog  onClose = {handleClose} open = {openDialog}>
            <DialogTitle>Change Password</DialogTitle>
            <div style={{marginLeft: 100, marginRight: 100}}>

            <div style={{marginBottom: 12}}>Old Password</div>
            <input className="properties-input" style={{marginBottom: 20}}
              value={oldPassword}
              name='old_password'
              placeholder="Enter Old Password"
              type="password"
              onChange={handleOldPassword}
            />

            <div style={{marginBottom: 12}}>New Password</div>
            <input className="properties-input" style={{marginBottom: 20}}
              value={newPassword}
              name='new_password'
              placeholder="Enter New Password"
              type="password"
              onChange={handleNewPassword}
            />

            <div style={{marginBottom: 12}}>Confirm Password</div>
            <input className="properties-input" style={{marginBottom: 20}}
              value={confirmPassword}
              name='confirm_password'
              placeholder="Confirm New Password"
              type="password"
              onChange={handleConfirmPassword}
            />
            <div style={{display: 'flex', justifyContent: 'center', visibility: match ? 'hidden' : 'visible'}}>
              <div style={{marginBottom: 10, color: 'red'}}>Passwords do not match!</div>
            </div>


            <div style={{display: 'flex', justifyContent: 'center', marginBottom: 20}}>
            <Button disabled={loading} click={(e)=>{
              e.preventDefault();
              if(newPassword == '' || oldPassword == '' || confirmPassword == '')
                return;

              if(match){
                changePassword();
              }

            }} style={{marginBottom: 10}} name={'Save and Update'}/>
            </div>
          
            </div>
            
         </Dialog>
    </div>
  );
}
