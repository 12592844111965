import React from "react";
import "./newsone.css";
import "../admissionSections/admissionone.css";

export default function NewsOne() {
  return (
    <div className="news-one">
      <div className="admission-one-div">
        <p className="news-second">
          Stay informed about upcoming school
          <span className="no-display-news">
            <strong> EVENTS</strong> and <strong>NEWS</strong>
          </span>
        </p>
        <p className="news-second classy-e">
          <span>EVENTS</span> and <span>NEWS</span>
        </p>
      </div>
    </div>
  );
}
