import React, { useEffect, useContext, useState } from "react";
import "./firstcat.css";
import { useLocation, useNavigate } from "react-router-dom";
// import { SubjectComponent } from "./FirstCat";
import { adminContext } from "../context/adminContext";

export default function FirstCat(props) {
  const navigate = useNavigate();
  const values = useContext(adminContext);
  const { teacherData, teachertoken } = values;
  const dataT = JSON.parse(teacherData);
  const location = useLocation();
  console.log(location);
  const type = location.state.type;

  // const type = location.state.type;
  const [loading, setLoading] = useState(true);
  const [subjects, setSubjects] = useState([]);
  const columns = 2;
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const colors = [
    "#AAD7F7",
    "#BDECCA",
    "#FBECA1",
    "#F8D6C7",
    "#DAD4F6",
    "FFC0CB",
    "#C4A484",
    "#D3D3D3",
    "#AAD7F7",
    "#BDECCA",
    "#FBECA1",
    "#F8D6C7",
    "#DAD4F6",
    "FFC0CB",
    "#C4A484",
    "#D3D3D3",
  ];


  const [session, setSession] = useState({});
  const getSession = async () => {
    console.log('session get');
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_session/view_current_session",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(teachertoken)
        })
      }
    );

    const data = await res.json();
    setLoading(false);
    const session = data?.sessionM;
    setSession(session);
  }

  useEffect(()=>{
    getSession();
  }, []);



  const viewSub = async () => {
    // had to do this because the way the subjects was initially entered doesn't match (in caps) with
    // how this endpoint would be expecting the subject names
    let class_name = dataT?.class_name;
    let temp_class_name = class_name.toLowerCase();
    if (temp_class_name == "creche") {
      class_name = "Creche";
    } else if (temp_class_name == "toddlers") {
      class_name = "Toddlers";
    } else if (temp_class_name == "infant community 1") {
      class_name = "Infant Community 1";
    } else if (temp_class_name == "infant community 2") {
      class_name = "Infant Community 2";
    } else if (temp_class_name == "nursery 1") {
      class_name = "Nursery 1";
    } else if (temp_class_name == "nursery 2") {
      class_name = "Nursery 2";
    } else if (temp_class_name == "grade 1") {
      class_name = "Grade 1";
    } else if (temp_class_name == "grade 2") {
      class_name = "Grade 2";
    } else if (temp_class_name == "grade 3") {
      class_name = "Grade 3";
    } else if (temp_class_name == "grade 4") {
      class_name = "Grade 4";
    } else if (temp_class_name == "grade 5") {
      class_name = "Grade 5";
    }

    const res = await fetch(
      "https://server-olhms.onrender.com/teacher_subject/view_subjects",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(teachertoken),
          class_name: class_name,
          // class_name: dataT?.class_name,
        }),
      }
    );
    const data = await res.json();
    setLoading(false);
    setSubjects(data?.subjects);

    console.log(data);
  };

  useEffect(() => {
    viewSub();
  }, []);

    // basic responsiveness handling
    const [mobile, setMobile] = useState(false);
    useEffect(() => {
      function handleResize() {
        if (document.documentElement.clientWidth <= 800) {
          setMobile(true);
        } else {
          setMobile(false);
        }
      }
  
      handleResize();
  
      window.addEventListener("resize", handleResize);
  
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
  return (
    <div className="set-questions">
      <div className="set-container">
        <div className="cat-session">
          <p>{session.current_term}</p>
          <p>{type}</p>
          <p>{session.session} Session</p>
        </div>
        <div className={mobile ? '' : "set-subjects"}>
          <p>
            <strong style={{fontSize: 20}}>Subjects</strong>
          </p>
          <div className={mobile ? '' : "set-grid"} style={mobile ? {
            
          } : {}}>
            {subjects?.map((item, index) => {
              const row = Math.floor(index / columns);
              const backgroundColor = colors[row % colors.length];
              return (
                <div key={index} style={mobile ? {marginTop: 16} : {}}>
                <SubjectComponent
                  key={index}
                  color={backgroundColor}
                  subject={item?.subject_name}
                  click={() =>
                    navigate("/teacher/sections", {
                      state: { subject: item?.subject_name, session, type, class_name: dataT.class_name},
                    })
                  }
                />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export function SubjectComponent(props) {
  return (
    <div className="grid-sub" onClick={props.click}>
      {props.color ? (
        <div className="sub-first" style={{ backgroundColor: props.color }}>
          {props.subject ? props.subject.slice(0, 1) : "M"}
        </div>
      ) : null}
      <div className="sub-second">
        {props.subject ? props.subject : "Mathematics"}
      </div>
    </div>
  );
}
