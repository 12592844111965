import React from "react";
import { useEffect } from "react";
import Phones from "../../images/phones.png";
import "./sectioneleven.css";
import Foot from "../../images/foot.png";
import PlayStore from "../../images/playstore.png";
import Elearn from "../../images/e-learning.png";

import {
  isAndroid,
  isIOS
} from "react-device-detect";

export default function SectionEleven() {

  // useEffect(() => {
    
  // }, []);

  return (
    <div className="section-eleven">
      <div className="display-foot">
        <div className="images-footlearn2">
          <div className="foot-image">
            <img src={Foot} alt="" />
          </div>
          <div className="elearn-image">
            <p className="elearn-image-main">
              OLHMS e-learning Mobile Application
            </p>
            <p className="elearn-image-italic">
              Inspired to be exceptionally different
            </p>
            {/* <img src={Elearn} alt="" /> */}
          </div>
        </div>
        <div className="section-eleven-phones">
          <img src={Phones} alt="" className="phones-foot" />
        </div>
      </div>
      <div>
        <div className="images-footlearn">
          <div className="foot-image">
            <img src={Foot} alt="" />
          </div>
          <div className="elearn-image">
            <p className="elearn-image-main">
              OLHMS e-learning Mobile Application
            </p>
            <p className="elearn-image-italic">
              Inspired to be exceptionally different
            </p>
            {/* <img src={Elearn} alt="" /> */}
          </div>
        </div>
        <div className="playstore-image" onClick={() =>{
          console.log('link clicked');
          if (isAndroid) {
            window.location.href =
              "https://play.google.com/store/apps/details?id=com.learn.olhms&hl=en&gl=US";
          }else if(isIOS) {
            window.location.href =
              "https://apps.apple.com/app/capsigo/id1547746310";
          } else{
            window.location.href =
              "https://play.google.com/store/apps/details?id=com.learn.olhms&hl=en&gl=US";
          }
        }}>
          <img src={PlayStore} alt="" />
        </div>
      </div>
    </div>
  );
}
