import React, { useState, useEffect } from "react";
import Blog1 from "../../images/blog1.png";
import Blog2 from "../../images/blog2.png";
import Blog3 from "../../images/blog3.png";
import Blog4 from "../../images/blog4.png";
import "./sectionten.css";
import "./sectioneight.css";
import Next from "../../images/next.png";
import Prev from "../../images/prev.png";
import { truncateString } from "../adminPages/Vlogs";
import { useNavigate } from "react-router-dom";

export default function SectionTen() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const [sliceValue, setSliceValue] = useState(4);
  const [showNumber, setShowNumber] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const viewBlogs = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_blog/view_blogs",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(),
      }
    );
    const data = await res.json();
    setLoading(false);
    setBlogs(data?.blogs);
    console.log(data);
  };
  useEffect(() => {
    viewBlogs();
  }, []);
  function handlePrev() {
    setIndex(index - 1);
  }

  function handleNext() {
    setIndex(index + 1);
  }
  const classArray = [
    {
      img: Blog1,
      header: "Discussing boundaries with kids",
      details:
        "Boundaries that provides an individualised education, tailored to meet all of your child's needs. We maintain small class sizes to ensure that",
    },
    {
      img: Blog2,
      header: "Improving Parent Child relationships",
      details:
        "Boundaries that provides an individualised education, tailored to meet all of your child's needs. We maintain small class sizes to ensure Keep reading",
    },
    {
      img: Blog3,
      header: "Psychological effect of employment and early child care",
      details:
        "Boundaries that provides an individualised education, tailored to meet all of your child's needs. We maintain small class sizes to ensure that",
    },
    {
      img: Blog4,
      header: "Choosing a parenting style",
      details:
        "Boundaries that provides an individualised education, tailored to meet all of your child's needs. We maintain small class sizes to ensure that",
    },
    {
      img: Blog1,
      header: "Discussing boundaries with kids",
      details:
        "Boundaries that provides an individualised education, tailored to meet all of your child's needs. We maintain small class sizes to ensure that",
    },
    {
      img: Blog2,
      header: "Improving Parent Child relationships",
      details:
        "Boundaries that provides an individualised education, tailored to meet all of your child's needs. We maintain small class sizes to ensure Keep reading",
    },
  ];

  useEffect(() => {
    function handleResize() {
      if (
        document.documentElement.clientWidth <= 1000 &&
        document.documentElement.clientWidth > 780
      ) {
        setSliceValue(3);
      } else if (document.documentElement.clientWidth <= 780) {
        setSliceValue(2);
      } else {
        setSliceValue(4);
      }

      if (document.documentElement.clientWidth <= 570) {
        setShowNumber(false);
      } else {
        setShowNumber(true);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="section-ten">
      <p className="Home-p">
        <strong>Blog</strong>
      </p>
      <div className="relative-blog">
        <div className="blog-flex">
          <div className="prev-button">
            {index > 0 && <img src={Prev} alt="" onClick={handlePrev} />}
          </div>
          {blogs.slice(index, index + sliceValue).map((zoomObj, index) => (
            <BlogHome
              key={index}
              img={zoomObj?.img_url}
              header={truncateString(zoomObj?.title, 33)}
              details={truncateString(zoomObj?.body, showNumber ? 120 : 50)}
              click={() =>
                navigate("/blog-content", {
                  state: {
                    img: zoomObj?.img_url,
                    title: zoomObj?.title,
                    body: zoomObj?.body,
                    timestamp: zoomObj?.timestamp,
                    view_count: zoomObj?.view_count,
                    comment_count: zoomObj?.comment_count,
                    like_count: zoomObj?.like_count,
                    blog_id: zoomObj?._id
                  },
                })
              }
            />
          ))}

          <div className="next-button">
            {index + sliceValue < classArray.length && (
              <img src={Next} alt="" onClick={handleNext} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export function BlogHome(props) {
  const [hovered, setHovered] = useState("");
  const [gradient, setGradient] = useState("");
  const change = () => {
    setHovered("change-color");
    setGradient("add-gradient");
  };
  const reset = () => {
    setHovered("");
    setGradient("");
  };
  return (
    <div className="home-blog">
      <div
        className="blog-image"
        onMouseOver={change}
        onMouseOut={reset}
        onClick={props.click}
      >
        <img src={props.img} alt="" className={gradient} />
      </div>
      <div className="home-blog-text">
        <p
          className={hovered}
          onMouseOver={change}
          onMouseOut={reset}
          onClick={props.click}
        >
          <strong>{props.header}</strong>
        </p>
        <div className="blog-details-div">
          <p className="blog-details">{props.details}</p>
          <p
            className={`keep-reading ${hovered}`}
            onMouseOver={change}
            onMouseOut={reset}
            onClick={props.click}
          >
            Keep reading
          </p>
        </div>
      </div>
    </div>
  );
}
