import React from "react";
import '../studentPages/resultdetails.css'
import Passport from "../../images/news1.png";
import { useNavigate } from "react-router-dom";
import '../studentPages/personaldata.css'
import "../teacherPages/teacherprofile.css";

export default function SettingsResultDetails() {
  const navigate = useNavigate();
  return (
    <>
      <div className="details-wrapper">
        <div className="result-details">
          <div className="details-container">
            <div className="details-content">
              <ul className="details-list">
                <li>Class:</li>
                <li>Term:</li>
                <li>Verdict:</li>
                <li>Validated by:</li>
                <li>Validation date:</li>
                <li>Class Teachers Remark:</li>
              </ul>
              <ul className="details-list faint-details">
                <li>Infant Community 1</li>
                <li>First Term</li>
                <li>Successful</li>
                <li>Miss Tracy</li>
                <li>12/05/2020 09:45:16 AM</li>
                <li>
                  A very bright and attentive student. Needs more attention in
                  use of English.
                  <br />
                  However she loves Mathematics and outdoor sporting activities.
                </li>
              </ul>
              <ul></ul>
            </div>
            <div className="download-container">
              <img src={Passport} alt="student-img" style={{ width: "20%" }} />
            </div>
          </div>
          <div className="subjects-flex">
            <div className="subjects-column">
              <div>Subject</div>

              <div className="details-flex">
                <div className="faint-details">Intoductory Technology</div>
                <div className="faint-details">Intoductory Technology</div>
                <div className="faint-details">Intoductory Technology</div>
                <div className="faint-details">Intoductory Technology</div>
                <div className="faint-details">Intoductory Technology</div>
                <div className="faint-details">Intoductory Technology</div>
                <div className="faint-details">Intoductory Technology</div>
                <div className="faint-details">Intoductory Technology</div>
                <div className="faint-details">Intoductory Technology</div>
                <div className="faint-details">Intoductory Technology</div>
              </div>
            </div>
            <div className="centralise-result">
              <div>1st C.A.T (20%)</div>
              <div className="details-flex">
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
              </div>
            </div>
            <div className="centralise-result">
              <div>2nd C.A.T (20%)</div>
              <div className="details-flex">
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
              </div>
            </div>
            <div className="centralise-result">
              <div>Exam (60%)</div>
              <div className="details-flex">
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
              </div>
            </div>
            <div className="centralise-result">
              <div>% Average</div>
              <div className="details-flex">
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
                <div className="faint-details">15</div>
              </div>
            </div>
            <div className="subjects-column">
              <div>Grade</div>
              <div className="details-flex">
                <div className="faint-details">Very good</div>
                <div className="faint-details">Very good</div>
                <div className="faint-details">Very good</div>
                <div className="faint-details">Very good</div>
                <div className="faint-details">Very good</div>
                <div className="faint-details">Very good</div>
                <div className="faint-details">Very good</div>
                <div className="faint-details">Very good</div>
                <div className="faint-details">Very good</div>
                <div className="faint-details">Very good</div>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              navigate(-1);
            }}
            className="back-button"
          >
            {/* <span>Back</span>
            <span className="arrow-div">
              <img src={Arrow} alt="" />
            </span> */}
          </div>
        </div>
      </div>
      <div className="word-row">
        <span>Interpretation of grades</span>
        <span>70(A)Excellent</span>
        <span>60-69(B)Very Good</span>
        <span>50-59(c)Good</span>
        <span>40-49(D)Fair</span>
        <span>39(F)Fail</span>
      </div>
      <div className="mainrow">
        <div className="firstrow">
          <div className="personal-data">
            <div className="data-wrapper">
              {/* <div className="data-div"> */}
              <div className="show-details">
                <h5>Result Sumarry</h5>
                <br />
                <div>Terms Total</div>
                <div>745/800</div>
                <div>First Term's Average</div>
                <div>98.5%</div>
                <div>No of Subject paased</div>
                <div>50</div>
                <div>No of Subject failed </div>

                <div>0</div>

                <div>Remark:</div>

                <div>Distintion</div>

                <h5>Next Term's Information</h5>
                <br />

                <div>Resumption Date</div>

                <div>9/12/2022</div>

                <div>Next Term Fees</div>

                <div>$40000</div>

                <div>Other fees</div>

                <div>$0</div>

                <div>Total Fees</div>

                <div>$40000</div>

                <h5>Teachers Information</h5>

                <br />

                <div>Class Teacher</div>
                <div style={{ whiteSpace: "nowrap" }}>Tracy Ologun</div>
                <div>Head Teacher</div>
                <div style={{ whiteSpace: "nowrap" }}>Mrs Akinisolo Samuel</div>
              </div>
            </div>
          </div>
        </div>
        {/* second row */}
        <div className="secondrow"></div>
        <div className="personal-data">
          <div className="data-wrapper">
            {/* <div className="data-div"> */}
            <div className="show-details">
              <h5 style={{ color: "orange" }}>B. AFFECTIVE TRAIT</h5>
              <br />
              <div>General Conduct</div>
              <div>impressive</div>
              <div>Assignments</div>
              <div>Satisfactory</div>
              <div>Class Participation</div>
              <div>Satisfactory</div>
              <div>Finishes Work on time </div>

              <div>Satisfactory</div>

              <div>Takes Care of materials</div>

              <div>Satisfactory</div>
              <div>Working Independently</div>

              <div>Satisfactory</div>

              <h5 style={{ color: "orange" }}>B. PSYCHO-MOTIVE TRAIT</h5>
              <br />

              <div>Regularity</div>

              <div>Impressive</div>

              <div>personal Cleaniness</div>

              <div>Satisfactory</div>

              <div>Punctuality </div>

              <div>Satisfactory</div>

              <div>Completion of work</div>

              <div>Satisfactory</div>
              {/* <div>Disturbing others</div> */}

              <div>Satisfactory</div>
              <div>Following Instructions</div>

              <div>Satisfactory</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
