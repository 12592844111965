import React from "react";
import "./about3.css";
import About1 from "../../images/about1.png";
import About2 from "../../images/about2.png";
import About3 from "../../images/about3.png";
import AboutEllipse from "../../images/about-ellipse.png";

export default function AboutThree() {
  return (
    <div className="about3">
      <div className="about3-content">
        <p>
          <strong>What Makes Us Exceptionally Different</strong>
        </p>
        <div className="about3-container">
          <div className="about3-text">
            <p>
              At Our Lady Of Hope Montessori, we adhere to a philosophy and
              method of education that is geared toward developing creative,
              independent and happy children who are consistently inspired to
              learn even as they become lifelong learners. We serve a diverse
              body of learners from various economic, ethnic and faith
              backgrounds by providing them with a friendly and safe environment
              where they can receive an excellent and world class Montessori
              education and grow in relationship with their Creator through
              grace and blessings of our Lord Jesus Christ.
            </p>
            <div className="about3-img no-show-about">
              <img src={About1} />
            </div>
            <ul className="about3-ul">
              <li>
                Our Curriculum makes us exceptionally different because it is a
                smooth blend of the Montessori philosophy and the Nigeran
                National curriculum infused with sound Christian faith and
                values that help our learners become global citizens.
              </li>
              <li>
                We think beyond traditional education to transform learning as
                we have a rich bouquet of extracurricular activities designed to
                educate and prepare your child for the future.
              </li>
              <div className="about3-img no-show-about">
                <img src={About2} />
              </div>
              <li>
                Our dedicated educators, faculty members and staff enhance your
                child's learning. We recruit and retain outstanding educators.
              </li>
              <li>
                Our learning environment and ambience is a learning tool as
                learning begins to take place the moment a child steps into our
                school environment even before interreacting with an educator.
              </li>
              <li>
                Timeless values and warm, nurturing care while using play way
                method of lesson delivery to ensure your child loves coming to
                school and learning.
              </li>
              <div className="about3-img no-show-about">
                <img src={About3} />
              </div>
            </ul>
          </div>
          <div className="about3-imgs">
            <div className="about3-img about3-img1">
              <img src={About1} />
            </div>

            <div className="about3-img about3-img2">
              <img src={About2} />
            </div>

            <div className="about3-img about3-img3">
              <img src={About3} />
            </div>
          </div>
        </div>
      </div>
      <div className="about-ellipse">
        <img src={AboutEllipse} alt="" />
      </div>
    </div>
  );
}
