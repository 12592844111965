import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Button, { Button2 } from "../../components/Button/Button";
import { SmallFeature } from "../homepageSections/Feature";
import Application from "../../images/application.png";
import Spin from "../../images/spin.png";
import Ellipse from "../../images/zoom-ellipse.png";
import Featured from "../../videos/featured.mp4";
import Learn1 from "../../videos/learn-1.mp4";
import Learn2 from "../../videos/learn-2.mp4";
import Learn3 from "../../videos/learn-3.mp4";
import Learn4 from "../../videos/learn-4.mp4";

import { truncateString } from "../adminPages/Vlogs";

import { adminContext } from "../context/adminContext";

export default function Apply() {
  const navigate = useNavigate();

  return (
    <div className="main-contact">
      <div className="apply-comp">
        <div className="apply-ellipse">
          <img src={Ellipse} />
        </div>
        <div className="apply-spin">
          <img src={Spin} alt="" />
        </div>
      </div>
      <div className="apply-container">
        <p className="apply-first-p">
          Get a place for your child at Our Lady Hope Montessori Schools
        </p>
        <div className="split-apply">
          <div className="apply-form">
            <img src={Application} alt="" />
          </div>
          <div className="apply-media">
            <p className="admission-media">Admission Procedure</p>
            <div className="apply-steps">
              <div className="apply-steps-content">
                <p>Step 1</p>
                <ul>
                  <li>
                    Pay for Prospectus/Admission application form (In-person or
                    Online)
                  </li>
                  <li>Fill and submit admission application form</li>
                </ul>
              </div>
              <div className="apply-steps-content">
                <p>Step 2</p>
                <div>
                  Seat for entrance exam and interview (In-person or Online)
                </div>
              </div>
              <div className="apply-steps-content">
                <p>Step 3</p>
                <div>
                  If successful in exam and interview, financial obligation is
                  advised.
                </div>
              </div>
              <div className="apply-steps-content">
                <p>Step 4</p>
                <div>
                  Financial obligation is advised (Raised bank draft or Online
                  Payment)
                </div>
              </div>
              <div className="apply-steps-content">
                <p>Step 5</p>
                <div>Submit the following: (In-person or Online)</div>
                <ul>
                  <li>Completed admission application form.</li>
                  <li>Passport photograph of child(ren) and parent.</li>
                  <li>Birth certificate of child</li>
                  <li>Baptismal card for Catholics where applicable</li>
                  <li>Last 2 previous results.</li>
                  <li>Bank draft/Online payment receipt</li>
                </ul>
              </div>
              <div className="apply-steps-content">
                <p>Step 6</p>
                <div>
                  Advised on resumption date/collection of dressing/ learning
                  kit and other items.
                </div>
              </div>
            </div>
            <div style={{ alignSelf: "center" }}>
              <Button
                name="Proceed with Application"
                click={() => navigate("/admission-form")}
              />
            </div>
            <p style={{ alignSelf: "center", color: "#C80A0A" }}>
              Please Note: We currently accept only Raised bank draft for Step 4
            </p>
          </div>
        </div>
      </div>
      <div className="white-contact"></div>
    </div>
  );
}

// fetch data from api
export function ClassDetails(props) {
  const navigate = useNavigate();
  const values = useContext(adminContext);
  const { admintoken } = values;
  const videos = [Learn1, Learn2];
  
  const [loading, setLoading] = useState(true);
  const [overview, setOverview] = useState('');
  const [learning_xp, setLearningXp] = useState('');
  const [entry_req, setEntryReq] = useState('');
  const [resources, setResources] = useState([]);
  const [entryReqs, setEntryReqs] = useState([]);

  // Fetch school information for specific schools
  const fetchSchoolData = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_school/view_school",
      {
        method: "POST",
        body: JSON.stringify({
          school_id: props.school_id
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json, text/plain, */*"
        },
      }
    );

    const data = await res.json();
    setOverview(data?.school.overview);
    setLearningXp(data?.school.experience);
    setEntryReq(data?.school.entry_requirements);
    setEntryReqs(data?.school.entry_requirements.split(':'));

    setLoading(false);
  };

  useEffect(()=>{
    fetchSchoolData();
  }, []);

  // fetch featured videos

  const viewLearning = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_video_learning/view_video_learnings",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pagec: 1 }),
      }
    );
    const data = await res.json();
    // setLoading(false);
    setResources(data?.video_learnings);
  };

  useEffect(() => {
    viewLearning();
  }, []);

  return (
    <>
      <div className="personal-data-apply">
        <div className="data-wrapper-apply">
          <div className="split-apply-page">
            <div className="application-overview">
              <div className="split-application">
                <p>Overview</p>
                <div>
                  {overview}
                </div>
              </div>
              <div className="split-application">
                <p>The Learning Experience</p>
                <div>
                  {learning_xp}
                </div>
              </div>
              <div className="square-grid">
                {props.images.map((item, i) => (
                  <div key={i} className="square-div">
                    <img src={item} alt="" />
                  </div>
                ))}
              </div>
            </div>
            <div className="split-enquiry-container">
              <div className="split-enquiry">
                <p>Entry Requirements</p>
                <div>
                  {/* {entry_req} */}
                  <ul>
                    {
                      entryReqs.map((req, index)=>(
                        <li key={index}>{req.trim()}</li>
                      ))
                    }
                  </ul>
                </div>
              </div>
              <div className="split-enquiry-button">
                <Button name="Apply Now" click={() => navigate("/apply")} />
                <Button2
                  style={{ color: "#E05615", backgroundColor: "#FCEEE8" }}
                  name="Make an Inquiry"
                  click={()=> navigate("/contact")}
                />
              </div>

              <div>
                <div className="second_feature">
                  <div className="apply-featured-vid">
                    <video
                      // width="370"
                      // height="360"
                      poster="https://res.cloudinary.com/dxcguwdyh/image/upload/v1686649236/temp_thumbs/Screenshot_2023-06-13_001049_x57wap.jpg"
                      src={Featured}
                      autoPlay={false}
                      controls
                    ></video>
                    <div className="absolute-feature">Featured Video</div>
                  </div>
                  <div className="split-learn">
                    <div className="vid-learn">Video Learning Series</div>
                    <p onClick={()=> navigate("/blog", {state: {view_all: true}})}>View all...</p>
                  </div>

                  <div className="feature-small">
                    {resources?.slice(0, 5).map((vid, index) => (
                      <SmallFeature
                        src={vid?.video_url}
                        key={index}
                        details={truncateString(vid?.summary, 100)}
                        poster_url={vid?.img_url}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
