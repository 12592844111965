import React from "react";
import { Outlet, NavLink } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import { TeacherNavBar } from "../components/NavBar/NavBar";
import "../components/Teacher/teacherside.css";
import TeacherIcon from "../images/ipad.png";
import { adminContext } from "./context/adminContext";
import { useContext } from "react";
import { capitalizeFirstLetter } from "./adminPages/StaffDatabase";
import { useState } from "react";

export default function Teacher() {
  const values = useContext(adminContext);
  const { teacherData } = values;
  const data = JSON.parse(teacherData);
  const [navbarHidden, setNavbarHidden] = useState(true);

  // console.log(data);

  // handle hiding the side navbar when the
  // screen width reduces below a certain point
  // add a hamburger icon to the navbar also which
  // will cause the navbar to appear and disapper
  // on press
  // then work on each individual screen to behave
  // accordingly when in mobile view
  // style navbar also to display properly
  // when screen width reduces

  return (
    <div>
      <TeacherNavBar
        name={
          capitalizeFirstLetter(data?.firstname) +
          " " +
          capitalizeFirstLetter(data?.lastname)
        }
        img={data?.img_url}
        hamburgerPressed={(state) => {
          setNavbarHidden(state);
        }}
      />
      <div className="sidebar-container">
        <div
          className="sidebar"
          style={{ display: navbarHidden ? "none" : "inherit" }}
        >
          <nav>
            <ul className="sidebar-li">
              <NavLink
                to="/teacher/profile"
                // style={() => {
                //   return {
                //     // background: isActive ? "white" : "",
                //     // marginBottom: "20px",
                //     paddingLeft: "0px",
                //   };
                // }}
              >
                <li className="profile-section-icon">
                  <div className="teacher-icon">
                    <img src={data?.img_url} alt="" />
                  </div>
                  <div className="teacher-profile">
                    <p className="teacher-name">
                      {capitalizeFirstLetter(data?.firstname) +
                        " " +
                        capitalizeFirstLetter(data?.lastname)}
                    </p>
                    <p className="class-name">
                      {capitalizeFirstLetter(data?.class_name)} -{" "}
                      {capitalizeFirstLetter(data?.center)} Centre
                    </p>
                    <p className="class-name">view profile</p>
                  </div>
                </li>
              </NavLink>
              {data?.role.toLowerCase().includes("store manager") ? (
                <li>
                  <NavLink
                    // to="/teacher/class-result"
                    to="/teacher/store"
                    style={({ isActive }) => {
                      return {
                        background: isActive ? "white" : "",
                        // marginBottom: "20px",
                      };
                    }}
                  >
                    School Store
                  </NavLink>
                </li>
              ) : null}

              <li>
                <NavLink
                  to="/teacher/resources"
                  style={({ isActive }) => {
                    return {
                      background: isActive ? "white" : "",
                      // marginBottom: "20px",
                    };
                  }}
                >
                  Class Resources
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={{
                    pathname: "/teacher/subjectlist",
                  }}
                  style={({ isActive }) => {
                    return {
                      background: isActive ? "white" : "",
                      // marginBottom: "20px",
                    };
                  }}
                  state={{
                    type: "1st Continuous Assessment Test",
                  }}
                >
                  1st C.A.T
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={{
                    pathname: "/teacher/2ndCAT",
                  }}
                  style={({ isActive }) => {
                    return {
                      background: isActive ? "white" : "",
                      // marginBottom: "20px",
                    };
                  }}
                  state={{
                    type: "2nd Continuous Assessment Test",
                  }}
                >
                  2nd C.A.T
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={{
                    pathname: "/teacher/exam",
                  }}
                  style={({ isActive }) => {
                    return {
                      background: isActive ? "white" : "",
                      // marginBottom: "20px",
                    };
                  }}
                  state={{
                    type: "Examination",
                  }}
                >
                  Exam
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/teacher/class-result"
                  style={({ isActive }) => {
                    return {
                      background: isActive ? "white" : "",
                      // marginBottom: "20px",
                    };
                  }}
                >
                  Result
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/teacher/archive"
                  style={({ isActive }) => {
                    return {
                      background: isActive ? "white" : "",
                      // marginBottom: "20px",
                    };
                  }}
                >
                  Question Archive
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
        <div className="main-content">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}
