import React, { useState } from "react";
import Youtube from "../../components/Youtube/Youtube";
import "./sectioneight.css";
import Next from "../../images/next.png";
import Prev from "../../images/prev.png";
import Testimony1 from "../../videos/testimony1.mp4";
import Testimony2 from "../../videos/testimony2.mp4";
import Testimony3 from "../../videos/testimony3.mp4";

export default function SectionEight() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const testimonials = [
    {
      poster_url: 'https://res.cloudinary.com/dxcguwdyh/image/upload/v1686676936/temp_thumbs/Screenshot_2023-06-13_181746_nthqwa.jpg',
      source: Testimony1,
      testimony:
      "I am Doctor Mrs. Faithful Oyakhilome. I'm Efua Oyakhilome's mother. Efua came in here, I think, in Infant Committee One. She's in Infant Committee Two right now. What I love about Our Lady of Hope Montessori School is that they are very precise in their teaching, and they carry everybody along—both the smart ones and the ones that are not too smart. They are all moving at the same pace. And I noticed that anytime my kid mixes up with other children, she's very expressive. She understands letters and alphabets, and the way she counts now, I can't really compare with other kids. So every time she's outside with her mates, I get very impressed. That's why I love Our Lady of Hope. I'm hoping to bring my other kids too.",
      name: "Dr. Mrs. Faithful Oyakhilome",
    },
    {
      poster_url: 'https://res.cloudinary.com/dxcguwdyh/image/upload/v1686676936/temp_thumbs/Screenshot_2023-06-13_181914_lm0jdd.jpg',
      source: Testimony2,
      testimony: "I'm Mrs. Ivie Osawaiye, and I have three kids in Our Lady of Hope Montessori School. The school is one of the best in town, and I'm really happy to be a part of this family. This year's fun-fest is, in fact, something else—way better than last year—and I know next year is going to be a lot better because the proprietress of the school never disappoints; she always exceeds expectations. Thumbs up to you and many, many more happy years ahead of us. Thank you very much",
      name: "Mrs. Ivie Osawaiye",
    },
    {
      poster_url: 'https://res.cloudinary.com/dxcguwdyh/image/upload/v1686676936/temp_thumbs/Screenshot_2023-06-13_181840_n6g2ol.jpg',
      source: Testimony3,
      testimony: `Hello, I'm Mrs. Irabor, and I'm privileged to have two kids here in Our Lady of Hope Montessori School. You know, before we came here, it was tough getting a good school for my children. But when we came, we were indeed impressed by the environment and everything. The school is indeed great; their method of teaching is wonderful, very conducive, and the children are so happy coming to learn. They make sure that learning is fun. When the children come back from school, they are always happy. They talk about their teachers. Sometimes I begin to wonder if the teachers love the children more than we, the parents. I'm really happy I brought my children here. I've indeed gotten results. I'm glad, and I still want to bring more of my kids here because I've gotten what I needed, you know. I've been privileged to visit other schools, but I couldn't get what I had here at Our Lady of Hope Montessori School. I want to say thank you to the school, to the teachers, to the proprietress, and everyone involved in making learning fun for my kids. Thank you.`,
      name: "Mrs. Irabor",
    },
  ];
  const handleNext = () => {
    const nextSlide = currentIndex + 1;
    if (nextSlide < testimonials.length) {
      setCurrentIndex(nextSlide);
    }
  };

  const handlePrev = () => {
    const prevSlide = currentIndex - 1;
    if (prevSlide >= 0) {
      setCurrentIndex(prevSlide);
    }
  };
  return (
    <div className="section-eight">
      <p className="eight-p">
        <strong>Parents Testimonials</strong>
      </p>
      <div className="slider-color">
        <div className="slider">
          <div className={currentIndex === 0 ? "hide-button" : "prev"}>
            <img src={Prev} onClick={handlePrev} alt="" />
          </div>
          <Youtube
            poster_url={testimonials[currentIndex].poster_url}
            source={testimonials[currentIndex].source}
            testimony={testimonials[currentIndex].testimony}
            // testimony2={testimonials[currentIndex].testimony2}
            name={testimonials[currentIndex].name}
          />
          <div
            className={
              currentIndex === testimonials.length - 1 ? "hide-button" : "next"
            }
          >
            <img src={Next} onClick={handleNext} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
