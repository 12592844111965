import React, { useEffect, useState, useContext } from "react";
import { Toggle } from "../teacherPages/Resources";
import { useLocation, useNavigate } from "react-router-dom";
import Add from "../../images/addition.png";
import image from "../../images/content-img.png";
import frame from "../../images/frame.png";
import { EventComponent } from "../homepageSections/SectionSix";
import { adminContext } from "../context/adminContext";
import { truncateString } from "./Vlogs";

export default function Event() {
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(true);
  const values = useContext(adminContext);
  const { admintoken } = values;
  const [loading, setLoading] = useState(true);
  const [news, setNews] = useState([]);
  const [events, setEvents] = useState([]);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [time, setTime] = useState("");
  const [expiry, setExpiry] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(
    new Date().toLocaleString("default", { month: "long" })
  );

  const [searchedEvents, setSearchedEvents] = useState([]);
  const [searchedNews, setSearchedNews] = useState([]);

  const [searchString, setSearchString] = useState("");
  const [currentTerm, setCurrentTerm] = useState("...");

  // const location = useLocation();

  const viewEvents = async () => {
    //get current session first
    try {
      const response = await fetch(
        "https://server-olhms.onrender.com/admin_session/view_current_session",
        {
          method: "POST",
          body: JSON.stringify({
            token: JSON.parse(admintoken),
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const res_data = await response.json();
      if (res_data.status === "ok") {
        //const current_session = res_data.sessionM["session"];
        const current_term = res_data.sessionM["current_term"];
        setCurrentTerm(current_term);
        // console.log(`----> ${current_term}`);
        let n_term;
        if (current_term == "First Term") {
          n_term = 1;
        } else if (current_term == "Second Term") {
          n_term = 2;
        } else {
          n_term = 3;
        }

        const res = await fetch(
          "https://server-olhms.onrender.com/admin_events/view_term_events",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ term: n_term }),
          }
        );
        const data = await res.json();
        setLoading(false);
        //console.log(data);
        const allNews = data?.events;
        let news = [];
        let events = [];
        allNews.forEach((item) =>
          item.category === "news"
            ? news.push(item)
            : item.category === "events"
            ? events.push(item)
            : null
        );

        // Order the array by timestamp
        // events.sort((a, b) => {
        //   return a.timestamp - b.timestamp;
        // });

        setNews(news);
        setEvents(events);
      } else {
        console.log(`${res_data}`);
      }
    } catch (e) {
      console.log(e);
    }
  };


  // const viewEvents = async () => {
  //   const res = await fetch(
  //     "https://server-olhms.onrender.com/admin_events/view_term_events",
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ term: JSON.parse(admintoken) }),
  //     }
  //   );
  //   const data = await res.json();
  //   setLoading(false);
  //   console.log(data);
  //   const allNews = data?.events;
  //   // setPendingDeliveries(results?.deliveries);
  //   let news = [];
  //   let events = [];
  //   allNews.forEach((item) =>
  //     item.category === "news"
  //       ? news.push(item)
  //       : item.category === "events"
  //       ? events.push(item)
  //       : null
  //   );

  //   setNews(news);
  //   setEvents(events);
  // };

  useEffect(() => {
    viewEvents();
  }, []);

  const handleSearchChange = (e) => setSearchString(e.target.value);

  const searchEvents = async (currPage) => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_events/search_events",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(admintoken),
          search_string: searchString,
          pagec: currPage,
        }),
      }
    );
    const data = await res.json();
    setLoading(false);
    const allNews = data?.events;
    // setPendingDeliveries(results?.deliveries);
    let news = [];
    let events = [];
    allNews.forEach((item) =>
      item.category === "news"
        ? news.push(item)
        : item.category === "events"
        ? events.push(item)
        : null
    );

    setSearchedNews(news);
    setSearchedEvents(events);
    console.log(data);
  };

  useEffect(() => {
    if (searchString != "") {
      searchEvents(1);
    }
  }, [searchString]);

  return (
    <div
      className="set-questions"
      style={{
        backgroundColor: "rgb(255, 248, 248)",
        height: "100%",
        alignItems: "flex-start",
      }}
    >
      <div className="set-container">
        <Toggle
          click1={() => {
            setToggle(true);
            setSearchString("");
          }}
          click2={() => {
            setToggle(false);
            setSearchString("");
          }}
          toggle={toggle}
          text1="News"
          text2="Events"
        />

        <div className="search-input">
          <input
            placeholder={`Search ${toggle ? "News" : "Events"}`}
            value={searchString}
            onChange={handleSearchChange}
          />
        </div>

        <div
          className="create-teacher"
          onClick={() =>
            toggle
              ? navigate("/admin/add-blog", {
                  state: {
                    type: 2,
                    img: "",
                    title: "",
                    details: "",
                    id: "",
                    category: "",
                    thumburl: "",
                  },
                })
              : navigate("/admin/add-resource", {
                  state: {
                    type: 1,
                    img: "",
                    title: "",
                    details: "",
                    id: "",
                  },
                })
          }
        >
          <img src={Add} />
        </div>
        {toggle ? (
          <div className="show-small-blog">
            {searchString != ""
              ? searchedNews?.map((item, i) => (
                  <Smallevent
                    key={item._id}
                    src={item?.img_url}
                    title={truncateString(item.event_name, 29)}
                    body={truncateString(item.description, 100)}
                    date={item.event_date}
                    click={() =>
                      navigate("/admin/add-blog", {
                        state: {
                          type: 2,
                          img: item?.img_url,
                          title: item?.event_name,
                          details: item?.description,
                          id: item?._id,
                          category: "",
                          thumburl: "",
                        },
                      })
                    }
                  />
                ))
              : news?.map((item, i) => (
                  <Smallevent
                    key={item._id}
                    src={item?.img_url}
                    title={truncateString(item.event_name, 29)}
                    body={truncateString(item.description, 100)}
                    date={item.event_date}
                    click={() =>
                      navigate("/admin/add-blog", {
                        state: {
                          type: 2,
                          img: item?.img_url,
                          title: item?.event_name,
                          details: item?.description,
                          id: item?._id,
                          category: "",
                          thumburl: "",
                        },
                      })
                    }
                  />
                ))}
          </div>
        ) : (
          <div className="show-event-page">
            <div className="first-div-show">
              <div className="news-calendar-event">
                <div
                  className="months-year-event"
                  onClick={() => setSelectedMonth("January")}
                  style={{
                    backgroundColor:
                      selectedMonth == "January"
                        ? "rgba(196, 80, 25, 0.12)"
                        : "#673401",
                    color: selectedMonth == "January" ? "#673401" : "white",
                  }}
                >
                  January
                </div>
                <div
                  className="months-year-event"
                  onClick={() => setSelectedMonth("February")}
                  style={{
                    backgroundColor:
                      selectedMonth == "February"
                        ? "rgba(196, 80, 25, 0.12)"
                        : "#673401",
                    color: selectedMonth == "February" ? "#673401" : "white",
                  }}
                >
                  February
                </div>
                <div
                  className="months-year-event"
                  onClick={() => setSelectedMonth("March")}
                  style={{
                    backgroundColor:
                      selectedMonth == "March"
                        ? "rgba(196, 80, 25, 0.12)"
                        : "#673401",
                    color: selectedMonth == "March" ? "#673401" : "white",
                  }}
                >
                  March
                </div>
                <div
                  className="months-year-event"
                  onClick={() => setSelectedMonth("April")}
                  style={{
                    backgroundColor:
                      selectedMonth == "April"
                        ? "rgba(196, 80, 25, 0.12)"
                        : "#673401",
                    color: selectedMonth == "April" ? "#673401" : "white",
                  }}
                >
                  April
                </div>
                <div
                  className="months-year-event"
                  onClick={() => setSelectedMonth("May")}
                  style={{
                    backgroundColor:
                      selectedMonth == "May"
                        ? "rgba(196, 80, 25, 0.12)"
                        : "#673401",
                    color: selectedMonth == "May" ? "#673401" : "white",
                  }}
                >
                  May
                </div>
                <div
                  className="months-year-event"
                  onClick={() => setSelectedMonth("June")}
                  style={{
                    backgroundColor:
                      selectedMonth == "June"
                        ? "rgba(196, 80, 25, 0.12)"
                        : "#673401",
                    color: selectedMonth == "June" ? "#673401" : "white",
                  }}
                >
                  June
                </div>
                <div
                  className="months-year-event"
                  onClick={() => setSelectedMonth("July")}
                  style={{
                    backgroundColor:
                      selectedMonth == "July"
                        ? "rgba(196, 80, 25, 0.12)"
                        : "#673401",
                    color: selectedMonth == "July" ? "#673401" : "white",
                  }}
                >
                  July
                </div>
                <div
                  className="months-year-event"
                  onClick={() => setSelectedMonth("August")}
                  style={{
                    backgroundColor:
                      selectedMonth == "August"
                        ? "rgba(196, 80, 25, 0.12)"
                        : "#673401",
                    color: selectedMonth == "August" ? "#673401" : "white",
                  }}
                >
                  August
                </div>
                <div
                  className="months-year-event"
                  onClick={() => setSelectedMonth("September")}
                  style={{
                    backgroundColor:
                      selectedMonth == "September"
                        ? "rgba(196, 80, 25, 0.12)"
                        : "#673401",
                    color: selectedMonth == "September" ? "#673401" : "white",
                  }}
                >
                  September
                </div>
                <div
                  className="months-year-event"
                  onClick={() => setSelectedMonth("October")}
                  style={{
                    backgroundColor:
                      selectedMonth == "October"
                        ? "rgba(196, 80, 25, 0.12)"
                        : "#673401",
                    color: selectedMonth == "October" ? "#673401" : "white",
                  }}
                >
                  October
                </div>
                <div
                  className="months-year-event"
                  onClick={() => setSelectedMonth("November")}
                  style={{
                    backgroundColor:
                      selectedMonth == "November"
                        ? "rgba(196, 80, 25, 0.12)"
                        : "#673401",
                    color: selectedMonth == "November" ? "#673401" : "white",
                  }}
                >
                  November
                </div>
                <div
                  className="months-year-event"
                  onClick={() => setSelectedMonth("December")}
                  style={{
                    backgroundColor:
                      selectedMonth == "December"
                        ? "rgba(196, 80, 25, 0.12)"
                        : "#673401",
                    color: selectedMonth == "December" ? "#673401" : "white",
                  }}
                >
                  December
                </div>
              </div>
              <div className="split-small-event">
                <p className="event-day">{selectedMonth} Events</p>
                <div className="show-small-news">
                  {events
                    ?.filter((item) => item.month === selectedMonth)
                    .map((item, i) => (
                      <EventComponent
                        style={{ backgroundColor: "white" }}
                        color={{ padding: "20px 24px" }}
                        month={item.month}
                        title={truncateString(item.event_name, 36)}
                        details={truncateString(item.description, 50)}
                        time={item.event_time}
                        day={item.event_date.slice(0, 2)}
                        click={() =>
                          navigate("/admin/add-resource", {
                            state: {
                              type: 1,
                              img: "",
                              title: item?.event_name,
                              details: item?.description,
                              id: item?._id,
                            },
                          })
                        }
                      />
                    ))}
                </div>
              </div>
            </div>
            <div className="second-div-show">
              <p className="event-day">All Events for {currentTerm}</p>
              {searchString != ""
                ? searchedEvents?.map((item, i) => (
                    <EventComponent
                      style={{ backgroundColor: "white" }}
                      color={{ padding: "20px 24px" }}
                      month={item.month}
                      title={truncateString(item.event_name, 36)}
                      details={truncateString(item.description, 50)}
                      time={item.event_time}
                      day={item.event_date.slice(0, 2)}
                      click={() =>
                        navigate("/admin/add-resource", {
                          state: {
                            type: 1,
                            img: "",
                            title: item?.event_name,
                            details: item?.description,
                            id: item?._id,
                          },
                        })
                      }
                    />
                  ))
                : events?.map((item, i) => (
                    <EventComponent
                      style={{ backgroundColor: "white" }}
                      color={{ padding: "20px 24px" }}
                      month={item.month}
                      title={truncateString(item.event_name, 36)}
                      details={truncateString(item.description, 50)}
                      time={item.event_time}
                      day={item.event_date.slice(0, 2)}
                      click={() =>
                        navigate("/admin/add-resource", {
                          state: {
                            type: 1,
                            img: "",
                            title: item?.event_name,
                            details: item?.description,
                            id: item?._id,
                          },
                        })
                      }
                    />
                  ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export function Smallevent(props) {
  return (
    <div
      className="split-small-blog"
      style={{ cursor: "pointer" }}
      onClick={props.click}
    >
      <div className="small-blog-image">
        <img src={props.src} alt="" />
      </div>
      <div className="small-blog-content">
        <p className="small-blog-title">{props.title}</p>
        <p>{props.date}</p>
        <div className="small-blog-text">{props.body}</div>
      </div>
    </div>
  );
}
