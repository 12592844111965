import React from "react";
import BlogOne from "./blog/BlogOne";
import BlogTwo from "./blog/BlogTwo";
import { useLocation } from "react-router-dom";
import { useRef, useEffect} from "react";

export default function Blog() {
  const state = useLocation().state;
  let view_all;
  if(state){
    view_all = state.view_all;
  }
  

  const div = useRef(null);
  useEffect(()=> {
    if(view_all == true){
      div.current.scrollIntoView({behavior: "smooth", block:"end"});
    }
  }, []);
  
  return (
    <div ref={div}>
      <BlogOne />
      <BlogTwo />
    </div>
  );
}
