import React, { useState, useEffect, useContext } from "react";
import { BackButton } from "../studentPages/PersonalData";
import { useNavigate } from "react-router-dom";
import "./staff.css";
import "../teacherPages/resources.css";
import Add from "../../images/addition.png";
import Passport from "../../images/news1.png";
import { adminContext } from "../context/adminContext";
import Popup from "reactjs-popup";
import { IoMdMore } from "react-icons/io";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Button, { Button2 } from "../../components/Button/Button";
import { Toaster, toast } from "react-hot-toast";
import { ClipLoader } from 'react-spinners';

export default function StaffDatabase() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [showView, setShowView] = useState(false);
  const [staff, setStaff] = useState([]);
  const [searchedStaff, setSearchedStaff] = useState([]);
  const [searchString, setSearchString] = useState("");

  const [stats, setStats] = useState({});
  const [staff_details, set_staff_details] = useState({});
  const values = useContext(adminContext);
  const { admintoken } = values;

  const [openDialog, handleDisplay] = useState(false);
  const openDialogBox = () => {
    handleDisplay(true);
  };
  const handleCloseDialogBox = () => {
    handleDisplay(false);
  };

  const Menu = (details) => (
    <div className="optionsMenu">
      <Popup
        trigger={
          <button className="optionsMenu-details">
            {" "}
            <IoMdMore />{" "}
          </button>
        }
        position="right top"
        on="click"
        closeOnDocumentClick
        mouseLeaveDelay={300}
        mouseEnterDelay={0}
        contentStyle={{ padding: "0px", border: "none" }}
        // arrow={false}
      >
        <ul className="optionsMenu-item">
          <li
            onClick={() =>
              navigate("/admin/view", {
                state: { num: 2, id: details?._id, staff: details },
              })
            }
          >
            Edit
          </li>
          <li
            onClick={() => {
              // console.log(details.email);
              // deleteStaff(details);
              set_staff_details(details);
              openDialogBox();
            }}
          >
            Delete
          </li>
          <li
            onClick={() => {
              navigate("/admin/email-staff", {
                state: { staff: details },
              });
            }}
          >
            Send Emails
          </li>
        </ul>
      </Popup>
    </div>
  );

  const viewStaff = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_staff/view_multiple_staff",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(admintoken),
        }),
      }
    );
    const data = await res.json();
    setLoading(false);
    if (data?.count != 0) {
      setStaff(data.staffs);
    }
    console.log(data);
  };

  useEffect(() => {
    viewStaff();
  }, []);

  const deleteStaff = async (staff) => {
    setLoadingButton(true);
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_staff/delete_staff",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(admintoken),
          staff_id: staff?._id,
          staff_email: staff?.email,
        }),
      }
    );
    const data = await res.json();
    if (data?.msg !== "success") {
      toast.error(data.msg, {
        icon: "❗️",
        position: "bottom-center",
      });
    }
    handleCloseDialogBox();
    setLoadingButton(false);
    viewStaff();
    console.log(data);
  };

  const handleSearchChange = (e) => setSearchString(e.target.value);

  // function to search for staff
  const searchStaffs = async (currPage) => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_staff/search_staff",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(admintoken),
          search_string: searchString,
          pagec: currPage,
        }),
      }
    );
    const data = await res.json();
    setLoading(false);
    if (data?.count != 0) {
      setSearchedStaff(data?.staffs);
    }
    console.log(data);
  };

  useEffect(() => {
    if (searchString != "") {
      searchStaffs(1);
    }
  }, [searchString]);

  const viewStatistics = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_statistics/view_statistics",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(admintoken),
        }),
      }
    );
    const data = await res.json();
    console.log(data);
    if (data.msg === "success") {
      setLoading(false);
      setStats(data.statistics);
    }
  };

  useEffect(() => {
    viewStatistics();
  }, []);

  if(loading) {
    return <h1
       className="loading-pages"
       style={{
         display: "flex",
         justifyContent: "center",
         alignItems: "center",
         paddingTop: "250px"
       }}
     >
       <ClipLoader color={"orange"} size={100} />
     </h1>
 }else{

  return (
    <div
      className="set-questions"
      style={{
        backgroundColor: "rgb(255, 248, 248)",
        height: "100%",
        alignItems: "flex-start",
      }}
    >
      <div className="set-container">
        <BackButton click={() => navigate(-1)} />
        <p className="data-number">Staff Database: {stats.no_of_staff}</p>
        <div className="search-input">
          <input
            placeholder="Search Staff Database"
            onChange={handleSearchChange}
            value={searchString}
          />
        </div>
        <div className="database-table">
          <table>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Full name</th>
                <th>Gender</th>
                <th>Staff ID</th>
                <th>Centre</th>
                <th>Role</th>
                <th>Employment Year</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {searchString != ""
                ? searchedStaff.map((item, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate("/admin/view", {
                            state: { num: 1, id: item?._id, staff: item },
                          })
                        }
                      >
                        {item?.firstname +
                          " " +
                          item?.middlename +
                          " " +
                          item?.lastname}
                      </td>
                      <td>{capitalizeFirstLetter(item?.gender[0])}</td>
                      <td>{item?.staff_id}</td>
                      <td>{capitalizeFirstLetter(item?.center)}</td>
                      <td>{capitalizeFirstLetter(item?.role)}</td>
                      <td>{item?.employment_year}</td>

                      {
                        <td>
                          <div className="popup-click">{Menu(item)}</div>
                        </td>
                      }

                      {/* <td>
                        <div className="dropdown">
                          <p className="dropdown-btn">&#8942;</p>
                          <div className="dropdown-content">
                            <p
                              onClick={() =>
                                navigate("/admin/view", {
                                  state: {
                                    num: 2,
                                    id: item?._id,
                                    staff: item,
                                  },
                                })
                              }
                            >
                              Edit
                            </p>
                            <p
                              onClick={() => {
                                deleteStaff(item?._id);
                              }}
                            >
                              Delete
                            </p>
                            <p
                              onClick={() => {
                                navigate("/admin/email-staff", {
                                  state: { staff: item },
                                });
                              }}
                            >
                              Send Email
                            </p>
                          </div>
                        </div>
                      </td> */}
                    </tr>
                  ))
                : staff.map((item, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate("/admin/view", {
                            state: { num: 1, id: item?._id, staff: item },
                          })
                        }
                      >
                        {item?.firstname +
                          " " +
                          item?.middlename +
                          " " +
                          item?.lastname}
                      </td>
                      <td>{capitalizeFirstLetter(item?.gender[0])}</td>
                      <td>{item?.staff_id}</td>
                      <td>{capitalizeFirstLetter(item?.center)}</td>
                      <td>{capitalizeFirstLetter(item?.role)}</td>
                      <td>{item?.employment_year}</td>

                      {
                        <td>
                          <div className="popup-click">{Menu(item)}</div>
                        </td>
                      }

                      {/* <td>
                        <div className="dropdown">
                          <p className="dropdown-btn">&#8942;</p>
                          <div className="dropdown-content">
                            <p
                              onClick={() =>
                                navigate("/admin/view", {
                                  state: {
                                    num: 2,
                                    id: item?._id,
                                    staff: item,
                                  },
                                })
                              }
                            >
                              Edit
                            </p>
                            <p
                              onClick={() => {
                                deleteStaff(item?._id);
                              }}
                            >
                              Delete
                            </p>
                            <p
                              onClick={() => {
                                navigate("/admin/email-staff", {
                                  state: { staff: item },
                                });
                              }}
                            >
                              Send Email
                            </p>
                          </div>
                        </div>
                      </td> */}
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
        <div
          className="create-teacher"
          onClick={() => navigate("/admin/create-staff")}
        >
          <img src={Add} />
        </div>
      </div>

      <Dialog onClose={handleCloseDialogBox} open={openDialog}>
        <DialogTitle>Delete Staff?</DialogTitle>
        <div style={{ marginLeft: 100, marginRight: 100 }}>
          <div style={{ marginBottom: 12 }}>
            Are you sure you want to delete{" "}
            {staff_details?.firstname +
              " " +
              staff_details?.middlename +
              " " +
              staff_details?.lastname}
            ?
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 20,
            }}
          >
          <div >

            <Button2
              
              click={(e) => {
                e.preventDefault();
                handleCloseDialogBox();
              }}
              style={{ marginBottom: 10,marginRight: 20, }}
              name={"Cancel"}
            />
            <Button
              disabled={loadingButton}
              click={(e) => {
                e.preventDefault();

                if(loadingButton == false){
                deleteStaff(staff_details);

                }

              }}
              style={{ marginBottom: 10, marginLeft: 20, }}
              name={"Delete"}
            />
          </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
 } 
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
