import React from "react";
import PlayGround from "../../images/playground.png";
import "./zoom.css";

export default function Zoom(props) {
  const mystyle = {
    backgroundImage: `url(${props.img})`,
  };
  const mytrap = {
    background: `${props.background}`,
    backdropFilter: `${props.filter}`,
  };
  return (
    <div
      onClick={props.click}
      className={props.classic == "zoom" ? "fill-zoom" : null}
    >
      <div className={props.classic}>
        <div className={props.zoomBackground} style={mystyle}></div>
        <div
          className={
            props.classic == "zoom" ? "zoom-trapezoid" : "zoom-trapezoid2"
          }
          style={mytrap}
        >
          <p>{props.header}</p>
        </div>
      </div>
      <div className={props.text}>
        {props.classic == "zoom" ? (
          <p className="show-zoom-header">{props.header}</p>
        ) : null}
        {props.body}
        {props.list1 ? (
          <ul>
            {props.list1 ? <li> {props.list1} </li> : null}
            {props.list2 ? <li> {props.list2} </li> : null}
            {props.list3 ? <li> {props.list3} </li> : null}
            {props.list4 ? <li> {props.list4} </li> : null}
            {props.list5 ? <li> {props.list5} </li> : null}
            {props.list6 ? <li> {props.list6} </li> : null}
          </ul>
        ) : null}
      </div>
    </div>
  );
}
