import React from "react";
import Mission from "../../images/mission.png";
import Vision from "../../images/vision.png";
import Values from "../../images/values.png";
import "./sectionthree.css";
import Spiral from "../../images/spiral.png";
import Elipse from "../../images/ellipses.png";
import WhatsApp from "../../images/whatsapp.png";
import Telegram from "../../images/telegram.png";

import ReactWhatsapp from "react-whatsapp";

export default function SectionThree() {
  return (
    <div className="section-three-wrapper">
      <div className="section-three">
        <div className="section-three-parts">
          <div className="mission-container">
            <img src={Mission} alt="mission" />
          </div>
          <strong>Our Mission</strong>
          <p>
            Our mission as a school is to CONSISTENTLY provide OUTSTANDING,
            WORLD CLASS educational solutions that inspires the love for
            learning in children of all ages.
          </p>
        </div>
        <div className="section-three-parts">
          <div className="mission-container">
            <img src={Vision} alt="vision" />
          </div>
          <strong>Our Vision</strong>
          <p>
            As a school, we envision ourselves to be the LEADING educational
            institution that provides the best GLOBAL LEARNING EXPERIENCE while
            boasting of the BEST PEOPLE and technology.
          </p>
        </div>
        <div className="section-three-parts">
          <div className="mission-container">
            <img src={Values} alt="values" />
          </div>
          <strong>Our Values</strong>
          <p>
            Our goal as a school is to consistently provide outstanding, world
            class educational solutions that inspires the love for learning in
            children of all ages.
          </p>
        </div>
      </div>
      <div className="invert-ellipse"></div>
      <div className="straight-ellipse"></div>
      <div className="spiral">
        <img src={Spiral} />
      </div>
      <div className="ellipse">
        <img src={Elipse} />
      </div>
      <div className="social-home">
        <div
          onClick={() => {
            const phoneNumber = "+2348134082834";

            // Construct the WhatsApp URL with the phone number
            const whatsappUrl = `https://api.whatsapp.com/send?phone=${encodeURIComponent(
              phoneNumber
            )}`;

            // // Redirect the user to WhatsApp
            // window.location.href = whatsappUrl;

            // Open WhatsApp in a new window
            window.open(whatsappUrl, "_blank");
          }}
          className="scroll-social1"
        >
          <img src={WhatsApp} alt="" />
        </div>
        <div
          onClick={() => {
            // Define the Telegram username or phone number
            const usernameOrPhoneNumber = "+2348151707292"; // Replace with the actual username or phone number

            // Construct the Telegram URL with the username or phone number
            const telegramUrl = `https://t.me/${encodeURIComponent(
              usernameOrPhoneNumber
            )}`;

            // Open Telegram in a new window
            window.open(telegramUrl, "_blank");
            // Redirect the user to Telegram
            // window.location.href = telegramUrl;
          }}
          className="scroll-social2"
        >
          <img src={Telegram} alt="" />
        </div>
      </div>
    </div>
  );
}
