import React from "react";
import { useState, useContext, useEffect } from "react";
import { adminContext } from "../context/adminContext";
import { useLocation, useNavigate } from "react-router-dom";
import { BackButton } from "../studentPages/PersonalData";
import dark from "../../images/dark.png";
import Button, { Button2 } from "../../components/Button/Button";
import "./vlogs.css";
import axios from "axios";
import Delete from "../../images/delete.png";
import dayjs from "dayjs";
import Popup from "../../components/Popup/Popup";
import Cancel from "../../images/cancel.png";

export default function AddBlog() {
  const location = useLocation();
  const navigate = useNavigate();
  const [deletable, setDeletable] = useState(false);
  const values = useContext(adminContext);
  const [loadButton, setLoadButton] = useState(false);
  const [fail, setFail] = useState(false);
  const { admintoken } = values;
  const type = location.state.type;
  const img = location.state.img;
  const title = location.state.title;
  const details = location.state.details;
  const id = location.state.id;
  const thumburl = location.state.thumburl;
  const categoryV = location.state.category;
  const [time, setTime] = useState("");
  const [expiry, setExpiry] = useState("");
  const [isSelected, setIsSelected] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedSrc, setSelectedSrc] = useState(img);
  const [realTime, setRealTime] = useState("");
  const [category, setCategory] = useState("Parenting");
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailsrc, setThumbnailscr] = useState(thumburl);
  const categories = [
    "Parenting",
    "Schooling",
    "Peer Influence",
    "Mother and Child",
    "Fathers",
  ];
  const handleRadio = (e) => {
    setCategory(e.target.value);
  };
  const [formData, setFormData] = useState({
    title: title,
    body: details,
  });

  const onFileChange = (e) => {
    if (type == 0 || type == 2) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState == 2) {
          setSelectedSrc(reader.result);
        }
      };

      reader.readAsDataURL(e.target.files[0]);
      setSelectedFile(e.target.files[0]);
      setIsSelected(true);
    } else {
      const file = e.target.files[0];
      setSelectedFile(file);
      setSelectedSrc(URL.createObjectURL(file));
      // console.log(selectedSrc);
    }
  };

  const onThumbnail = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState == 2) {
        setThumbnailscr(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
    setThumbnail(e.target.files[0]);
  };

  const handleChange = (e) => {
    const target = e.target;

    const { name, value } = target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDelete = async (e) => {
    // console.log(id);
    e.preventDefault();
    if (type == 0) {
      const res = await fetch(
        "https://server-olhms.onrender.com/admin_blog/delete_blog",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: JSON.parse(admintoken),
            blog_id: id,
          }),
        }
      );
      setDeletable(false);
      navigate(-1);
    } else if (type == 1) {
      const res = await fetch(
        "https://server-olhms.onrender.com/admin_vlog/delete_vlog",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: JSON.parse(admintoken),
            vlog_id: id,
          }),
        }
      );
      setDeletable(false);
      navigate(-1);
    } else if (type == 2) {
      const res = await fetch(
        "https://server-olhms.onrender.com/admin_events/delete_event",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: JSON.parse(admintoken),
            event_id: id,
          }),
        }
      );
      setDeletable(false);
      navigate(-1);
    } else if (type == 3) {
      const res = await fetch(
        "https://server-olhms.onrender.com/admin_video_learning/delete_video_learning",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: JSON.parse(admintoken),
            video_learning_id: id,
          }),
        }
      );
      setDeletable(false);
      navigate(-1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadButton(true);
    const dayy = new Date();
    const month = dayy.toLocaleString("default", { month: "long" });
    const realDate = formatDate(new Date());

    const time = new Date().toLocaleTimeString();
    let timeT = convertToAmPm(time);
    const realTime = timeT;
    //  console.log(timeT);

    if (id != "" && type == 0) {
      const bodyFormData = new FormData();

      bodyFormData.append("token", JSON.parse(admintoken));
      bodyFormData.append("body", formData.body);
      bodyFormData.append("title", formData.title);
      bodyFormData.append(
        "duration",
        `${calculateReadingTime(formData.body)} minutes`
      );
      bodyFormData.append("blog_id", id);
      bodyFormData.append("blog-images", selectedFile);

      axios
        .post(
          "https://server-olhms.onrender.com/admin_blog/edit_blog",
          bodyFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )

        .then((response) => {
          if (response.status === 200) {
            // navigate(formData.fleet_id !== "" ? "/success" : "/account", {
            //   state: { id: id, token: token },
            // });

            navigate("/admin/blog");
            setLoadButton(false);
          } else {
            // setMessage("Error occured");
            // setLoading(false);
            setLoadButton(false);
            setFail(true);
          }
          console.log(response.data);
        })
        .catch((error) => {
          // console.log(error);
          // setLoading(false);
          setLoadButton(false);
          setFail(true);
        });
    } else if (id == "" && type == 0) {
      const bodyFormData = new FormData();

      bodyFormData.append("token", JSON.parse(admintoken));
      bodyFormData.append("body", formData.body);
      bodyFormData.append("title", formData.title);
      bodyFormData.append(
        "duration",
        `${calculateReadingTime(formData.body)} minutes`
      );
      bodyFormData.append("blog-images", selectedFile);

      axios
        .post(
          "https://server-olhms.onrender.com/admin_blog/create_blog",
          bodyFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )

        .then((response) => {
          if (response.status === 200) {
            // navigate(formData.fleet_id !== "" ? "/success" : "/account", {
            //   state: { id: id, token: token },
            // });
            navigate("/admin/blog");
            setLoadButton(false);
          } else {
            // setMessage("Error occured");
            // setLoading(false);
            setLoadButton(false);
            setFail(true);
          }
          console.log(response.data);
        })
        .catch((error) => {
          // console.log(error);
          // setLoading(false);
          setLoadButton(false);
          setFail(true);
        });
    } else if (id != "" && type == 1) {
      const bodyFormData = new FormData();

      bodyFormData.append("token", JSON.parse(admintoken));
      bodyFormData.append("summary", formData.body);
      bodyFormData.append("title", formData.title);
      bodyFormData.append(
        "duration",
        `${calculateReadingTime(formData.body)} minutes`
      );
      bodyFormData.append("vlog_id", id);
      bodyFormData.append("vlog-videos", selectedFile);
      bodyFormData.append("category", category);
      bodyFormData.append("preview", formData.title);

      axios
        .post(
          "https://server-olhms.onrender.com/admin_vlog/edit_vlog",
          bodyFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )

        .then((response) => {
          if (response.status === 200) {
            // navigate(formData.fleet_id !== "" ? "/success" : "/account", {
            //   state: { id: id, token: token },
            // });
            navigate("/admin/blog");
            setLoadButton(false);
          } else {
            // setMessage("Error occured");
            // setLoading(false);
            setLoadButton(false);
          }
          console.log(response.data);
          setLoadButton(false);
          setFail(true);
        })
        .catch((error) => {
          console.log(error);
          // setLoading(false);
          setLoadButton(false);
          setFail(true);
        });
    } else if (id == "" && type == 1) {
      const ImageFormData = new FormData();
      ImageFormData.append("token", JSON.parse(admintoken));
      ImageFormData.append("video-images", thumbnail);
      axios
        .post(
          "https://server-olhms.onrender.com/admin_vlog/upload_file",
          ImageFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )

        .then((response) => {
          if (response.status === 200) {
            const image = response.data.lesson.url;
            const bodyFormData = new FormData();

            bodyFormData.append("token", JSON.parse(admintoken));
            bodyFormData.append("summary", formData.body);
            bodyFormData.append("title", formData.title);
            bodyFormData.append(
              "duration",
              `${calculateReadingTime(formData.body)} minutes`
            );
            bodyFormData.append("vlog-videos", selectedFile);
            bodyFormData.append("category", category);
            bodyFormData.append("preview", formData.title);
            bodyFormData.append("img_url", image);

            axios
              .post(
                "https://server-olhms.onrender.com/admin_vlog/create_vlog",
                bodyFormData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )

              .then((response) => {
                if (response.status === 200) {
                  // navigate(formData.fleet_id !== "" ? "/success" : "/account", {
                  //   state: { id: id, token: token },
                  // });
                  navigate("/admin/blog");
                  setLoadButton(false);
                } else {
                  // setMessage("Error occured");
                  // setLoading(false);
                  setLoadButton(false);
                  setFail(true);
                }
                console.log(response.data);
              })
              .catch((error) => {
                console.log(error);
                // setLoading(false);
                setLoadButton(false);
                setFail(true);
              });
          } else {
            // setMessage("Error occured");
            // setLoading(false);
            setLoadButton(false);
            setFail(true);
          }
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
          // setLoading(false);
          setLoadButton(false);
          setFail(true);
        });
    } else if (id == "" && type == 2) {
      const response = await fetch(
        "https://server-olhms.onrender.com/admin_session/view_current_session",
        {
          method: "POST",
          body: JSON.stringify({
            token: JSON.parse(admintoken),
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );

      const res_data = await response.json();
      // console.log(`----------> ${JSON.stringify(res_data)}`);
      if (res_data.status === "ok") {
        const current_term = res_data.sessionM["current_term"];

        const bodyFormData = new FormData();

        bodyFormData.append("token", JSON.parse(admintoken));
        bodyFormData.append("description", formData.body);
        bodyFormData.append("event_name", formData.title);
        bodyFormData.append("event_time", realTime);
        bodyFormData.append("event_date", realDate);
        bodyFormData.append("category", "news");
        bodyFormData.append("month", month);
        bodyFormData.append("event-images", selectedFile);
        bodyFormData.append("term", current_term);

        axios
          .post(
            "https://server-olhms.onrender.com/admin_events/create_event",
            bodyFormData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )

          .then((response) => {
            if (response.status === 200) {
              // navigate(formData.fleet_id !== "" ? "/success" : "/account", {
              //   state: { id: id, token: token },
              // });
              navigate("/admin/news");
              setLoadButton(false);
            } else {
              // setMessage("Error occured");
              // setLoading(false);
              setLoadButton(false);
              setFail(true);
            }
            console.log(response.data);
          })
          .catch((error) => {
            // console.log(error);
            // setLoading(false);
            setLoadButton(false);
            setFail(true);
          });
      } else {
        setLoadButton(false);
        setFail(true);
      }
    } else if (id != "" && type == 2) {
      const bodyFormData = new FormData();

      bodyFormData.append("token", JSON.parse(admintoken));
      bodyFormData.append("description", formData.body);
      bodyFormData.append("event_name", formData.title);
      bodyFormData.append("event_time", realTime);
      bodyFormData.append("event_date", realDate);
      bodyFormData.append("category", "news");
      bodyFormData.append("month", month);
      bodyFormData.append("event_id", id);
      bodyFormData.append("event-images", selectedFile);

      axios
        .post(
          "https://server-olhms.onrender.com/admin_events/edit_event",
          bodyFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )

        .then((response) => {
          if (response.status === 200) {
            // navigate(formData.fleet_id !== "" ? "/success" : "/account", {
            //   state: { id: id, token: token },
            // });
            navigate("/admin/news");
            setLoadButton(false);
          } else {
            // setMessage("Error occured");
            // setLoading(false);
            setLoadButton(false);
            setFail(true);
          }
          console.log(response.data);
        })
        .catch((error) => {
          // console.log(error);
          // setLoading(false);
          setLoadButton(false);
          setFail(true);
        });
    } else if (id == "" && type == 3) {
      const ImageFormData = new FormData();
      ImageFormData.append("token", JSON.parse(admintoken));
      ImageFormData.append("video-images", thumbnail);

      axios
        .post(
          "https://server-olhms.onrender.com/admin_video_learning/upload_file",
          ImageFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )

        .then((response) => {
          if (response.status === 200) {
            const image = response.data.lesson.url;
            const bodyFormData = new FormData();

            bodyFormData.append("token", JSON.parse(admintoken));
            bodyFormData.append("summary", formData.body);
            bodyFormData.append("title", "learning series");

            bodyFormData.append("video_learning-videos", selectedFile);
            bodyFormData.append("img_url", image);

            axios
              .post(
                "https://server-olhms.onrender.com/admin_video_learning/create_video_learning",
                bodyFormData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )

              .then((response) => {
                if (response.status === 200) {
                  // navigate(formData.fleet_id !== "" ? "/success" : "/account", {
                  //   state: { id: id, token: token },
                  // });
                  navigate("/admin/blog");
                  setLoadButton(false);
                } else {
                  // setMessage("Error occured");
                  // setLoading(false);
                  setLoadButton(false);
                  setFail(true);
                }
                console.log(response.data);
              })
              .catch((error) => {
                // console.log(error);
                // setLoading(false);
                setLoadButton(false);
                setFail(true);
              });
          } else {
            // setMessage("Error occured");
            // setLoading(false);
            setLoadButton(false);
            setFail(true);
          }
          console.log(response.data);
        })
        .catch((error) => {
          // console.log(error);
          // setLoading(false);
          setLoadButton(false);
          setFail(true);
        });
    } else if (id != "" && type == 3) {
      const bodyFormData = new FormData();

      bodyFormData.append("token", JSON.parse(admintoken));
      bodyFormData.append("summary", formData.body);
      bodyFormData.append("title", "learning series");

      bodyFormData.append("video_learning-videos", selectedFile);
      bodyFormData.append("video_learning_id", id);

      axios
        .post(
          "https://server-olhms.onrender.com/admin_video_learning/edit_video_learning",
          bodyFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )

        .then((response) => {
          if (response.status === 200) {
            // navigate(formData.fleet_id !== "" ? "/success" : "/account", {
            //   state: { id: id, token: token },
            // });
            navigate("/admin/blog");
            setLoadButton(false);
          } else {
            // setMessage("Error occured");
            // setLoading(false);
            setLoadButton(false);
            setFail(true);
          }
          console.log(response.data);
        })
        .catch((error) => {
          // console.log(error);
          // setLoading(false);
          setLoadButton(false);
          setFail(true);
        });
    }
  };

  const viewCategories = async (e) => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_category/view_categories",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: JSON.parse(admintoken) }),
      }
    );
    const data = await res.json();
    // setLoading(false);
    // setBlogs(data?.blogs);
    console.log(data);
  };

  useEffect(() => {
    if (type == 1) {
      viewCategories();
    }
  }, []);
  return (
    <div
      className="set-questions"
      style={{
        backgroundColor: "rgb(255, 248, 248)",
        height: "100%",
        alignItems: "flex-start",
      }}
    >
      <div className="set-container">
        <BackButton click={() => navigate(-1)} />

        <form className="vlog-form" onSubmit={handleSubmit}>
          <div className="vlog-split">
            <div className="form-element">
              <legend>
                {type === 0 || type === 2
                  ? "Tap the icon to add Image"
                  : "Tap the icon to add Video"}
              </legend>
              {selectedSrc == "" ? (
                <div className="dark-div">
                  <label>
                    <div className="vector-dark">
                      <img src={dark} alt="" />
                      <input
                        type="file"
                        accept={
                          type === 1 || type === 3
                            ? ".mp4, .mov, .avi, .wmv, .flv, .mkv, .m4v, .mpeg, .mpg, .webm"
                            : ".png, .jpg, .jpeg, .gif"
                        }
                        onChange={onFileChange}
                      />
                    </div>
                  </label>
                </div>
              ) : selectedSrc != "" && type == 0 ? (
                <div className="dark-div">
                  <img src={selectedSrc} alt="" className="dark-div-img" />
                </div>
              ) : selectedSrc != "" && type == 2 ? (
                <div className="dark-div">
                  <img src={selectedSrc} alt="" className="dark-div-img" />
                </div>
              ) : selectedSrc != "" && type == 1 ? (
                <div className="dark-div">
                  <video
                    src={selectedSrc}
                    controls
                    width="100%"
                    height="100%"
                    title={formData.title}
                  ></video>
                </div>
              ) : selectedSrc != "" && type == 3 ? (
                <div className="dark-div">
                  <video
                    src={selectedSrc}
                    controls
                    width="100%"
                    height="100%"
                    title={formData.title}
                  ></video>
                </div>
              ) : null}
              {selectedSrc == "" ? null : (
                <div className="show-blog-img">
                  <label>
                    Change {type == 1 || type == 3 ? "Video" : "Image"}
                    <input
                      type="file"
                      accept={
                        type === 1 || type === 3
                          ? ".mp4, .mov, .avi, .wmv, .flv, .mkv, .m4v, .mpeg, .mpg, .webm"
                          : ".png, .jpg, .jpeg, .gif"
                      }
                      onChange={onFileChange}
                    />
                  </label>
                </div>
              )}
            </div>

            {type != 3 ? (
              <div className="blog-element">
                <label>
                  {type === 0
                    ? `Blog Title (${formData?.title.length}/150)`
                    : type === 1
                    ? `Video Title (${formData?.title.length}/150)`
                    : `News Title/ Headline (${formData?.title.length}/150)`}
                </label>
                <input
                  placeholder="Intro.."
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                />
              </div>
            ) : (
              <div className="blog-element">
                <label>{`Video Description (${formData.body.length}/300)`}</label>
                <textarea
                  placeholder="Video Description"
                  maxLength="300"
                  name="body"
                  value={formData.body}
                  onChange={handleChange}
                  style={{ height: "100px" }}
                />
              </div>
            )}

            {type != 3 ? (
              <div className="blog-element">
                <label>
                  {type === 0
                    ? `Blog Story (${formData.body.length}/5000)`
                    : type === 1
                    ? `Video Summary (${formData.body.length}/1000)`
                    : `Body of News (${formData.body?.length}/5000)`}
                </label>
                <textarea
                  placeholder="Intro.."
                  maxLength={type === 1 ? "1000" : "5000"}
                  name="body"
                  value={formData.body}
                  onChange={handleChange}
                />
              </div>
            ) : null}
            {type == 1 || type == 3 ? (
              <div className="form-element">
                <legend>Tap to upload video thumbnail</legend>
                {thumbnailsrc == "" ? (
                  <div className="vector-div">
                    <label>
                      <div className="vector">
                        <img src={dark} alt="" />
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg, .gif"
                          onChange={onThumbnail}
                        />
                      </div>
                    </label>
                  </div>
                ) : (
                  <div className="vector-div">
                    <img src={thumbnailsrc} alt="" className="dark-div-img" />
                  </div>
                )}

                {thumbnailsrc == "" ? null : (
                  <div className="show-blog-img">
                    <label>
                      Change Image
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg, .gif"
                        onChange={onThumbnail}
                      />
                    </label>
                  </div>
                )}
              </div>
            ) : null}
          </div>
          <div className="split-vlog-button">
            <button className="post-vlog" type="submit">
              {id == "" ? "Post" : "Edit"}{" "}
              {type === 0
                ? "Article"
                : type === 1 || type === 3
                ? "Video"
                : "News"}
            </button>
            {id != "" ? (
              <button
                className="vlog-button"
                onClick={(e) => {
                  e.preventDefault();
                  setDeletable(true);
                }}
              >
                <div className="delete-vlog">
                  <img src={Delete} />
                </div>
                Delete{" "}
                {type === 0
                  ? "Article"
                  : type === 1 || type === 3
                  ? "Video"
                  : "News"}
              </button>
            ) : null}
          </div>
        </form>
        {type === 1 ? (
          <div className="category-split">
            <p>
              Choose Category to add Video (Please note that your Video can
              appear under one category only)
            </p>
            <div className="radio-row">
              {categories.map((item) => (
                <div className="category-radio">
                  <input
                    type="radio"
                    name="category"
                    value={item}
                    onChange={handleRadio}
                    checked={category == item}
                  />
                  <label>{item}</label>
                </div>
              ))}
            </div>
            {/* <Button2 name="Edit Categories" /> */}
          </div>
        ) : null}
      </div>
      <Popup isOpen={deletable}>
        <div className="deletable-div">
          <p>Delete Post</p>
          <div>Are you sure you want to delete this post?</div>
          <div>
            <Button2 name="No" click={() => setDeletable(false)} />
            <Button name="Yes" click={handleDelete} />
          </div>
        </div>
      </Popup>
      <Popup isOpen={loadButton}>
        <p>Uploading File</p>
      </Popup>
      <Popup isOpen={fail}>
        <p>Upload Failed. Try Again</p>
        <br/>
        <Button name="Okay" click={() => setFail(false)} />
      </Popup>
    </div>
  );
}

export function calculateReadingTime(content) {
  const wordsPerMinute = 200; // average reading speed in words per minute
  const wordCount = content.split(" ").length; // count the number of words in the content
  const readingTime = Math.ceil(wordCount / wordsPerMinute); // calculate the estimated reading time in minutes

  return readingTime;
}

export function convertToAmPm(time24) {
  let time = time24.split(":");
  let hours = parseInt(time[0], 10);
  let minutes = parseInt(time[1], 10);
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let time12 = hours + ":" + minutes + " " + ampm;
  return time12;
}

export function formatDate(dateStr) {
  let date = new Date(dateStr);
  let day = date.getDate();
  let suffix = getDaySuffix(day);
  let month = date.toLocaleString("default", { month: "long" });
  let year = date.getFullYear();
  return day + suffix + " " + month + ", " + year;
}

function getDaySuffix(day) {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}
