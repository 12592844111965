import React, { useState, useRef, useContext, useEffect } from "react";
import video from "../../images/video.png";
import novideo from "../../images/novideo.png";
import nonote from "../../images/nonote.png";
import attach from "../../images/attach.png";
import pdf from "../../images/pdf.png";
import { useNavigate } from "react-router-dom";
import "./resources.css";
import { Triple, Triple2 } from "./ResultTeacher";
import { SubjectComponent } from "./FirstCat";
import { adminContext } from "../context/adminContext";

export default function Resources() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [subjects, setSubjects] = useState([]);
  const [toggle, setToggle] = useState("first");
  // const videoRef = useRef(null);

  const values = useContext(adminContext);
  const { teacherData, teachertoken } = values;
  const dataT = JSON.parse(teacherData);
  const columns = 2;
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const colors = [
    "#AAD7F7",
    "#BDECCA",
    "#FBECA1",
    "#F8D6C7",
    "#DAD4F6",
    "FFC0CB",
    "#C4A484",
    "#D3D3D3",
    "#AAD7F7",
    "#BDECCA",
    "#FBECA1",
    "#F8D6C7",
    "#DAD4F6",
    "FFC0CB",
    "#C4A484",
    "#D3D3D3",
  ];

  const viewSub = async () => {
    // had to do this because the way the subjects was initially entered doesn't match (in caps) with
    // how this endpoint would be expecting the subject names
    let class_name = dataT?.class_name;
    let temp_class_name = class_name.toLowerCase();
    if (temp_class_name == "creche") {
      class_name = "Creche";
    } else if (temp_class_name == "toddlers") {
      class_name = "Toddlers";
    } else if (temp_class_name == "infant community 1") {
      class_name = "Infant Community 1";
    } else if (temp_class_name == "infant community 2") {
      class_name = "Infant Community 2";
    } else if (temp_class_name == "nursery 1") {
      class_name = "Nursery 1";
    } else if (temp_class_name == "nursery 2") {
      class_name = "Nursery 2";
    } else if (temp_class_name == "grade 1") {
      class_name = "Grade 1";
    } else if (temp_class_name == "grade 2") {
      class_name = "Grade 2";
    } else if (temp_class_name == "grade 3") {
      class_name = "Grade 3";
    } else if (temp_class_name == "grade 4") {
      class_name = "Grade 4";
    } else if (temp_class_name == "grade 5") {
      class_name = "Grade 5";
    }

    const res = await fetch(
      "https://server-olhms.onrender.com/teacher_subject/view_subjects",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(teachertoken),
          class_name: class_name,
          // class_name: dataT?.class_name,
        }),
      }
    );
    const data = await res.json();
    setLoading(false);
    setSubjects(data?.subjects);

    console.log(data);
  };

  useEffect(() => {
    viewSub();
  }, []);

  // basic responsiveness handling
  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (document.documentElement.clientWidth <= 800) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="set-questions">
      <div className="set-container">
        <Triple2
          text1="1st Term"
          text2="2nd Term"
          text3="3rd Term"
          toggle={toggle}
          click1={() => {
            setToggle("first");
          }}
          click2={() => {
            setToggle("second");
          }}
          click3={() => {
            setToggle("third");
          }}
        />

        <div className={mobile ? "" : "set-subjects"}>
          <p>
            <strong style={{ fontSize: 20 }}>Subjects</strong>
          </p>
          <div className={mobile ? "" : "set-grid"} style={mobile ? {} : {}}>
            {subjects?.map((item, index) => {
              const row = Math.floor(index / columns);
              const backgroundColor =
                colors[Math.floor(Math.random() * colors.length)];
              return (
                <div key={index} style={mobile ? { marginTop: 16 } : {}}>
                  <SubjectComponent
                    color={backgroundColor}
                    subject={item?.subject_name}
                    click={() =>
                      navigate("/teacher/term-weeks", {
                        state: {
                          term:
                            toggle == "first"
                              ? "First Term"
                              : toggle == "second"
                              ? "Second Term"
                              : toggle == "third"
                              ? "Third Term"
                              : null,
                          subject: item?.subject_name,
                          id: item?._id,
                        },
                      })
                    }
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
    // <div className="resources-main">
    //   <div className="resources-content">
    //     <div className="resources-term">
    //       <div className="class-term">1st TERM</div>
    //     </div>

    //     <Toggle
    //       click1={() => setToggle(true)}
    //       click2={() => setToggle(false)}
    //       toggle={toggle}
    //       text1="Videos"
    //       text2="Notes"
    //     />
    //     <div
    //       className="add-video"
    //       onClick={() =>
    //         navigate("/teacher/upload", { state: { file: toggle ? 0 : 1 } })
    //       }
    //     >
    //       <div className={toggle ? "video-icon" : "attach-icon"}>
    //         <img src={toggle ? video : attach} alt="" />
    //       </div>
    //       <p>{toggle ? "Add Video" : "Add Notes"}</p>
    //     </div>
    //     {/* <ClassContent>
    //       <NoteComponent />
    //       <NoteComponent />
    //       <NoteComponent />
    //       <NoteComponent />
    //       <NoteComponent />
    //       <NoteComponent />
    //       <NoteComponent />
    //       <NoteComponent />
    //       <NoteComponent />
    //       <NoteComponent />
    //     </ClassContent> */}
    //     {toggle ? (
    //       <Novideo
    //         img={novideo}
    //         text="No Video has been uploaded. Tap the icon to upload a new video"
    //       />
    //     ) : (
    //       <Novideo
    //         img={nonote}
    //         text="No Notes have been uploaded. Tap the icon to upload a  new Note"
    //       />
    //     )}
    //   </div>
    // </div>
  );
}

export function Toggle(props) {
  return (
    <div className="slider-button">
      <div
        onClick={props.click1}
        className="slider-option"
        id={props.toggle ? "active" : ""}
      >
        {props.text1}
      </div>
      <div
        className="slider-option"
        id={props.toggle ? "" : "active2"}
        onClick={props.click2}
      >
        {props.text2}
      </div>
    </div>
  );
}

export function Toggle2(props) {
  return (
    <div className="slider-button2">
      <div
        onClick={props.click1}
        className="slider-option"
        id={props.toggle ? "active-slide" : ""}
      >
        {props.text1}
      </div>
      <div
        className="slider-option"
        id={props.toggle ? "" : "active-slide"}
        onClick={props.click2}
      >
        {props.text2}
      </div>
    </div>
  );
}

export function Novideo(props) {
  return (
    <div className="no-video">
      <div className="novideo">
        <img src={props.img} alt="" />
      </div>
      <p>{props.text}</p>
    </div>
  );
}

export function NoteComponent(props) {
  return (
    <div className="fetched-note">
      <div className="pdf-div">
        <img src={pdf} alt="" />
      </div>
      <div className="title-divider">
        <div>
          <p>
            <strong>{props.title}</strong>
          </p>
          <p className="font-sub">{props.weekLes}</p>
        </div>
        <div className="dropdown">
          <p className="dropdown-btn">&#8942;</p>
          <div class="dropdown-content" onClick={props.onClick}>
            <p onClick={props.onDelete}>Delete Note</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export function VideoComponent(props) {
  return (
    <div className="fetched-video">
      <div className="videodiv">
        <video
          poster={props.thumbnail_url}
          src={props.lesson_url}
          width="100%"
          height="100%"
          autoPlay={false}
          controls
        ></video>
      </div>
      <div className="title-divider">
        <div>
          <p>
            <strong>{props.title}</strong>
          </p>
          <p>{props.weekLes}</p>
        </div>
        <div className="dropdown">
          <p className="dropdown-btn">&#8942;</p>
          <div class="dropdown-content" onClick={props.onClick}>
            <p onClick={props.onDelete}>Delete Video</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export function ClassContent(props) {
  return (
    <div className="class-content">
      <div className="video-flex">{props.children}</div>
    </div>
  );
}
