import React from "react";
import { useState, useEffect } from "react";
import "./blogone.css";
import Slide1 from "../../images/slide1.png";
import Slide2 from "../../images/slide2.png";
import Slide3 from "../../images/slide3.png";
import Slide4 from "../../images/slide4.png";
import { useNavigate } from "react-router-dom";
import { truncateString } from "../adminPages/Vlogs";

export default function BlogOne() {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [resources, setResources] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const viewBlogs = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_blog/view_blogs",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(),
      }
    );
    const data = await res.json();
    setLoading(false);
    setBlogs(data?.blogs);
    console.log(data);
  };
  useEffect(() => {
    viewBlogs();
  }, []);

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === blogs.length - 1 ? 0 : prevIndex + 1
    );
  };
  useEffect(() => {
    const timer = setInterval(goToNext, 5000);

    return () => clearInterval(timer);
  });

  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (document.documentElement.clientWidth <= 800) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  const mystyle = { backgroundImage: `url(${blogs[currentIndex]?.img_url})` };
  return (
    <div className="blog-one" style={mystyle}>
      <div className={mobile ? "blog-one-overlay-mobile" : "blog-one-overlay"}>
      <div className="blog-one-flex">
        <div className="blog-title-p">
          <p className="blog-p-one">{blogs[currentIndex]?.title}</p>
          <p className="blog-p-two">
            {truncateString(blogs[currentIndex]?.body, 120)}
          </p>
        </div>
        <div>
          <button
            className="blog-button"
            onClick={() =>
              navigate("/blog-content", {
                state: {
                  img: blogs[currentIndex]?.img_url,
                  title: blogs[currentIndex]?.title,
                  body: blogs[currentIndex]?.body,
                  timestamp: blogs[currentIndex]?.timestamp,
                  view_count: blogs[currentIndex]?.view_count,
                  comment_count: blogs[currentIndex]?.comment_count,
                  like_count: blogs[currentIndex]?.like_count,
                  blog_id: blogs[currentIndex]?._id
                },
              })
            }
          >
            Read article
          </button>
        </div>
      </div>
      </div>
    </div>
  );
}
