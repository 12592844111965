import React from "react";
import "./profilepix.css";
import aang from "../../../images/aang.jpg";

export const ProfilePix = (img, fullname, profile, email, phone_no) => {
  //console.log(email, fullname, img);
  //console.log(fullname);
  return (
    <div className="my-chat-profile-prop">
      <div className="my-chat-profile-picture">
        <img
          src={img === "a" || img === "" ? aang : img}
          alt="icon"
          className="my-chat-support-profile-pic"
        />
      </div>
      <div className="my-chat-profile-text">
        <h6>{fullname}</h6>
        <h6>{email}</h6>
      </div>
    </div>
  );
};
