import React, { useState, useEffect } from "react";
import "./personaldata.css";
import Passport from "../../images/news1.png";
import Arrow from "../../images/arrow.png";
import "../teacherPages/teacherprofile.css";
import { useLocation } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast"; // Import the Toaster component

export default function PersonalData() {
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const student = JSON.parse(localStorage.getItem("studentData"));
  const studentToken = localStorage.getItem("student_rubbish");

  const [permanentAddress, setPermanentAddress] = useState(
    student.guardian_info.address
  );
  const [country, setCountry] = useState(student.country);
  const [religion, setReligion] = useState(student.religion);
  const [parentPhone, setParentPhone] = useState(
    student.guardian_info.phone_no
  );
  const [parentEmail, setParentEmail] = useState(student.guardian_info.email);

  const handlePermaAddress = (e) => setPermanentAddress(e.target.value);
  const handleCountry = (e) => setCountry(e.target.value);
  const handleParentPhone = (e) => setParentPhone(e.target.value);
  const handleParentEmail = (e) => setParentEmail(e.target.value);
  const handleReligion = (e) => setReligion(e.target.value);

  const changePassword = async () => {
    setLoading(true);

    try {
      const res = await fetch(
        "https://server-olhms.onrender.com/student_profile/edit_student_profile",
        {
          method: "POST",
          body: JSON.stringify({
            token: JSON.parse(studentToken),
            permanent_address: permanentAddress,
            country: country,
            parent_phone: parentPhone,
            parent_email: parentEmail,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const data = await res.json();
      console.log(data);

      if (data.msg == "success") {
        setLoading(false);

        setCountry(data.student.country);
        setParentEmail(data.student.guardian_info.email);
        setParentPhone(data.student.guardian_info.phone_no);
        setPermanentAddress(data.student.guardian_info.address);

        localStorage.setItem("studentData", JSON.stringify(data.student));

        toast.success("Profile details updated successfully", {
          position: "bottom-center",
        });
        setEdit(false);
      } else {
        setLoading(false);
        console.log(data);
        alert(`${data.msg }`);
      }
    } catch (error) {
      setLoading(false);
      alert("Sorry, an error occured. Please try again later");
      console.log(error);
    }
  };

  const handleSubmit = () => {
    if (edit == true && !loading) {
      changePassword();
    }

    if (edit === true) {
      setEdit(false);
    } else setEdit(true);
  };

  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (document.documentElement.clientWidth <= 800) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className="personal-data"
      style={mobile ? { paddingLeft: 30, paddingRight: 30 } : {}}
    >
      <div className="data-wrapper">
        <div className="edit-data" onClick={handleSubmit}>
          {edit ? "Update & Save Personal Data" : "Edit Personal Data"}
        </div>
        {/* <div className="data-div"> */}
        <div className="show-details">
          <div>First Name:</div>
          <div className="light-property">{student.firstname}</div>
          <div>Middle Name:</div>
          <div className="light-property">{student.middlename}</div>
          <div>Last Name:</div>
          <div className="light-property">{student.lastname}</div>
          <div>Permanent Address:</div>
          {edit ? (
            <input
              className="properties-input"
              name="perma_address"
              value={permanentAddress}
              onChange={handlePermaAddress}
            />
          ) : (
            <div className="light-property">{permanentAddress}</div>
          )}
          <div>Country:</div>
          {edit ? (
            <input
              className="properties-input"
              name="country"
              value={country}
              onChange={handleCountry}
            />
          ) : (
            <div className="light-property">{country}</div>
          )}
          <div>Parent's Phone Number 1:</div>
          {edit ? (
            <input
              className="properties-input"
              name="parent_phone"
              value={parentPhone}
              onChange={handleParentPhone}

              maxLength={11}
            />
          ) : (
            <div className="light-property">{parentPhone}</div>
          )}
          {/* <div>Parent's Phone Number 2:</div>
          {edit ? (
            <input className="properties-input" />
          ) : (
            <div className="light-property">Nil</div>
          )} */}
          <div> Parent's Email:</div>
          {edit ? (
            <input
              className="properties-input"
              name="parent_email"
              value={parentEmail}
              onChange={handleParentEmail}
            />
          ) : (
            <div className="light-property">{parentEmail}</div>
          )}

          <div> Religion:</div>
          {/* {edit ? (
            <input
              className="properties-input"
              name="parent_email"
              value={religion}
              onChange={handleReligion}
            />
          ) : ( */}
            <div className="light-property">{religion}</div>
          {/* )} */}



          {/* <div>Religion:</div>
          <div className="light-property">Christian</div> */}
          <div>Gender:</div>
          <div className="light-property">{student.gender}</div>
          <div>Date of Birth:</div>
          <div className="light-property">{student.date_of_birth}</div>
          <div>Passport Photo:</div>
          <div className="data-img">
            <img src={student.img_url} alt="" style={{ objectFit: "cover" }} />
          </div>
        </div>
        {/* </div> */}
        {edit ? (
          <BackButton
            click={() => {
              setEdit(false);
            }}
          />
        ) : null}
      </div>
    </div>
  );
}

export function BackButton(props) {
  return (
    <div onClick={props.click} className="back-button" style={props.style}>
      <span>Back</span>
      <span className="arrow-div">
        <img src={Arrow} alt="" />
      </span>
    </div>
  );
}
