import React, {useState, useEffect} from "react";
import "./namecomponent.css";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { height } from "@mui/system";
import Button from "../Button/Button";
import { useNavigate, useLocation } from "react-router-dom";

export default function NameComponent() {
  const navigate = useNavigate();
  const student = JSON.parse(localStorage.getItem('studentData'));
  const studentToken = localStorage.getItem('student_rubbish');

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [match, setMatch] = useState(true)

  const [loading, setLoading] = useState(false);
  const [openDialog, handleDisplay] = useState(false);

  const handleClose = () => {
    handleDisplay(false);
    setConfirmPassword('');
    setOldPassword('');
    setNewPassword('');
  };

  const openDialogBox = () => {
     handleDisplay(true);
  };
  const dialogStyle = {
     padding: "20px",
  };

  const changePassword = async () => {
    setLoading(true);

    try {
      const res = await fetch(
        "https://server-olhms.onrender.com/student_auth/change_password_web",
        {
          method: "POST",
          body: JSON.stringify({
            token: JSON.parse(studentToken),
            old_password: oldPassword,
            new_password: newPassword,
            confirm_password: confirmPassword
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const data = await res.json();
      console.log(data);

      if (data.msg == "Success") {
        setLoading(false)
        alert("password change successful");
        handleClose();
      } else {
        setLoading(false)
        console.log(data);
        alert("An error occured");

      }
    } catch (error) {
      setLoading(false)
      alert("An error occured");
      console.log(error);
    }
  }

  const handleOldPassword = e => setOldPassword(e.target.value);
  const handleNewPassword = e => setNewPassword(e.target.value);
  const handleConfirmPassword = e => setConfirmPassword(e.target.value);

  useEffect(()=>{
    if(newPassword == confirmPassword){
      setMatch(true)
    }else{
      setMatch(false);
    }
  }, [newPassword, confirmPassword]);

  return (
    <div className="name-img">
      <div className="name-component">
        <div className="student-info">
          <p className="student-name">{student.fullname}</p>
          <p className="faint-info">{`Student ID: ${student.student_id}`}</p>
          <p className="faint-info">{`Admission year: ${student.addmission_year}`}</p>
          <p className="faint-info">{`Current Session: ${student.current_session}`}</p>
          <p className="faint-info">{`Current Term: ${student.current_term}`}</p>
          <p className="faint-info">{`Current Class: ${student.class_name}`}</p>
        </div>
        <div onClick={()=>{
          openDialogBox();
        }} className="password-button">Change Password</div>
      </div>

      <Dialog  onClose = {handleClose} open = {openDialog}>
            <DialogTitle>Change Password</DialogTitle>
            <div style={{marginLeft: 100, marginRight: 100}}>

            <div style={{marginBottom: 12}}>Old Password</div>
            <input className="properties-input" style={{marginBottom: 20}}
              value={oldPassword}
              name='old_password'
              placeholder="Enter Old Password"
              type="password"
              onChange={handleOldPassword}
            />

            <div style={{marginBottom: 12}}>New Password</div>
            <input className="properties-input" style={{marginBottom: 20}}
              value={newPassword}
              name='new_password'
              placeholder="Enter New Password"
              type="password"
              onChange={handleNewPassword}
            />

            <div style={{marginBottom: 12}}>Confirm Password</div>
            <input className="properties-input" style={{marginBottom: 20}}
              value={confirmPassword}
              name='confirm_password'
              placeholder="Confirm New Password"
              type="password"
              onChange={handleConfirmPassword}
            />
            <div style={{display: 'flex', justifyContent: 'center', visibility: match ? 'hidden' : 'visible'}}>
              <div style={{marginBottom: 10, color: 'red'}}>Passwords do not match!</div>
            </div>


            <div style={{display: 'flex', justifyContent: 'center', marginBottom: 20}}>
            <Button disabled={loading} click={(e)=>{
              e.preventDefault();
              if(newPassword == '' || oldPassword == '' || confirmPassword == '')
                return;

              if(match){
                changePassword();
              }

            }} style={{marginBottom: 10}} name={'Save and Update'}/>
            </div>
          
            </div>
            
         </Dialog>
    </div>
  );
}

export function AdmissionComp(props) {
  return (
    <div className="name-img" style={props.style}>
      <div className="complete-apply">
        <div className="babies">{props.title}</div>
        <div className="toddlers">{props.classes}</div>
      </div>
    </div>
  );
}
