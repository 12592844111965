import React, {useState, useEffect} from "react";
import "./git.css";
import { useNavigate } from "react-router-dom";

export default function GetInTouch() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [touches, setTouches] = useState([]);
  const [count, setCount] = useState(0);
  const [stats, setStats] = useState({});

  let adminToken = localStorage.getItem("admin_rubbish");

  // function to get total get in touches document
  const getStats = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_statistics/view_statistics",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({token: JSON.parse(adminToken)}),
      }
    );

    const data = await res.json();
    
    setStats(data?.statistics);
  };

  // function to get all get in touches

  const getGetInTouches = async (currPage) => {
    setLoading(true);
    const res = await fetch(
      "https://server-olhms.onrender.com/student_get_in_touch/view_get_in_touches",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pagec: currPage, token: JSON.parse(adminToken)}),
      }
    );

    const data = await res.json();

    if(data?.count == 0){
      setLoading(false);
      console.log("here");
      setTouches([]);
    }else{
      console.log("here instead");
      setLoading(false);
      setTouches(data?.get_in_touches);
      setCount(data?.count);
    }
  };

  useEffect(()=>{
    getGetInTouches(currentPage);
    getStats();
  }, [currentPage]);

  return (
    <div
      className="set-questions"
      style={{
        backgroundColor: "rgb(255, 248, 248)",
        height: "100%",
        alignItems: "flex-start",
      }}
    >
      <div className="git-wrap">
        <div className="split-through">
          <p className="data-number">Get In Touch</p>
          <p>{`All (${stats.no_get_in_touches})`}</p>
        </div>
        <div className="database-table git-table">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone Number</th>
                <th>Email Address</th>
                <th>Date </th>
              </tr>
            </thead>
            <tbody>
            {
                touches.map((item, index)=>

                    (
                      <tr key={index}
                      onClick={() => navigate("/admin/view-message", { state: item })}
                      style={{
                        cursor: "pointer",
                        borderBottom: "1px solid rgba(10, 10, 10, 0.2)",
                      }}
                    >
                    <td>{item.fullname}</td>
                    <td>{item.phone_no}</td>
                    <td>{item.email}</td>
                    <td>{convertTime(item.timestamp)}</td>
              
                  </tr>)
                  
                )
              }
            </tbody>
          </table>
        </div>
        <div className="pagination-git">
          <div>{`Showing 1 to ${count} of ${stats.no_get_in_touches} Messages`}</div>
          <div className="view-more-git">
            <div onClick={()=>{
              let cPage = currentPage;
              if(currentPage >= 2){
                setCurrentPage(--cPage);
                getGetInTouches(cPage);
              }
            }} className="shade-direction">
              <i className="fas fa-angle-left"></i>
            </div>
            View more
            <div onClick={()=>{
              let cPage = currentPage;
              setCurrentPage(++cPage);
              getGetInTouches(cPage);
            }} className="shade-direction">
              <i className="fas fa-angle-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const convertTime = (timestamp) => {
  return new Date(timestamp).toString().split('GMT')[0];
}
