import React from "react";
import "./sectionnine.css";
import { useNavigate } from "react-router-dom";

export default function SectionNine() {
  const navigate = useNavigate();
  return (
    <div className="section-nine">
      <div className="section-nine-div">
        <p className="section-nine-first">
          <strong>Begin your kids educational journey</strong>
        </p>
        <p className="section-nine-second">
          Choosing Our Lady of Hope Montessori School for your child is one of
          the most important decisions that you will ever make. Nothing beats
          seeing and experiencing our School in action, so we encourage all
          prospective parents and pupils to visit OLHMS and talk to staff and
          students; this helps confirm that we really are the right school.
        </p>
        <div className="section-nine-button">
          <button
            id="get-in-touch"
            onClick={() => navigate("/contact", { state: { type: 0 } })}
          >
            Get in Touch
          </button>
          <button id="apply" onClick={() => navigate("/apply")}>
            Apply Now
          </button>
        </div>
      </div>
    </div>
  );
}
