import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BackButton } from "../studentPages/PersonalData";
import { Toggle } from "../teacherPages/Resources";
import Passport from "../../images/news1.png";
import Button, { Button2 } from "../../components/Button/Button";
import Edit from "../../images/edit.png";
import Add from "../../images/add.png";
import Addition from "../../images/addition.png";
import MenuMore from "../../images/menuMore.png";
import NoPayment from "../../images/no_payment.png";
import { adminContext } from "../context/adminContext";
import { capitalizeFirstLetter } from "./StaffDatabase";
import User from "../../images/user.png";
import { ClipLoader } from "react-spinners";
import { Triple } from "../teacherPages/ResultTeacher";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Mail from "../../images/mail.png";
import ClickAwayListener from "react-click-away-listener";
import { toast } from "react-hot-toast";
import Popup from "reactjs-popup";
import { IoMdMore } from "react-icons/io";
import dayjs from "dayjs";

export default function SettingsViewStudent() {
  const [openDialog, handleDisplay] = useState(false);

  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    handleDisplay(false);
  };

  const openDialogBox = () => {
    handleDisplay(true);
  };
  const dialogStyle = {
    padding: "20px",
  };

  const location = useLocation();
  const reg = location.state.reg; // student

  const navigate = useNavigate();
  const [toggle, setToggle] = useState("personal");
  const [view, setView] = useState(true);
  const type = location.state.num;
  const id = location.state.id;

  const center = location.state.center;
  const className = location.state.className;
  const class_key = location.state.class_key;
  const overall_class_key = location.state.overall_class_key;
  const tempAdminToken = location.state.tempAdminToken;

  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState({});
  const [student, setStudentData] = useState({});
  const [paymentData, setPaymentData] = useState({});
  const values = useContext(adminContext);
  const { admintoken } = values;
  //   console.log(not);
  const [formData, setFormData] = useState({
    firstname: "",
    middlename: "",
    lastname: "",
    country: "",
    address: "",
    number1: "",
    number2: "",
    email: "",
    religion: "",
    gender: "male",
    centre: "Emerald",
    role: "",
    year: "2011",
  });
  const [isSelected, setIsSelected] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedSrc, setSelectedSrc] = useState("");
  const handleChange = (e) => {
    const target = e.target;
    // const value = target.type === "select" ? target.selected : target.value;
    // const name = target.name;
    const { name, value } = target;
    setFormData({ ...formData, [name]: value });
    console.log(formData);
  };
  const onFileChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState == 2) {
        setSelectedSrc(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
    setIsSelected(true);
  };

  const [invoice, setInvoice] = useState({
    payment_type: "School Fees",
    session: "2022/2023",
    term: "First Term",
    payment_desc: "",
    amount_paid_fig: "",
    fullname: "",
    amount_in_words: "",
  });

  const handleInvoiceChange = (e) => {
    const { name, value } = e.target;
    const temp = { ...invoice };
    temp[name] = value;
    setInvoice(temp);
  };

  // const [loading, setloading] = useState(false);
  const handleCheck = (e) => {
    setValidated(e.target.checked);
  };

  const [payments, setPayments] = useState([]);

  // get current date
  let date = new Date();
  const presentDate = `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}`;

  const [loadingResetDialogButton, setLoadingResetDialogButton] =
    useState(false);
  const [openResetDialog, setOpenResetDialog] = useState(false);
  const openResetDialogBox = () => {
    setOpenResetDialog(true);
  };
  const closeResetDialogBox = () => {
    setOpenResetDialog(false);
  };

  const DateConverter = (timestamp, days_to_add) => {
    const futureDate_temp = dayjs(timestamp); //Fri, 20 Oct 2023 13:36:22 GMT
    const futureDate = futureDate_temp
      .add(days_to_add, "day")
      .format("MMM DD, YYYY"); // Feb 17, 2024
    const currentDate = dayjs(); // Mon, 06 Nov 2023 12:09:30 GMT

    if (days_to_add === 0) {
      //just showing the date login code was generated
      return {
        converted_date: dayjs(timestamp).format("MMM DD, YYYY"),
        login_code_has_expired: false,
      };
    } else if (futureDate_temp.isAfter(currentDate)) {
      return {
        converted_date: futureDate,
        login_code_has_expired: false,
      };
    } else {
      return {
        converted_date: futureDate,
        login_code_has_expired: false,
      };
    }
  };

  const Menu = (payment) => (
    <div className="optionsMenu">
      <Popup
        trigger={
          <button className="optionsMenu-details">
            {" "}
            <IoMdMore />{" "}
          </button>
        }
        position="right top"
        on="click"
        closeOnDocumentClick
        mouseLeaveDelay={300}
        mouseEnterDelay={0}
        contentStyle={{ padding: "0px", border: "none" }}
        // arrow={false}
      >
        <ul className="optionsMenu-item">
          {payment.payment_type == "School Fees" ? (
            <li
              onClick={() => {
                setPaymentData(payment);
                openDialogBox();
              }}
            >
              View Login details
            </li>
          ) : null}

          <li
            onClick={() => {
              toast.error("Feature is currently being worked upon", {
                icon: "⏳️",
                position: "bottom-center",
              });
            }}
          >
            View invoice
          </li>
        </ul>
      </Popup>
    </div>
  );

  useEffect(() => {
    const viewStudent = async () => {
      const res = await fetch(
        "https://server-olhms.onrender.com/admin_students/view_student",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: JSON.parse(admintoken),
            student_id: id,
          }),
        }
      );
      const data = await res.json();
      setData(data);
      setIsLoaded(true);
      setStudentData(data.student);
      console.log(data);
    };

    viewStudent();
  }, []);

  const [results, setResults] = useState({
    creche: [],
    toddlers: [],
    infant1: [],
    infant2: [],
    nursery1: [],
    nursery2: [],
    grade1: [],
    grade2: [],
    grade3: [],
    grade4: [],
    grade5: [],
  });

  const viewResults = async () => {
    setLoading(true);
    const res = await fetch(
      "https://server-olhms.onrender.com/guardian_result/view_all_results",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: tempAdminToken,
          student_id: reg.student_id,
        }),
      }
    );
    const data = await res.json();
    setLoading(false);

    const temp = { ...results };
    data?.results.map((result) => {
      let class_name_lowercase = result.class_name.toLowerCase();
      if (class_name_lowercase == "creche") {
        temp["creche"].push(result);
      }

      if (class_name_lowercase == "toddlers") {
        temp["toddlers"].push(result);
      }

      if (class_name_lowercase == "infant community 1") {
        temp["infant1"].push(result);
      }

      if (class_name_lowercase == "infant community 2") {
        temp["infant2"].push(result);
      }

      if (class_name_lowercase == "nursery 1") {
        temp["nursery1"].push(result);
      }

      if (class_name_lowercase == "nursery 2") {
        temp["nursery2"].push(result);
      }

      if (class_name_lowercase == "grade 1") {
        temp["grade1"].push(result);
      }

      if (class_name_lowercase == "grade 2") {
        temp["grade2"].push(result);
      }

      if (class_name_lowercase == "grade 3") {
        temp["grade3"].push(result);
      }

      if (class_name_lowercase == "grade 4") {
        temp["grade4"].push(result);
      }

      if (class_name_lowercase == "grade 5") {
        temp["grade5"].push(result);
      }
    });

    setResults(temp);
    setLoading(false);
    console.log(data);
  };

  useEffect(() => {
    viewResults();
  }, []);

  const [addPayment, setAddPayment] = useState(false);
  const [loginCode, setLoginCode] = useState("");
  const makePayment = async () => {
    setLoading(true);
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_payment/make_payment",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: tempAdminToken,
          student_id: reg.student_id,
          term: invoice.term,
          session: invoice.session,
          student_name: student.fullname,
          description: invoice.payment_desc,
          admin_name: invoice.fullname,
          amount: invoice.amount_paid_fig,
          payment_date: presentDate,
          payment_type: invoice.payment_type,
          amount_in_words: invoice.amount_in_words,
        }),
      }
    );
    const data = await res.json();
    const temp = [...payments];
    temp.push(data?.payment);
    setPayments(temp);

    let temp_payment = data?.payment;
    temp_payment["login_code"] = data?.login_code;

    setPaymentData(temp_payment);
    
    setLoading(false);

    setLoginCode(data?.login_code);
    if (invoice.payment_type == "School Fees") {
      openDialogBox();
    } else {
      setAddPayment(false);
    }

    console.log(data);
  };

  const getPayments = async () => {
    setLoading(true);
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_payment/view_student_payments",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: tempAdminToken,
          student_id: reg.student_id,
        }),
      }
    );
    const data = await res.json();
    setLoading(false);

    // console.log(data);

    setPayments(data?.payments);
    console.log(data);
  };

  useEffect(() => {
    getPayments();
  }, []);

  const sendMail = async () => {
    setLoading(true);
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_payment/send_login_code_mail",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: tempAdminToken,
          term: invoice.term,
          session: invoice.session,
          student_name: reg.fullname,
          email: reg.guardian_info.email,
          login_code: loginCode,
        }),
      }
    );
    const data = await res.json();
    if (data?.msg == "success") {
      alert("Email sent");
      handleClose();
      setAddPayment(false);
    } else {
      alert("An error occured");
    }
    setLoading(false);

    console.log(data);
  };

  const sessionOptions = [
    "2019/2020",
    "2020/2021",
    "2021/2022",
    "2022/2023",
    "2023/2024",
    "2024/2025",
    "2025/2026",
    "2026/2027",
    "2027/2028",
    "2028/2029",
    "2029/2030",
  ];

  const [morePopup, setMorePopup] = useState(false);

  if (!isLoaded) {
    console.log("got here");
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "100px",
        }}
      >
        <ClipLoader color={"orange"} size={100} />
      </h1>
    );
  } else if (data?.msg === "success") {
    return (
      <div
        className="set-questions"
        style={{
          backgroundColor: "rgb(255, 248, 248)",
          height: "100%",
          alignItems: "flex-start",
        }}
      >
        <div className="set-container">
          <BackButton
            click={() => {
              if (addPayment) {
                setAddPayment(false);
              } else if (view || type === 2) {
                navigate(-1);
              } else {
                setView(true);
              }
            }}
          />
          {type === 0 ? (
            <TripleS
              click1={() => setToggle("personal")}
              click2={() => setToggle("result")}
              click3={() => setToggle("payments")}
              toggle={toggle}
              text1="Personal Data"
              text2="Result"
              text3="Payments"
            />
          ) : type === 1 ? (
            <p
              style={{
                fontWeight: "600",
                fontSize: "20px",
                alignSelf: "flex-start",
                marginTop: "40px",
              }}
            >
              Personal Data
            </p>
          ) : null}

          {toggle == "payments" ? (
            <div style={{ alignSelf: "flex-start" }}>
              <p style={{ fontSize: 20, fontWeight: "bold", marginBottom: 16 }}>
                Generate Payment Invoice
              </p>
              <p
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  paddingBottom: "10px",
                }}
              >
                {student.fullname}
              </p>
              <p
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  paddingBottom: "10px",
                }}
              >
                Student ID: {student.student_id}
              </p>
              {student.login_code === "not set" ? null : payments.length > 0 ? (
                <div>
                  <span>{`Last Login code for ${
                    payments[payments.length - 1]["term"]
                  } ${
                    loginCode == "" || loginCode == undefined
                      ? student.login_code
                      : loginCode
                  } was generated ${
                    DateConverter(
                      payments[payments.length - 1]["timestamp"],
                      0
                    )["converted_date"]
                  }`}</span>

                  <br />

                  <div
                    style={{
                      // marginLeft: 30,
                      // marginRight: 30,
                      display: "flex",
                      fontSize: 12,
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      style={{
                        fontSize: 12,
                        color: "#F20A0A",
                        // flex: "display",
                        // flexDirection: "row",
                        // justifyContent: "center",
                      }}
                    >{`Login code validity date: ${
                      DateConverter(
                        payments[payments.length - 1]["timestamp"],
                        120
                      )["converted_date"]
                    }`}</span>

                    {DateConverter(
                      payments[payments.length - 1]["timestamp"],
                      120
                    )["login_code_has_expired"] === true ? (
                      <p
                        onClick={openResetDialogBox}
                        // onClick={()=>{
                        //   console.log(`---> ${student.login_code}`);
                        // }}
                        style={{
                          backgroundColor: "red",
                          color: "white",
                          fontWeight: "500",
                          cursor: "pointer",
                          padding: "0 10px",
                          marginLeft: "10px",
                          borderRadius: "5px",
                        }}
                      >
                        RESET LOGIN CODE
                      </p>
                    ) : null}
                  </div>

                  <span
                    style={{
                      fontSize: 12,
                    }}
                  >
                    N/B: Login code should be reset after the validity date (120
                    days)
                  </span>
                </div>
              ) : null}
            </div>
          ) : null}

          {/* Payments list for specific studente */}
          {toggle == "payments" && payments.length > 0 && !addPayment ? (
            <div
              className="class-list"
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <table>
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Payment Type</th>
                    <th>Term</th>
                    <th>Session</th>
                    <th>Invoice No</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {payments.map((payment, index) => (
                    <tr key={index} style={{ cursor: "pointer" }}>
                      <td>{index + 1}</td>
                      <td>{payment.payment_type}</td>
                      <td>{payment.term}</td>
                      <td>{payment.session}</td>
                      <td>{payment.timestamp}</td>
                      <td>{payment.payment_date}</td>
                      <td>N{payment.amount}</td>

                      <td>
                        <div
                          style={{
                            position: "relative",
                            left: "50px",
                            top: "2px",
                          }}
                        >
                          {Menu(payment)}
                        </div>
                      </td>

                      {/* <td>
                        <div>
                          {
                            <div className="dropdown" style={{ padding: 8 }}>
                              <p className="dropdown-btn">&#8942;</p>
                              <div className="dropdown-content">
                                {payment.payment_type == "School Fees" ? (
                                  <p onClick={() => openDialogBox()}>
                                    View Login Details
                                  </p>
                                ) : null}
                                <p onClick={() => {}}>View Invoice</p>
                              </div>
                            </div>
                          }
                        </div>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>

              <img
                style={{
                  height: 80,
                  width: 80,
                  alignSelf: "end",
                  justifySelf: "end",
                }}
                src={Addition}
                onClick={() => {
                  setAddPayment(true);
                }}
              />
            </div>
          ) : null}

          {toggle == "payments" && addPayment ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div style={{ flex: 0.5 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 16,
                  }}
                >
                  <p style={{ marginRight: 60 }}>Select Payment Type:</p>

                  <select
                    style={{ width: 250 }}
                    value={invoice["payment_type"]}
                    name="payment_type"
                    onChange={(e) => handleInvoiceChange(e)}
                  >
                    <option value="School Fees">School Fees</option>

                    <option value="Store Items">Store Items</option>

                    <option value="Others">Others</option>
                  </select>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 16,
                  }}
                >
                  <p style={{ marginRight: 60 }}>Term:</p>
                  <select
                    style={{ width: 250 }}
                    value={invoice["term"]}
                    name="term"
                    onChange={(e) => handleInvoiceChange(e)}
                  >
                    <option value="First Term">First Term</option>
                    <option value="Second Term">Second Term</option>
                    <option value="Third Term">Third Term</option>
                  </select>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 16,
                  }}
                >
                  <p style={{ marginRight: 60 }}>Session:</p>
                  <select
                    style={{ width: 250 }}
                    value={invoice["session"]}
                    name="session"
                    onChange={(e) => handleInvoiceChange(e)}
                  >
                    {sessionOptions.map((sess) => (
                      <option value={sess}>{sess}</option>
                    ))}
                  </select>
                </div>
                <div style={{ width: "100%", marginTop: 16 }}>
                  <p>Payment Description:</p>
                  <textarea
                    style={{
                      width: "100%",
                      height: 200,
                      marginTop: 8,
                      padding: 8,
                    }}
                    name="payment_desc"
                    value={invoice["payment_desc"]}
                    onChange={handleInvoiceChange}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 16,
                  }}
                >
                  <p style={{ marginRight: 60 }}>Amount Paid in Figures(N):</p>
                  <input
                    style={{ width: 250 }}
                    value={invoice["amount_paid_fig"]}
                    name="amount_paid_fig"
                    onChange={(e) => handleInvoiceChange(e)}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 16,
                  }}
                >
                  <p style={{ marginRight: 60 }}>Amount Paid in Words:</p>
                  <input
                    style={{ width: 250 }}
                    value={invoice.amount_in_words}
                    name="amount_in_words"
                    onChange={(e) => handleInvoiceChange(e)}
                  />
                </div>

                <div className="validate-box" style={{ marginTop: 32 }}>
                  <input
                    type="checkbox"
                    value="valid"
                    checked={validated}
                    onChange={handleCheck}
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                  />
                  <label>
                    I hereby acknowledge receipt of{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {student.fullname}
                    </span>
                    , School Fees payment draft worth{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {invoice.amount_paid_fig}
                    </span>{" "}
                    today{" "}
                    <span style={{ fontWeight: "bold" }}>{presentDate}</span>
                  </label>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 16,
                  }}
                >
                  <p style={{ marginRight: 60 }}>Full Name</p>
                  <input
                    style={{ width: 250 }}
                    value={invoice["fullname"]}
                    name="fullname"
                    onChange={(e) => handleInvoiceChange(e)}
                  />
                </div>
              </div>

              {/* Pdf preview */}
              <div style={{ flex: 0.5 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    disabled={
                      (loading == true && validated == true) ||
                      (loading == false && validated == false)
                    }
                    // disabled={!validated}
                    name="Proceed and generate app login details"
                    click={(e) => {
                      if (validated) {
                        makePayment();
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          ) : toggle == "payments" && payments.length <= 0 ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                style={{ marginTop: 64, height: 300, width: 300 }}
                src={NoPayment}
              />
              <p style={{ marginTop: 24 }}>
                No payment history. Tap the icon to add a document a new payment
                draft.
              </p>
              <img
                style={{ height: 80, width: 80, alignSelf: "end" }}
                src={Addition}
                onClick={() => {
                  setAddPayment(true);
                }}
              />
            </div>
          ) : null}

          {toggle == "result" ? (
            <div className="result-wrapper">
              <p>Class</p>
              <div className="result-flex">
                {results.creche.length > 0 ? (
                  <ResultComponent
                    results={results.creche}
                    class="Creche"
                    tempAdminToken={tempAdminToken}
                    student_img_url={student.img_url}
                  />
                ) : null}
                {results.toddlers.length > 0 ? (
                  <ResultComponent
                    results={results.toddlers}
                    class="Toddlers"
                    tempAdminToken={tempAdminToken}

                    student_img_url={student.img_url}
                  />
                ) : null}
                {results.infant1.length > 0 ? (
                  <ResultComponent
                    results={results.infant1}
                    class="Infant Community 1"
                    tempAdminToken={tempAdminToken}

                    student_img_url={student.img_url}
                  />
                ) : null}
                {results.infant2.length > 0 ? (
                  <ResultComponent
                    results={results.infant2}
                    class="Infant Community 2"
                    tempAdminToken={tempAdminToken}

                    student_img_url={student.img_url}
                  />
                ) : null}
                {results.nursery1.length > 0 ? (
                  <ResultComponent
                    results={results.nursery1}
                    class="Nursery 1"
                    tempAdminToken={tempAdminToken}

                    student_img_url={student.img_url}
                  />
                ) : null}
                {results.nursery2.length > 0 ? (
                  <ResultComponent
                    results={results.nursery2}
                    class="Nursery 2"
                    tempAdminToken={tempAdminToken}

                    student_img_url={student.img_url}
                  />
                ) : null}
                {results.grade1.length > 0 ? (
                  <ResultComponent
                    results={results.grade1}
                    class="Grade 1"
                    tempAdminToken={tempAdminToken}

                    student_img_url={student.img_url}
                  />
                ) : null}
                {results.grade2.length > 0 ? (
                  <ResultComponent
                    results={results.grade2}
                    class="Grade 2"
                    tempAdminToken={tempAdminToken}

                    student_img_url={student.img_url}
                  />
                ) : null}
                {results.grade3.length > 0 ? (
                  <ResultComponent
                    results={results.grade3}
                    class="Grade 3"
                    tempAdminToken={tempAdminToken}

                    student_img_url={student.img_url}
                  />
                ) : null}
                {results.grade4.length > 0 ? (
                  <ResultComponent
                    results={results.grade4}
                    class="Grade 4"
                    tempAdminToken={tempAdminToken}

                    student_img_url={student.img_url}
                  />
                ) : null}
                {results.grade5.length > 0 ? (
                  <ResultComponent
                    results={results.grade5}
                    class="Grade 5"
                    tempAdminToken={tempAdminToken}

                    student_img_url={student.img_url}
                  />
                ) : null}
              </div>
            </div>
          ) : null}

          {type === 0 && toggle == "personal" ? (
            view ? (
              <div style={{ alignSelf: "flex-start" }}>
                <p style={{ fontSize: 20, fontWeight: "bold" }}>
                  Student ID: {student.student_id}
                </p>
                <p style={{ fontSize: 20, fontWeight: "bold" }}>
                  Email: {student.guardian_info.email}
                </p>
                <p style={{ fontSize: 20, fontWeight: "bold" }}>
                  Password: {student.student_id}
                </p>
              </div>
            ) : null
          ) : null}

          {type === 0 && toggle == "personal" ? (
            view ? (
              <div style={{ alignSelf: "flex-end" }}>
                <Button2
                  click={(e) => {
                    e.preventDefault();
                    navigate("/admin/processed-applications", {
                      state: { reg: { ...reg, is_admitted: true } },
                    });
                  }}
                  name="View Admission Form"
                />
              </div>
            ) : null
          ) : null}
          {type === 0 && toggle == "personal" ? (
            <div className="show-details" style={{ marginTop: "30px" }}>
              <div>First Name:</div>
              {view ? (
                <div className="light-property">{student.firstname}</div>
              ) : (
                <input
                  className="properties-input"
                  name="firstname"
                  onChange={handleChange}
                  value={formData.firstname}
                />
              )}
              <div>Middle Name:</div>
              {view ? (
                <div className="light-property">Nil</div>
              ) : (
                <input
                  className="properties-input"
                  name="lastname"
                  onChange={handleChange}
                  value={formData.lastname}
                />
              )}
              <div>Last Name:</div>
              {view ? (
                <div className="light-property">{student.lastname}</div>
              ) : (
                <input
                  className="properties-input"
                  name="firstname"
                  onChange={handleChange}
                  value={formData.firstname}
                />
              )}
              <div>Permanent Address:</div>
              {view ? (
                <div className="light-property">
                  {student.guardian_info.address}
                </div>
              ) : (
                <input
                  className="properties-input"
                  name="firstname"
                  onChange={handleChange}
                  value={formData.firstname}
                />
              )}
              <div>Country:</div>
              {view ? (
                <div className="light-property">{student.country}</div>
              ) : (
                <input
                  className="properties-input"
                  name="firstname"
                  onChange={handleChange}
                  value={formData.firstname}
                />
              )}
              <div>Parent's Phone Number 1:</div>
              {view ? (
                <div className="light-property">
                  {student.guardian_info.phone_no}
                </div>
              ) : (
                <input
                  className="properties-input"
                  name="firstname"
                  onChange={handleChange}
                  value={formData.firstname}
                />
              )}
              {/* <div>Parent's Phone Number 2:</div>
              {view ? (
                <div className="light-property">John</div>
              ) : (
                <input
                  className="properties-input"
                  name="firstname"
                  onChange={handleChange}
                  value={formData.firstname}
                />
              )} */}
              <div> Parent's Email:</div>
              {view ? (
                <div className="light-property">
                  {student.guardian_info.email}
                </div>
              ) : (
                <input
                  className="properties-input"
                  name="firstname"
                  onChange={handleChange}
                  value={formData.firstname}
                />
              )}
              {/* <div>Religion:</div>
              {view ? (
                <div className="light-property">John</div>
              ) : (
                <input
                  className="properties-input"
                  name="firstname"
                  onChange={handleChange}
                  value={formData.firstname}
                />
              )} */}
              <div>Gender:</div>
              {view ? (
                <div className="light-property">{student.gender}</div>
              ) : (
                <div className="properties-input">
                  <select
                    value={formData.gender}
                    name="gender"
                    onChange={handleChange}
                  >
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>
              )}
              <div>Date of Birth:</div>
              <div className="light-property">{student.date_of_birth}</div>
              <div>Passport Photo:</div>
              {view ? (
                <div className="data-img">
                  <img
                    src={student.img_url}
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                </div>
              ) : (
                <div className="teacher-image">
                  {isSelected ? (
                    <div className="data-img">
                      <img
                        src={selectedSrc}
                        alt=""
                        style={{ objectFit: "cover" }}
                      />
                    </div>
                  ) : null}
                  <div className="add-image">
                    <label>
                      <img src={Add} alt="" />
                      <input
                        required
                        onChange={onFileChange}
                        type="file"
                        accept=".png, .jpg, .jpeg, .gif"
                        name="selectedFile"
                      />
                    </label>
                  </div>
                </div>
              )}
            </div>
          ) : type === 1 ? (
            <div className="show-details" style={{ marginTop: "30px" }}>
              <div>First Name:</div>
              <div className="light-property">{student?.firstname}</div>
              <div>Middle Name:</div>
              <div className="light-property">{student?.middlename}</div>
              <div>Last Name:</div>
              <div className="light-property">{student?.lastname}</div>
              <div>Permanent Address:</div>
              <div className="light-property">{student?.address}</div>
              <div>Country:</div>
              <div className="light-property">{student?.country}</div>
              <div>Parents Phone Number 1:</div>
              <div className="light-property">
                {student?.guardian_info?.phone_no}
              </div>
              <div>Parents Phone Number 2:</div>
              <div className="light-property">{student?.phone_no2}</div>
              <div>Parents Email:</div>
              <div className="light-property">
                {student?.guardian_info?.email}
              </div>
              <div>Religion:</div>
              <div className="light-property">{student?.religion}</div>
              <div>Gender:</div>
              <div className="light-property">
                {capitalizeFirstLetter(student?.gender)}
              </div>
              <div>Student ID:</div>
              <div className="light-property">{student?.student_id}</div>
              <div>Date of Birth</div>
              <div className="light-property">{student?.date_of_birth}</div>
              <div>Passport Photo:</div>
              <div className="data-img">
                <img
                  src={student?.img_url ? student?.img_url : User}
                  alt=""
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          ) : type === 2 ? (
            <div className="show-details" style={{ marginTop: "30px" }}>
              <div>First Name:</div>
              <input
                className="properties-input"
                name="firstname"
                onChange={handleChange}
                value={formData.firstname}
              />
              <div>Middle Name:</div>
              <input
                className="properties-input"
                name="firstname"
                onChange={handleChange}
                value={formData.firstname}
              />
              <div>Last Name:</div>
              <input
                className="properties-input"
                name="firstname"
                onChange={handleChange}
                value={formData.firstname}
              />
              <div>Permanent Address:</div>
              <input
                className="properties-input"
                name="firstname"
                onChange={handleChange}
                value={formData.firstname}
              />
              <div>Country:</div>
              <input
                className="properties-input"
                name="firstname"
                onChange={handleChange}
                value={formData.firstname}
              />
              <div>Phone Number 1:</div>
              <input
                className="properties-input"
                name="firstname"
                onChange={handleChange}
                value={formData.firstname}
              />
              <div>Phone Number 2:</div>
              <input
                className="properties-input"
                name="firstname"
                onChange={handleChange}
                value={formData.firstname}
              />
              <div>Email:</div>
              <input
                className="properties-input"
                name="firstname"
                onChange={handleChange}
                value={formData.firstname}
              />
              <div>Religion:</div>
              <input
                className="properties-input"
                name="firstname"
                onChange={handleChange}
                value={formData.firstname}
              />
              <div>Gender:</div>
              <div className="properties-input">
                <select
                  value={formData.gender}
                  name="gender"
                  onChange={handleChange}
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
              <div>Student ID:</div>
              <div className="light-property">John</div>
              <div>Centre:</div>
              <div className="properties-input">
                <select
                  value={formData.centre}
                  name="centre"
                  onChange={handleChange}
                >
                  <option value="Emerald">Emerald</option>
                  <option value="Topaz">Topaz</option>
                </select>
              </div>
              <div>Role:</div>
              <input
                className="properties-input"
                name="firstname"
                onChange={handleChange}
                value={formData.firstname}
              />
              <div>Employment Year:</div>
              <div className="light-property">John</div>
              <div>Photo:</div>
              <div className="teacher-image">
                {isSelected ? (
                  <div className="data-img">
                    <img src={selectedSrc} alt="" />
                  </div>
                ) : null}
                <div className="add-image">
                  <label>
                    <img src={Add} alt="" />
                    <input
                      required
                      onChange={onFileChange}
                      type="file"
                      accept=".png, .jpg, .jpeg, .gif"
                      name="selectedFile"
                    />
                  </label>
                </div>
              </div>
            </div>
          ) : null}
          {type === 0 ? (
            view ? (
              <div className="create-teacher" onClick={() => setView(false)}>
                <img src={view ? "" : Edit} />
              </div>
            ) : (
              <Button2 name="Update & Save Personal Data" />
            )
          ) : type === 2 ? (
            <Button2 name="Update & Save Personal Data" />
          ) : null}
        </div>

        <Dialog onClose={handleClose} open={openDialog}>
          <DialogTitle> {`${student.fullname} App Login Details`} </DialogTitle>
          <p style={dialogStyle}>
            <div style={{ marginLeft: 30, marginRight: 30 }}>
              <span>{`Student ID: ${student.student_id}`}</span>
              <br />
              <span>{`${paymentData["term"]} Login Code: ${paymentData["login_code"]}`}</span>
              <br />
            </div>
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <img
              style={{
                height: 40,
                width: 40,
                // marginBottom: 30,
                cursor: "pointer",
              }}
              src={Mail}
              onClick={() => {
                console.log("send mail");
                sendMail();
              }}
            />
          </div>

          <div
            style={{
              marginLeft: 30,
              marginRight: 30,
              // fontSize: 12,
              // color: "#F20A0A",
              // flex: "display",
              // flexDirection: "row",
              // justifyContent: "center",
            }}
          >
            <span>Tap the email icon to send login code to parent's mail</span>

            <br />
          </div>

          <div
            style={{
              marginLeft: 30,
              marginRight: 30,
              fontSize: 12,
              flex: "display",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <br />

            <span>{`Login code was generated on: ${
              DateConverter(paymentData.timestamp, 0)["converted_date"]
            }`}</span>

            <br />

            <div
              style={{
                // marginLeft: 30,
                // marginRight: 30,
                display: "flex",
                fontSize: 12,
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <span
                style={{
                  fontSize: 12,
                  color: "#F20A0A",
                  // flex: "display",
                  // flexDirection: "row",
                  // justifyContent: "center",
                }}
              >{`Login code validity date: ${
                DateConverter(paymentData.timestamp, 120)["converted_date"]
              }`}</span>
            </div>

            <br />
            <br />
          </div>
        </Dialog>

        <Dialog onClose={closeResetDialogBox} open={openResetDialog}>
          <DialogTitle>Reset Login Code?</DialogTitle>
          <div style={{ marginLeft: 50, marginRight: 50 }}>
            <div style={{ marginBottom: 30 }}>
              Are you sure you want to reset {student.fullname}'s Login Code?
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 20,
              }}
            >
              <div>
                <Button2
                  click={(e) => {
                    e.preventDefault();
                    closeResetDialogBox();
                  }}
                  style={{ marginBottom: 10, marginRight: 20 }}
                  name={"Cancel"}
                />
                <Button
                  disabled={loadingResetDialogButton}
                  click={async (e) => {
                    e.preventDefault();

                    if (loadingResetDialogButton == false) {
                      //  deleteStaff(staff_details);
                      setLoadingResetDialogButton(true);

                      try {
                        const res = await fetch(
                          "https://server-olhms.onrender.com/admin_payment/deactivate_login_code",
                          {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                              token: JSON.parse(admintoken),
                              student_id: student.student_id,
                            }),
                          }
                        );

                        const data = await res.json();

                        // console.log(`---res---> ${JSON.stringify(res)}`);
                        // console.log(`--data----> ${data}`);
                        setLoadingResetDialogButton(false);
                        if (res.status == 200) {
                          closeResetDialogBox();
                          toast.success("Login code reset", {
                            position: "bottom-center",
                          });
                        } else {
                          toast.error(data["msg"], {
                            icon: "❗️",
                            position: "bottom-center",
                          });
                        }
                      } catch (e) {
                        toast.error(
                          "An error occured with the server. Please try again later",
                          {
                            icon: "❗️",
                            position: "bottom-center",
                          }
                        );

                        // console.log(error);
                        setLoadingResetDialogButton(false);
                      }
                    }
                  }}
                  style={{ marginBottom: 10, marginLeft: 20 }}
                  name={"Reset"}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

export function TripleS(props) {
  return (
    <div className="slider-button">
      <div
        onClick={props.click1}
        className="slider-option"
        id={props.toggle === "personal" ? "active" : ""}
      >
        {props.text1}
      </div>
      <div
        className="slider-option"
        id={props.toggle === "result" ? "active2" : ""}
        onClick={props.click2}
      >
        {props.text2}
      </div>
      <div
        onClick={props.click3}
        className="slider-option"
        id={props.toggle === "payments" ? "active3" : ""}
      >
        {props.text3}
      </div>
    </div>
  );
}

export function ResultComponent(props) {
  const navigate = useNavigate();
  return (
    <div className="result-content">
      <div className="faint-result classname">{props.class}</div>
      <div className="terms">
        {props.results.some((result) => result.term == "First Term") ? (
          <div
            className="faint-result terms-container"
            onClick={() => {
              navigate("/admin/settings-specific-result", {
                state: {
                  result: props.results.find(
                    (result) => result.term == "First Term"
                  ),
                  is_validated: props.results.find(
                    (result) => result.term == "First Term"
                  ).is_validated,
                  tempAdminToken: props.tempAdminToken,
                  student_img: props.student_img_url,
                },
              });
            }}
          >
            First Term
          </div>
        ) : null}

        {props.results.some((result) => result.term == "Second Term") ? (
          <div
            className="faint-result terms-container"
            onClick={() => {
              navigate("/admin/settings-specific-result", {
                state: {
                  result: props.results.find(
                    (result) => result.term == "Second Term"
                  ),
                  is_validated: props.results.find(
                    (result) => result.term == "Second Term"
                  ).is_validated,
                  tempAdminToken: props.tempAdminToken,
                  student_img: props.student_img_url,
                },
              });
            }}
          >
            Second Term
          </div>
        ) : null}

        {props.results.some((result) => result.term == "Third Term") ? (
          <div
            className="faint-result terms-container"
            onClick={() => {
              navigate("/admin/settings-specific-result", {
                state: {
                  result: props.results.find(
                    (result) => result.term == "Third Term"
                  ),
                  is_validated: props.results.find(
                    (result) => result.term == "Third Term"
                  ).is_validated,
                  tempAdminToken: props.tempAdminToken,
                  student_img: props.student_img_url,
                },
              });
            }}
          >
            Third Term
          </div>
        ) : null}
      </div>
    </div>
  );
}
