import React, { useState, useEffect } from "react";
import "../homepageSections/sectionten.css";
import "./gallerytwo.css";

export default function GalleryTwo() {
  
  const [index, setIndex] = useState(0);
  const [sliceValue, setSliceValue] = useState(3);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);

  const viewGallery = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_gallery/view_all_gallery",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(),
      }
    );
    const data = await res.json();
    // setLoading(false);
    const gallery = data?.gallery;
    let img = [];
    let vid = [];
    gallery.forEach((item) =>
      item.type == "image" && item.urls.length > 0
        ? img.push(item)
        : item.type == "video" && item.urls.length > 0
        ? vid.push(item)
        : null
    );

    setImages(img);
    setVideos(vid);
    // setVlogs(data?.vlogs);

    console.log(vid);
  };

  useEffect(() => {
    viewGallery();
  }, []);
  function handlePrev() {
    setIndex(index - 1);
  }

  function handleNext() {
    setIndex(index + 1);
  }
  const classArray = [
    "https://www.youtube.com/embed/71h8MZshGSs",
    "https://www.youtube.com/embed/71h8MZshGSs",
    "https://www.youtube.com/embed/71h8MZshGSs",
    "https://www.youtube.com/embed/S6BQeWJxOcw",
    "https://www.youtube.com/embed/71h8MZshGSs",
    "https://www.youtube.com/embed/S6BQeWJxOcw",
  ];

  useEffect(() => {
    function handleResize() {
      if (document.documentElement.clientWidth <= 1000) {
        setSliceValue(2);
      } else {
        setSliceValue(3);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="gallery-two">
      <div className="satisfy">Video Gallery</div>
      <div className="shift-page">
        <div className="pagination shifted">
          <button onClick={handlePrev} disabled={index < 1}>
            <i className="fas fa-angle-left"></i> Previous
          </button>
          <button
            style={{ background: "rgba(224, 86, 21, 0.33)" }}
            onClick={handleNext}
            disabled={!(index + sliceValue < classArray.length)}
          >
            Next <i className="fas fa-angle-right"></i>
          </button>
        </div>
      </div>
      <div className="gallery-vid-flex">
        {videos?.slice(index, index + sliceValue).map((item, index) => (
          <div className="youtube-gallery" key={index}>
            {item.urls.map((obj, i) => (
              <video
                width="100%"
                height="100%"
                src={obj}
                controls
                autoPlay={false}
              ></video>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
