import React, { useState, useEffect, useContext } from "react";
import { Triple } from "../teacherPages/ResultTeacher";
import { useNavigate } from "react-router-dom";
import { adminContext } from "../context/adminContext";
import { BackButton } from "../studentPages/PersonalData";
import { ClipLoader } from "react-spinners";

export default function Applications() {
  const navigate = useNavigate();
  const [triple, setTriple] = useState("first");
  const [studentApplications, setStudentApplications] = useState([]);
  const [processedApplications, setProcessedApplications] = useState([]);
  const [data, setData] = useState({});
  const [count, setCount] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const values = useContext(adminContext);
  const { admintoken } = values;

  const [currentPageNew, setCurrentPageNew] = useState(1);
  const [currentPageProc, setCurrentPageProc] = useState(1);

  const DateFormatter = (timestamp) => {
    return new Date(timestamp).toLocaleDateString();
  };

  const fetchStudentApplications = async (currPage) => {
    try {
      const res = await fetch(
        "https://server-olhms.onrender.com/admin_registration/new_registrations",
        {
          method: "POST",
          body: JSON.stringify({
            token: JSON.parse(admintoken),
            pagec: currPage,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const data = await res.json();
      console.log(data.reg_docs);
      if (data.status === "ok") {
        setIsLoaded(true);
        // setData(data);
        if (data?.count != 0) {
          setStudentApplications(data.reg_docs);
        }
        setCount(data?.count);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchStudentApplications(currentPageNew);
  }, []);

  const fetchProcessedApplications = async (currPage) => {
    try {
      const res = await fetch(
        "https://server-olhms.onrender.com/admin_registration/processed_applications",
        {
          method: "POST",
          body: JSON.stringify({
            token: JSON.parse(admintoken),
            pagec: currPage,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const data = await res.json();
      console.log(data);
      if (data.status === "ok") {
        setIsLoaded(true);
        // setData(data);
        if (data?.count != 0) {
          setProcessedApplications(data.reg_docs);
        }
        setCount(data.count);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchProcessedApplications(currentPageProc);
  }, [currentPageProc]);

  const handleClick1 = (e) => {
    e.preventDefault();
    setIsLoaded(false);
    setTriple("second");
    fetchProcessedApplications(currentPageProc);
  };

  const handleClick2 = (e) => {
    e.preventDefault();
    setIsLoaded(false);
    setTriple("first");
    fetchStudentApplications(currentPageNew);
  };

  const searchApplication = async () => {
    try {
      // check for the page and use the endpoint accourdingly
      const link =
        triple === "first"
          ? "https://server-olhms.onrender.com/admin_registration/search_new_registrations"
          : "https://server-olhms.onrender.com/admin_registration/search_processed_registrations";
      const res = await fetch(link, {
        method: "POST",
        body: JSON.stringify({
          token: JSON.parse(admintoken),
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json, text/plain, */*",
        },
      });
      const dataM = await res.json();
      console.log(dataM.reg_docs);
      if (dataM.status === "ok") {
        setIsLoaded(true);
        setData(dataM);
        if (dataM.count != 0) {
          setStudentApplications(dataM.reg_docs);
        }
        setCount(dataM.count);
      }
    } catch (e) {
      console.log(e);
    }
  };

  if (!isLoaded) {
    return (
      <h1
        className="loading-pages"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "250px"
        }}
      >
        <ClipLoader color={"orange"} size={100} />
      </h1>
    );
  } else {
    return (
      <div
        className="set-questions"
        style={{
          backgroundColor: "rgb(255, 248, 248)",
          height: "100%",
          alignItems: "flex-start",
          marginLeft: 100,
        }}
      >
        <div className="set-container" style={{ height: "100%" }}>
          <BackButton click={() => navigate(-1)} />
          <p className="data-number">Admission Applications</p>
          <div className="git-wrap">
            <div className="split-through">
              <div className="type-of-item">
                <p
                  onClick={handleClick2}
                  className={triple == "first" ? "active-pay" : "inactive-pay"}
                >
                  New Applications
                </p>
                <p
                  onClick={handleClick1}
                  className={triple == "second" ? "active-pay" : "inactive-pay"}
                >
                  Processed Applications
                </p>
                <p
                  onClick={() => {
                    setTriple("third");
                    navigate("/admin/student");
                  }}
                  className={triple == "third" ? "active-pay" : "inactive-pay"}
                >
                  Admitted students
                </p>
              </div>
              <div className="search-input">
                <input
                  placeholder={`Search ${
                    triple == "first" ? "New" : "Processed"
                  } Applications`}
                  style={{ backgroundColor: "#F3F2F2" }}
                />
              </div>
              <p>All ({count})</p>
            </div>
            <div className="database-table git-table">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Phone Number</th>
                    <th>Email Address</th>
                    <th>Application Date</th>
                  </tr>
                </thead>
                <tbody>
                  {triple == "first"
                    ? studentApplications.map((reg, i) => (
                        <tr
                          key={i}
                          onClick={() =>
                            navigate(
                              triple == "first"
                                ? "/admin/new-applications"
                                : "/admin/processed-applications",
                              {
                                state: {
                                  type:
                                    triple == "first"
                                      ? 1
                                      : triple == "second"
                                      ? 2
                                      : triple == "third"
                                      ? 3
                                      : null,
                                  reg,
                                },
                              }
                            )
                          }
                          style={{
                            cursor: "pointer",
                            borderBottom: "1px solid rgba(10, 10, 10, 0.2)",
                          }}
                        >
                          <td>{reg?.fullname}</td>
                          <td>{reg?.guardian_info?.phone_no}</td>
                          <td>{reg?.guardian_info?.email}</td>
                          <td>{DateFormatter(reg?.timestamp)}</td>
                        </tr>
                      ))
                    : triple == "second"
                    ? processedApplications.map((reg, i) => (
                        <tr
                          key={i}
                          onClick={() =>
                            navigate(
                              triple == "first"
                                ? "/admin/new-applications"
                                : "/admin/processed-applications",
                              {
                                state: {
                                  type:
                                    triple == "first"
                                      ? 1
                                      : triple == "second"
                                      ? 2
                                      : triple == "third"
                                      ? 3
                                      : null,
                                  reg,
                                },
                              }
                            )
                          }
                          style={{
                            cursor: "pointer",
                            borderBottom: "1px solid rgba(10, 10, 10, 0.2)",
                          }}
                        >
                          <td>{reg?.fullname}</td>
                          <td>{reg?.guardian_info?.phone_no}</td>
                          <td>{reg?.guardian_info?.email}</td>
                          <td>{DateFormatter(reg?.timestamp)}</td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
            <div className="pagination-git">
              <div>{`Showing 1 to ${count} of ${count} Applications`}</div>
              <div className="view-more-git">
                <div
                  onClick={() => {
                    let cPage =
                      triple == "first" ? currentPageNew : currentPageProc;
                    if (cPage >= 2) {
                      if (triple == "first") {
                        setCurrentPageNew(--cPage);
                        fetchStudentApplications(cPage);
                      }

                      if (triple == "second") {
                        setCurrentPageProc(--cPage);
                        fetchProcessedApplications(cPage);
                      }
                    }
                  }}
                  className="shade-direction"
                >
                  <i className="fas fa-angle-left"></i>
                </div>
                View more
                <div
                  onClick={() => {
                    let cPage =
                      triple == "first" ? currentPageNew : currentPageProc;
                    if (triple == "first") {
                      setCurrentPageNew(++cPage);
                      fetchStudentApplications(cPage);
                    }

                    if (triple == "second") {
                      setCurrentPageProc(++cPage);
                      fetchProcessedApplications(cPage);
                    }
                  }}
                  className="shade-direction"
                >
                  <i className="fas fa-angle-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    // } else if(count === 0) {
    //   return (
    //     <div
    //       className="set-questions"
    //       style={{
    //         backgroundColor: "rgb(255, 248, 248)",
    //         height: "100%",
    //         alignItems: "flex-start",
    //         marginLeft: 100
    //       }}
    //     >
    //       <div className="set-container" style={{ height: "100%" }}>
    //         <BackButton click={() => navigate(-1)} />
    //         <p className="data-number">Admission Applications</p>
    //       <div className="git-wrap">
    //         <div className="split-through">
    //           <div className="type-of-item">
    //             <p
    //               onClick={handleClick2}
    //               className={triple == "first" ? "active-pay" : "inactive-pay"}
    //             >
    //               New Applications
    //             </p>
    //             <p
    //               onClick={handleClick1}
    //               className={triple == "second" ? "active-pay" : "inactive-pay"}
    //             >
    //               Processed Applications
    //             </p>
    //             <p
    //               onClick={() => {
    //                 setTriple("third");
    //                 navigate("/admin/student");
    //               }}
    //               className={triple == "third" ? "active-pay" : "inactive-pay"}
    //             >
    //               Admitted students
    //             </p>
    //           </div>
    //           <div className="search-input">
    //             <input
    //               placeholder={`Search ${
    //                 triple == "first" ? "New" : "Processed"
    //               } Applications`}
    //               style={{ backgroundColor: "#F3F2F2" }}
    //             />
    //           </div>
    //           <p>All ({count})</p>
    //         </div><h1>No registrations at the moment</h1>
    //       </div>
    //       </div>
    //     </div>
    //   )
  }
}
