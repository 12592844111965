import React, { useState, useContext, useEffect } from "react";
import { Toggle } from "../teacherPages/Resources";
import { adminContext } from "../context/adminContext";
import video from "../../images/video.png";
import image from "../../images/add-gallery.png";
import { useNavigate } from "react-router-dom";
import GalleryThree from "../gallery/GalleryThree";
import { Triple } from "../teacherPages/ResultTeacher";
import axios from "axios";
import qs from "qs";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Button, { Button2 } from "../../components/Button/Button";

export default function GalleryAdmin() {
  const navigate = useNavigate();
  const [toggle, setToggle] = useState("first");
  const values = useContext(adminContext);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [featuredVideos, setFeaturedVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [delItem, setDelItem] = useState("");
  const [delItemUrl, setDelItemUrl] = useState("");
  const { admintoken } = values;

  const [item_to_delete, set_item_to_delete] = useState({});
  const [loadingDialogButton, setLoadingDialogButton] = useState(false);
  const [openDialog, handleDialog] = useState(false);
  const openDialogBox = () => {
    handleDialog(true);
  };
  const closeDialogBox = () => {
    handleDialog(false);
  };

  const viewFeaturedVideos = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_featured_video/view_all_featured_video",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        // body: JSON.stringify({ token: JSON.parse(admintoken) }),
      }
    );
    const data = await res.json();
    // console.log(`--------------->${JSON.stringify(data)} `);
    if (data?.status == "ok") {
      const f_vid = data?.featured_video;
      console.log(f_vid);
      setFeaturedVideos(f_vid);
    } else {
      alert("oOps! An error seems to have occured");
    }
  };

  useEffect(() => {
    viewFeaturedVideos();
  }, []);

  const viewGallery = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_gallery/view_all_gallery",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: JSON.parse(admintoken) }),
      }
    );
    const data = await res.json();
    // console.log(`--------------->${JSON.stringify(data)} `);
    if (data?.status == "ok") {
      const gallery = data?.gallery;
      let img = [];
      let vid = [];
      gallery.forEach((item) =>
        item.type == "image"
          ? img.push(item)
          : item.type == "video"
          ? vid.push(item)
          : null
      );

      setImages(img);
      setVideos(vid);
    } else {
      alert("oOps! An error seems to have occured");
    }
  };

  // function to delete image from gallery
  const deleteImage_new = async () => {
    setLoadingDialogButton(true);

    const galId = item_to_delete._id;
    const ids = item_to_delete.ids;
    const urls = [];
    urls.push(delItemUrl);

    let delIds = [];
    delIds.push(delItem);

    try {
      const data = {
        token: JSON.parse(admintoken),
        deleted_ids: delIds,
        ids: ids,
        urls: urls,
        gallery_id: galId,
        type: "image",
      };

      // console.log(`---> ${JSON.stringify(data)}`);

      const options = {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: qs.stringify(data),
        url: "https://server-olhms.onrender.com/admin_gallery/delete_file",
      };

      axios(options)
        .then((response) => {
          if (response.status === 200) {
            setLoadingDialogButton(false);
            closeDialogBox();
            navigate("/admin/gallery");
          } else {
            // setMessage("Error occured");
            setLoadingDialogButton(false);
          }
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
          setLoadingDialogButton(false);
        });
    } catch (e) {
      setLoadingDialogButton(false);
      console.log(e);
    }
  };

  // function to delete image from gallery
  const deleteImage = async (delIds, ids, urls, galId) => {
    setLoading(true);

    try {
      const data = {
        token: JSON.parse(admintoken),
        deleted_ids: delIds,
        ids: ids,
        urls: urls,
        gallery_id: galId,
        type: "image",
      };

      // console.log(`---> ${JSON.stringify(data)}`);

      const options = {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: qs.stringify(data),
        url: "https://server-olhms.onrender.com/admin_gallery/delete_file",
      };

      axios(options)
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            navigate("/admin/gallery");
          } else {
            // setMessage("Error occured");
            setLoading(false);
          }
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  // function to delete videos from gallery
  const deleteVideo = async (delIds, coverImgIds, galId) => {
    setLoading(true);

    try {
      const data = {
        token: JSON.parse(admintoken),
        deleted_ids: delIds,
        cover_img_ids: coverImgIds,
        type: "video",
        gallery_id: galId,
      };

      const options = {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: qs.stringify(data),
        url: "https://server-olhms.onrender.com/admin_gallery/delete_file",
      };

      axios(options)
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            navigate("/admin/gallery");
            setToggle("second");
          } else {
            // setMessage("Error occured");
            setLoading(false);
          }
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };


  const deleteFeatured = async () => {

    // console.log(JSON.stringify(item_to_delete));
    setLoadingDialogButton(true);

    try {
      const data = {
        token: JSON.parse(admintoken),
        featured_video_id: item_to_delete["_id"],
        video_id: item_to_delete["video_id"],
        img_id: item_to_delete["img_id"]
      };

      const options = {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: qs.stringify(data),
        url: "https://server-olhms.onrender.com/admin_featured_video/delete_featured_video",
      };

      axios(options)
        .then((response) => {
          if (response.status === 200) {
            setLoadingDialogButton(false);
            navigate("/admin/gallery");
          } else {
            alert("An error occured while deleting video");
            setLoadingDialogButton(false);
          }
          console.log(response.data);
        })
        .catch((error) => {
          alert("oOps! An error occured");
          
          setLoadingDialogButton(false);
        });
    } catch (e) {
      alert("oOps! An error occured");
      setLoadingDialogButton(false);
      console.log(e);
    }
  };


  useEffect(() => {
    viewGallery();
  }, []);

  return (
    <div
      className="set-questions"
      style={{
        backgroundColor: "rgb(255, 248, 248)",
        height: "100%",
        alignItems: "flex-start",
      }}
    >
      <div className="set-container">
        <Triple
          text1="Photo Gallery"
          text2="Video Gallery"
          text3="Featured Videos"
          toggle={toggle}
          click1={() => {
            setToggle("first");
          }}
          click2={() => {
            setToggle("second");
          }}
          click3={() => {
            setToggle("third");
          }}
        />
        <div
          className="add-video"
          onClick={() =>
            navigate("/admin/add-gallery", {
              state: {
                type:
                  toggle == "first"
                    ? 0
                    : toggle == "second"
                    ? 1
                    : toggle == "third"
                    ? // : toggle == "thrid"
                      2
                    : null,
              },
            })
          }
        >
          <div className="video-icon">
            <img src={toggle == "first" ? image : video} alt="" />
          </div>
          <p>{toggle == "first" ? "Add Image" : "Add Video"}</p>
        </div>
        {toggle == "first" ? (
          images?.map((item, index) => (
            <div style={{ alignSelf: "flex-start" }}>
              <p style={{ marginBottom: 24 }}>
                <strong>{item.gallery_name}</strong>
              </p>
              <div className="gallery-flex-admin" style={{ marginBottom: 32 }}>
                {item.urls.map((obj, i) => (
                  <div
                    className="gallery-images-grid"
                    style={{ marginBottom: 60 }}
                  >
                    <img src={obj} alt={`Image ${index}`} />
                    <button
                      style={{
                        backgroundColor: "grey",
                        color: "white",
                        fontSize: 16,
                        paddingTop: 6,
                        paddingBottom: 6,
                        paddingLeft: 26,
                        paddingRight: 26,
                        borderRadius: 5,
                        cursor: "pointer",
                        outline: "none",
                        border: "none",
                      }}
                      onClick={() => {
                        

                        const galId = item._id;
                        const ids = item.ids;
                        console.log(ids);
                        const urls = item.urls;

                        let delId = ids.splice(i, 1);
                        console.log(delId);

                        let delUrl = urls.splice(i, 1);
                        setDelItem(delId);

                        deleteImage([delId], ids, urls, galId);
                        
                        // ___
                        // const ids = item.ids;
                        // let delId = ids.splice(i, 1);
                        // setDelItem(delId);
                        
                        // const urls = item.urls;
                        // let delUrl = urls.splice(i, 1);
                        // setDelItemUrl(delUrl);

                        // set_item_to_delete(item);
                        // openDialogBox();
                      }}
                    >
                      Delete
                    </button>
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : toggle == "second" ? (
          <div className="show-small-blog">
            {videos?.map((item, index) => (
              <div className="videodiv" key={index}>
                {item.urls.map((obj, i) => (
                  <div>
                    <video
                      key={i}
                      src={obj}
                      title=""
                      controls
                      //   frameborder="0"
                    ></video>
                    <button
                      style={{
                        backgroundColor: "grey",
                        color: "white",
                        fontSize: 16,
                        paddingTop: 6,
                        paddingBottom: 6,
                        paddingLeft: 26,
                        paddingRight: 26,
                        borderRadius: 5,
                        cursor: "pointer",
                        outline: "none",
                        border: "none",
                      }}
                      onClick={() => {
                        console.log("ITEM:: :: ", item);

                        const galId = item._id;

                        const delId = item.ids[0];
                        const coverImgIds = item.img_id;
                        setDelItem(delId);

                        let vids = videos;
                        vids.splice(index, 1);
                        setVideos(videos);
                        deleteVideo([delId], [coverImgIds], galId);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                ))}
              </div>
            ))}
          </div>
        ) : toggle == "third" ? (
          <div className="show-small-blog">
            {featuredVideos?.map((item, index) => (
              <div className="videodiv" key={index}>
                <div>
                  <video
                    key={index}
                    src={item.video_link || item.video_url}
                    title=""
                    controls
                    poster={item.img_url}
                  ></video>

                  <button
                    style={{
                      backgroundColor: "grey",
                      color: "white",
                      fontSize: 16,
                      paddingTop: 1,
                      paddingBottom: 6,
                      paddingLeft: 20,
                      paddingRight: 20,
                      borderRadius: 5,
                      cursor: "pointer",
                      outline: "none",
                      border: "none",
                    }}
                    onClick={() => {
                      openDialogBox()
                      set_item_to_delete(item)
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>

      <Dialog onClose={closeDialogBox} open={openDialog}>
        <DialogTitle>
          Delete {toggle == "first" ? "photo" : "video"}?
        </DialogTitle>
        <div style={{ marginLeft: 100, marginRight: 100 }}>
          <div style={{ marginBottom: 30 }}>
            <p>
              Are you sure you want to delete this{" "}
              {toggle == "first" ? "photo" : "video"}?
            </p>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 20,
            }}
          >
            <div>
              <Button2
                click={(e) => {
                  e.preventDefault();
                  closeDialogBox();
                }}
                style={{ marginBottom: 10, marginRight: 20 }}
                name={"Cancel"}
              />
              <Button
                disabled={loadingDialogButton}
                click={(e) => {
                  e.preventDefault();

                  console.log(toggle);

                  if (toggle == "first") {
                    deleteImage_new();
                  } else if(toggle == "second")  {
                  }else{
                    deleteFeatured();
                    
                  }
                }}
                style={{ marginBottom: 10, marginLeft: 20 }}
                name={"Delete"}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
