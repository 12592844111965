import React, { useState, useContext, useEffect } from "react";
import { BackButton } from "../studentPages/PersonalData";
import { useNavigate, useLocation } from "react-router-dom";
import { Toggle } from "./Resources";
import Question from "../../images/noquestion.png";
import Submission from "../../images/nosubmission.png";
import Addition from "../../images/addition.png";
import { Novideo } from "./Resources";
import "./checksubject.css";
import "./submitted.css";
import { adminContext } from "../context/adminContext";
import Button from "../../components/Button/Button";

export default function StudentAnswersTheory() {
  const navigate = useNavigate();
  const location = useLocation();
  const session = location.state.session;
  const subject = location.state.subject;
  const ca_id = location.state.ca_id;
  const section_present = location.state.section_present;
  const type = location.state.type;
  const submission = location.state.sub;

  const values = useContext(adminContext);
  const { teacherData, teachertoken } = values;
  const dataT = JSON.parse(teacherData);

  const [loading, setLoading] = useState(true);
  const [ca, setCa] = useState({
    questions_theory: []
  });

  const [toggle, setToggle] = useState(true);

  const tableValues = ["S/N", "Name", "Student Id", "Score", "Grade"];

  const setCaNo = (type) => {
    if(type == '1st Continuous Assessment Test'){
      return 1;
    }
    if(type == '2nd Continuous Assessment Test'){
      return 2;
    }else{
      return 3;
    }
  }

  const setAssessType = (type) => {
    if(type == '1st Continuous Assessment Test'){
      return 'ca';
    }
    if(type == '2nd Continuous Assessment Test'){
      return 'ca';
    }else{
      return 'exam';
    }
  }

  const [scores, setScores] = useState({});
  const [totalScore, setTotalScore] = useState(submission.score == undefined || submission == '' ? 0 : parseInt(submission.score));

  const handleStudentScore = e => {
    const {name, value} = e.target;
    const tScores = {...scores};
    tScores[name] = value;
    setScores(tScores);

    let score = 0
    for (const key in tScores){
        if(tScores[key] != ''){
            score += parseInt(tScores[key]);
        }
    }
    console.log(score);
    setTotalScore(score);
  }

  // get continous assessment for particular session and term
  const viewContinousAssessment = async () => {
    setLoading(true);
    const res = await fetch(
      "https://server-olhms.onrender.com/teacher_continous_assessment/view_continous_assessments",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(teachertoken),
          session: session.session,
          term: session.current_term,
          subject: subject,
          ca: setCaNo(type),
          type: setAssessType(type)
        }),
      }
    );
    const data = await res.json();
    setLoading(false);
    if(data?.msg == 'no continous_assessments at the moment'){
      setToggle(true);
    }else{
      if(data?.continous_assessment.questions_theory.length > 0){
        setToggle(false);
        setCa(data?.continous_assessment);
      }else{
        setToggle(true);
      }
      
    }
    
    console.log(data);
  };

  useEffect(() => {
    viewContinousAssessment();
  }, []);


  // function to update the score for section b
  const uploadScore = async () => {
    setLoading(true);

    const res = await fetch(
        "https://server-olhms.onrender.com/teacher_continous_assessment/submit_assessment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: JSON.parse(teachertoken),
            continous_assessment_id: ca._id,
            session: session.session,
            term: session.current_term,
            student_id: submission.student_id,
            student_name: submission.student_name,
            ca: setCaNo(type),
            section: 'b',
            subject: subject,
            score: totalScore,
            answers: submission.answers
          }),
        }
      );
      const data = await res.json();
      setLoading(false);
      console.log(data);
  }

  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (document.documentElement.clientWidth <= 800) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={mobile ? '' : 'set-questions'} style={mobile ? {margin: 24} : {}}>
      <div className="set-container">
        <BackButton click={() => navigate(-1)} />
        <div className="session-flex">
          <div className="cat-session">
            <p>{session.current_term}</p>
            <p>{type}</p>
            <p>{session.session} Session</p>
          </div>
          <div className="imported-sub">{subject} - SECTION B</div>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
          <p style={{color: '#E05615'}}>{`Test time: ${ca.questions_theory.length > 0 ? ca.duration_b : '00 hrs 00 mins'}`}</p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'start', marginBottom: 16}}>
            <p style={{fontWeight: 'bold'}}>{`Name: ${submission.student_name}`}</p>
            <p style={{fontWeight: 'bold'}}>{`Student ID: ${submission.student_id}`}</p>
        </div>

        {toggle ? (
          <Novideo
            img={Question}
            text="No Questions have been set. Tap the icon to start setting questions"
          />
        ) : (
              ca.questions_theory.map((question, i) => (
                <div key={i} className='questions-wrapper'>
          <ul className="input-list"
                  style={{
                    listStylePosition: "outside",
                    listStylequestion_type: "none",
                    columnGap: "30px",
                    display: mobile ? 'block' : 'inherit'
                  }}>

            
            <li
              className="input-item"
              style={{
                width: "",
              }}>
                  <span style={{fontWeight: "bold"}}>Q{i + 1}.{" "}</span>
                  <p>
                    {question.question_type === "Theory without video" 
                      ? `${question.question}_______________`
                      : question.question}
                  </p>

              </li>
                    <li style={mobile ? {flexDirection: 'row', display: 'flex', listStyleType: "none", marginTop: 8} : {flexDirection: 'row', display: 'flex', listStyleType: "none"}}>
                    {(
                      <p style={{fontWeight: 'bold'}}>{`(${question.mark} ${
                        question.mark == 1 ? "Mark" : "Marks"
                      })`}</p>
                    )}
                    <input 
                        style={{marginLeft: 10, width: 30}} 
                        type='text'
                        name={`name_${i}`}
                        value={scores[`name_${i}`]}
                        onChange={handleStudentScore}
                    />
                  </li>
          </ul>

          {question.question_type === "Theory with video" ? (
                  <>
                    <div className="selected-image">
                    <div className="show-img">
                      <video 
                        style={{marginTop: 6}}
                        src={question.video_url} alt=""
                        controls
                        width="300"
                        height="150"
                        title={"video"} />
                    </div>
                      
                    </div>
                    <div className="correct-answer-div">
                      {/* Enter Correct Answer <input className="correct-answer" name='correct_answer' onChange={(e) => handleCorrectAnswer(e, i)}/> */}
                      {/* <p style={{color: '#E05615', marginLeft: 30}}>{`ANS: ${question.correct_answer.toUpperCase()}`}</p> */}
                    </div>
                  </>
                ) : (
                  <div className="correct-answer-div">
                    {/* Enter Correct Answer <input className="correct-answer"  name='correct_answer' onChange={(e) => handleCorrectAnswer(e, i)}/> */}
                    {/* <p style={{color: '#E05615', marginLeft: 30}}>{`ANS: ${question.correct_answer.toUpperCase()}`}</p> */}
                  </div>
                )}
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'start', marginBottom: 16}}>
                    <p style={{fontWeight: 'bold'}}>Student's Answer:</p>
                    <p>{`${submission.answers[i]}`}</p>
                </div>
          </div>
              ))
            )}
        
        {/* (
          <div className="submitted-students">
            <p>Student List</p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "5px",
              }}
            >
              <Table columns="0.5fr 2fr 1fr 1fr 1fr" data={tableValues} />

              <div
                // key={index}
                style={{
                  display: "grid",
                  gridTemplateColumns: "0.5fr 2fr 1fr 1fr 1fr",
                  fontWeight: "300",
                  gridGap: "30px",
                }}
              >
                <div>1</div>
                <div>English Language</div>
                <div>odsjcnsfsf</div>
                <div>70</div>
                <div>Excellent</div>
              </div>
            </div>
          </div>
        ) */}
        
        {/* {toggle ? (
          <Novideo
            img={Question}
            text="No Questions have been set. Tap the icon to start setting questions"
          />
        ) : (
          <Novideo
            img={Submission}
            text="No Student submission has occurred at this time. Please check back after a test."
          />
        )} */}

        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <Button
                name='Finish and upload'
                disabled={loading}
                click={(e)=>{
                    e.preventDefault();
                    uploadScore();
                }}
            />

            <div style={{ marginLeft: mobile ? 20 : 150, display: 'flex', flexDirection: 'row'}}>
                <p style={{fontWeight: 'bold', marginRight: 24}}>Total Score </p>
                <div style={{fontWeight: 'bold', color: 'white', background: '#E05615', height: 40, width: 80, display:'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 6}}>
                    {totalScore}
                </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export function Table(props) {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: props.columns,
        fontWeight: "500",
        gridGap: "30px",
      }}
    >
      {props.data.map((item, index) => (
        <div key={index}>{item}</div>
      ))}
    </div>
  );
}

export function Table2(props) {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: props.columns,
        fontWeight: "500",
        gridGap: "30px",
      }}
    >
      {props.data.map((item, index) => (
        <div
          key={index}
          style={{ textAlign: item.center === true ? "center" : "" }}
        >
          {item.data}
        </div>
      ))}
    </div>
  );
}
