import React, {useEffect, useState} from "react";
import { Outlet, NavLink } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import { LoggedNavBar, TeacherNavBar } from "../components/NavBar/NavBar";
import "../components/Teacher/teacherside.css";
import TeacherIcon from "../images/shola.png";

export default function Admin() {
  const [admin, setAdmin] = useState({
    img_url: ''
  });
  const [setData, setSetData] = useState(true);
  const [navbarHidden, setNavbarHidden] = useState(true);

  let imgUrl = localStorage.getItem("img");
  let name = localStorage.getItem("name");
  let role = localStorage.getItem("role");
  let adminToken = localStorage.getItem("admin_rubbish");

  const updateData = (nName) => {
    name = nName;
    setSetData(false);
  }

  const getAdminData = async () => {

    try {
      const res = await fetch("https://server-olhms.onrender.com/admin_profile/view_profile", {
        method: "POST",
        body: JSON.stringify({
          token: JSON.parse(adminToken)
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json, text/plain, */*",
        },
      });
      const data = await res.json();

      if (data.msg == "success") {
        
        setAdmin(data?.admin);
        admin.updateData = updateData;
        console.log(data);
        console.log(data?.admin);
      } else {
        console.log("did not work");
      }
    } catch (error) {
      alert("error logging in");
      console.log(error);
    }
  }

  useEffect(()=>{
    getAdminData();
  }, []);

  return (
    <div>
      <TeacherNavBar imgUrl={admin.img_url} name={name} hamburgerPressed={(state)=>{
          setNavbarHidden(state);
        }}/>
      <div className="sidebar-container">
        <div className="sidebar" style={{display: navbarHidden ? 'none' : 'inherit'}}>
          <nav>
            <ul className="sidebar-li">
              <NavLink to={{
                pathname: "/admin/profile",
              }} state={
                  admin

                }>
                <li className="profile-section-icon">
                  <div className="teacher-icon">
                    <img src={admin.img_url} alt="" />
                  </div>
                  <div className="teacher-profile">
                    <p className="teacher-name">{name}</p>
                    <p className="class-name">{role}</p>
                    <p className="class-name">view profile</p>
                  </div>
                </li>
              </NavLink>
              <li>
                <NavLink
                  to="/admin/dashboard"
                  style={({ isActive }) => {
                    return {
                      background: isActive ? "white" : "",
                      // marginBottom: "20px",
                    };
                  }}
                >
                  Dashboard
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/gallery"
                  style={({ isActive }) => {
                    return {
                      background: isActive ? "white" : "",
                      // marginBottom: "20px",
                    };
                  }}
                >
                  Gallery/Featured Video
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/blog"
                  style={({ isActive }) => {
                    return {
                      background: isActive ? "white" : "",
                      // marginBottom: "20px",
                    };
                  }}
                >
                  Blog/Vlog/Video Series
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/news"
  // to={{
  //   pathname: "/admin/news",
  //   state: { data: /* your data here */ }
  // }}
                  style={({ isActive }) => {
                    return {
                      background: isActive ? "white" : "",
                      // marginBottom: "20px",
                    };
                  }}
                >
                  News & Events
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/user_support"
                  // to="/admin/chat"
                  style={({ isActive }) => {
                    return {
                      background: isActive ? "white" : "",
                      // marginBottom: "20px",
                    };
                  }}
                >
                  Chat
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/git"
                  style={({ isActive }) => {
                    return {
                      background: isActive ? "white" : "",
                      // marginBottom: "20px",
                    };
                  }}
                >
                  Get in Touch
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/store"
                  style={({ isActive }) => {
                    return {
                      background: isActive ? "white" : "",
                      // marginBottom: "20px",
                    };
                  }}
                >
                  School Store
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/archive"
                  style={({ isActive }) => {
                    return {
                      background: isActive ? "white" : "",
                      // marginBottom: "20px",
                    };
                  }}
                >
                  Question Archive
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/settings"
                  style={({ isActive }) => {
                    return {
                      background: isActive ? "white" : "",
                      // marginBottom: "20px",
                    };
                  }}
                >
                  Settings
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
        <div className="main-content">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}
