import React from "react";
import "./sectionone.css";
import { useState, useEffect } from "react";

export default function SectionOne() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const testimonialSlider = [
    <div className="section-one fade">
      <div className="trapezoid">
        <p className="trap-head">Lifelong Love for Learning</p>
        <p className="trap-text">
          Our Personalised hands-on approach develops strong academic skills in
          your kids creating a joyful learning passion!
        </p>
      </div>
    </div>,
    <div className="section-one-two fade">
      <div className="trapezoid">
        <p className="trap-head">World Class Facilities</p>
        <p className="trap-text">
          Our World class educational facilities inspires the love for learning
          in children of all ages
        </p>
      </div>
    </div>,
    <div className="section-one-three fade">
      <div className="trapezoid">
        <p className="trap-head">Innovators and Thinkers</p>
        <p className="trap-text">
          Our Students are taught innovative thinking which makes them better
          problem solvers and helps them become more adaptable to seize new
          opportunities.
        </p>
      </div>
    </div>,
    <div className="section-one-four fade">
      <div className="trapezoid">
        <p className="trap-head">A Joyful learning Experience</p>
        <p className="trap-text">
          We make celebrating holidays in the classroom fun and exciting,
          nuturing cultural values in our students.
        </p>
      </div>
    </div>,
    <div className="section-one-five fade">
      <div className="trapezoid">
        <p className="trap-head">Artists and Creators</p>
        <p className="trap-text">
          From early childhood, Students experience the joy of art, music, dance
          and creativity.
        </p>
      </div>
    </div>,
    <div className="section-one-six fade">
      <div className="trapezoid">
        <p className="trap-head">Leaders and Trailblazers</p>
        <p className="trap-text">
          Our Students are confident and engaged learners who lead through
          collaboration and compassion
        </p>
      </div>
    </div>,
  ];
  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonialSlider.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const timer = setInterval(goToNext, 7000);

    return () => clearInterval(timer);
  });
  return (
    <div className="section-one-container">
      {testimonialSlider[currentIndex]}
      <div className="show-trapezoid">
        <div className="dot-container">
          {testimonialSlider.map((_, idx) => (
            <div
              className={`dot${currentIndex === idx ? " active-dot" : ""}`}
              key={idx}
              onClick={() => setCurrentIndex(idx)}
            ></div>
          ))}
        </div>

        <div className="show-trapezoid-content">
          {currentIndex == 0 ? (
            <>
              <p className="trap-head">Lifelong Love for Learning</p>
              <p className="trap-text">
                Our Personalised hands-on approach develops strong academic
                skills in your kids creating a joyful learning passion!
              </p>
            </>
          ) : currentIndex == 1 ? (
            <>
              <p className="trap-head">World Class Facilities</p>
              <p className="trap-text">
                Our World class educational facilities inspires the love for
                learning in children of all ages
              </p>
            </>
          ) : currentIndex == 2 ? (
            <>
              <p className="trap-head">Innovators and Thinkers</p>
              <p className="trap-text">
                Our Students are taught innovative thinking which makes them
                better problem solvers and helps them become more adaptable to
                seize new opportunities.
              </p>
            </>
          ) : currentIndex == 3 ? (
            <>
              <p className="trap-head">A Joyful learning Experience</p>
              <p className="trap-text">
                We make celebrating holidays in the classroom fun and exciting,
                nuturing cultural values in our students.
              </p>
            </>
          ) : currentIndex == 4 ? (
            <>
              <p className="trap-head">Artists and Creators</p>
              <p className="trap-text">
                From early childhood, Students experience the joy of art, music,
                dance and creativity.
              </p>
            </>
          ) : currentIndex == 5 ? (
            <>
              <p className="trap-head">Leaders and Trailblazers</p>
              <p className="trap-text">
                Our Students are confident and engaged learners who lead through
                collaboration and compassion.
              </p>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}
