import React from "react";
import Footer from "../components/Footer/Footer";
import NavBar from "../components/NavBar/NavBar";
import Explore from "./homepageSections/Explore";
import Feature from "./homepageSections/Feature";
import SectionEight from "./homepageSections/SectionEight";
import SectionEleven from "./homepageSections/SectionEleven";
import SectionFive from "./homepageSections/SectionFive";
import SectionFour from "./homepageSections/SectionFour";
import SectionNine from "./homepageSections/SectionNine";
import SectionOne from "./homepageSections/SectionOne";
import SectionSeven from "./homepageSections/SectionSeven";
import SectionSix from "./homepageSections/SectionSix";
import SectionTen from "./homepageSections/SectionTen";
import SectionThree from "./homepageSections/SectionThree";
import SectionTwo from "./homepageSections/SectionTwo";

export default function HomePage() {
  return (
    <>
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <Explore />
      <SectionFive />
      <SectionSeven />
      <SectionEight />
      <SectionNine />
      <SectionSix />
      <Feature />
      <SectionTen />
      <SectionEleven />
    </>
  );
}
