import React from "react";
import AboutFour from "./about/AboutFour";
import AboutIntersect from "./about/AboutIntersect";
// import AboutOne from "./about/AboutOne";
import AboutThree from "./about/AboutThree";
// import SectionThree from "./homepageSections/SectionThree";

export default function About() {
  return (
    <div>
      <AboutIntersect />
      <AboutThree />
      <AboutFour />
    </div>
  );
}
