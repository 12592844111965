import React from "react";
import { useState, useEffect, useContext } from "react";
import "./setquestions.css";
import { BackButton } from "../studentPages/PersonalData";
import { useNavigate, useLocation } from "react-router-dom";
import Add from "../../images/addition.png";
import Button, { Button2 } from "../../components/Button/Button";
import { adminContext } from "../context/adminContext";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast"; // Import the Toaster component

export default function SetQuestions() {
  const navigate = useNavigate();
  const location = useLocation();
  const session = location.state.session;
  const subject = location.state.subject;
  const ca_id = location.state.ca_id;
  const section_present = location.state.section_present;
  const type = location.state.type;

  const values = useContext(adminContext);
  const { teacherData, teachertoken } = values;
  const dataT = JSON.parse(teacherData);

  const [loading, setLoading] = useState(false);
 
  const [questions, setQuestions] = useState([
    {
      question_type: "Multiple Choice Question without Image",
      mark: 1,
      question: "",
      correct_answer: "a",
      answer_type: "",
      option1: "",
      option2: "",
      option3: "",
      option4: "",
      selectedSrc: "",
      selectedFile: null,
      isSelected: false,
      imgs: [],
    },
  ]);

  const setCaNo = (type) => {
    if (type == "1st Continuous Assessment Test") {
      return 1;
    }
    if (type == "2nd Continuous Assessment Test") {
      return 2;
    } else {
      return 3;
    }
  };

  const setAssessType = (type) => {
    if (type == "1st Continuous Assessment Test") {
      return "ca";
    }
    if (type == "2nd Continuous Assessment Test") {
      return "ca";
    } else {
      return "exam";
    }
  }; 
  const [preview, setPreview] = useState(false);
  const handleQuestionChange = (e, index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].question = e.target.value;
    setQuestions(updatedQuestions);
  };
  const handleOption1Change = (e, index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].option1 = e.target.value;
    setQuestions(updatedQuestions);
  };
  const handleOption2Change = (e, index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].option2 = e.target.value;
    setQuestions(updatedQuestions);
  };
  const handleOption3Change = (e, index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].option3 = e.target.value;
    setQuestions(updatedQuestions);
  };
  const handleOption4Change = (e, index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].option4 = e.target.value;
    setQuestions(updatedQuestions);
  };
  const handleQuestionquestion_typeChange = (e, index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].question_type = e.target.value;
    setQuestions(updatedQuestions);
  };

  const handleMarkChange = (e, index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].mark = e.target.value;
    setQuestions(updatedQuestions);
  };

  const handleCorrectAnswer = (e, index) => {
    console.log(e.target.value);
    const updatedQuestions = [...questions];
    updatedQuestions[index].correct_answer = e.target.value;
    setQuestions(updatedQuestions);
  };

  const handleAddQuestion = (e) => {
    e.preventDefault();
    setQuestions([
      ...questions,
      {
        question_type: "Multiple Choice Question without Image",
        mark: 1,
        question: "",
        correct_answer: "a",
        answer_type: "",
        option1: "",
        option2: "",
        option3: "",
        option4: "",
        selectedSrc: "",
        selectedFile: null,
        isSelected: false,
        imgs: [],
      },
    ]);
  };

  const onFileChange = (index) => (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState == 2) {
        setQuestions(
          questions.map((question, i) => {
            if (i !== index) return question;
            return {
              ...question,
              selectedSrc: reader.result,
              selectedFile: e.target.files[0],
              isSelected: true,
            };
          })
        );
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    uploadAssessmentQuestions();
  };

  const uploadAssessmentQuestions = async () => {
    setLoading(true);

    const questionsImgUrls = [];
    const questionsImgIds = [];

    for (let i = 0; i < questions.length; i++) {
      let img_urls = [];
      let img_ids = [];

      if (questions[i].question_type != "Multiple Images Question") {
        if (
          questions[i].selectedFile != null &&
          questions[i].selectedFile != undefined
        ) {
          const formData = new FormData();
          formData.append("token", JSON.parse(teachertoken));
          formData.append("assessment_images", questions[i].selectedFile);

          const options = {
            method: "POST",
            headers: {
              "content-question_type": "application/x-www-form-urlencoded",
            },
            data: formData,
            url: "https://server-olhms.onrender.com/teacher_continous_assessment/upload_files",
          };

          const response = await axios(options);
          if (response.status == 200) {
            console.log("response: :: :: ", response);
            img_urls = response.data?.ca_imgs.urls;
            img_ids = response.data?.ca_imgs.ids;
          }
        }

        console.log(img_urls, img_ids);
      } else if (questions[i].question_type == "Multiple Images Question") {
        for (let j = 0; j < questions[i].imgs.length; j++) {
          if (
            questions[i].imgs[j] != null &&
            questions[i].imgs[j] != undefined
          ) {
            const formData = new FormData();
            formData.append("token", JSON.parse(teachertoken));
            formData.append("assessment_images", questions[i].imgs[j]);

            const options = {
              method: "POST",
              headers: {
                "content-question_type": "application/x-www-form-urlencoded",
              },
              data: formData,
              url: "https://server-olhms.onrender.com/teacher_continous_assessment/upload_files",
            };

            const response = await axios(options);
            if (response.status == 200) {
              console.log("response: :: :: ", response);
              img_urls.push(response.data?.ca_imgs.urls[0]);
              img_ids.push(response.data?.ca_imgs.ids[0]);
            }
          }
        }
        console.log(img_urls, img_ids);
      }

      questionsImgUrls.push(img_urls);
      questionsImgIds.push(img_ids);
    }

    const t_questions = [];
    for (let i = 0; i < questions.length; i++) {
      t_questions.push({
        section: "A",
        question: questions[i].question,
        question_type: questions[i].question_type,
        correct_answer: questions[i].correct_answer,
        img_urls: questionsImgUrls[i],
        img_ids: questionsImgIds[i],
        options: [
          questions[i].option1,
          questions[i].option2,
          questions[i].option3,
          questions[i].option4,
        ],
        answer_type: questions[i].question_type,
        mark: questions[i].mark,
      });
    }

    const res = await fetch(
      "https://server-olhms.onrender.com/teacher_continous_assessment/create_continous_assessment",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(teachertoken),
          no_of_sections: 1,
          subject: subject,
          class_name: dataT?.class_name,
          term: session.current_term,
          ca: setCaNo(type),
          type: setAssessType(type),
          ca_date: "nil",
          ca_time: "nil",
          duration_a: `${durH} hr ${durM} mins`,
          session: session.session,
          questions: t_questions,
          section: "a",
          ca_id,
          previous_section: section_present,
        }),
      }
    );
    const data = await res.json();
    navigate(-1);
    console.log(data);
  };

  const [durH, setDurH] = useState("");
  const [durM, setDurM] = useState("");

  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (document.documentElement.clientWidth <= 800) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className={mobile ? "" : "set-questions"}
      style={mobile ? { margin: 24 } : {}}
    >
      <div className="set-container">
        {preview === false ? <BackButton click={() => navigate(-1)} /> : null}
        <div className="session-flex">
          <div className="cat-session">
            <p>{session.current_term}</p>
            <p>{type}</p>
            <p>{session.session} Session</p>
          </div>
          <div className="imported-sub">{subject} - SECTION A</div>
        </div>
        <form className="questions-form">
          <div className="question-elements">
            <div style={{ alignSelf: "center" }}>
            <p style={{ marginBottom: 8 }}>
              Duration for the {type}: </p>
              <select
                className="correct-answer"
                onChange={(e) => {
                  setDurH(e.target.value);
                }}
              >
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
              {"  "}
              Hours{" "}
              <select
                className="correct-answer"
                onChange={(e) => {
                  setDurM(e.target.value);
                }}
              >
                <option value="00">00</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="30">30</option>
                <option value="35">35</option>
                <option value="40">40</option>
                <option value="45">45</option>
                <option value="50">50</option>
                <option value="55">55</option>
              </select>
              {"  "}
              Minutes
            </div>
            {questions.map((question, i) => (
              <div className="questions-wrapper" key={i}>
                {preview === false ? (
                  <div
                    className="question-name"
                    style={
                      mobile
                        ? {
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                            marginBottom: 8,
                          }
                        : {}
                    }
                  >
                    <div
                      className="question-title"
                      style={
                        mobile
                          ? {
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "start",
                              marginBottom: 12,
                            }
                          : {}
                      }
                    >
                      <p
                        className="question_type-of"
                        style={
                          mobile
                            ? { marginBottom: 12, fontWeight: "bold" }
                            : { fontWeight: "bold" }
                        }
                      >
                        Question Type
                      </p>
                      <div className="form-element">
                        <div className="select-question">
                          <select
                            value={question.question_type}
                            onChange={(e) =>
                              handleQuestionquestion_typeChange(e, i)
                            }
                          >
                            <option value="Multiple Choice Question without Image">
                              Multiple Choice Question without Image
                            </option>
                            <option value="Multiple Choice Question with Image">
                              Multiple Choice Question with Image
                            </option>
                            <option value="True/False without image">
                              True/False without image
                            </option>
                            <option value="True/False with image">
                              True/False with image
                            </option>
                            <option value="German without image">
                              German without image
                            </option>
                            <option value="German with image">
                              German with image
                            </option>
                            <option value="Spell without image">
                              Spell without image
                            </option>
                            <option value="Spell with image">
                              Spell with image
                            </option>
                            <option value="Multiple Images Question">
                              Multiple Images Question
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="question-mark">
                      <div className="form-element">
                        <div className="select-mark">
                          <select
                            value={question.mark}
                            onChange={(e) => handleMarkChange(e, i)}
                          >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </select>
                        </div>
                      </div>
                      <p>Marks</p>
                    </div>
                  </div>
                ) : null}

                <ul
                  className="input-list"
                  style={{
                    listStylePosition: "outside",
                    listStylequestion_type: "none",
                    columnGap: preview ? "30px" : "",
                    display: mobile ? "block" : "inherit",
                  }}
                >
                  <li
                    className="input-item"
                    style={{
                      width: preview ? "" : "100%",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>Q{i + 1}. </span>
                    {preview === false ? (
                      <input
                        question_type="text"
                        placeholder="Enter Question"
                        value={question.question}
                        onChange={(e) => handleQuestionChange(e, i)}
                      />
                    ) : (
                      <p>
                        {question.question_type === "German without image" ||
                        question.question_type === "Spell without image"
                          ? `${question.question}_______________`
                          : question.question}
                      </p>
                    )}
                  </li>

                  <li
                    style={
                      mobile
                        ? { listStyleType: "none", marginTop: 8 }
                        : { listStyleType: "none" }
                    }
                  >
                    {preview === true ? (
                      <p style={{ fontWeight: "bold" }}>{`(${question.mark} ${
                        question.mark == 1 ? "Mark" : "Marks"
                      })`}</p>
                    ) : null}
                  </li>
                </ul>

                {question.question_type ===
                "Multiple Choice Question without Image" ? (
                  preview === false ? (
                    <ul className="options-list">
                      <li className="input-options">
                        <input
                          question_type="text"
                          placeholder="Enter Option 1"
                          value={question.option1}
                          onChange={(e) => handleOption1Change(e, i)}
                        />
                      </li>
                      <li className="input-options">
                        <input
                          question_type="text"
                          placeholder="Enter Option 2"
                          value={question.option2}
                          onChange={(e) => handleOption2Change(e, i)}
                        />
                      </li>
                      <li className="input-options">
                        <input
                          question_type="text"
                          placeholder="Enter Option 3"
                          value={question.option3}
                          onChange={(e) => handleOption3Change(e, i)}
                        />
                      </li>
                      <li className="input-options">
                        <input
                          question_type="text"
                          placeholder="Enter Option 4"
                          value={question.option4}
                          onChange={(e) => handleOption4Change(e, i)}
                        />
                      </li>
                      <div className="correct-answer-div">
                        Enter Correct Answer{" "}
                        <select
                          className="correct-answer"
                          onChange={(e) => handleCorrectAnswer(e, i)}
                        >
                          <option value="a">A</option>
                          <option value="b">B</option>
                          <option value="c">C</option>
                          <option value="d">D</option>
                        </select>
                      </div>
                    </ul>
                  ) : (
                    <ul className="see-options">
                      <li>{question.option1}</li>
                      <li>{question.option2}</li>
                      <li>{question.option3}</li>
                      <li>{question.option4}</li>
                    </ul>
                  )
                ) : question.question_type ===
                  "Multiple Choice Question with Image" ? (
                  <div className="display-questions">
                    <div className="selected-image">
                      {question.isSelected ? (
                        <div className="show-img">
                          <img src={question.selectedSrc} alt="" />
                        </div>
                      ) : null}
                      {preview === false ? (
                        <div className="add-image">
                          <label>
                            <img src={Add} alt="" />
                            <input
                              required
                              onChange={onFileChange(i)}
                              type="file"
                              accept=".png, .jpg, .jpeg, .gif"
                              name="selectedFile"
                            />
                          </label>
                        </div>
                      ) : null}
                    </div>
                    {preview === false ? (
                      <ul className="options-list">
                        <li className="input-options">
                          <input
                            question_type="text"
                            placeholder="Enter Option 1"
                            value={question.option1}
                            onChange={(e) => handleOption1Change(e, i)}
                          />
                        </li>
                        <li className="input-options">
                          <input
                            question_type="text"
                            placeholder="Enter Option 2"
                            value={question.option2}
                            onChange={(e) => handleOption2Change(e, i)}
                          />
                        </li>
                        <li className="input-options">
                          <input
                            question_type="text"
                            placeholder="Enter Option 3"
                            value={question.option3}
                            onChange={(e) => handleOption3Change(e, i)}
                          />
                        </li>
                        <li className="input-options">
                          <input
                            question_type="text"
                            placeholder="Enter Option 4"
                            value={question.option4}
                            onChange={(e) => handleOption4Change(e, i)}
                          />
                        </li>
                        <div className="correct-answer-div">
                          Enter Correct Answer{" "}
                          <select
                            className="correct-answer"
                            onChange={(e) => handleCorrectAnswer(e, i)}
                          >
                            <option value="a">A</option>
                            <option value="b">B</option>
                            <option value="c">C</option>
                            <option value="d">D</option>
                          </select>
                        </div>
                      </ul>
                    ) : (
                      <ul className="see-options">
                        <li>{question.option1}</li>
                        <li>{question.option2}</li>
                        <li>{question.option3}</li>
                        <li>{question.option4}</li>
                      </ul>
                    )}
                  </div>
                ) : question.question_type === "True/False without image" ? (
                  preview === false ? (
                    <ul className="options-list">
                      <li className="input-options">
                        <input question_type="text" value="True" disabled />
                      </li>
                      <li className="input-options">
                        <input question_type="text" value="False" disabled />
                      </li>
                      <div className="correct-answer-div">
                        Enter Correct Answer{" "}
                        <select
                          className="correct-answer"
                          onChange={(e) => handleCorrectAnswer(e, i)}
                        >
                          <option value="a">A</option>
                          <option value="b">B</option>
                        </select>
                      </div>
                    </ul>
                  ) : (
                    <ul className="see-options">
                      <li>True</li>
                      <li>False</li>
                    </ul>
                  )
                ) : question.question_type === "True/False with image" ? (
                  <div className="display-questions">
                    <div className="selected-image">
                      {question.isSelected ? (
                        <div className="show-img">
                          <img src={question.selectedSrc} alt="" />
                        </div>
                      ) : null}
                      {preview === false ? (
                        <div className="add-image">
                          <label>
                            <img src={Add} alt="" />
                            <input
                              required
                              onChange={onFileChange(i)}
                              type="file"
                              accept=".png, .jpg, .jpeg, .gif"
                              name="selectedFile"
                            />
                          </label>
                        </div>
                      ) : null}
                    </div>
                    {preview === false ? (
                      <ul className="options-list">
                        <li className="input-options">
                          <input question_type="text" value="True" disabled />
                        </li>
                        <li className="input-options">
                          <input question_type="text" value="False" disabled />
                        </li>
                        <div className="correct-answer-div">
                          Enter Correct Answer{" "}
                          <select
                            className="correct-answer"
                            onChange={(e) => handleCorrectAnswer(e, i)}
                          >
                            <option value="a">A</option>
                            <option value="b">B</option>
                          </select>
                        </div>
                      </ul>
                    ) : (
                      <ul className="see-options">
                        <li>True</li>
                        <li>False</li>
                      </ul>
                    )}
                  </div>
                ) : question.question_type === "German with image" ||
                  question.question_type === "Spell with image" ? (
                  <>
                    <div className="selected-image">
                      {question.isSelected ? (
                        <>
                          <div className="show-img">
                            <img src={question.selectedSrc} alt="" />
                          </div>
                        </>
                      ) : null}
                      {preview === false ? (
                        <>
                          <div className="add-image">
                            <label>
                              <img src={Add} alt="" />
                              <input
                                required
                                onChange={onFileChange(i)}
                                type="file"
                                accept=".png, .jpg, .jpeg, .gif"
                                name="selectedFile"
                              />
                            </label>
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div className="correct-answer-div">
                      Enter Correct Answer{" "}
                      <input
                        className="correct-answer"
                        name="correct_answer"
                        onChange={(e) => handleCorrectAnswer(e, i)}
                      />
                    </div>
                  </>
                ) : question.question_type === "German without image" ||
                  question.question_type === "Spell without image" ? (
                  <div className="correct-answer-div">
                    Enter Correct Answer{" "}
                    <input
                      className="correct-answer"
                      name="correct_answer"
                      onChange={(e) => handleCorrectAnswer(e, i)}
                    />
                  </div>
                ) : question.question_type === "Multiple Images Question" ? (
                  <>
                    <Test
                      idx={i}
                      toggle={preview}
                      imgs={question.imgs}
                      setImgs={(imgs) => {
                        const newQuestions = [...questions];
                        newQuestions[i].imgs = imgs;
                        setQuestions(newQuestions);
                      }}
                    />
                    <div className="correct-answer-div">
                      Enter Correct Answer{" "}
                      <select
                        className="correct-answer"
                        onChange={(e) => handleCorrectAnswer(e, i)}
                      >
                        <option value="a">A</option>
                        <option value="b">B</option>
                        <option value="c">C</option>
                        <option value="d">D</option>
                      </select>
                    </div>
                  </>
                ) : null}
              </div>
            ))}
          </div>

          <button
            type="button"
            className="add-button"
            onClick={handleAddQuestion}
          >
            + Add another question
          </button>
        </form>

        {preview === false ? (
          <Button
            name="Preview Questions"
            click={() => {
              if (durH === "" && durM === "") {
                toast.error("Please set a time for the test", {
                  icon: "❗️",
                  position: "bottom-center",
                });
              } else {
                setPreview(true);
              }
            }}
          />
        ) : (
          <div className="split-button">
            <Button2
              name="Back to edit Questions"
              click={() => {
                console.log(durH);
                console.log(durM);
                setPreview(false);
              }}
            />
            <Button
              disabled={loading}
              name="Finish and upload"
              click={handleSubmit}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export function Test({ idx, toggle, imgs, setImgs }) {
  const [fileLimit, setFileLimit] = useState(false);
  const [selected, setSelected] = useState(false);
  // const [imgs, setImgs] = useState([]);
  const [fire, setFire] = useState(false);

  useEffect(() => {
    handleDelete();
    setFire(false);
  }, [fire === true]);

  const picUploaded = [...imgs];

  const handleMulti = (files) => {
    let limitExceeded = false;
    files.some((file) => {
      if (picUploaded.findIndex((f) => f.name === file.name) === -1) {
        picUploaded.push(file);
        if (picUploaded.length === 4) setFileLimit(true);
        if (picUploaded.length > 4) {
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setImgs(picUploaded);
  };
  const handleDisplay = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleMulti(chosenFiles);
    setSelected(true);
  };

  const handleDelete = (url) => {
    if (imgs.length === 4) {
      setFileLimit(false);
    }
    let i = -1;
    const found = imgs.some((element) => {
      i++;
      return element === url;
    });
    if (found) {
      imgs.splice(i, 1);
      setFire(true);
    }
  };
  return (
    <div className="questions-wrapper">
      <div className="display-questions">
        <div className="selected-image">
          {selected === true
            ? imgs.map((item, index) => (
                <div className="show-img" key={index}>
                  <img src={URL.createObjectURL(item)} alt="" />
                  {toggle === false ? (
                    <p
                      onClick={(e) => {
                        e.preventDefault();
                        handleDelete(item);
                      }}
                      className="remove-img"
                    >
                      x
                    </p>
                  ) : null}
                </div>
              ))
            : ""}
          {toggle === false ? (
            <div className="add-image">
              <label>
                <img src={Add} alt="" />
                <input
                  required
                  onChange={handleDisplay}
                  type="file"
                  accept=".png, .jpg, .jpeg, .gif"
                  name="selectedFile"
                />
              </label>
            </div>
          ) : null}
        </div>
        {toggle === false ? (
          <p className="warning-p">
            N/B: A maximum of 4 images can be added per question
          </p>
        ) : null}
      </div>
    </div>
  );
}
