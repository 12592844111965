import React from "react";
import { useState, useEffect } from "react";
// import img from "../../images/blog-content.png";
import "../news/read.css";
import NewsOne from "../../images/news1.png";
import { EventComponent } from "../homepageSections/SectionSix";
import Calendar from "../../images/Calendar.png";
import Shola2 from "../../images/shola2.png";
import { useLocation, useNavigate } from "react-router-dom";
import { NewsComponent } from "../news/NewsTwo";
import { BlogColumn } from "./BlogTwo";
import { PopularReads } from "./BlogTwo";
import Learn1 from "../../videos/learn-1.mp4";
import Learn2 from "../../videos/learn-2.mp4";
import Learn3 from "../../videos/learn-3.mp4";
import Learn4 from "../../videos/learn-4.mp4";
import Dyk from "../../images/dyk.png";
import Button from "../../components/Button/Button";
import { truncateString } from "../adminPages/Vlogs";
import axios from "axios";
import qs from "qs";
import { Toaster, toast } from "react-hot-toast"; // Import the Toaster component

export default function Readblog() {
  const location = useLocation();
  const img = location.state.img;
  const title = location.state.title;
  const body = location.state.body;
  const timestamp = location.state.timestamp;
  const [view_count, setViewCount] = useState(location.state.view_count);
  const [comment_count, setCommentCount] = useState(
    location.state.comment_count
  );
  const [like_count, setLikeCount] = useState(location.state.like_count);
  const blog_id = location.state.blog_id;

  console.log("blog_id: ::: ", blog_id);

  const videos = [Learn1, Learn2, Learn3, Learn4];
  const [blogs, setBlogs] = useState([]);
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(true);
  const [likedPost, setlikedPost] = useState(false);

  const viewBlogs = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_blog/view_blogs",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(),
      }
    );
    const data = await res.json();
    setLoading(false);
    setBlogs(data?.blogs);
    console.log(data);
  };
  const viewLearning = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_video_learning/view_video_learnings",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pagec: 1 }),
      }
    );
    const data = await res.json();
    // setLoading(false);
    setResources(data?.video_learnings);
    console.log(data);
  };
  useEffect(() => {
    viewBlogs();
    viewLearning();
  }, []);

  // operation to like blog post
  const [likeBlog, setLikeBlog] = useState({});

  const like_or_unlike_blogPost = ()=>{

    if (likedPost == false) {
      likeBlogPost();
    } else {
      unlikeBlogPost();
    }
  }

  const likeBlogPost = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/student_likes/like_blog",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ blog_id }),
      }
    );
    const data = await res.json();
    // setLoading(false);
    setLikeBlog(data?.blog);
    console.log(data);
    // update like count
    setLikeCount(data?.blog.like_count);
    setlikedPost(true);
  };

  // operation to unlike blog post
  const [unlikeBlog, setUnlikeBlog] = useState({});
  const unlikeBlogPost = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/student_likes/unlike_blog",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ blog_id }),
      }
    );
    const data = await res.json();
    // setLoading(false);
    setUnlikeBlog(data?.blog);
    
    console.log(data);
    setLikeCount(data?.blog.like_count);
    setlikedPost(false);
  };

  // endpoint to view a blog post so that the view count
  // can increase

  const viewAndIncreaseCountBlog = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/student_blog/view_blog",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ blog_id }),
      }
    );
    const data = await res.json();
    let nBlog = data?.blog;
    setViewCount(nBlog.view_count);
  };

  useEffect(() => {
    viewAndIncreaseCountBlog();
  }, []);

  // to handle commenting
  const [cName, setCName] = useState("");
  const [cEmail, setCEmail] = useState("");
  const [cComment, setCComment] = useState("");
  const [isCommenting, setIsCommenting] = useState(false);

  const handleNameChange = (e) => {
    setCName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setCEmail(e.target.value);
  };

  const handleCommentChange = (e) => {
    setCComment(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsCommenting(true);

    const data = {
      owner_name: cName,
      owner_email: cEmail,
      comment: cComment,
      blog_id: blog_id,
    };

    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: qs.stringify(data),
      url: "https://server-olhms.onrender.com/student_comment/comment",
    };

    axios(options)
      .then((response) => {
        if (response.status == 200) {
          console.log("response: :: :", response);
          let comment = response?.data.comment;
          console.log("comment: :: :", comment);
          setCommentCount(response?.data.blog.comment_count);
        }

        setCName("");
        setCEmail("");
        setCComment("");

        setIsCommenting(false);
        //getComments();
      })
      .catch((e) => {
        console.log("an error occured:  :  ", e);

        setIsCommenting(false);
      });
  };

  // endpoint to get comments
  const [comments, setComments] = useState([]);
  const [commentsPage, setCommentsPage] = useState(1);

  const getComments = async () => {
    console.log(commentsPage);
    const res = await fetch(
      "https://server-olhms.onrender.com/student_comment/get_comments",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ blog_id, pagec: commentsPage }),
      }
    );
    const data = await res.json();
    console.log("COMMENTS: :: : ", data?.comments);
    setComments([...data?.comments, ...comments]);
    console.log(comments);
  };

  useEffect(() => {
    getComments();
  }, [commentsPage, comment_count]);

  const [email, setEmail] = useState("");

  const handleNewsLetterChange = (e) => {
    setEmail(e.target.value);
  };

  const subscribe = async () => {
    toast.error("Please wait while we sign you up for our newsletter", {
      icon: "⏳",
      position: "bottom-center",
    });
    setLoading(true);
    const res = await fetch(
      "https://server-olhms.onrender.com/guardian_newsletter/subscribe_newsletter",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
        }),
      }
    );

    const data = await res.json();
    if (data?.msg == "success") {
      alert("Successful Subscription");
      setEmail("");
      setLoading(false);
    } else {
      alert(data);
      setLoading(false);
    }
  };

  return (
    <div className="news-read">
      <div className="part-two">
        <div className="content-image">
          <img src={img} alt="" />
        </div>
        <div className="news-two-wrapper">
          <div className="news-section-one">
            <p className="news-section-one-p">
              <strong>{title}</strong>
              <p className="split-comments-like">
                <span className="show-days-ago">{`${timeAgo(
                  timestamp
                )}, ${view_count} views`}</span>
                <span>
                  {like_count}
                  <i
                    onClick={() => {
                      like_or_unlike_blogPost();
                    }}
                    class="fas fa-heart"
                    style={likedPost ?{ color: "red", marginLeft: 6, marginRight: 6 }:{ color: "grey", marginLeft: 6, marginRight: 6 }}
                  ></i>{" "}
                  {comment_count}{" "}
                  <i
                    onClick={() => {
                      like_or_unlike_blogPost();
                    }}
                    class="fas fa-comment"
                    style={{ color: "grey", cursor: "pointer", marginLeft: 6 }}
                  ></i>
                </span>
              </p>
            </p>
            <div className="real-content-news">{body}</div>
            <p
              onClick={() => {
                /// fire endpoint to like post
                like_or_unlike_blogPost();
              }}
            >
              <i class="fas fa-heart" style={likedPost ? { color: "red" } : { color: "grey" }}></i> Like this
              Post
            </p>
            <form onSubmit={handleSubmit}>
              <div className="comment-on-div">
                <p className="comment-on">
                  <strong>Comment on this Post</strong>
                </p>
                <div>
                  <input
                    placeholder="Enter your name"
                    name="name"
                    value={cName}
                    onChange={handleNameChange}
                  />
                  <input
                    placeholder="Enter your email address"
                    name="email"
                    value={cEmail}
                    onChange={handleEmailChange}
                  />
                </div>
                <div>
                  <textarea
                    placeholder="Enter your comment"
                    name="comment"
                    value={cComment}
                    onChange={handleCommentChange}
                  />
                </div>
                <Button
                  name="Post"
                  submit={handleSubmit}
                  disabled={isCommenting}
                />
              </div>
            </form>
            <div>
              <p className="comment-on" style={{ marginBottom: 16 }}>
                <strong>Recent Comments</strong>
              </p>
              <div>
                <div className="blog-read-column">
                  {comments?.map((comment, index) => (
                    <CommentComp
                      comment={comment?.comment}
                      owner_name={comment?.owner_name}
                      key={index}
                      timestamp={comment?.timestamp}
                    />
                  ))}
                </div>

                <p
                  style={{ marginTop: 18 }}
                  onClick={() => {
                    let temp = commentsPage + 1;
                    setCommentsPage(temp);
                    console.log("After first increase:: :: ", commentsPage);
                    getComments();
                  }}
                >
                  More comments...
                </p>
              </div>
            </div>
          </div>
          <div className="news-section-two">
            <div className="subscribe-section">
              <div className="news-section-two-p">
                <p>
                  <strong>Never miss an Update</strong>
                </p>
                <p className="light-letter">Subscribe to our News letter</p>
              </div>
              <div className="news-input-section">
                <input
                  placeholder="Enter your email address"
                  className="news-input"
                  name="newsletter"
                  value={email}
                  onChange={handleNewsLetterChange}
                />
                <button
                  disabled={loading}
                  onClick={(e) => {
                    e.preventDefault();
                    if (email === "") {
                      toast.error("Please add an email address", {
                        icon: "❗️",
                        position: "bottom-center",
                      });
                    } else {
                      subscribe();
                    }
                  }}
                >
                  Subscribe
                </button>
              </div>
            </div>

            <div className="big-events-section">
              <p className="big-events">
                <strong>Popular Reads</strong>
              </p>

              <div className="blog-read-column">
                {blogs?.slice(0, 3).map((item, i) => (
                  <BlogColumn
                    title={truncateString(item?.title, 36)}
                    header={item?.title}
                    body={item?.body}
                    img={item?.img_url}
                    details={truncateString(item?.body, 100)}
                    timestamp={item?.timestamp}
                    view_count={item?.view_count}
                    comment_count={item?.comment_count}
                    like_count={item?.like_count}
                    blog_id={item?._id}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="popular-grid-container" style={{ marginTop: "30px" }}>
        <p
          className="popular-grid-p"
          style={{ marginBottom: 16, marginTop: 16 }}
        >
          <strong>Other Articles</strong>
        </p>
        <div className="flex-popular-grid">
          {blogs?.slice(0, 4).map((item, i) => (
            <PopularReads
              x={i + 1}
              title={item?.title}
              details={item?.body}
              img={item?.img_url}
              timestamp={item?.timestamp}
              view_count={item?.view_count}
              comment_count={item?.comment_count}
              like_count={item?.like_count}
              blog_id={item?._id}
            />
          ))}
        </div>
      </div>

      <div className="popular-grid-container" style={{ marginTop: "30px" }}>
        <p className="popular-grid-p">
          <strong>Most Viewed Video Learning Series</strong>
        </p>
        <div className="flex-popular-grid">
          {resources?.map((vid, index) => (
            <PopularFeature
              poster_url={vid?.img_url}
              src={vid?.video_url}
              key={index}
              text={truncateString(vid?.summary, 100)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export function PopularFeature(props) {
  return (
    <div className="feature-text-flex">
      <video
        width="280"
        height="220"
        src={props.src}
        autoPlay={false}
        controls
        poster={props.poster_url}
      ></video>
      <p>{props.text}</p>
    </div>
  );
}

export function CommentComp({ owner_name, comment, timestamp }) {
  return (
    <div>
      <p>
        <strong>{owner_name}</strong>
      </p>
      <p>{comment}</p>
      <p>{timeAgo(timestamp)}</p>
    </div>
  );
}

export const timeAgo = (timestamp) => {
  let date = new Date(timestamp);
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = Math.floor(seconds / 31536000);
  if (interval > 1) {
    return interval + " years ago";
  }

  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + " months ago";
  }

  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + " days ago";
  }

  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + " hours ago";
  }

  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + " minutes ago";
  }

  if (seconds < 10) return "just now";

  return Math.floor(seconds) + " seconds ago";
};
