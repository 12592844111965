import React from "react";
import Button, { Button2, Button3 } from "../../components/Button/Button";
import "./admissionone.css";
import Boy from "../../images/boy.png";
import Girl from "../../images/girl.png";
import Book from "../../images/book.png";
import Cap from "../../images/cap.png";
import Script from "../../images/script.png";
import Student from "../../images/student.png";
import Ellipse1 from "../../images/ellipses-1.png";
import Planet from "../../images/planet.png";
import Fruit from "../../images/fruit.png";
import Ellipse2 from "../../images/ellipses-2.png";
import { useNavigate } from "react-router-dom";

export default function AdmissionOne() {
  const navigate = useNavigate();
  return (
    <div className="admission-one">
      {/* <div className="admission-relative"> */}
      <div className="admission-one-div">
        <p className="admission-one-p">
          <strong>A Better Future for your Kids</strong>
        </p>
        <p className="admission-second">
          Raising a breed of Innovators and Problem Solvers
        </p>
      </div>
      <div className="apply-div">
        <Button3
          name="Make Inquiry"
          click={() => navigate("/contact", { state: { type: 0 } })}
        />
        <Button
          name="Apply Now"
          click={() => navigate("/apply", { state: { type: 0 } })}
        />
      </div>
      {/* </div> */}
      {/* <img src={Boy} alt="" className="boy-float" />
      <img src={Girl} alt="" className="girl-float" />
      <img src={Student} alt="" className="student-float" />
      <img src={Planet} alt="" className="planet-float" />
      <img src={Script} alt="" className="script-float" />
      <img src={Book} alt="" className="book-float" />
      <img src={Cap} alt="" className="cap-float" />
      <img src={Fruit} alt="" className="fruit-float" />
      <img src={Ellipse2} alt="" className="ellipse2-float" />
      <img src={Ellipse1} alt="" className="ellipse1-float" />
      <img src={Ellipse2} alt="" className="ellipse3-float" /> */}
    </div>
  );
}
