import React from "react";
import "./sectionfive.css";
import { useState } from "react";
import Component1 from "../../components/Component1/Component1";
import Image1 from "../../images/new1.png";
import Image2 from "../../images/new2.png";
import Image3 from "../../images/component3.png";
import { useNavigate } from "react-router-dom";

export default function SectionFive() {
  const navigate = useNavigate();
  const classArray = [
    {
      image: Image1,
      text: "Curriculum goals for the early childhood program involve creating an environment with many opportunities for growth in social emotional and cognitive development, emergent literacy, social studies, science and technology, mathematical thinking, physical development and health.",
      span: "Baby's and Toddlers",
      click: () => navigate("/baby", { state: { type: 0 } }),
    },
    {
      image: Image2,
      text: "Challenging. Inspiring. Engaging. These are a few words used to describe the unique Kindergarten curriculum. The academic experience of students is complemented with education in the arts, athletics and character - an empowering combination that results in kids who lead, volunteer, inspire and excel.",
      span: "Infant School",
      click: () => navigate("/infant", { state: { type: 1 } }),
    },
    {
      image: Image3,
      text: "The success of our Lower School centers on creating the optimal foundation for each student's educational journey. Each teacher is committed to fostering a love of learning and to delivering engaging academic instruction in a supportive and nurturing environment.",
      span: "Grade School",
      click: () => navigate("/grade", { state: { type: 2 } }),
    },
  ];
  return (
    <div className="section-five">
      {classArray.map((zoomObj, index) => (
        <Component1
          img={zoomObj.image}
          text={zoomObj.text}
          span={zoomObj.span}
          click={zoomObj.click}
        />
      ))}
    </div>
  );
}
