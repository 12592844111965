import React, { useEffect, useState, useContext } from "react";
import Clock from "../../images/clock.png";
import SemiCircle from "../../images/semi-circle.png";
import zoomEllipses from "../../images/zoom-ellipse-2.png";
import { useNavigate } from "react-router-dom";
import "./sectionsix.css";
import { truncateString } from "../adminPages/Vlogs";

export default function SectionSix() {
  const [loading, setLoading] = useState(true);
  const [news, setNews] = useState([]);
  const [events, setEvents] = useState([]);
  const [currentEvents, setCurrentEvents] = useState([]);
  const viewEvents = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_events/view_events",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        // body: JSON.stringify({ token: JSON.parse(admintoken) }),
      }
    );
    const data = await res.json();
    setLoading(false);
    console.log(data);
    const allNews = data?.events;
    // setPendingDeliveries(results?.deliveries);
    let news = [];
    let events = [];
    let monthEvents = [];
    allNews.forEach((item) =>
      item.category === "news"
        ? news.push(item)
        : item.category === "events"
        ? events.push(item)
        : null
    );
    allNews.forEach((item) =>
      item.category === "events" &&
      item.month === new Date().toLocaleString("default", { month: "long" })
        ? monthEvents.push(item)
        : null
    );
    setNews(news);
    setEvents(events);
    setCurrentEvents(monthEvents);
  };

  const values = [1, 2, 3, 4, 5, 6, 7, 8];

  useEffect(() => {
    viewEvents();
  }, []);

  const navigate = useNavigate();
  return (
    <div className="section-six">
      <div className="section-six-content">
        <p className="six-p">
          <strong>Upcoming Events</strong>
        </p>
        <p
          className="view-more-event"
          onClick={() => {
            navigate("/news");
          }}
        >
          View all Events
        </p>
        <div className="event-grid">
          {events?.slice(0, 6).map((item, i) => (
            <EventComponent
              month={item.month}
              title={truncateString(item.event_name, 36)}
              details={truncateString(item.description, 50)}
              time={item.event_time}
              day={item.event_date.slice(0, 2)}
              x={i + 1}
            />
          ))}
          {/* {values?.slice(0, 6).map((item, i) => (
            <EventComponent
              month="Oct"
              title="October Open Day"
              details="Time for Parents to see their kids progress onsite."
              time="10:00am"
              day="08"
            />
          ))} */}
        </div>
      </div>
      <div className="straight-ellipse-event"></div>
      <div className="side-ellipse-event">
        <img src={SemiCircle} />
      </div>
      <div className="zoom-ellipse-event">
        <img src={zoomEllipses} alt="" />
      </div>
    </div>
  );
}

export function EventComponent(props) {
  return (
    <div className={`events-flex item${props.x}`} onClick={props.click}>
      <div className="event-date" style={props.color}>
        <p>{props.day ? props.day : "08"}</p>{" "}
        <p className="underline-day">
          {props.month ? props.month.slice(0, 3) : "Oct"}
        </p>
      </div>
      <div className="actual-event" style={props.style}>
        <div>
          <p className="full-event">
            <strong>{props.title ? props.title : "October Open Day"}</strong>
          </p>
          <p className="event-details">
            {props.details ? props.details : null}
          </p>
        </div>
        <div className="event-time">
          <img src={Clock} alt="" /> <p>{props.time}</p>
        </div>
      </div>
    </div>
  );
}
