import React, { useState, useContext } from "react";
import { BackButton } from "../studentPages/PersonalData";
import { useNavigate, useLocation } from "react-router-dom";
import dark from "../../images/dark.png";
import Delete from "../../images/delete.png";
import Pdf from "../../images/pdf.png";
import dayjs from "dayjs";
import { convertToAmPm } from "./AddBlog";
import { formatDate } from "./AddBlog";
import axios from "axios";
import Popup from "../../components/Popup/Popup";
import Button from "../../components/Button/Button";
import { Button2 } from "../../components/Button/Button";
import { adminContext } from "../context/adminContext";

export default function AddResource() {
  const navigate = useNavigate();
  const location = useLocation();
  const [deletable, setDeletable] = useState(false);
  const values = useContext(adminContext);
  const [loadButton, setLoadButton] = useState(false);
  const [fail, setFail] = useState(false);
  const { admintoken } = values;
  const [category, setCategory] = useState("First");
  const categories = ["First", "Second", "Third"];
  const handleRadio = (e) => {
    setCategory(e.target.value);
  };

  const type = location.state.type;
  const img = location.state.img;
  const title = location.state.title;
  const details = location.state.details;
  const id = location.state.id;
  const pdfName = location.state.pdfName;
  const [time, setTime] = useState("");
  const [expiry, setExpiry] = useState("");
  const [isSelected, setIsSelected] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedSrc, setSelectedSrc] = useState(img);
  const [realTime, setRealTime] = useState("");
  const [realDate, setRealDate] = useState("");
  const [month, setMonth] = useState("");
  const [pdfFile, setPdfFile] = useState(null);

  const [formData, setFormData] = useState({
    title: title,
    body: details,
  });

  const handleDate = (e) => {
    const newDate = dayjs(e.target.value).format("YYYY-MM-DD");
    setExpiry(newDate);
    // console.log(newDate);

    setRealDate(dayjs(e.target.value).format("DD-MM-YYYY"));
    const dayy = new Date(e.target.value);
    setMonth(dayy.toLocaleString("default", { month: "long" }));
  };

  const handleTime = (e) => {
    setTime(e.target.value);

    let timeT = convertToAmPm(e.target.value);
    setRealTime(timeT);
    // console.log(timeT);
  };
  const handleDelete = async (e) => {
    e.preventDefault();
    if (type == 1) {
      const res = await fetch(
        "https://server-olhms.onrender.com/admin_events/delete_event",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: JSON.parse(admintoken),
            event_id: id,
          }),
        }
      );
      setDeletable(false);
      navigate(-1);
    }
  };

  const handleChange = (e) => {
    const target = e.target;

    const { name, value } = target;
    setFormData({ ...formData, [name]: value });
    // console.log(formData);
    const realDate = formatDate(new Date());
    console.log(realDate);
  };
  const onFileChange = (e) => {
    if (type != 1) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState == 2) {
          setSelectedSrc(reader.result);
        }
      };

      reader.readAsDataURL(e.target.files[0]);
      setSelectedFile(e.target.files[0]);
      setIsSelected(true);
    } else {
      const file = e.target.files[0];
      setSelectedFile(file);
      setSelectedSrc(URL.createObjectURL(file));
      // console.log(selectedSrc);
    }
  };

  const handleIgnore = (e) => {
    e.preventDefault();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadButton(true);
    const files = [selectedFile, pdfFile];

    if (id == "" && type == 1) {
      const bodyFormData = new FormData();

      bodyFormData.append("token", JSON.parse(admintoken));
      bodyFormData.append("description", formData.body);
      bodyFormData.append("event_name", formData.title);
      bodyFormData.append("event_time", realTime);
      bodyFormData.append("event_date", realDate);
      bodyFormData.append("category", "events");
      bodyFormData.append("month", month);
      bodyFormData.append("term", category);

      axios
        .post(
          "https://server-olhms.onrender.com/admin_events/create_event",
          bodyFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )

        .then((response) => {
          if (response.status === 200) {
            // navigate(formData.fleet_id !== "" ? "/success" : "/account", {
            //   state: { id: id, token: token },
            // });
            navigate("/admin/news");
            setLoadButton(false);
          } else {
            // setMessage("Error occured");
            // setLoading(false);
            setLoadButton(false);
            setFail(true);
          }
          console.log(response.data);
        })
        .catch((error) => {
          setLoadButton(false);
          setFail(true);
          // console.log(error);
          // setLoading(false);
        });
    } else if (id != "" && type == 1) {
      const bodyFormData = new FormData();

      bodyFormData.append("token", JSON.parse(admintoken));
      bodyFormData.append("description", formData.body);
      bodyFormData.append("event_name", formData.title);
      bodyFormData.append("event_time", realTime);
      bodyFormData.append("event_date", realDate);
      bodyFormData.append("month", month);
      bodyFormData.append("event_id", id);
      bodyFormData.append("term", category);

      axios
        .post(
          "https://server-olhms.onrender.com/admin_events/edit_event",
          bodyFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )

        .then((response) => {
          if (response.status === 200) {
            // navigate(formData.fleet_id !== "" ? "/success" : "/account", {
            //   state: { id: id, token: token },
            // });
            navigate("/admin/news");
            setLoadButton(false);
          } else {
            // setMessage("Error occured");
            // setLoading(false);
            setLoadButton(false);
            setFail(true);
          }
          console.log(response.data);
        })
        .catch((error) => {
          // console.log(error);
          // setLoading(false);
          setLoadButton(false);
          setFail(true);
        });
    } else if (id == "" && type == 0) {
      const bodyFormData = new FormData();

      bodyFormData.append("token", JSON.parse(admintoken));
      bodyFormData.append("title", formData.title);
      for (let i = 0; i < files.length; i++) {
        bodyFormData.append("additional_resourcess", files[i]);
      }

      axios
        .post(
          "https://server-olhms.onrender.com/admin_additional_resources/create_additional_resources",
          bodyFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )

        .then((response) => {
          if (response.status === 200) {
            // navigate(formData.fleet_id !== "" ? "/success" : "/account", {
            //   state: { id: id, token: token },
            // });
            navigate("/admin/blog");
          } else {
            // setMessage("Error occured");
            // setLoading(false);
          }
          console.log(response.data);
        })
        .catch((error) => {
          // console.log(error);
          // setLoading(false);
        });
    } else if (id != "" && type == 0) {
      const bodyFormData = new FormData();

      bodyFormData.append("token", JSON.parse(admintoken));
      bodyFormData.append("title", formData.title);
      bodyFormData.append("additional_resources_id", id);
      for (let i = 0; i < files.length; i++) {
        bodyFormData.append("additional_resourcess", files[i]);
      }
      axios
        .post(
          "https://server-olhms.onrender.com/admin_additional_resources/edit_additional_resources",
          bodyFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )

        .then((response) => {
          if (response.status === 200) {
            // navigate(formData.fleet_id !== "" ? "/success" : "/account", {
            //   state: { id: id, token: token },
            // });
            navigate("/admin/blog");
          } else {
            // setMessage("Error occured");
            // setLoading(false);
          }
          console.log(response.data);
        })
        .catch((error) => {
          // console.log(error);
          // setLoading(false);
        });
    }
  };
  return (
    <div
      className="set-questions"
      style={{
        backgroundColor: "rgb(255, 248, 248)",
        height: "100%",
        alignItems: "flex-start",
      }}
    >
      <div className="set-container">
        <BackButton click={() => navigate(-1)} />
        {type === 0 ? (
          <form className="resources-form" onSubmit={handleSubmit}>
            <div className="blog-element">
              <label>File Title ({formData.title.length}/150)</label>
              <input
                placeholder="Intro.."
                name="title"
                value={formData.title}
                onChange={handleChange}
              />
            </div>
            <div className="form-element">
              <legend>Tap the icon to upload file</legend>
              {pdfFile || pdfName == true ? (
                <div className="vector-div-pdf">
                  <img src={Pdf} alt="" />
                </div>
              ) : (
                <div className="vector-div-dark">
                  <label>
                    <div className="vector">
                      <img src={dark} alt="" />
                      <input
                        type="file"
                        accept="application/pdf"
                        onChange={(event) => setPdfFile(event.target.files[0])}
                      />
                    </div>
                  </label>
                </div>
              )}
              {(pdfFile || pdfName == true) && (
                <p>Selected file: {pdfFile ? pdfFile.name : title}</p>
              )}

              {(pdfFile || pdfName == true) && (
                <div className="show-blog-img">
                  <label>
                    Change File
                    <input
                      type="file"
                      accept="application/pdf"
                      onChange={(event) => setPdfFile(event.target.files[0])}
                    />
                  </label>
                </div>
              )}
            </div>
            <div className="form-element">
              <legend>Tap the icon to upload cover image (optional)</legend>
              {selectedSrc == "" ? (
                <div className="vector-div">
                  <label>
                    <div className="vector">
                      <img src={dark} alt="" />
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg, .gif"
                        onChange={onFileChange}
                      />
                    </div>
                  </label>
                </div>
              ) : (
                <div className="vector-div">
                  <img src={selectedSrc} alt="" className="dark-div-img" />
                </div>
              )}

              {selectedSrc == "" ? null : (
                <div className="show-blog-img">
                  <label>
                    Change Image
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg, .gif"
                      onChange={onFileChange}
                    />
                  </label>
                </div>
              )}
            </div>
            <button className="post-vlog">
              {id == "" ? "Upload" : "Edit"} file
            </button>
          </form>
        ) : type === 1 ? (
          <form className="vlog-form" onSubmit={handleSubmit}>
            <div className="vlog-split">
              <div className="blog-element">
                <label>
                  Event Title/ Headline ({formData.title.length}/150)
                </label>
                <input
                  placeholder="Intro.."
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                />
              </div>

              <div className="blog-element">
                <label>Body of Message ({formData?.body.length}/500)</label>
                <textarea
                  placeholder="Kids go home for the brief 3 days Easter celebration."
                  maxLength="500"
                  className="event-message"
                  style={{ height: "150px" }}
                  name="body"
                  value={formData.body}
                  onChange={handleChange}
                />
              </div>

              <input
                type="date"
                className="date-time"
                value={expiry}
                placeholder="Pick Date"
                onChange={handleDate}
              />

              <input
                type="time"
                className="date-time"
                placeholder="Pick time"
                name="license-expiry"
                value={time}
                onChange={handleTime}
              />
              <div className="category-split">
                <p>Select Term</p>
                <div className="radio-row">
                  {categories.map((item) => (
                    <div className="category-radio">
                      <input
                        type="radio"
                        name="category"
                        value={item}
                        onChange={handleRadio}
                        checked={category == item}
                      />
                      <label>{item}</label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="split-vlog-button">
              <button type="submit" className="post-vlog">
                {id == "" ? "Post" : "Edit"} Event
              </button>
              <button className="vlog-button" onClick={handleIgnore}>
                <div className="delete-vlog">
                  <img src={Delete} />
                </div>
                Delete Event
              </button>
            </div>
          </form>
        ) : null}
      </div>
      <Popup isOpen={deletable}>
        <div className="deletable-div">
          <p>Delete Post</p>
          <div>Are you sure you want to delete this post?</div>
          <div>
            <Button2 name="No" click={() => setDeletable(false)} />
            <Button name="Yes" click={handleDelete} />
          </div>
        </div>
      </Popup>
      <Popup isOpen={loadButton}>
        <p>Uploading File</p>
      </Popup>
      <Popup isOpen={fail}>
        <p>Upload Failed. Try Again</p>
        <Button name="Okay" click={() => setFail(false)} />
      </Popup>
    </div>
  );
}
