import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BackButton } from "../studentPages/PersonalData";
import { Toggle } from "../teacherPages/Resources";
import Passport from "../../images/news1.png";
import { Button2 } from "../../components/Button/Button";
import Edit from "../../images/edit.png";
import Add from "../../images/add.png";
import { adminContext } from "../context/adminContext";
import { capitalizeFirstLetter } from "./StaffDatabase";
import User from "../../images/user.png";

export default function View() {
  const location = useLocation();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(true);
  const [view, setView] = useState(true);
  const type = location.state.num;
  const id = location.state.id;
  const aStaff = location.state.staff;

  const [loading, setLoading] = useState(true);
  const [staff, setStaff] = useState({});
  const values = useContext(adminContext);
  const { admintoken } = values;

  //   console.log(not);
  const [formData, setFormData] = useState({
    firstname: aStaff.firstname,
    middlename: aStaff.middlename == undefined ? '' : aStaff.middlename,
    lastname: aStaff.lastname,
    country: aStaff.country,
    address: aStaff.address,
    phone_no1: aStaff.phone_no1,
    phone_no2: aStaff.phone_no2,
    email: aStaff.email,
    religion: aStaff.religion,
    gender: aStaff.gender,
    center: aStaff.center,
    role: aStaff.role == undefined ? aStaff.roles.toString() : aStaff.role,
    employment_year: aStaff.employment_year
  });

  const [isSelected, setIsSelected] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedSrc, setSelectedSrc] = useState("");
  const handleChange = (e) => {
    const target = e.target;
    // const value = target.type === "select" ? target.selected : target.value;
    // const name = target.name;
    const { name, value } = target;
    setFormData({ ...formData, [name]: value });
    console.log(formData);
  };
  const onFileChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState == 2) {
        setSelectedSrc(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
    setIsSelected(true);
  };

  const viewStaff = async () => {
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_staff/view_single_staff",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(admintoken),
          staff_id: id,
        }),
      }
    );
    const data = await res.json();
    setLoading(false);
    setStaff(data.staff);
    // console.log(data);
    // console.log(id);
  };

  useEffect(() => {
    viewStaff();
  }, []);

  const editStaff = async () => {
    
    const res = await fetch(
      "https://server-olhms.onrender.com/admin_staff/edit_staff",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(admintoken),
          staff_id: id,
          firstname: formData.firstname,
          middlename: formData.middlename,
          lastname: formData.lastname,
          country: formData.country,
          address: formData.address,
          phone_no1: formData.phone_no1,
          phone_no2: formData.phone_no2,
          email: formData.email,
          religion: formData.religion,
          gender: formData.gender,
          center: formData.center,
          role: formData.role,
          employment_year: formData.employment_year
        })
      }
    );
    const data = await res.json();
    setLoading(false);
    setStaff(data.staff);
    setFormData(data.staff);
    console.log(data.staff);
    navigate(-1)
    // console.log(data);
    // console.log(id);
  };


  if (loading === false)
    return (
      <div
        className="set-questions"
        style={{
          backgroundColor: "rgb(255, 248, 248)",
          height: "100%",
          alignItems: "flex-start",
        }}
      >
        <div className="set-container">
          <BackButton
            click={() => (view || type === 2 ? navigate(-1) : setView(true))}
          />
          {type === 0 ? (
            <Toggle
              click1={() => setToggle(true)}
              click2={() => setToggle(false)}
              toggle={toggle}
              text1="Personal Data"
              text2="Result"
            />
          ) : type === 1 ? (
            <p
              style={{
                fontWeight: "600",
                fontSize: "20px",
                alignSelf: "flex-start",
                marginTop: "40px",
              }}
            >
              Personal Data
            </p>
          ) : null}
          {type === 0 ? (
            view ? (
              <div style={{ alignSelf: "flex-end" }}>
                <Button2 name="View Admission Form" />
              </div>
            ) : null
          ) : null}
          {type === 0 ? (
            <div className="show-details" style={{ marginTop: "30px" }}>
              <div>First Name:</div>
              {view ? (
                <div className="light-property">John</div>
              ) : (
                <input
                  className="properties-input"
                  name="firstname"
                  onChange={handleChange}
                  value={formData}
                />
              )}
              <div>Middle Name:</div>
              {view ? (
                <div className="light-property">John</div>
              ) : (
                <input
                  className="properties-input"
                  name="middlename"
                  onChange={handleChange}
                  value={formData.middlename}
                />
              )}
              <div>Last Name:</div>
              {view ? (
                <div className="light-property">John</div>
              ) : (
                <input
                  className="properties-input"
                  name="lastname"
                  onChange={handleChange}
                  value={formData.lastname}
                />
              )}
              <div>Permanent Address:</div>
              {view ? (
                <div className="light-property">John</div>
              ) : (
                <input
                  className="properties-input"
                  name="firstname"
                  onChange={handleChange}
                  value={formData.firstname}
                />
              )}
              <div>Country:</div>
              {view ? (
                <div className="light-property">John</div>
              ) : (
                <input
                  className="properties-input"
                  name="firstname"
                  onChange={handleChange}
                  value={formData.firstname}
                />
              )}
              <div>Parent's Phone Number 1:</div>
              {view ? (
                <div className="light-property">John</div>
              ) : (
                <input
                  className="properties-input"
                  name="firstname"
                  onChange={handleChange}
                  value={formData.firstname}
                />
              )}
              <div>Parent's Phone Number 2:</div>
              {view ? (
                <div className="light-property">John</div>
              ) : (
                <input
                  className="properties-input"
                  name="firstname"
                  onChange={handleChange}
                  value={formData.firstname}
                />
              )}
              <div> Parent's Email:</div>
              {view ? (
                <div className="light-property">John</div>
              ) : (
                <input
                  className="properties-input"
                  name="firstname"
                  onChange={handleChange}
                  value={formData.firstname}
                />
              )}
              <div>Religion:</div>
              {view ? (
                <div className="light-property">John</div>
              ) : (
                <input
                  className="properties-input"
                  name="firstname"
                  onChange={handleChange}
                  value={formData.firstname}
                />
              )}
              <div>Gender:</div>
              {view ? (
                <div className="light-property">John</div>
              ) : (
                <div className="properties-input">
                  <select
                    value={formData.gender}
                    name="gender"
                    onChange={handleChange}
                  >
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>
              )}
              <div>Date of Birth:</div>
              <div className="light-property">03-04-2010</div>
              <div>Passport Photo:</div>
              {view ? (
                <div className="data-img">
                  <img src={Passport} alt="" style={{ objectFit: "cover"}}/>
                </div>
              ) : (
                <div className="teacher-image">
                  {isSelected ? (
                    <div className="data-img">
                      <img src={selectedSrc} alt="" style={{ objectFit: "cover"}}/>
                    </div>
                  ) : null}
                  <div className="add-image">
                    <label>
                      <img src={Add} alt="" />
                      <input
                        required
                        onChange={onFileChange}
                        type="file"
                        accept=".png, .jpg, .jpeg, .gif"
                        name="selectedFile"
                      />
                    </label>
                  </div>
                </div>
              )}
            </div>
          ) : type === 1 ? (
            <div className="show-details" style={{ marginTop: "30px" }}>
              <div>First Name:</div>
              <div className="light-property">{staff?.firstname}</div>
              <div>Middle Name:</div>
              <div className="light-property">{staff?.middlename}</div>
              <div>Last Name:</div>
              <div className="light-property">{staff?.lastname}</div>
              <div>Permanent Address:</div>
              <div className="light-property">{staff?.address}</div>
              <div>Country:</div>
              <div className="light-property">{staff?.country}</div>
              <div>Phone Number 1:</div>
              <div className="light-property">{staff?.phone_no1}</div>
              <div>Phone Number 2:</div>
              <div className="light-property">{staff?.phone_no2}</div>
              <div>Email:</div>
              <div className="light-property">{staff?.email}</div>
              <div>Religion:</div>
              <div className="light-property">{staff?.religion}</div>
              <div>Gender:</div>
              <div className="light-property">
                {capitalizeFirstLetter(staff?.gender)}
              </div>
              <div>Staff ID:</div>
              <div className="light-property">{staff?.staff_id}</div>
              <div>Centre:</div>
              <div className="light-property">
                {capitalizeFirstLetter(staff?.center)}
              </div>
              <div>Role:</div>
              <div className="light-property">
                {capitalizeFirstLetter(staff?.role)}
              </div>
              <div>Employment Year:</div>
              <div className="light-property">{staff?.employment_year}</div>
              <div>Photo:</div>
              <div className="data-img">
                <img src={staff?.img_url ? staff?.img_url : User} alt="" />
              </div>
            </div>
          ) : type === 2 ? (
            <div className="show-details" style={{ marginTop: "30px" }}>
              <div>First Name:</div>
              <input
                className="properties-input"
                name="firstname"
                onChange={handleChange}
                value={formData.firstname}
              />
              <div>Middle Name:</div>
              <input
                className="properties-input"
                name="middlename"
                onChange={handleChange}
                value={formData.middlename}
              />
              <div>Last Name:</div>
              <input
                className="properties-input"
                name="lastname"
                onChange={handleChange}
                value={formData.lastname}
              />
              <div>Permanent Address:</div>
              <input
                className="properties-input"
                name="address"
                onChange={handleChange}
                value={formData.address}
              />
              <div>Country:</div>
              <input
                className="properties-input"
                name="country"
                onChange={handleChange}
                value={formData.country}
              />
              <div>Phone Number 1:</div>
              <input
                className="properties-input"
                name="phone_no1"
                onChange={handleChange}
                value={formData.phone_no1}
                maxLength={11}
              />
              <div>Phone Number 2:</div>
              <input
                className="properties-input"
                name="phone_no2"
                onChange={handleChange}
                value={formData.phone_no2}
                maxLength={11}
              />
              <div>Email:</div>
              <input
                className="properties-input"
                name="email"
                onChange={handleChange}
                value={formData.email}
              />
              <div>Religion:</div>
              <input
                className="properties-input"
                name="religion"
                onChange={handleChange}
                value={formData.religion}
              />
              <div>Gender:</div>
              <div className="properties-input">
                <select
                  value={formData.gender}
                  name="gender"
                  onChange={handleChange}
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
              <div>Staff ID:</div>
              <div className="light-property">{aStaff.staff_id}</div>
              <div>Centre:</div>
              <div className="properties-input">
                <select
                  value={formData.center}
                  name="center"
                  onChange={handleChange}
                >
                  <option value="Emerald">Emerald</option>
                  <option value="Topaz">Topaz</option>
                </select>
              </div>
              <div>Role:</div>
              <input
                className="properties-input"
                name="role"
                onChange={handleChange}
                value={formData.role}
              />
              <div>Employment Year:</div>
              <div className="light-property">{formData.employment_year}</div>
              <div>Photo:</div>
              <div className="teacher-image">
                {isSelected ? (
                  <div className="data-img">
                    <img src={selectedSrc} alt="" />
                  </div>
                ) : null}
                <div className="add-image">
                  <label>
                    <img src={aStaff.img_url} alt="" />
                    <input
                      required
                      onChange={onFileChange}
                      type="file"
                      accept=".png, .jpg, .jpeg, .gif"
                      name="selectedFile"
                    />
                  </label>
                </div>
              </div>
            </div>
          ) : null}
          {type === 0 ? (
            view ? (
              <div className="create-teacher" onClick={() => setView(false)}>
                <img src={Edit} />
              </div>
            ) : (
              <Button2 click={e => {
                e.preventDefault();
                
              }} name="Update & Save Personal Data" />
            )
          ) : type === 2 ? (
            <Button2 click={e => {
                e.preventDefault();
                editStaff();
              }} name="Update & Save Staff Data" />
          ) : null}
        </div>
      </div>
    );
}