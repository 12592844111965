import React, { useState } from "react";
import "./popup.css";

export default function Popup(props) {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      {props.isOpen && (
        <div className="popup-wrapper">
          <div className="popup">
            <div className="popup-content">{props.children}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export function Popup1(props) {
  return props.trigger ? (
    <div className="nedu-popup">
      <div className="nedu-popup-inner">
        <button
          className="nedu-close-btn"
          onClick={() => props.setTrigger(false)}
        >
          close
        </button>
        {props.children}
      </div>
    </div>
  ) : (
    ""
  );
}

export function Popup2(props) {
  return props.trigger ? (
    <div className="nedu-popup">
      <div className="nedu-popup-inner-1">
        <p className="nedu-close-btn" onClick={() => props.setTrigger(false)}>
          x
        </p>
        {props.children}
      </div>
    </div>
  ) : (
    ""
  );
}

export function Popup3(props) {
  return props.trigger ? (
    <div className="nedu-popup">
      <div className="nedu-popup-inner-1">
        {/* <p className="nedu-close-btn" onClick={() => props.setTrigger(false)}>
          x
        </p> */}
        {props.children}
      </div>
    </div>
  ) : (
    ""
  );
}
