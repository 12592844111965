import React from "react";
import { AdmissionComp } from "../../components/Student/NameComponent";
import { AdmissionNav } from "../../components/Student/StudentNav";
import Toddlers from "../../images/toddlers.png";
import BabiesToddlers from '../../images/babies_toddlers.jpg';
import Rec1 from "../../images/rec1.png";
import Rec2 from "../../images/rec2.png";
import Rec3 from "../../images/rec3.png";
import Rec4 from "../../images/rec4.png";
import Rec5 from "../../images/rec5.png";
import Rec6 from "../../images/rec6.png";
import { ClassDetails } from "./Apply";

export default function Baby() {
  const Babyclasses = ["Babies", "Toddlers"];
  const images = [Rec1, Rec2, Rec3, Rec4, Rec5, Rec6];
  return (
    <div>
      <AdmissionComp
        style={{ backgroundImage: `url(${BabiesToddlers})` }}
        title="Babies and Toddlers"
        classes={Babyclasses.map((item, i) => (
          <p key={i}>&#x2022; {item}</p>
        ))}
      />
      <AdmissionNav />
      <ClassDetails images={images} school='babies_toddlers' school_id='642b9a5c94b988f5dd1daa03'/>
    </div>
  );
}
