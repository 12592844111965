import React from "react";
import AdmissionFour from "./admissionSections/AdmissionFour";
import AdmissionOne from "./admissionSections/AdmissionOne";
import AdmissionThree from "./admissionSections/AdmissionThree";
import AdmissionTwo from "./admissionSections/AdmissionTwo";
import FAQ from "./admissionSections/FAQ";
import SectionEleven from "./homepageSections/SectionEleven";
import SectionNine from "./homepageSections/SectionNine";

export default function Admission() {
  return (
    <div>
      <AdmissionOne />
      <AdmissionTwo />
      <AdmissionThree />
      <AdmissionFour />
      <SectionNine />
      <SectionEleven />
      <FAQ />
    </div>
  );
}
