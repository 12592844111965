import React from "react";
import "./button.css";
import ClipLoader from "react-spinners/ClipLoader";

export default function Button(props) {
  return (
    <button
      onSubmit={props.submit}
      className="button-style"
      onClick={props.click}
      disabled={props.disabled}
      style={{
        backgroundColor: props.disabled ? "#686363" : "#E05615",
        border: props.disabled ? "1px solid #686363" : "1px solid #e05615",
      }}
    >
      <span>
        {props.loading ? (
          <ClipLoader color={"white"} loading={props.loading} size={30} />
        ) : (
          props.name
        )}
      </span>
    </button>
  );
}

export function Button2(props) {
  return (
    <button className="button2-style" onClick={props.click} style={props.style} disabled={props.disabled}>
      {props.name}
    </button>
  );
}

export function Button3(props) {
  return (
    <button
      className="button2-style"
      onClick={props.click}
      disabled={props.disabled}
      style={{
        color: "white",
        border: "1px solid white",
      }}
    >
      {props.name}
    </button>
  );
}

// 4tKnight
export function MyButton(props) {
  return (
    <button
      onSubmit={props.submit}
      className="button-style"
      onClick={props.click}
      disabled={props.disabled}
      style={{
        backgroundColor: props.button_color,
        border: props.border_color,
      }}
    >
      <span>
        {props.loading ? (
          <ClipLoader color={"white"} loading={props.loading} size={30} />
        ) : (
          props.name
        )}
      </span>
    </button>
  );
}

