import React from "react";
import "./galleryone.css";

export default function GalleryOne() {
  return (
    <div className="gallery-one">
      <div className="glass-gallery">
        <div className="circle-gallery">
          <p className="gallery-first-p">Explore our School</p>
          <p className="gallery-second-p">
            We pride ourselves in promoting academic, social, physical, and
            character development
          </p>
        </div>
      </div>
    </div>
  );
}
