import React, { useState, useContext } from "react";
import { adminContext } from "../context/adminContext";
import { useNavigate, useLocation } from "react-router-dom";
import { BackButton } from "../studentPages/PersonalData";
import "./uploadresources.css";
import axios from "axios";
import attach from "../../images/attach.png";
import vector from "../../images/Vector.png";
import Button, { Button2 } from "../../components/Button/Button";

export default function UploadResources() {
  const navigate = useNavigate();
  const location = useLocation();
  const fileType = location.state.file;
  const term = location.state.term;
  const values = useContext(adminContext);
  const { teacherData, teachertoken } = values;
  const dataT = JSON.parse(teacherData);
  const week = location.state.week;
  const subject = location.state.subject;
  const id = location.state.id;
  const [category, setCategory] = useState("video");
  const [details, setDetails] = useState("");
  const handleRadio = (e) => {
    setCategory(e.target.value);
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedSrc, setSelectedSrc] = useState("");
  const [pdfFile, setPdfFile] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailsrc, setThumbnailscr] = useState("");
  const [loading, setLoading] = useState(false);
  // const [selectedOption, setSelectedOption] = useState("option1");
  const [title, setTitle] = useState("");
  const [lesson, setLesson] = useState(1);
  const onThumbnail = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState == 2) {
        setThumbnailscr(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
    setThumbnail(e.target.files[0]);
  };

  const handleChange = (e) => {
    setDetails(e.target.value);
  };

  const handleLesson = (e) => {
    setLesson(e.target.value);
  };

  function capitalizeFirstLetters(sentence) {
    // Split the sentence into an array of words.
    const words = sentence.split(" ");

    // Capitalize the first letter of each word.
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }

    // Join the words back into a sentence.
    return words.join(" ");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const week_no = parseInt(week.substring(5));
    // console.log(week_no);

    //     const class_name = dataT?.class_name;

    // const class_name = capitalizeFirstLetters(class_name);
    //
    let class_name = dataT?.class_name;

    if (
      class_name == "infant community 1" ||
      class_name == "infant community 2"
    ) {
      class_name = capitalizeFirstLetters(class_name);
    }

    console.log(
      JSON.stringify({
        token: JSON.parse(teachertoken),
        subject_name: subject,
        class_name: class_name,
        week_no: week_no,
        term: term,
      })
    );

    if (fileType == 0) {
      try {
        const res = await axios.post(
          "https://server-olhms.onrender.com/teacher_subject/add_lesson",
          {
            token: JSON.parse(teachertoken),
            subject_name: subject,
            class_name: class_name,
            week_no: week_no,
            term: term,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json, text/plain, */*",
            },
          }
        );
        // console.log(`--------------->${JSON.stringify(res)} `);
        // const res_data = await res.json();

        // console.log(res_data);

        if (res.status == 200) {
          const files = [selectedFile, thumbnail];
          // console.log(week);
          const bodyFormData = new FormData();

          bodyFormData.append("token", JSON.parse(teachertoken));
          bodyFormData.append("term", term);
          bodyFormData.append("week_no", week_no);
          bodyFormData.append("lesson_no", lesson);
          bodyFormData.append("lesson_type", "video");
          bodyFormData.append("description", details);
          bodyFormData.append("title", title);
          bodyFormData.append("class_name", class_name);
          bodyFormData.append("subject_name", subject);

          for (let i = 0; i < files.length; i++) {
            bodyFormData.append("lesson-materials", files[i]);
          }

          axios
            .post(
              "https://server-olhms.onrender.com/teacher_subject/add_lesson_materials",
              bodyFormData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )

            .then((response) => {
              if (response.status === 200) {
                // navigate(formData.fleet_id !== "" ? "/success" : "/account", {
                //   state: { id: id, token: token },
                // });
                navigate("/teacher/all-resources", {
                  state: { term: term, week: week, subject: subject, id },
                });
                // setLoadButton(false);
                setLoading(false);
              } else if (response["msg"] == "Lesson already exists") {
                alert(
                  "A Lesson already exists here. Add another lesson or delete this current one first to continue with this action"
                );
                setLoading(false);
              } else {
                setLoading(false);
                alert("oOps! An error occured. Please try again");
              }
              console.log(response.data);
            })
            .catch((error) => {
              console.log(`---------in------> ${error}`);
              setLoading(false);
              if (error.response.data["msg"] == "Lesson already exists") {
                alert("Lesson already exists");
              } else {
                alert("oOps! An error occured.");
              }
            });
        } else {
          if (res["msg"] == "Subject not found") {
            alert("Subject not found");
          } else if (res["msg"] == "Lesson already exists") {
            alert("Lesson already exists");
          } else {
            alert("oOps! An error occured. Please try again");
          }
          setLoading(false);
        }
      } catch (error) {
        console.log(`-------out-------->${error} `);
        if (error.response.data["msg"] == "Subject not found") {
          alert("Subject not found");
        } else if (error.response.data["msg"] == "Lesson already exists") {
          alert("Lesson already exists");
        } else {
          alert("oOps! An error occured.");
        }
        setLoading(false);
      }
    } else if (fileType == 1) {
      // console.log(class_name);
      try {
        const res = await fetch(
          "https://server-olhms.onrender.com/teacher_subject/add_lesson",
          {
            method: "POST",
            body: JSON.stringify({
              token: JSON.parse(teachertoken),
              subject_name: subject,
              class_name: class_name,
              week_no: week_no,
              term: term,
            }),
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json, text/plain, */*",
            },
          }
        );
        // const res_data = await res.json();
        // console.log(res_data);

        if (res.status == 200) {
          const bodyFormData = new FormData();

          bodyFormData.append("token", JSON.parse(teachertoken));
          bodyFormData.append("term", term);
          bodyFormData.append("week_no", week_no);
          bodyFormData.append("lesson_no", lesson);
          bodyFormData.append("lesson_type", "class note");
          bodyFormData.append("description", title);
          bodyFormData.append("title", title);
          bodyFormData.append("class_name", class_name);
          bodyFormData.append("subject_name", subject);
          bodyFormData.append("lesson-materials", pdfFile);
          axios
            .post(
              "https://server-olhms.onrender.com/teacher_subject/add_lesson_materials",
              bodyFormData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )

            .then((response) => {
              if (response.status === 200) {
                // navigate(formData.fleet_id !== "" ? "/success" : "/account", {
                //   state: { id: id, token: token },
                // });
                navigate("/teacher/all-resources", {
                  state: { term: term, week: week, subject: subject, id },
                });
                // setLoadButton(false);
                setLoading(false);
              } else if (response.data["msg"] == "Subject not found") {
                alert("Subject not found");
              } else if (response["msg"] == "Lesson already exists") {
                alert(
                  "A Lesson already exists here. Add another lesson or delete this current one first to continue with this action"
                );
                setLoading(false);
              } else {
                setLoading(false);
                alert("oOps! An error occured. Please try again");
              }
              console.log(response.data);
            })
            .catch((error) => {
              console.log(`---------in------> ${error}`);
              setLoading(false);
              if (error.response.data["msg"] == "Subject not found") {
                alert("Subject not found");
              } else if (
                error.response.data["msg"] == "Lesson already exists"
              ) {
                alert("Lesson already exists");
              } else {
                alert("oOps! An error occured.");
              }
            });
        } else {
          // console.log(`-----------here------> ${JSON.stringify(res)} `);
          if (res["msg"] == "Subject not found") {
            alert("Subject not found");
          } else if (res["msg"] == "Lesson already exists") {
            alert("Lesson already exists");
          } else {
            alert("oOps! An error occured. Please try again");
          }
          setLoading(false);
        }
      } catch (error) {
        if (error.response.data["msg"] == "Subject not found") {
          alert("Subject not found");
        } else if (error.response.data["msg"] == "Lesson already exists") {
          alert("Lesson already exists");
        } else {
          alert("oOps! An error occured.");
        }
        setLoading(false);
      }
    } else if (fileType == 3 && category == "video") {
      const bodyFormData = new FormData();

      bodyFormData.append("token", JSON.parse(teachertoken));
      bodyFormData.append("term", term);
      bodyFormData.append("week", week_no);
      bodyFormData.append("lesson_no", lesson);
      bodyFormData.append("lesson_type", "video");
      bodyFormData.append("description", details);
      bodyFormData.append("class_name", class_name);
      bodyFormData.append("subject_name", subject);
      bodyFormData.append("title", title);
      bodyFormData.append("additional_resourcess", selectedFile);
      axios
        .post(
          "https://server-olhms.onrender.com/teacher_additional_resources/create_additional_resources",
          bodyFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )

        .then((response) => {
          if (response.status === 200) {
            // navigate(formData.fleet_id !== "" ? "/success" : "/account", {
            //   state: { id: id, token: token },
            // });
            navigate("/teacher/all-resources", {
              state: { term: term, week: week, subject: subject, id },
            });
            // setLoadButton(false);
            setLoading(false);
          } else {
            // setMessage("Error occured");
            setLoading(false);
            // setLoadButton(false);
            // setFail(true);
          }
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          // setLoadButton(false);
          // setFail(true);
          alert("An error occured. Please try again later");
        });
    } else if (fileType == 3 && category == "note") {
      const bodyFormData = new FormData();

      bodyFormData.append("token", JSON.parse(teachertoken));
      bodyFormData.append("term", term);
      bodyFormData.append("week", week_no);
      bodyFormData.append("lesson_no", lesson);
      bodyFormData.append("lesson_type", "class note");
      bodyFormData.append("description", title);
      bodyFormData.append("class_name", class_name);
      bodyFormData.append("subject_name", subject);
      bodyFormData.append("title", title);
      bodyFormData.append("additional_resourcess", pdfFile);
      axios
        .post(
          "https://server-olhms.onrender.com/teacher_additional_resources/create_additional_resources",
          bodyFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )

        .then((response) => {
          if (response.status === 200) {
            // navigate(formData.fleet_id !== "" ? "/success" : "/account", {
            //   state: { id: id, token: token },
            // });
            navigate("/teacher/all-resources", {
              state: { term: term, week: week, subject: subject, id },
            });
            // setLoadButton(false);
            setLoading(false);
          } else {
            // setMessage("Error occured");
            setLoading(false);
            // setLoadButton(false);
            // setFail(true);
          }
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          // setLoadButton(false);
          // setFail(true);
          alert("An error occured. Please try again later");
        });
    }
  };

  const onFileChange = (e) => {
    if (fileType === 0 || (fileType == 3 && category == "video")) {
      const file = e.target.files[0];
      setSelectedFile(file);
      setSelectedSrc(URL.createObjectURL(file));
      // console.log(selectedSrc);
    }
  };

  return (
    <div className="upload-resources">
      <div className="upload-resources-div">
        <BackButton click={() => navigate(-1)} />
        <div
          className="session-flex"
          style={{
            alignSelf: "flex-start",
            alignItems: "flex-start",
            rowGap: "10px",
          }}
        >
          <div className="cat-session">
            <p>
              {term == "first term"
                ? "First Term"
                : term == "second term"
                ? "Second Term"
                : term == "thrid term"
                ? "Third Term"
                : null}
            </p>
          </div>
          <div style={{ fontSize: "20px" }}>
            {subject} -{" "}
            <span style={{ fontWeight: "300", fontSize: "20px" }}>{week}</span>
          </div>
        </div>

        <form className="resources-form" onSubmit={handleSubmit}>
          {fileType == 3 ? (
            <div className="split-type-3">
              <legend>Select Resource Type</legend>
              <div className="split-type-4">
                <label>
                  <input
                    type="radio"
                    name="category"
                    onChange={handleRadio}
                    value="video"
                    checked={category == "video"}
                  />
                  <span>Video</span>
                </label>
                <label>
                  <input
                    type="radio"
                    name="category"
                    onChange={handleRadio}
                    value="note"
                    checked={category == "note"}
                  />
                  <span>Note</span>
                </label>
              </div>
            </div>
          ) : null}

          <div className="form-element">
            <label>
              {fileType == 0 || (fileType == 3 && category == "video")
                ? "Video"
                : fileType == 1 || (fileType == 3 && category == "note")
                ? "Note"
                : null}{" "}
              Title eg Introduction to Basic Maths equations
            </label>
            <input
              placeholder="Intro.."
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          <div className="split-select">
            <div className="form-element">
              <label>Lesson</label>
              <div className="select-container">
                <select value={lesson} onChange={handleLesson}>
                  <option value={1}>Lesson 1</option>
                  <option value={2}>Lesson 2</option>
                  <option value={3}>Lesson 3</option>
                  <option value={4}>Lesson 4</option>
                  <option value={5}>Lesson 5</option>
                  <option value={6}>Lesson 6</option>
                  <option value={7}>Lesson 7</option>
                </select>
              </div>
            </div>
          </div>

          {fileType == 0 || (fileType == 3 && category == "video") ? (
            <div className="form-element">
              <label>Video Description ({details.length}/300)</label>
              <textarea
                value={details}
                onChange={handleChange}
                maxLength="300"
              />
            </div>
          ) : null}

          {fileType === 1 || (fileType == 3 && category == "note") ? (
            <div className="form-element">
              <legend>Tap the icon to attach and upload file</legend>
              <label>
                <div className="add-video">
                  <div className="attach-icon">
                    <img src={attach} alt="" />
                  </div>
                  <p>Attach file</p>
                  <input
                    type="file"
                    accept="application/pdf"
                    onChange={(event) => setPdfFile(event.target.files[0])}
                  />
                </div>
              </label>
              {pdfFile && (
                <p>Selected file: {pdfFile ? pdfFile.name : title}</p>
              )}
            </div>
          ) : fileType === 0 || (fileType == 3 && category == "video") ? (
            <div className="split-thumbnail">
              <div className="form-element" style={{ maxWidth: "300px" }}>
                <legend>Add Video</legend>
                {selectedSrc == "" ? (
                  <div className="vector-div">
                    <label>
                      <div className="vector">
                        <img src={vector} alt="" />
                        <input
                          type="file"
                          accept=".mp4, .mov, .avi, .wmv, .flv, .mkv, .m4v, .mpeg, .mpg, .webm"
                          onChange={onFileChange}
                        />
                      </div>
                    </label>
                  </div>
                ) : (
                  <>
                    <div className="vector-div">
                      <video
                        src={selectedSrc}
                        controls
                        width="100%"
                        height="100%"
                        // title={formData.title}
                      ></video>
                    </div>
                    <div className="show-blog-img">
                      <label>
                        Change Video
                        <input
                          type="file"
                          accept={
                            ".mp4, .mov, .avi, .wmv, .flv, .mkv, .m4v, .mpeg, .mpg, .webm"
                          }
                          onChange={onFileChange}
                        />
                      </label>
                    </div>
                  </>
                )}
              </div>
              <div className="form-element" style={{ maxWidth: "300px" }}>
                <legend>Upload Thumb</legend>
                {thumbnailsrc == "" ? (
                  <div className="vector-div">
                    <label>
                      <div className="vector">
                        <img src={vector} alt="" />
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg, .gif"
                          onChange={onThumbnail}
                        />
                      </div>
                    </label>
                  </div>
                ) : (
                  <div className="vector-div">
                    <img src={thumbnailsrc} alt="" className="dark-div-img" />
                  </div>
                )}

                {thumbnailsrc == "" ? null : (
                  <div className="show-blog-img">
                    <label>
                      Change Image
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg, .gif"
                        onChange={onThumbnail}
                      />
                    </label>
                  </div>
                )}
              </div>
            </div>
          ) : null}
          <div className="submit-resources">
            <Button
              name="Finish and Upload"
              disabled={loading}
              loading={loading}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
