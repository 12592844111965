import React, { useState, useContext, useEffect } from "react";
import "./resources.css";
import "./setquestions.css";
import "./submitted.css";
import "./resultTeacher.css";
import validated from "../../images/validated.png";
import { Table } from "./CheckSubject";
import { useNavigate } from "react-router-dom";
import { adminContext } from "../context/adminContext";

export default function ResultTeacher() {
  const navigate = useNavigate();
  const [toggle, setToggle] = useState("first");
  const tableValues = ["S/N", "Name", "Student ID", "Position", "Validated"];

  const values = useContext(adminContext);
  const { teacherData, teachertoken } = values;
  const dataT = JSON.parse(teacherData);

  const [loading, setLoading] = useState(true);
  
  const [session, setSession] = useState({});
  const [results, setResults] = useState([]);

  const getSession = async () => {

    const res = await fetch(
      "https://server-olhms.onrender.com/admin_session/view_current_session",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(teachertoken)
        })
      }
    );

    const data = await res.json();
    setLoading(false);
    const session = data?.sessionM;

    viewResults(session);
  }


  const getTerm = term => {
    if(term == 'first'){
      return 'First Term'
    }else if(term == 'second'){
      return 'Second Term';
    }else{
      return 'Third Term';
    }
  }


  function capitalizeWords(sentence) {
    // Split the sentence into an array of words.
    const words = sentence.split(' ');
  
    // Capitalize the first letter of each word.
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
  
    // Join the words back into a sentence.
    return words.join(' ');
  }


  const viewResults = async (session) => {
    setLoading(true);

    let class_name = capitalizeWords(dataT.class_name);

    const res = await fetch(
      "https://server-olhms.onrender.com/teacher_result/view_results",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: JSON.parse(teachertoken),
          session: session.session,
          term: getTerm(toggle),
          class_name: class_name,
          center: dataT.center + " Centre",
        }),
      }
    );
    const data = await res.json();
    setLoading(false);

    
    setResults(data?.results);
    console.log(data);
    setSession(session);
  };

  useEffect(()=>{
    getSession();
  }, [toggle]);

  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (document.documentElement.clientWidth <= 800) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);


  return (
    <div className={mobile ? '' : 'set-questions'} style={mobile ? {margin: 24} : {}}>
      <div className="set-container">
        <div className="session-flex">
          <div className="cat-session">
            <p>{session.session} Session</p>
          </div>
        </div>
        <Triple
          text1="1st Term"
          text2="2nd Term"
          text3="3rd Term"
          toggle={toggle}
          click1={() => {
            setToggle("first");
          }}
          click2={() => {
            setToggle("second");
          }}
          click3={() => {
            setToggle("third");
          }}
        />
        <div className="submitted-students">
          <p>Student List</p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "5px",
            }}
          >
            <Table columns="0.5fr 2fr 1fr 1fr 1fr" data={tableValues} />

            {
                results.map((result, index) => (
                <div
                    key={index}
                    style={{
                      display: "grid",
                      gridTemplateColumns: "0.5fr 2fr 1fr 1fr 1fr",
                      fontWeight: "300",
                      gridGap: "30px",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/teacher/student-result", {state: {result, session, is_validated: result.is_validated}})}
                  >
                  <div>{index + 1}</div>
                  <div>{result.fullname}</div>
                  <div>{result.student_id}</div>
                  <div>{'...'}</div>
                  <div className="validated-div">
                    <img src={result.is_validated ? validated : ''} />
                  </div>
                  
                </div>
                ))
              }
            
          </div>
        </div>
      </div>
    </div>
  );
}

export function Triple(props) {
  return (
    <div className="slider-button">
      <div
        onClick={props.click1}
        className="slider-option"
        id={props.toggle === "first" ? "active" : ""}
      >
        {props.text1}
      </div>
      <div
        className="slider-option"
        id={props.toggle === "second" ? "active2" : ""}
        onClick={props.click2}
      >
        {props.text2}
      </div>
      <div
        onClick={props.click3}
        className="slider-option"
        id={props.toggle === "third" ? "active3" : ""}
      >
        {props.text3}
      </div>
    </div>
  );
}

export function Triple2(props) {
  return (
    <div className="slider-button">
      <div
        onClick={props.click1}
        className="slider-option"
        id={props.toggle === "first" ? "active4" : ""}
      >
        {props.text1}
      </div>
      <div
        className="slider-option"
        id={props.toggle === "second" ? "active4" : ""}
        onClick={props.click2}
      >
        {props.text2}
      </div>
      <div
        onClick={props.click3}
        className="slider-option"
        id={props.toggle === "third" ? "active4" : ""}
      >
        {props.text3}
      </div>
    </div>
  );
}
