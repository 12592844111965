import React, { useState, useContext, useEffect } from "react";
import Passport from "../../images/news1.png";
import { useNavigate } from "react-router-dom";
import { BackButton } from "../studentPages/PersonalData";
import Add from "../../images/addition.png";
import Button, { Button2 } from "../../components/Button/Button";
import { adminContext } from "../context/adminContext";
import axios from "axios";
import Popup from "../../components/Popup/Popup";
import { Popcentral } from "./CreateStaff";

export default function CreateAdmin() {
  const navigate = useNavigate();
  const [create, setCreate] = useState(false);
  const values = useContext(adminContext);
  const { admintoken } = values;
  const [isSelected, setIsSelected] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedSrc, setSelectedSrc] = useState("");

  const [show, setShow] = useState(false);
  const [staffPassword, setStaffPassword] = useState("");
  const [staffId, setStaffId] = useState("");
  const [staffMail, setStaffMail] = useState("");

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstname: "",
    middlename: "",
    lastname: "",
    state: "",
    country: "Nigeria",
    address: "",
    number1: "",
    number2: "",
    email: "",
    religion: "Christian",
    gender: "female",
    centre: "Emerald",
    role: "",
    year: 2019,
  });

  const handleChange = (e) => {
    const target = e.target;

    const { name, value } = target;
    setFormData({ ...formData, [name]: value });
    // console.log(formData);
  };
  const onFileChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState == 2) {
        setSelectedSrc(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
    setIsSelected(true);
  };
  // useEffect(() => {

  //   console.log(years[years.length - 1]);
  // }, []);

  const years = [
    // 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028
    2016,
    2017, 2019, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028,
    2029, 2030, 2031, 2032, 2033, 2034, 2035,
  ];

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  // if (years[years.length - 1] != currentYear) {
  //   years.push(currentYear);
  // }
  // console.log();
  const handleCreate = (e) => {
    setLoading(true);
    e.preventDefault();
    const bodyFormData = new FormData();

    bodyFormData.append("token", JSON.parse(admintoken));
    bodyFormData.append("firstname", formData.firstname);
    bodyFormData.append("lastname", formData.lastname);
    bodyFormData.append("middlename", formData.middlename);
    bodyFormData.append("email", formData.email);
    bodyFormData.append("phone_no1", formData.number1);
    bodyFormData.append("phone_no2", formData.number2);
    bodyFormData.append("address", formData.address);
    bodyFormData.append("state", formData.state);
    bodyFormData.append("country", formData.country);
    bodyFormData.append("religion", formData.religion);
    bodyFormData.append("center", `${formData.centre} Centre`);
    bodyFormData.append("gender", formData.gender);
    bodyFormData.append("employment_year", formData.year);
    bodyFormData.append("roles", [formData.role.toLowerCase()]);
    bodyFormData.append("admin-images", selectedFile);

    // console.log(JSON.parse(admintoken));

    axios
      .post(
        "https://server-olhms.onrender.com/master_admin/add_admin",
        bodyFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      // const data = await res.json();
      // console.log(res.data);
      .then((response) => {
        if (response.status === 200) {
          setCreate(false);
          setLoading(false);
          setShow(true);
          setStaffId(response.data.login_details.staff_id);
          setStaffPassword(response.data.login_details.password);
          setStaffMail(response.data.login_details.email);
        } else {
          alert("An error occured. Please try again");
          setLoading(false);
        }
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error.response.data);
        if (error.response.data["status"] === "error") {
          alert(`${error.response.data["msg"]}`);
        } else {
          alert("oOps! An error occured.");
        }
        setLoading(false);
      });
  };

  const handleIgnore = (e) => {
    e.preventDefault();
    setCreate(true);
  };

  return (
    <div
      className="set-questions"
      style={{
        alignItems: "flex-start",
      }}
    >
      <div className="set-container">
        <BackButton click={() => navigate(-1)} />
        <form
          style={{ marginTop: "50px" }}
          // onSubmit={handleCreate}
          className="show-details-form"
        >
          <div className="show-details">
            <div>First Name:</div>
            <input
              className="properties-input"
              name="firstname"
              onChange={handleChange}
              value={formData.firstname}
            />
            {/* <div>Middle Name:</div>
            <input
              className="properties-input"
              name="middlename"
              onChange={handleChange}
              value={formData.middlename}
            /> */}
            <div>Last Name:</div>
            <input
              className="properties-input"
              name="lastname"
              onChange={handleChange}
              value={formData.lastname}
            />
            <div>Permanent Address:</div>
            <input
              className="properties-input"
              name="address"
              onChange={handleChange}
              value={formData.address}
            />
            <div>Country:</div>
            <input
              className="properties-input"
              name="country"
              onChange={handleChange}
              value={formData.country}
            />
            <div>State:</div>
            <input
              className="properties-input"
              name="state"
              onChange={handleChange}
              value={formData.state}
            />
            <div>Phone Number 1:</div>
            <input
              className="properties-input"
              name="number1"
              onChange={handleChange}
              value={formData.number1}
              maxLength={11}
            />
            <div>Phone Number 2:</div>
            <input
              className="properties-input"
              name="number2"
              onChange={handleChange}
              value={formData.number2}
              maxLength={11}
            />
            <div>Email:</div>
            <input
              className="properties-input"
              name="email"
              onChange={handleChange}
              value={formData.email}
            />
            <div>Religion:</div>
            <input
              className="properties-input"
              name="religion"
              onChange={handleChange}
              value={formData.religion}
            />
            <div>Gender:</div>
            <div className="properties-input">
              <select
                value={formData.gender}
                name="gender"
                onChange={handleChange}
                // value={formData.firstname}
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
            <div>Centre:</div>
            <div className="properties-input">
              <select
                value={formData.centre}
                name="centre"
                onChange={handleChange}
              >
                <option value="Emerald">Emerald</option>
                <option value="Topaz">Topaz</option>
              </select>
            </div>
            <div>Admin Role:</div>
            <input
              className="properties-input"
              name="role"
              onChange={handleChange}
              value={formData.role}
            />
            <div>Employment Year:</div>
            <div className="properties-input">
              <select value={formData.year} name="year" onChange={handleChange}>
                {years.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </div>
            <div>Photo:</div>
            <div className="teacher-image">
              {isSelected ? (
                <div className="data-img">
                  <img
                    src={selectedSrc}
                    alt=""
                    style={{ objectFit: "cover", paddingBottom: "10px" }}
                  />
                </div>
              ) : null}
              <div className="add-image">
                <label>
                  <img src={Add} alt="" />
                  <input
                    // required
                    onChange={onFileChange}
                    type="file"
                    accept=".png, .jpg, .jpeg, .gif"

                    // name="selectedFile"
                  />
                </label>
              </div>
            </div>
          </div>

          <Button2
            name="Add to Admin Database and generate Login Details"
            click={handleIgnore}
          />
        </form>
        <Popup isOpen={create}>
          <div className="central-pop">
            <p>Create Admin Record</p>
            <div className="central-details">
              Are you sure you want to create an admin record for{" "}
              {formData.firstname} {formData.lastname}. Then subsequently
              generate login details
            </div>
            <div className="pop-central">
              <Button2
                name="No"
                click={() => {
                  setCreate(false);
                  setLoading(false);
                }}
              />
              <Button disabled={loading} name="Yes" click={handleCreate} />
            </div>
          </div>
        </Popup>

        <Popup isOpen={show}>
          <div className="central-pop">
            <p>Admin Login Details</p>
            <div className="central-details">
              <p>Staff ID: {staffId}</p>
              <p>Email: {staffMail}</p>
              <p>Password: {staffPassword}</p>
            </div>
            <div className="pop-central">
              <p
                style={{ color: "#E05615", cursor: "pointer" }}
                onClick={() => {
                  setShow(false);
                  navigate("/admin/administrators");
                }}
              >
                Go to Admin Database
              </p>
            </div>
          </div>
        </Popup>
      </div>
    </div>
  );
}
