import React, { useState, useEffect, useContext } from "react";
import {Carousel} from '3d-react-carousal';
import { adminContext } from "../context/adminContext";

import "./carousel.css";
import AdvantageOne from "../../images/class-1.png";
import AdvantageTwo from "../../images/class-2.png";
import Advantage3 from "../../images/class-3.png";
import Advantage4 from "../../images/class-4.png";
import Advantage5 from "../../images/class-5.png";
import Advantage6 from "../../images/class-6.png";
import Advantage7 from "../../images/class-7.png";
import Advantage8 from "../../images/class-8.png";
import Advantage9 from "../../images/class-1.png";
import Advantage10 from "../../images/class-2.png";
import Advantage11 from "../../images/class-3.png";
import Advantage12 from "../../images/class-4.png";
import Advantage13 from "../../images/class-5.png";
import Advantage14 from "../../images/class-6.png";
import Advantage15 from "../../images/class-7.png";
import Advantage16 from "../../images/class-8.png";

export default function CarouselView() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const values = useContext(adminContext);
  const { admintoken } = values;

  const handleNext = () => {
    const nextSlide = currentIndex + 1;
    if (nextSlide < images.length) {
      setCurrentIndex(nextSlide);
    }
  };

  const handlePrev = () => {
    const prevSlide = currentIndex - 1;
    if (prevSlide >= 0) {
      setCurrentIndex(prevSlide);
    }
  };
  const [images, setImages] = useState([
    AdvantageOne,
    AdvantageTwo,
    Advantage3,
    Advantage4,
    Advantage5,
    Advantage6,
    Advantage7,
    Advantage8,
    Advantage9,
    Advantage10,
    Advantage11,
    Advantage12,
    Advantage13,
    Advantage14,
    Advantage15,
    Advantage16,
  ]);

  let slides = [];

  images.map((image, index) => slides.push(<img  src={image} alt={index} />))

  return (
    <div>
      <div className="satisfy" style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 32,
        marginBottom: 32
      }}>Photo Gallery</div>
      <div className="carousel-container" >
      <Carousel slides={slides} autoplay={true} interval={3000}/>
        <div className="carousel">
          {/* {images.map((zoomObj, index) => (
            <div
              className={
                index == currentIndex
                  ? "carousel-item current"
                  : "carousel-item"
              }
              key={index}
            >
              <img src={zoomObj} />
            </div>
          ))} */}
        </div>
      </div>
    </div>
  );
}
