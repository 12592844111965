// ____
// Bug fixes:
/// chat-wrapper class is whats causing the extra cut under
// ____

import React, { useState, useEffect, useRef, useContext } from "react";
import "./support.css";
import { ProfilePix } from "./ProfilePix";

import send_button from "../../../images/send_button.png";
// import sendicon from "../../../images/sendicon.png";
import attachfileicon from "../../../images/attachfileicon.png";
import aang from "../../../images/aang.jpg";
import emptybox from "../../../images/emptybox.png";
import audioFile from "../../../images/new_noti.wav";
import audioFile2 from "../../../images/beep2.wav";

import { adminContext } from "../../context/adminContext";
// import { TokenContext } from "../../../token_context";

import relativeTime from "dayjs/plugin/relativeTime";
import {
  collection,
  query,
  where,
  onSnapshot,
  getDoc,
  doc,
  getDocs,
  QuerySnapshot,
  setDoc,
  addDoc,
  orderBy,
  updateDoc
} from "firebase/firestore";
import { db, storage, serverTimestamp }  from "../../../firebase_setup/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { ClipLoader } from "react-spinners";
import dayjs from "dayjs";

const audio = new Audio(audioFile);

dayjs.extend(relativeTime);

// uploading images to fire store

// flies for audio when sending a message
const audio2 = new Audio(audioFile2);

export default function User_Support() {
  const value = useContext(adminContext);
  // const value = useContext(TokenContext);
  const { setNewMsg, listen, setListen, adminRole_deprecated } = value;
  const adminRole = [localStorage.getItem("role")];
  const admin_id = localStorage.getItem("adminId");
  // const admin_id = JSON.parse(localStorage.getItem("adminId"));
  const admin_name = localStorage.getItem("name");
  // const admin_name = JSON.parse(localStorage.getItem("name"));
  let admin_img = localStorage.getItem("img");
  if(admin_img == undefined) {
    admin_img = '';
  }

  console.log(adminRole);

  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const token = localStorage.getItem("admin_rubbish");
  const [result, setResult] = useState("");
  const [conv_id, setConvId] = useState("");
  const [result2, setResult2] = useState("");
  const [data2, setData2] = useState([]);
  const [profile_pic, setProfilePic] = useState("");
  const [sender_name, setSenderName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_no, setPhoneNO] = useState("");
  const [content, setContent] = useState("");
  const [receiver_id, setReceiverId] = useState("");
  const [sub, setSub] = useState(false);
  const [img, setImg] = useState("");
  const [display, setDisplay] = useState("");
  const allowedRoles = ['Users Customer care', 'master', 'Master Adminstrator'];
  const [firstFire, setFirstFire] = useState(true);
  const [count, setCount] = useState(0);
  const [conversations, setConversation] = useState([]);
  const [admin_ids, setAdminIds] = useState([]);

  const [loading, setLoading] = useState(false);

  const [conversationId, setConversationId] = useState("");

  const [conversationDetails, setConversationDetails] = useState('');
  // const [messageCount, setMessageCount] = useState(null);

  const messagesEndRef = useRef();
  const submitRef = useRef();
  // this is where the conversation on the left side was listenend to
  useEffect(() => {
    const mesRef = collection(db, "conversations");
    const q = query(mesRef, orderBy("latest_message_timestamp", "desc"));
    const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
      const list = [];
      QuerySnapshot?.forEach((doc) => {
        list.push(doc?.data());
        setConversation(list);
      });
    });
    return () => unsubscribe();
  }, [isLoaded === false]);

  // console.log(role);
  // console.log(adminRole);

  useEffect(() => {
    const caller = async () => {
      try {
        const response = await fetch(
          "https://server-olhms.onrender.com/admin_view_ops/help_feedback_admin_ids",
          {
            method: "POST",
  
            body: JSON.stringify({
              token: token.slice(1,-1),
            }),
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json, text/plain, */*",
            },
          }
        );
        // setData(await response.json());
        const resultM = await response.json();
        // let admin_ids = [];
        // let rec_id = "";
        // console.log(resultM);
        // console.log(item.members[0], item.members[1]);
  
        if (resultM.msg === "Success") {
          console.log("data gotten succesfully");
          // populate the admin_is field with the ids gotten from the endpoint
          setAdminIds(resultM.admin_ids);
        } else {
          console.log("somen error occurred");
        }
      } catch (error) {
        console.log(error);
      }
    }
    caller();
  }, [isLoaded === false]);
  

  const Messager = (item, i) => {
    const admin_id = JSON.parse(localStorage.getItem("adminId"));
    const DateConverter = (props) => {
      const timer = item?.timestamp ? dayjs(props.value.seconds * 1000).format('MMM DD, YYYY') : '...';
      return timer;
    };

    const TimeConverter = (props) => {
      console.log(item);
      const timer = item?.timestamp ? dayjs(props.value.seconds * 1000).format('hh:mm a') : '...';
      return timer;
    }
    // let DATE = {};
    // const TimeConverter = (props) => {
    //   // console.log(props)
    //   const date = new Date(props.value);
    //   DATE = {
    //     date: date.toLocaleDateString(),
    //     time: date.toLocaleTimeString(),
    //     combined: `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`,
    //   };
    //   return DATE.time;
    // };
    // const DateConverter = (props) => {
    //   // console.log(props)
    //   const date = new Date(props.value);
    //   DATE = {
    //     date: date.toLocaleDateString(),
    //     time: date.toLocaleTimeString(),
    //     combined: `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`,
    //   };
    //   return DATE.date;
    // };

    let cname = "";
    // console.log(admin_ids, receiver_id);
    const present = admin_ids.some(element => element === item.sender_id);
    // console.log(present, i);
    if (present) {
      cname = "my-chat-outgoing-msgs";
    } else {
      cname = "my-chat-incoming-msgs";
    }
    return (
      <div
        className={
          cname === "my-chat-outgoing-msgs"
            ? "my-chat-shadow-outgoing-msg-container"
            : "my-chat-shadow-incoming-msg-container"
        }
        ref={messagesEndRef}
        key={i}
      >
        <div
          className={
            cname === "my-chat-outgoing-msgs"
              ? "my-chat-shadow-outgoing-msg-wrapper"
              : "my-chat-shadow-incoming-msg-wrapper"
          }
        >
          {/* <p className='date-of-msg'> */}

          <div className={cname}>
            {item.message_type === "image" ? (
              <img
                src={item.content}
                width="100px"
                height=" 100px"
                style={{ marginBottom: "5px" }}
              />
            ) : (
              <p style={{color: "rgba(0, 0, 0, 0.7)"}}>{item?.content}</p>
            )}
          </div>
          <p
            className={
              "my-chat-shadow-date-of-msg"
            }
          >
            <TimeConverter value={item?.timestamp} /> <br />
            <DateConverter value={item?.timestamp} />
          </p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    GetConvers();
    setNewMsg(false);
  }, [isLoaded === false]);


  useEffect(() => {
    let countM = {};
    const check = onSnapshot(
      doc(db, "admin_notifiers", "hf_messages"),
      async (doc) => {
        countM = doc.data();
        if (firstFire == true) {
          setCount(countM.messages_count);
          setFirstFire(false);
        } else {
          if (countM.messages_count !== count) {
            setCount(countM.messages_count);
            console.log("sound played");
            audio.play();
            setNewMsg(true);
          }
        }
      }
    );
  }, [isLoaded === false]);

  const ContentTrimer = (content) => {
    if (content.length > 45) {
      const Mcontent = content.slice(0, 45) + "...";
      return Mcontent;
    } else {
      return content;
    }
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    setNewMsg(false);
  }, [data2, content]);

  // useEffect(() => {
  //   const updateDocument = async () => {
  //     const updateRef = doc(db, "conversations", conversationId);

  //     await updateDoc(updateRef, {
  //       unread_msg_count: 0,
  //     });
  //   };
  //   updateDocument();
  // }, [data2]);

  const handleClick = async (item) => {
    console.log(item.latest_message_sender_id, receiver_id);
    let rec_id = "";
    console.log("data gotten succesfully");
    // populate the admin_is field with the ids gotten from the endpoint
    item.members.forEach(admin => {
      const present = admin_ids.includes(admin);
      if(present === false) {
        return rec_id = admin;
      }
    });
    console.log(item);
    setConvId(item.latest_message_conversation_id);
    setSub(false);
    setProfilePic(item.other_user_img);
    setSenderName(item.other_username);
    setReceiverId(rec_id);
    setPhoneNO(item.other_user_phone);
    setEmail(item.other_user_email);
    //console.log(item._id);
    // this is where i'm trying to update the collections doc
    const mesRef = collection(
      db,
      "hf_collection",
      item?.latest_message_conversation_id,
      item?.latest_message_conversation_id
    );
    const q = query(mesRef, orderBy("timestamp", "asc"));
    const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
      const list = [];
      QuerySnapshot.forEach((doc) => {
        list.push(doc?.data());
        setData2(list);
        setConversationId(item?.latest_message_conversation_id);
        setConversationDetails(item);
      });
    });

    const updateRef = doc(
      db,
      "conversations",
      item?.latest_message_conversation_id
    );

    await updateDoc(updateRef, {
      unread_msg_count: 0,
    });

    try {
      const response = await fetch(
        "https://server-olhms.onrender.com/admin_view_ops/help_feedback_admin_ids",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // setData(await response.json());
      const resultM = await response.json();
      let admin_ids = [];
      let rec_id = "";
      // console.log(resultM);
      // console.log(item.members[0], item.members[1]);

      if (resultM.msg === "Success") {
        console.log("data gotten succesfully");
        // populate the admin_is field with the ids gotten from the endpoint
        item.members.forEach(admin => {
          const present = resultM.admin_ids.includes(admin);
          if(present === false) {
            return rec_id = admin;
          }
        });
        // console.log(item);
        setConvId(item.latest_message_conversation_id);
        setSub(false);
        setProfilePic(item.other_user_img);
        setSenderName(item.other_username);
        setReceiverId(rec_id);
        setPhoneNO(item.other_user_phone);
        setEmail(item.other_user_email);
        //console.log(item._id);
      } else {
        console.log("some error occurred");
      }
    } catch (error) {
      console.log(error);
    }

    // check for the receivers id
    // admin_ids.forEach((admin_id) => {
    // if(admin_id )
    // })
  };

  const GetConvers = async () => {
    let resultM = {};
    try {
      const response = await fetch(
        "https://server-olhms.onrender.com/admin_chat/get_conversations_users",
        {
          method: "POST",

          body: JSON.stringify({
            token: token.slice(1,-1),
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // getData(await response.json());
      resultM = await response.json();
      console.log(resultM);
      setResult(resultM);
      setSub(true);
      setIsLoaded(true);
      setData(resultM.conversations);
      console.log(resultM.conversations[0].latest_message.conversation_id);
      setConvId(resultM.conversations[0].latest_message.conversation_id);
      setProfilePic(resultM.conversations[0].other_user_img);
      setSenderName(resultM.conversations[0].other_username);
      // setReceiverId(resultM.conversations[0]._id);
      setPhoneNO(resultM.conversations[0].other_user_phone);
      setEmail(resultM.conversations[0].other_user_email);
      // setAttachment(resultM)
      console.log(resultM.conversations);
      console.log(resultM);
      console.log(data);
      //   GetMessages();

      if (response.status === 200) {
        console.log("data gotten succesfully");
      } else {
        console.log("some error occurred");
      }

      const response1 = await fetch(
        "https://server-olhms.onrender.com/admin_view_ops/help_feedback_admin_ids",
        {
          method: "POST",

          body: JSON.stringify({
            token: token.slice(1,-1),
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // setData(await response.json());
      const result = await response1.json();
      // let admin_ids = [];
      let rec_id = "";
      // console.log(result);
      // console.log(item.members[0], item.members[1]);

      if (result.msg === "Success") {
        console.log("data gotten succesfully");
        // populate the admin_is field with the ids gotten from the endpoint
        resultM.conversations[0].members.forEach(admin => {
          const present = result.admin_ids.includes(admin);
          if(present === false) {
            return rec_id = admin;
          }
        });
        console.log(rec_id);
        setReceiverId(rec_id);
      } else {
        console.log("some error occurred");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const submitForm = (e) => {
  //   e.preventDefault();
  // };

  // const GetMessages = async () => {
  //   try {
  //       console.log(conv_id);
  //       const response = await fetch(
  //           "https://server-olhms.onrender.com/delivery_agent_chat/get_messages",
  //           {
  //             method: "POST",

  //             body: JSON.stringify({
  //               token: token,,
  //               conv_id: conv_id,
  //               pagec: 1
  //             }),
  //             headers: {
  //               "Content-Type": "application/json",
  //               Accept: "application/json, text/plain, */*"
  //             },
  //           }
  //       )
  //       // getData(await response.json());
  //       const resultM = await response.json();
  //       console.log(resultM)
  //       setData2(resultM.messages);
  //       setResult2(resultM)
  //       console.log(resultM.messages);
  //       console.log(data2);
  //       // setSenderName(resultM.latest_message.sender_name);

  //       if(response.status === 200) {
  //           console.log('data gotten succesfully')
  //           console.log(data2);
  //       } else {
  //           console.log('some error occurred')
  //       }
  //   } catch(error) {
  //       console.log(error);
  //   }
  // }

  // function keyMessage(event) {}

  const SendMessage = async (event) => {
    event.preventDefault();

    if (event.keyCode === 13 && event.shiftKey === false) {
      // shadows sending image to firebase
      let url;
      const contentToDB = content;
      setContent("");
      if (img) {
        const imageRef = ref(
          storage,
          `messageAttachment/${new Date().getTime()}-${img.name}`
        );
        const snap = await uploadBytes(imageRef, img);
        const durl = await getDownloadURL(ref(storage, snap.ref.fullPath));
        url = durl;
      }

      // console.log(url)
      // console.log(img, img.name)

      // const timestamp = Date.now()

      // const docRef = await addDoc(collection(db, "chat-coversation" ), {
      //       content: content,
      //       conv_id: conv_id,
      //       receiver_id: receiver_id,
      //       sender_id: admin_id,
      //       sender_img: admin_img,
      //       sender_name: admin_name,
      //       timestamp: serverTimestamp(),
      //       who_sent: 'admin',
      //       which_user: 'user',
      //       user_id: receiver_id,
      //       media: url || ''
      // });
      //     console.log("Document written with ID: ", docRef.id);
      setContent("");

      // this was setting the doc which means replacing / merging the doc every time

      //! admin_id.replace(/"/g, '')... I'm doing this because when the admin_id is stored in local storage it is stored with "", and this will be writted to firebase with the strings and with out checks on this page, it will not allow things to work properly, so that's why I'm removing it using the replace method

      const timestamp = Date.now();
      await setDoc(
        doc(
          db,
          "hf_collection",
          conversationId,
          conversationId,
          `${timestamp}`
        ),
        {
          content: contentToDB ? contentToDB : url,
          conv_id: conversationId,
          // receiver_id: receiver_id,
          sender_id: admin_id.replace(/"/g, ''),
          sender_img: admin_img || "a",
          sender_name: admin_name,
          timestamp: serverTimestamp(),
          file_name: contentToDB ? 'a' : "IMG_" + timestamp +".jpg",
          who_sent: "admin",
          which_user: "user",
          // user_id: receiver_id,
          // media: url || ''
          message_type: img ? "image" : "text",
        }
      );
      audio2.play();
      setImg("");
      setDisplay("");

      // const updateRef = doc(db, "conversations", conversationId);

      // await updateDoc(updateRef, {
      //   latest_message_content: contentToDB ? contentToDB : url,
      //   latest_message_conversation_id: conversationId,
      //   latest_message_sender_id: admin_id,
      //   latest_message_sender_img: admin_img || "a",
      //   latest_message_sender_name: admin_name,
      //   latest_message_timestamp: timestamp,
      // });

      // // update the messages count field
      // await setDoc(
      //   doc(
      //     db,
      //     "hf_collection",
      //     "statistics"
      //   ),
      //   {messages_count: count + 1}
      // );

      await fetch(
        "https://server-olhms.onrender.com/help_feedback/send_message",
        {
          method: "POST",
          body: JSON.stringify({
            token: token,
            // receiver_id: receiver_id,
            sender_name: admin_name,
            new_conv: false,
            sender_img: admin_img || "a",
            content: contentToDB ? contentToDB : img.name,
            conv_id: conversationId,
            who_sent: "admin",
            which_user: "user",
            user_id: receiver_id,
            message_type: img ? "image" : "text",
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      setImg("");
      setDisplay("");
    } else if (!event.code) {
      setLoading(true);
      let url;
      const contentToDB = content;
      setContent("");
      if (img) {
        const imageRef = ref(
          storage,
          `messageAttachment/${new Date().getTime()}-${img.name}`
        );
        const snap = await uploadBytes(imageRef, img);
        const durl = await getDownloadURL(ref(storage, snap.ref.fullPath));
        url = durl;
      }

      // this was setting the doc which means replacing / merging the doc every time

      const timestamp = Date.now();
      await setDoc(
        doc(
          db,
          "hf_collection",
          conversationId,
          conversationId,
          `${timestamp}`
        ),
        {
          content: contentToDB ? contentToDB : url,
          conv_id: conversationId,
          // receiver_id: receiver_id,
          sender_id: admin_id.replace(/"/g, ''),
          sender_img: admin_img || "a",
          sender_name: admin_name,
          timestamp: serverTimestamp(),
          file_name: contentToDB ? 'a' : "IMG_" + timestamp+".jpg",
          who_sent: "admin",
          which_user: "user",
          // user_id: receiver_id,
          // media: url || ''
          message_type: img ? "image" : "text",
        }
      );
      audio2.play();
      setImg("");
      setDisplay("");

      await fetch(
        "https://server-olhms.onrender.com/help_feedback/send_message",
        {
          method: "POST",
          body: JSON.stringify({
            token: token,
            // receiver_id: receiver_id,
            sender_name: admin_name,
            new_conv: false,
            sender_img: admin_img || "a",
            content: contentToDB ? contentToDB : img.name,
            conv_id: conversationId,
            who_sent: "admin",
            which_user: "user",
            user_id: receiver_id,
            message_type: img ? "image" : "text",
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      setContent("");
      setImg("");
      setDisplay("");
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setImg(e.target.files[0]);
    setDisplay(URL.createObjectURL(e.target.files[0]));
  };
  if (!isLoaded) {
    return (
      <h1 className="my-chat-loading-pages1">
        <ClipLoader color={"orange"} size={100} />
      </h1>
    );
  } else if (adminRole?.find(role => allowedRoles?.includes(role)) == undefined || adminRole?.find(role => allowedRoles?.includes(role)) == null) {

    return (
      <div className='my-chat-parent-container-spec'>
        <div className='my-chat-main-container-spec'>
          <h3>Unauthorized</h3><br></br><br></br>
          <p>Your account is not authorized to access this page</p>
        </div>
      </div>
    )
  } else if (conversations?.length !== 0) {
    // console.log(conv_id);
    return (
      <div>
        <div>
          <div className="my-chat-support-chat">
            <div className="my-chat-chat-wrapper">
              <div className="my-chat-chat-left-side">
                <div className="my-chat-chat-left-side-top">
                  {/* {data?.map((item, i) => (
                    <div
                      className="Chat-profile"
                      onClick={() => {
                        handleClick(item);
                      }}
                    >
                      <div>
                        {ProfilePix(
                          item?.other_user_img,
                          item?.other_username,
                          "User",
                          item?.other_user_email,
                          item?.other_user_phone
                        )}
                      </div>
                      <div className="chat-report">
                        <p className='time-of-msg'>now</p>
                        <img src={report} alt="report flag" />
                        <span>{messageCount?.unread_user_message_count}</span>
                      </div>
                      <p>{ContentTrimer(item?.latest_message?.content)}</p>
                      <p className='no-of-messages'>4</p>
                    </div>
                  ))} */}

                  {/* this is where i'm tryinig to map of the collection to display them on the screen  */}
                  {conversations &&
                    conversations.map((item, i) => (
                      <>
                        {item?.which_user === "user" && (
                          <div
                            className="my-chat-Chat-profile"
                            onClick={() => {
                              handleClick(item);
                            }}
                            key={i}
                          >
                            <div>
                              {ProfilePix(
                                item?.other_user_img,
                                item?.other_username,
                                "User",
                                item?.other_user_email,
                                item?.other_user_phone
                              )}
                            </div>
                            <div className="my-chat-chat-report">
                              <p className="my-chat-time-of-msg">
                                {dayjs(
                                  item?.latest_message_timestamp
                                ).fromNow()}
                              </p>
                              {/* <img src={report} alt="report flag" /> */}
                              {/* <span>{messageCount?.unread_user_message_count}</span> */}
                              {item?.unread_msg_count === 0 ? (
                                ""
                              ) : (
                                <p className="my-chat-unread-message-count">
                                  {item?.unread_msg_count}
                                </p>
                              )}
                            </div>

                            {/* this is where i'm using the message count  */}
                            <p>
                              {" "}
                              {item?.latest_message_sender_name} :{" "}
                              {ContentTrimer(item?.latest_message_content)}
                            </p>
                            {/* <p className="no-of-messages">4</p> */}
                          </div>
                        )}
                      </>
                    ))}
                </div>
              </div>
              <div className="my-chat-chat-right-side">
                {display && (
                  <span className="my-chat-photo_display">
                    {display && (
                      <img src={display} width="100%" height="100%" />
                    )}{" "}
                  </span>
                )}
                <div className="my-chat-shadow-chat-right-side-wrapper">
                  {conversationDetails ? (
                    <div className="my-chat-main-chat-discussion">
                      <div className="my-chat-profile-picture1">
                        <img // src={profile_pic ? profile_pic : aang}
                          src={
                            conversationDetails?.other_user_img === "a" ||
                            conversationDetails?.other_user_img === ""
                              ? aang
                              : conversationDetails?.other_user_img
                          }
                          className="my-chat-support-profile-pic1"
                        />
                      </div>
                      <div className="my-chat-your-profile">
                        <h3>{conversationDetails.other_username}</h3>
                        <h6>{conversationDetails.other_user_email}</h6>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <h3
                        style={{
                          fontSize: "16px",
                          width: "100%",
                          textAlign: "center",
                          margin: "10px 0",
                          fontWeight: "500",
                        }}
                      >
                        Start a Conversation
                      </h3>
                    </div>
                  )}
                  

                  {/* >>>>>>>>>>>> FOCUS HERE */}
                  <div className="my-chat-messages-wrapper">
                    {!data2 ? (
                      <ClipLoader color={"#1FAA08"} size={10} />
                    ) : (
                      <>{data2?.map((item, i) => Messager(item, i))}</>
                    )}
                    

                    {/* >>>>>>>>>>> THIS IS THE COMPONENT RESPONSIBLE FOR THE SEND MESSAGE */}
                    <div className="my-chat-chat-section">
                      {conversationDetails ? (
                        <form
                          // onKeyUp={SendMessage}
                          onSubmit={SendMessage}
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                          }}
                          ref={submitRef}
                        >
                          <div className="my-chat-shadow-typing-bar">
                            {/* <input type="text" placeholder='Type your message here' value={content} onChange={(e) => setContent(e.target.value)}/> */}

                            <textarea
                              disabled={img}
                              role="textbox"
                              placeholder="Type your message..."
                              className="my-chat-shadow-text-area"
                              rows="1"
                              cols="20"
                              typeof="submit"
                              value={content}
                              onChange={(e) => setContent(e.target.value)}
                              onSubmit={SendMessage}
                              onKeyUp={SendMessage}
                            ></textarea>
                          </div>
                          <div className="my-chat-chat-icons">
                            <label
                              htmlFor="img"
                              className="my-chat-shadow-label-text"
                              id={content ? "disabled" : ""}
                            >
                              <img
                                src={attachfileicon}
                                width="30px"
                                height="30px"
                              />
                            </label>
                            <input
                              type="file"
                              name="img"
                              id="img"
                              accept="image/*"
                              onChange={handleChange}
                              style={{ display: "none" }}
                              // onKeyUp={SendMessage}
                              disabled={content}
                            />

                            <div className="my-chat-send-message">
                              <button
                                style={{
                                  backgroundColor: "transparent",
                                  border: "none",
                                  width: "45px",
                                }}
                                disabled={loading}
                                id={!content && !img ? "disabled" : ""}
                              >
                                <img
                                  src={send_button}
                                  onClick={SendMessage}
                                  onSubmit={SendMessage}
                                />
                              </button>
                            </div>
                          </div>
                        </form>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (conversations?.length === 0) {
    return (
      <div style={{ marginTop: "50px" }}>
        <div className="my-chat-found-not">
          <img src={emptybox} alt="not found" />
          <p>No Conversations for this admin</p>
        </div>
      </div>
    );
  }
}
