import React, { useEffect, useRef } from "react";
import "./sectiontwo.css";
import Welcome from "../../videos/welcome-vid.mp4";
import WelcomeImg from "../../images/welcome-img.png";

export default function SectionTwo() {
  // const iframeRef = useRef();
  // useEffect(() => {
  //   const iframe = iframeRef.current;
  //   iframe.setAttribute("src", `${iframe.getAttribute("src")}?autoplay=0`);
  // }, []);
  return (
    <div>
      <div className="section-two">
        <div className="youtube-section-frame">
          <video
            // width="600"
            // height="400"
            src={Welcome}
            controls
            autoPlay={false}
            poster={WelcomeImg}
          ></video>
        </div>
        <div className="youtube-section-text">
          <strong>A Welcome Note</strong>
          <p>
            On behalf of the board, management and other members of our
            exceptional school, I welcome you to our school community. Our
            school prides itself on offering our learners a nurturing
            environment which challenges all of our children to do their very
            best work, to learn and practice the importance of being a
            responsible, respectful and God-fearing person. We have beautiful
            Montessori environments filled with Montessori materials that
            provide a hands-on approach to learning as children work from the
            concrete to abstract in their thinking. Our educators and staff
            members help learners gain independence through making good choices
            while learning and working in multi-aged or single aged classrooms.
            <br />
            <br />
            We look forward to a fulfilled experience with you as you join our
            school community. If you have questions about Our Lady Of Hope
            Montessori School, please do not hesitate to reach out via our
            designated platforms, We will be happy to speak with you about a
            truly wonderful place for your family and children to be in school.
            We are a community of exceptionally different people, welcome!
          </p>
        </div>
      </div>
    </div>
  );
}
<div></div>;
