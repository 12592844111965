import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SubjectComponent } from "../teacherPages/FirstCat";
import { BackButton } from "../studentPages/PersonalData";

export default function SettingsTermWeeks() {

  const navigate = useNavigate();
  const location = useLocation();
  const columns = 2;

  const numbers = [
    "Week 1",
    "Week 2",
    "Week 3",
    "Week 4",
    "Week 5",
    "Week 6",
    "Week 7",
    "Week 8",
    "Week 9",
    "Week 10",
    "Week 11",
    "Week 12",
    "Week 13",
    "Week 14"
  ];

  const term = location.state.term;
  const subject = location.state.subject;
  const id = location.state.id;
  const className = location.state.className;
  const tempAdminToken = location.state.tempAdminToken;
  const center = location.state.center;
  const overClassName = location.state.overClassName;

  // basic responsiveness handling
  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    function handleResize() {
      if (document.documentElement.clientWidth <= 800) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="set-questions">
      <div className={mobile ? '' : "set-container"} style={{ rowGap: "50px" }}>
        <BackButton click={() => navigate(-1)} />
        <div
          className="session-flex"
          style={{
            alignSelf: "flex-start",
            alignItems: "flex-start",
            rowGap: "10px",
          }}
        >
          <div className="cat-session">
            <p>
              {term == "first term"
                ? "First Term"
                : term == "second term"
                ? "Second Term"
                : term == "third term"
                ? "Third Term"
                : null}
            </p>
          </div>
          <div style={{ fontSize: "20px", marginTop: mobile ? 30 : 0, fontWeight: 'bold' }}>{center}</div>
          <div style={{ fontSize: "20px", marginTop: mobile ? 30 : 0 }}>{overClassName}</div>
          <div style={{ fontSize: "20px", marginTop: mobile ? 30 : 0 }}>{term.toUpperCase()}</div>
          <div style={{ fontSize: "20px", marginTop: mobile ? 30 : 0 }}>{subject}</div>
        </div>

        <div className="set-subjects">
          <div className={mobile ? '' : "set-grid"} style={mobile ? {
            
          } : {}}>
            {numbers.map((item, index) => {
              const row = Math.floor(index / columns);

              return (
                <div key={index} style={mobile ? {marginTop: 16} : {}}>
                <SubjectComponent
                  subject={item}
                  click={() =>
                    navigate("/admin/settings-all-resources", {
                      state: {
                        term: term,
                        week: item,
                        subject: subject,
                        id: id,
                        className,
                        tempAdminToken,
                        center,
                        overClassName
                      },
                    })
                  }
                />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
